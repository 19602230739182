import React, { useState, useRef, Fragment } from 'react';
import { useForm, useFieldArray } from "react-hook-form"
import axios from 'axios';

const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}




export default function AddNewButtonTemp({ onSave, nodeId, datas, showError, setNodes, setLoad, load }) {
    const [loading, setLoading] = useState(false);

    const myInputRef = useRef(null);
    const {
        register: interAdd,
        handleSubmit: interAddSubmit,
        clearErrors,
        control,
        reset: interAddReset,

        setValue, getValues,
        formState: { errors: interAddErr }
    } = useForm({
        defaultValues: {
            "button": JSON.parse(datas.api.interactive_btn),
            "template_name": datas.extra.title,
            "header": datas.extra.header_data,
            "body": datas.extra.body,
            "footer": datas.extra.footer
        }
    })
    // [{"title" : ""}]

    const [tempCount, setTempCount] = useState(0);
    const [headerCount, setHeaderCount] = useState(0);
    const [bodyCount, setBodyCount] = useState(0);
    const [footerCount, setFooterCount] = useState(0);
    const [btnTitleCount, setBtnTitleCount] = useState(0);
    const [varHeaderCount, setVarHeaderCount] = useState(0)


    const [varBodyCount, setVarBodyCount] = useState(0)
    const { fields, append, remove } = useFieldArray({ control, name: "button" });

    const onSubmit = (data) => {
        console.log(data)
        if (datas.api.ndsType == "new") {
            const btn_arr = []
            data.button.map(i => {
                btn_arr.push(i.title)
            })


            const newForm = {
                "int_type": "button",
                "header_type": data.header_type,
                "header_data": data.header,
                "body": data.body,
                "footer": data.footer,
                "template_name": data.template_name,
                "auth_key": localStorage.getItem('wa_auth_key'),
                "action": btn_arr,
                "btn_title": "",
            }

            console.log(newForm)


            try {
                axios.post("https://tickmaker.io/api/save-interactive-template", newForm, headers).then(response => {

                    if (response.data.status == "success") {



                        try {
                            axios.post("https://tickmaker.io/api/get-int-template-by-id", {
                                "auth_key": localStorage.getItem('wa_auth_key'),
                                "template_id": response.data.data.template_id,
                                "interactive_type": "button",
                            }, headers).then(res => {
                                if (response.data.status == "success") {
                                    setNodes(nds => nds.map(node => {
                                        if (node.id == nodeId) {
                                            const nodeVal = node.data.value
                                            node.data.label = data.template_name
                                            nodeVal.type = "whatsapp"
                                            nodeVal.api.ndsType = "edit"
                                            let extras = {
                                                "template_id": response.data.data.template_id,
                                                "title": data.template_name,
                                                "int_type": "button",
                                                "header_type": data.header_type,
                                                "header_data": data.header,
                                                "file_name": null,
                                                "body": data.body,
                                                "footer": data.footer,
                                                "is_variable": "0"
                                            }
                                            nodeVal.api.template_id = response.data.data.template_id
                                            nodeVal.api.interactive_btn = JSON.stringify(res.data.data)
                                            nodeVal.extra = extras
                                        }
                                        return node;
                                    }))
                                    setLoad(!load)
                                } else {
                                    console.log(response)
                                }
                                // toast.success(response.data.message)
                            })
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        console.log(response)
                    }
                    // toast.success(response.data.message)
                })
            } catch (error) {
                console.log(error)
                // throw new Error('Failed to fetch day from API');
            }











        }
        // interAdd('listObj', { value: JSON.stringify(newFun(fields)) })
        // onSave(data);
        // interAddReset();
        // setListObj({ "0": "" })
    };
    const addBodyVar = () => {
        if (varBodyCount <= 20) {
            const currentValue = getValues('body');
            const newValue = currentValue + "{{" + (varBodyCount + 1) + "}}";
            setValue('body', newValue.toString());
            setValue('is_variable', "1");
            setVarBodyCount(varBodyCount + 1)
        } else {
            showError("You can add only 20 variable in Body")
        }
    }
    const addHeaderVar = () => {
        if (varHeaderCount < 1) {
            const currentValue = getValues('header');
            const newValue = currentValue + "{{" + (varHeaderCount + 1) + "}}";
            setValue('header', newValue.toString());
            setValue('is_variable', "1");
            setVarHeaderCount(varHeaderCount + 1)
        } else {
            showError("You can add only one variable in header")
        }

    }


    const newFun = (array) => {
        const formattedObject = {};
        array.forEach((item, index) => {
            formattedObject[index.toString()] = item.values;
        });
        return formattedObject
    }

    const btnView = () => {
        if (fields.length <= 2) {
            return (
                <div className="line_sep">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="text-center">
                                <div class="top_ashish_mrg">
                                    <button className="Button_div_box_plu" type='button' onClick={() => append({ title: '', button_id: "6548a4fed2f1a" })}>
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }


    const errorFun = (row_index) => {
        if (interAddErr && interAddErr.button) {
            const btnErr = interAddErr.button;
            if (btnErr[`${row_index}`].title) {
                return (<div className="meg_lat_new">Button text is required</div>)
            }
        }
    }

    const newErr = (type) => {
        if (type == "body") {
            if (interAddErr.body.type == "required") {
                showError("The Body field is required")
            } else if (interAddErr.body.type == "maxLength") {
                return ("Max Length of Body is 1000")
            }
        } else if (type == "template_name") {
            if (interAddErr.template_name.type == "required") {
                showError("The Template Name is required")
            } else if (interAddErr.template_name.type == "maxLength") {
                return ("Max Length of Template Name is 512")
            }
        }
        else if (type == "footer") {
            if (interAddErr.footer.type == "maxLength") {
                return ("Max Length of Footer is 50")
            }
        }

        clearErrors(type)
    }


    const Buttons = ({ index, item }) => {
        const [charCount, setCharCount] = useState(0);
        

        return (
            <div>



                {errorFun(index)}
                <input type="hidden" value={index} {...interAdd(`button.${index}.button_no`)} />
                <input type="hidden" {...interAdd(`button.${index}.button_id`)} />
                <input maxLength={20} key={item.id} {...interAdd(`button.${index}.title`, { required: true, maxLength: 20, 

                    onChange: (e) => setCharCount(e.target.value.length)
                 })} className="form-control mt-2" placeholder='Enter Button Text' />
                <p className="cer_cont">{20 - charCount} / 20 </p>




            </div>
        )
    }
   
    const gettingLen = (name) => {
        if (getValues(name) && getValues(name) != null) {
            return getValues(name).length
        } else {
            return 0
        }
    }

    return (
        <div ref={myInputRef}>
            <form onSubmit={interAddSubmit(onSubmit)}>
                <input {...interAdd("nodeId", { required: true })} value={nodeId} type='hidden' />
                <input {...interAdd("waInterType", { required: true })} value="button" type='hidden' />
                <input {...interAdd("btn_title", { required: true })} value="btn_title" type='hidden' />

                <div className="header_teml">
                    <label className="header_heading"> <i class="fa-regular fa-address-card"></i> Tempalte Name </label>
                    <div className="header_enter_text_box">
                        <input maxLength={512} {...interAdd("template_name", { required: true, maxLength: 512, onChange: (e) => setTempCount(e.target.value.length) })} className="form-control" placeholder="Enter Tempalte Name " />
                        {interAddErr.template_name && newErr("template_name")}
                    </div>

                    <p className="cer_cont">{512 - tempCount} / 512 </p>
                </div>

                <div className="header_teml">
                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header Type</label>
                    <div className="header_enter_text_box">
                        <select className="form-control" {...interAdd("header_type", { required: true })}>
                            <option value="text" >Text</option>
                            <option value="image" >Image</option>
                        </select>
                    </div>
                </div>

                <div>
                    
                </div>
                <div className="header_teml">
                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                    <div className="header_enter_text_box">
                        <input  {...interAdd("header", { onChange: (e) => setHeaderCount(e.target.value.length) })} className="form-control" placeholder="Enter Header" />
                        <div className="text-right">
                            <button className="btn btn-sm ad_var_btn" type='button' onClick={(e) => addHeaderVar()} >
                                <i className="fa fa-plus"></i> Var
                            </button>
                        </div>
                    </div>
                    <p className="cer_cont">{headerCount}  </p>
                </div>

                <div className="body_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                    <div className="body_enter_text_box">
                        <textarea maxLength={1000} {...interAdd("body", { required: true, maxLength: 1000, onChange: (e) => setBodyCount(e.target.value.length) })} className="form-control" placeholder="Enter Body" />
                        {interAddErr.body && newErr("body")}
                        <div className="text-right">
                            <button className="btn btn-sm ad_var_btn" type='button' onClick={(e) => addBodyVar()} >
                                <i className="fa fa-plus"></i> Var
                            </button>
                        </div>
                    </div>

                    <p className="cer_cont">{1000 - bodyCount} / 1000 </p>
                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                    <div className="footer_enter_text_box">
                        <input maxLength={50} {...interAdd("footer", { maxLength: 50, onChange: (e) => setFooterCount(e.target.value.length) })} className="form-control" placeholder="Enter Footer" />
                        {interAddErr.footer && newErr("footer")}
                    </div>
                    <p className="cer_cont">{50 - footerCount} / 50 </p>
                </div>

                <div className="line_ashish" />

                {
                    fields.map((item, index) => {
                        return (
                            <div className='row' key={item.id}>
                                <div className='col-md-10'>
                                   
                                    <Buttons index={index} item={item }/>
                                </div>
                                <div className='col-md-2'>
                                    <button className="btn-danger btn-circle ml-1 mt-2" type='button' onClick={() => remove(index)} >
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
                {btnView()}


                <div class="row">
                    <div class="col-md-12 mb-3">
                        <div class="text-center">
                            <button className="btn btn-success btn-full" type='submit' >{loading ? "Saving..." : "Save"}</button>

                            {loading && <div className="loader">Loading...</div>}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )

}