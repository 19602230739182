import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

import { GetFetcher,  PostFetcher} from '../Flow/api';

const host = "http://oiomni.com:8000/api"
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');


const headers = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
    'Accept': "application/json",
    'mode': 'no-cors',
}

export default function BtnList() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [isLoad, setLoad] = useState(true);



    const [data_num, setDataNum] = useState([]);
    const [data_title, setDataTitle] = useState([]);
    const [isChartView, setChartView] = useState(false);


    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');



    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            localStorage.removeItem('token');
            window.location.href = '/';
        }else {
            getReport();
        }
    },[isLoad])


    async function getReport() {

        const res_data = await PostFetcher(host + '/btnResponse/', headers,{campaign_id: id})

        res_data.data.links.shift();
        res_data.data.links.pop();

        const arr = res_data.data.links.map(item => item.url)
        setTotal(arr);
        setLoad(false)
        setData(res_data.data.data);
    }

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchValue !== '') {
            const filteredData = data.filter((item) => {
                return [item.receiver,item.btn_title ].join('').toLowerCase().includes(searchValue.toLowerCase())
            })
            console.log(filteredData)
            setFilteredResults(filteredData)
        } else {
            setFilteredResults(data)
        }
    }

    async function test(e) {
        const res_data = await PostFetcher(e.target.value, headers,{campaign_id: id})
        res_data.data.links.shift();
        res_data.data.links.pop();

        const arr = res_data.data.links.map(item => item.url)
       
        setTotal(arr);
        setData(res_data.data.data);
    }

    const tableContain = (datas) => {


        return (
                <table className="w-full table_my">
                    <thead className="table_my_th">
                        <tr>
                            <th scope="col" className="py-3 px-6">Details</th>
                            <th scope="col" className="py-3 px-6">Mobiles No</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        datas.map(item => {
                            return (
                                <tr className="table_my_td border-gray-700">

                                    <td className="py-3 px-6 font-semibold  text-sm">
                                        {item.btn_title}<br/>
                                        <p className="text-gray-500">{item.dlr_time}</p>
                                    </td>
                                    <td className="py-3 px-6 font-semibold text-center">{item.receiver}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>  
            )
    }

    return (
        <>

            <div className="mx-auto max-w-5xl pt-6">
                <div className="flex flex-row justify-between w-full ">
                    <div className="relative">
                        <div className="flex absolute  inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <input onChange={(e) => searchItems(e.target.value)}
                            className="search_bg placeholder-gray-800 border border-gray-300 text-black text-sm rounded-lg  block w-full pl-10 p-2.5"
                            placeholder="Search..."
                        />
                    </div>
                </div>
            </div>

            <div className="flex mx-auto max-w-5xl pt-6">
                 {
                        searchInput.length > 1 ? tableContain(filteredResults) : tableContain(data)
                    }
                  
            </div>  


            <div className="mx-auto max-w-5xl pt-6 flex justify-center"> 
                <button 
                    onClick={(e) => test(e)}
                    value={total[0]}
                    className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee]"
                ><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                {
                    total.map((el, i) =>
                        <button 
                            onClick={(e) => test(e)}
                            value={el}
                            className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee] mx-2"
                        >{i+1}</button>
                    )
                }
                <button 
                    onClick={(e) => test(e)}
                    value={total[total.length - 1]}
                    className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee]"
                ><i className="fa fa-arrow-right" aria-hidden="true"></i></button> 
            </div>
        </>
    );
}
