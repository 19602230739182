/* eslint-disable */ 
import axios from 'axios'

const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};

export const GetFetcher = async (url, headers) => {
    return await axios.get(url, {
        'headers':headers
    })
    .then(function (response) {
        if(response.status == 200){
            return response.data
        }
    })
    .catch(function (error) {
        const err = error.response
        if(err.status == 401){
            noAuth()
        }
    });
};

export const PostFetcher = async (url, headers, body) => {
    return await axios.post(url,body, {
        'headers':headers
    })
    .then(function (response) {
        if(response.status == 200){
            return response.data
        }
    })
    .catch(function (error) {
        const err = error.response
        if(err.status == 401){
            noAuth()
        }
    });
};


    