import React from 'react';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from "react-cookie";
import 'react-toastify/dist/ReactToastify.css';
export default function LoginAs() {
    
    const { id } = useParams();
    const [cookies, setCookie] = useCookies(['token', 'user_id', 'auth_key', 'name', 'username', 'wa_auth_key'])

    const handleSubmit = async() => {
        const res = await fetch('https://oiomni.com/api/loginAs', {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                auth_key: id,
            }),
        });
        const data = await res.json();
        if(data.status === 200){
            localStorage.clear(); 



            localStorage.setItem('token', data.token.original.access_token)
            localStorage.setItem('user_id', data.user_details.id)
            localStorage.setItem('auth_key', data.user_details.auth_key)

            localStorage.setItem('name', data.user_details.name)
            localStorage.setItem('username', data.user_details.username)
            localStorage.setItem('wa_auth_key', data.user_details.wa_auth_key)

            
            let expires = new Date()
            expires.setTime(expires.getTime() + (data.token.original.expires_in * 1000))



            setCookie('token', data.token.original.access_token, { path: '/', expires: expires })
            setCookie('user_id', data.user_details.id, { path: '/', expires: expires })
            setCookie('auth_key', data.user_details.auth_key, { path: '/', expires: expires })
            setCookie('name', data.user_details.name, { path: '/', expires: expires })
            setCookie('username', data.user_details.username, { path: '/', expires: expires })
            setCookie('wa_auth_key', data.user_details.wa_auth_key, { path: '/', expires: expires })
       
            toast.success(data.message)
            window.location.href = '/new'
        }else {
            toast.error('Please check your Auth Key');
            localStorage.clear(); 
            window.location.href = '/'
        }
    };

    handleSubmit();

    return (<p><ToastContainer/></p>);
}
