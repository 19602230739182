import React, { useState, useRef, Fragment } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
const wa_authKey = localStorage.getItem('wa_auth_key');


const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};



const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}





export function WaTemplates(props) {
    const [tempVar, setTempVar] = useState({});

    const comp = props.waComponent

    const main_arr = props.mainArr



    const settingInterVar = (type, data_types, index, val) => {
        let i = "index" + String(index)
       
        let t = {}
        t[`${i}`] = { "col": val, "type": data_types }

        if (type == "body") {
            tempVar.body = { ...tempVar.body, ...t }
        } else if (type == "header") {
            tempVar.header = { ...tempVar.header, ...t }
        } else if (type == "button") {
            tempVar.button = { ...tempVar.button, ...t }
        }
        
        setTempVar(tempVar)
    }


    return (
        <Fragment>
            {
                JSON.parse(comp).map(x => {

                    if (x.type == "BODY" && x.example && x.example.length != 0) {
                        return x.example.body_text[0].map((t, index) => {
                            return (
                                <select name={"body" + index} onChange={(e) => settingInterVar("body", "text", index, e.target.value)} required className="form-control">
                                    <option value="" selected disabled>Selected</option>
                                    {main_arr.map((item, key) => <option value={key}>{item}</option>)}
                                </select>
                            )

                        })
                    }

                    else if (x.type == "HEADER" && x.example && x.example.length != 0) {
                        if (x.format == "TEXT") {

                            return x.example.header_text.map((t, index) => {
                                return (
                                    <select name={"header" + index} onChange={(e) => settingInterVar("header", x.type, index, e.target.value)} required className="form-control">
                                        <option value="" selected disabled>Selected</option>
                                        {main_arr.map((item, key) => <option value={key}>{item}</option>)}
                                    </select>
                                )
                            })
                        } else {
                            return x.example.header_handle.map((t, index) => {
                                return (
                                    <select name={"header" + index} onChange={(e) => settingInterVar("header", x.type, index, e.target.value)} required className="form-control">
                                        <option value="" selected disabled>Selected</option>
                                        {main_arr.map((item, key) => <option value={key}>{item}</option>)}
                                    </select>
                                )
                            })
                        }
                    }

                    else if (x.type == "BUTTONS" && x.buttons.length != 0) {
                        let new_var_arr = {}
                        return (
                            <Fragment>
                                {
                                    x.buttons.map((t, index) => {
                                        if (t.type == "URL" && t.example && t.example.length != 0) {
                                            t.example.map((j) => {
                                                return t.example.map((j) => {
                                                    return (
                                                        <select name={"button" + index} onChange={(e) => settingInterVar("button", t.type, index, e.target.value)} required className="form-control">
                                                            <option value="" selected disabled>Selected</option>
                                                            {main_arr.map((item, key) => <option value={key}>{item}</option>)}
                                                        </select>
                                                    )
                                                })
                                            })
                                        }
                                        else if (t.type == "QUICK_REPLY") new_var_arr = { ...new_var_arr, ...t }
                                    })
                                }
                                <textarea name="temp_btn" id="temp_btn">{JSON.stringify([new_var_arr])}</textarea>
                            </Fragment>
                        )
                    }
                })
            }
            <textarea name='tempVar' value={JSON.stringify(tempVar)}>{JSON.stringify(tempVar)}</textarea>
        </Fragment>
    )
}




export function WaInterBtn(props) {
    const myInputRef = useRef(null);
    const [tempVar, setTempVar] = useState({});
    const comp = props.waComponent
    const main_arr = props.mainArr

    const btnQuery = useQuery({
        queryKey: ['inter_details'],
        queryFn: async () => {
            try {
                const response = await axios.post("http://whatsapp.bulk24sms.com/api/get-int-template-by-id",{
                    "auth_key": wa_authKey,
                    "template_id": comp.template_id,
                    "interactive_type": comp.int_type,
                }, headers);
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },
    })


    const settingInterVar = (type, data_types, index, val) => {
        let i = "index" + String(index)
        
        let t = {}
        t[`${i}`] = { "col": val, "type": data_types }

        if (type == "body") {
            tempVar.body = { ...tempVar.body, ...t }
        } else if (type == "header") {
            tempVar.header = { ...tempVar.header, ...t }
        } else if (type == "button") {
            tempVar.button = { ...tempVar.button, ...t }
        }
        console.log(tempVar)

        const main_box = myInputRef.current
        main_box.querySelector('#tempVar').value = JSON.stringify(tempVar)
        setTempVar(tempVar)
    }

    const main_fun = () => {
        if (comp.is_variable == 1) {
            const headVarCount = (comp.header_data.match(/\{{\d+\}}/g) || []);
            const bodyVarCount = (comp.body.match(/\{{\d+\}}/g) || []);

            const headerFun = () => {
                if (comp.header_type == "text" && headVarCount.length != 0) {
                    return headVarCount.map((t, index) => {
                        return (
                            <select name={"header" + index} onChange={(e) => settingInterVar("header", "text", index, e.target.value)} required className="form-control">
                                <option value="" selected disabled>Selected</option>
                                {main_arr.map((item, key) => <option value={key}>{item}</option>)}
                            </select>
                        )
                    })
                }
            }

            const bodyFun = () => {
                if (bodyVarCount.length != 0) {
                    return bodyVarCount.map((t, index) => {
                        return (
                            <select name={"body" + index} onChange={(e) => settingInterVar("body", "text", index, e.target.value)} required className="form-control">
                                <option value="" selected disabled>Selected</option>
                                {main_arr.map((item, key) => <option value={key}>{item}</option>)}
                            </select>
                        )
                    })
                }
            }

            return (
                <Fragment>
                    {headerFun()}
                    {bodyFun()}
                </Fragment>
            )
        } 
    }


    return (
        <div ref={myInputRef}>
            {main_fun()}
            <textarea name='temp_btn' id='temp_btn' value={JSON.stringify(btnQuery.data)}>{JSON.stringify(btnQuery.data)}</textarea>
            <textarea name='tempVar' id='tempVar' value={JSON.stringify(tempVar)}>{JSON.stringify(tempVar)}</textarea>
        </div>
    )
    



}
