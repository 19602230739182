/* eslint-disable */
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';

const host = "http://oiomni.com/api"

export default function Signup() {
    const { register, handleSubmit, formState: { errors } } = useForm();

    async function formSubmit(datas) {
        await axios.post(host + "/register", datas).then(function (res) {
            if (res.status == 200) window.location.href = '/'
        })
            .catch(function (error) {
                const err = error.response.data
                if (err.status == 401) {
                    toast.error(err.message)
                } else if (err.status == 403) {
                    Object.values(err.message).map(val => {
                        toast.error(val[0])
                    })
                }
            })
    }



    return (
        <div className="body_color">
            <div className="form_box drop-shadow-xl">
                <div className=" min-h-full items-center justify-center ">
                    <div className="w-full max-w-md space-y-8">
                        <h2 className=" text-center text-3xl font-bold tracking-tight text-gray-900">Signup</h2>
                        <form className="mt-8 space-y-6" onSubmit={handleSubmit(formSubmit)}>

                            <div className="flex">
                                <div className="-space-y-px rounded-md p-1">
                                    <input
                                        placeholder="Enter Name" {...register("name", { required: true })}
                                        type="text"
                                        className="relative block w-full appearance-none rounded-md border_new px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                                    />
                                    {errors.name && <span className="font-sans text-xs text-red-800 p-1">
                                        Name is required
                                    </span>}
                                </div>

                                <div className="-space-y-px rounded-md p-1">
                                    <input
                                        placeholder="Enter Username" {...register("username", { required: true })}
                                        type="text"
                                        className="relative block w-full appearance-none rounded-md border_new px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                                    />
                                    {errors.username && <span className="font-sans text-xs text-red-800 p-1">
                                        UserName is required
                                    </span>}
                                </div>
                            </div>


                            <div className="flex">
                                <div className="-space-y-px rounded-md p-1">
                                    <input
                                        placeholder="Enter Email" {...register("email", { required: true })} type="email"
                                        className="relative block w-full appearance-none rounded-md border_new px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                                    />
                                    {errors.email && <span className="font-sans text-xs text-red-800 p-1">
                                        Email is required
                                    </span>}
                                </div>

                                <div className="-space-y-px rounded-md p-1">
                                    <input
                                        placeholder="Enter Mobile Number" {...register("contact_number", { required: true })} type="text"
                                        className="relative block w-full appearance-none rounded-md border_new px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                                    />
                                    {errors.contact_number && <span className="font-sans text-xs text-red-800 p-1">
                                        Contact Number is required
                                    </span>}
                                </div>
                            </div>


                            <div className="flex">
                                <div className="-space-y-px rounded-md p-1">
                                    <input
                                        placeholder="Enter Password" {...register("password", { required: true })} type="password"
                                        className="relative block w-full appearance-none rounded-md border_new px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                                    />
                                    {errors.password && <span className="font-sans text-xs text-red-800 p-1">
                                        Password is required
                                    </span>}
                                </div>
                                <div className="-space-y-px rounded-md p-1">
                                    <input
                                        placeholder="Enter Confirm Password" {...register("conf_password", { required: true })} type="password"
                                        className="relative block w-full appearance-none rounded-md border_new px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                                    />
                                    {errors.conf_password && <span className="font-sans text-xs text-red-800 p-1">
                                        Confirm Password is required
                                    </span>}
                                </div>
                            </div>


                            <button type="submit"
                                className="group relative btn_bg flex w-full  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-1 focus:ring-teal-600 focus:ring-offset-2"
                            >
                                <i className="fa fa-user-plus pr-1" aria-hidden="true"></i>Sign Up
                            </button>

                            <div className="login_btn">
                                <Link to="/" ><i className="fa fa-user-circle pr-1" aria-hidden="true"></i>Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}