import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

import { GetFetcher,  PostFetcher} from '../Flow/api';

const host = "http://oiomni.com:8000/api"; const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');


const headers = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
    'Accept': "application/json",
    'mode': 'no-cors',
}

export default function LogInstant() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [isLoad, setLoad] = useState(true);

    const [btnRes, setBtnRes] = useState({})
    const [btnResModel, setBtnResModel] = useState("")





    const [data_num, setDataNum] = useState([]);
    const [data_title, setDataTitle] = useState([]);
    const [isChartView, setChartView] = useState(false);


    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');



    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            localStorage.removeItem('token');
            window.location.href = '/';
        }else {
            getReport();
        }
    },[isLoad])


    async function getReport() {

        const res_data = await PostFetcher(host + '/logInstant/', headers,{campaign_id: id})

        var data2 = res_data.data.data2
        

        let b = [];
        b.push(["Status", "Count"])
        data2.map(x => {
            b.push([x.status, x.total])
        })






        res_data.data.data.links.shift();
        res_data.data.data.links.pop();

        const arr = res_data.data.data.links.map(item => item.url)

        var by_btn_title = res_data.data.btn_title

        setBtnRes(by_btn_title)
        var by_number = res_data.data.number
        let a = [];
        a.push(["Button Title", "Count"])
        Object.keys(by_btn_title).map(x => {
            a.push([x, by_btn_title[x].length])
        })
        

        setTotal(arr);
        setLoad(false)
        setDataTitle(a);
        setDataNum(b);
        setData(res_data.data.data.data);
    }

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchValue !== '') {
            const filteredData = data.filter((item) => {
                return [item.receiver,item.msg_id ].join('').toLowerCase().includes(searchValue.toLowerCase())
            })
            console.log(filteredData)
            setFilteredResults(filteredData)
        } else {
            setFilteredResults(data)
        }
    }

    async function test(e) {
        const res_data = await PostFetcher(e.target.value, headers,{campaign_id: id})

        res_data.data.data.links.shift();
        res_data.data.data.links.pop();

        const arr = res_data.data.data.links.map(item => item.url)
       
        setTotal(arr);
        setData(res_data.data.data.data);
    }

    const tableContain = (datas) => {
        return datas.map((item) => {
            return (
                <tr className="table_my_td border-gray-700">
                    <td className="py-3 px-6 font-semibold  text-sm ">
                        {item.msg_id}<br/>
                        <p className="text-gray-500">
                            {item.dlr_time}
                        </p>
                    </td>
                    <td className="py-3 px-6 font-semibold text-center">
                        {item.receiver}
                    </td>
                    <td className="py-4 px-6 text-center">
                        {item.status}
                    </td>
                    

                    <td className=" text-center font-semibold">
                    {
                        item.response == 1 ? (
                            <button className="text-[#35bb9e]">
                                <Link to={"/statistics/" + item.msg_id}>
                                    Response
                                </Link>
                                
                            </button>
                        ) : (
                            <button className="text-[#a94442]" >
                                No Response
                            </button>
                        )
                        
                    }
                    </td>
                    {/*<td className="py-4 px-6 text-center">
                        {item.description}
                    </td>*/}
                </tr>
            )
        })
    }

    const btn_num = (value_title) => {

        return (
            <>
                <div className="justify-center  flex  fixed inset-0 z-50 ">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            


                            <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => {setBtnResModel("")}}
                                >
                                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <div className="relative p-4 flex-auto">
                                <table className="w-full table_my">
                                    <thead className="table_my_th">
                                        <tr>
                                            <th scope="col" className="py-3 px-6">Mobiles No</th>
                                            <th scope="col" className="py-3 px-6"> Response Time</th>                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            btnRes[value_title].map((item) => {
                                                return (
                                                    <tr className="table_my_td border-gray-700">
                                                        <td className="py-3 px-6 font-semibold text-center">
                                                            {item.receiver}
                                                        </td>
                                                        <td className="py-4 px-6 text-center">
                                                            {item.dlr_time}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
        );  
    }

    return (
        <>

            <div className="mx-auto max-w-5xl pt-6">
                <div className="flex flex-row justify-between w-full ">
                    <button 
                        onClick={(e) => setChartView(!isChartView)}
                        type="button"
                        className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee]"
                    ><i className="fa fa-pie-chart mr-1" aria-hidden="true"></i>Chart</button>

                    <div className="relative">
                        <div className="flex absolute  inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <input onChange={(e) => searchItems(e.target.value)}
                            className="search_bg placeholder-gray-800 border border-gray-300 text-black text-sm rounded-lg  block w-full pl-10 p-2.5"
                            placeholder="Search..."
                        />
                    </div>
                </div>
            </div>
            {btnResModel == "" ? ("") : btn_num(btnResModel)}

            {
                isChartView == false ? ("") : (

                    <div className="flex mx-auto max-w-5xl mt-4 justify-between rounded-2xl p-2 bg-white border-2 border-[#1286a8]">
                        <div>
                            <Chart
                                chartType="PieChart"
                                data={data_title}
                                options={{title: "Button Click", is3D: true}}
                                width={"100%"}
                                height={"300px"}
                            />


                            <div className="flex">
                                {
                                    Object.keys(btnRes).map((item) => {
                                        return <button 
                                            onClick={(e) => setBtnResModel(item)}
                                            type="button"
                                            className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee]"
                                        ><i className="fa fa-pie-chart mr-1" aria-hidden="true"></i>{item}</button>
                                    })
                                }
                            </div>
                        </div>


                        <div>
                            <Chart
                                chartType="PieChart"
                                data={data_num}
                                options={{title: "Count By Status", is3D: true}}
                                width={"100%"}
                                height={"300px"}
                            />
                            
                        </div>
                    </div>
                )
            }

            <div className="flex mx-auto max-w-5xl pt-6">
                <table className="w-full table_my">
                    <thead className="table_my_th">
                        <tr>
                            <th scope="col" className="py-3 px-6">Message ID</th>
                            <th scope="col" className="py-3 px-6">Mobiles No</th>
                            <th scope="col" className="py-3 px-6">Status</th>
                            <th scope="col" className="py-3 px-6">Response</th>
                            {/*<th scope="col" className="py-3 px-6">Description</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        { searchInput.length > 1 ? tableContain(filteredResults) : tableContain(data)}
                    </tbody>
                </table>
            </div>  


            <div className="mx-auto max-w-5xl pt-6 flex justify-center"> 
                <button 
                    onClick={(e) => test(e)}
                    value={total[0]}
                    className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee]"
                ><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                {
                    total.map((el, i) =>
                        <button 
                            onClick={(e) => test(e)}
                            value={el}
                            className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee] mx-2"
                        >{i+1}</button>
                    )
                }
                <button 
                    onClick={(e) => test(e)}
                    value={total[total.length - 1]}
                    className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee]"
                ><i className="fa fa-arrow-right" aria-hidden="true"></i></button> 
            </div>
        </>
    );
}
