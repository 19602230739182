




import axios from 'axios';
import { useState, useRef, useEffect, Fragment } from 'react';
import { useForm } from "react-hook-form";

import SelectOptions from '../components/SelectOptions';

const host = "https://oiomni.com/api"
const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}

let searchTerm = /\{#var#\}/g;

export default function VoiceBox({ setNodes, apisData, id, bsspSender, bsspTemp, eventData }) {
    const [refresher, setRefresher] = useState(false)



    const [voiceType, setVoiceType] = useState(apisData.voice_type)
    const [content, setContent] = useState(apisData.content)
    const [route, setRoute] = useState(apisData.content)
    const textareaRef = useRef(null);

    const { register } = useForm();




    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        // textarea.style.height = 'auto'; 
        // textarea.style.height = `${textarea.scrollHeight}px`;
    };


    const MainBox = () => {
        if (voiceType != "") {
            if (voiceType == "file") {
                return (
                    <Fragment>
                        <input type="file" className="form-control" />
                    </Fragment>
                )
            } else if (voiceType == "text") {
                return (
                    <Fragment>
                        <textarea
                            className="form-control" ref={textareaRef} defaultValue={content}
                            {...register("content", { value: content, onChange: (e) => contentSet(e.target.value) })} style={{ overflow: 'hidden', resize: 'none' }}
                        />
                    </Fragment>
                )
            }
        }
    }






    const routeSet = (val) => {

        setRoute(val)
        setNodes(nds => nds.map(node => {
            if (node.id == id) {
                node.data.value.api.route = val
            }
            return node;
        }))

        setRefresher(!refresher)
    }

    const contentSet = (val) => {
        setContent(val)
        setNodes(nds => nds.map(node => {
            if (node.id == id) {
                node.data.value.api.content = val
            }
            return node;
        }))

        setRefresher(!refresher)
    }


    const typeSet = (val) => {
        setVoiceType(val)
        setNodes(nds => nds.map(node => {
            if (node.id == id) {
                node.data.value.api.voice_type = val
            }
            return node;
        }))
        setRefresher(!refresher)
    }





    return (
        <div className="card-body mt-0">
            <div className="contact_add_div">
                <div className="mx-auto">
                    <select className="form-select" {...register("voice_type", { onChange: (e) => typeSet(e.target.value) })}>
                        <option value="" disabled selected>Select Voice Type</option>
                        <option value="text">Text</option>
                        <option value="file">File</option>
                    </select>
                    <MainBox />
                    <label><input {...register("route", { onChange: (e) => routeSet(e.target.value) })} type="radio" value="TR" />Trans</label>
                    <label><input {...register("route", { onChange: (e) => routeSet(e.target.value) })} type="radio" value="PR" />Promo</label>
                </div>
            </div>
        </div>
    );
}