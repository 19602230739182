import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './sb-admin-2.min.css';
import './ashish-css.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App.jsx';
import { BrowserRouter } from "react-router-dom";


import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
const queryClient = new QueryClient();

//<React.StrictMode>

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    // </QueryClientProvider>
);