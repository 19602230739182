export default function Spinner() {


    return (
        <div className="flex justify-center items-center h-screen">
            <div className="relative inline-flex">
                <div className="w-16 h-16 bg-[#1286a8] rounded-full"></div>
                <div className="w-16 h-16 bg-[#1286a8] rounded-full absolute top-0 left-0 animate-ping"></div>
                <div className="w-16 h-16 bg-[#1286a8] rounded-full absolute top-0 left-0 animate-pulse"></div>
				
            </div>
        </div>
    )
}