




import axios from 'axios';
import { useState, useRef, useEffect, Fragment } from 'react';
import { useForm } from "react-hook-form";

import SelectOptions from '../components/SelectOptions';

const host = "https://oiomni.com/api"
const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}


export default function SaveFunnelBox({ setNodes, apisData, id, eventData, funnelQuery }) {
    const [refresher, setRefresher] = useState(false)



    const [funnelDetail, setFunnelDetail] = useState(apisData.funnelDetail)
    const [funnelName, setFunnelName] = useState("")

    const textareaRef = useRef(null);

    const { register } = useForm();




    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        // textarea.style.height = 'auto'; 
        // textarea.style.height = `${textarea.scrollHeight}px`;
    };


    console.log(funnelQuery)









    // const typeSet = (val) => {
    //     setVoiceType(val)
    //     setNodes(nds => nds.map(node => {
    //         if (node.id == id) {
    //             node.data.value.api.voice_type = val
    //         }
    //         return node;
    //     }))
    //     setRefresher(!refresher)
    // }



    //  {funnelQuery.data.data.segment_list.map(x => x.id == event.data.value.api.funnelDetail ? <h6>{x.segment_name} Funnel is Setted</h6> : "")}

    return (
        <div className="card-body mt-0">
            <div className="contact_add_div">
                <div className="mx-auto">

                    <p>{funnelDetail}</p>
                    <p>{funnelName}</p>





                    <select className="form-select" {...register("voice_type")}>
                        <option value="" disabled selected>Select Voice Type</option>

                        {
                            funnelQuery.data.segment_list.map(i => {
                                return (
                                    <option value={i.id}>{i.segment_name}</option>
                                )
                            })
                        }







                    </select>
                    {/* 
                    <MainBox />
                    <label><input {...register("route", { onChange: (e) => routeSet(e.target.value) })} type="radio" value="TR" />Trans</label>
                    <label><input {...register("route", { onChange: (e) => routeSet(e.target.value) })} type="radio" value="PR" />Promo</label> */}
                </div>
            </div>
        </div>
    );



}