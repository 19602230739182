import React, { useState, useRef, Fragment } from 'react';
import { useForm, useFieldArray } from "react-hook-form"
import axios from 'axios';
import ReactDOM from 'react-dom/client';
const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}




export default function PreviewComp({ flow }) {

    const myInputRef = useRef(null);


    const ViewFunction = ({ type, datas, allNodes, nodeId }) => {
        console.log(datas)

        if (datas.type == "on_message") {
            return (
                <Fragment>
                    {
                        Object.values(datas.api.keywords).map(i => {

                            
                            return (

                                <RightBlock type="on_message" btnType="" data={i} datas={datas} />

                            )
                        })
                    }
                </Fragment>
            )
        } else if (datas.type == "whatsapp") {

            const item = datas.extra
            if (datas.api.whatsapp_type == "interactive") {
                const buttonRes = (btnData, parent, typo) => {
                    flow.edges.map(i => {
                        console.log(parent, i.source)
                        if (parent == i.source) {

                            const splitHandle = i.data.extra.srcHandle.split("-")
                            console.log(splitHandle)
                            console.log(btnData)
                            if (typo == "list" && i.data.extra.srcHandle.split("-")[1] == btnData.row_title) {
                                const newDiv = document.createElement('div');
                                if (myInputRef.current) {
                                    myInputRef.current.appendChild(newDiv);
                                    ReactDOM.createRoot(newDiv).render(
                                        <Fragment>
                                            <RightBlock type="reply" btnType="list" data={btnData} datas={datas} />
                                            <MainViewByChild allNodes={allNodes} child={i.target} type="left" datas={datas} />
                                        </Fragment>
                                    );
                                }
                            } else if (typo == "button" && i.data.extra.srcHandle.split("-")[1] == btnData.title) {
                                const newDiv = document.createElement('div');
                                if (myInputRef.current) {
                                    myInputRef.current.appendChild(newDiv);
                                    console.log(i.target)
                                    ReactDOM.createRoot(newDiv).render(
                                        <Fragment>
                                            <RightBlock type="reply" btnType="button" data={btnData} datas={datas} />
                                            <MainViewByChild allNodes={allNodes} child={i.target} type="left" datas={datas} />
                                        </Fragment>
                                    );
                                }
                            }
                        }
                    })

                }

                const ButtonFunc = ({ i }) => {
                    const main_arr = JSON.parse(datas.api.interactive_btn)
                    if (!main_arr || main_arr == null) {
                        console.log(datas)
                        return;
                    }


                    return main_arr.map((x, sec_ind) => {
                        if (datas.api.interactive_type == "list") {
                            return (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='flex mb-2'>
                                            <div className="Button_div_box ">
                                                <p onClick={(e) => buttonRes(x, nodeId, "list")}> {x.row_title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else if (datas.api.interactive_type == "button") {
                            return (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='flex mb-2'>
                                            <div className="Button_div_box ">
                                                <p onClick={(e) => buttonRes(x, nodeId, "button")}> {x.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }


                const header_fun = (i) => {
                    if (i.header_data != "" && i.header_data != null) {
                        if (i.header_type == "text") {
                            return (
                                <div className="header_teml">
                                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                    <div className="header_enter_text_box">
                                        <p>{i.header_data}</p>
                                    </div>
                                </div>
                            )
                        } else if (i.header_type == "image") {
                            return (
                                <div className="header_teml">
                                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                    <div className="header_enter_text_box">
                                        <div className="img-fluid">  <img src={i.header_data} alt="WA Header Image" /> </div>
                                    </div>
                                </div>
                            )
                        }
                    }
                }

                const footer_fun = (i) => {
                    if (i.footer != "" && i.footer != null) {
                        return (
                            <div className="footer_teml mt-2">
                                <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                                <div className="footer_enter_text_box">
                                    <p>{i.footer}</p>
                                </div>
                            </div>
                        )
                    }
                }

                return (
                    <div className="left_box_main">
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <div className="card-body mt-0 ">
                                {header_fun(item)}
                                <div className="body_teml mt-1 mb-1">
                                    <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                                    <div className="body_enter_text_box">
                                        <p>{item.body}</p>
                                    </div>
                                </div>
                                {footer_fun(item)}
                                <div className="line_ashish"> </div>
                                <div className="Button_teml">
                                    <ButtonFunc i={item} />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                )
            } else if (datas.api.whatsapp_type == "template") {
                return (
                    <Fragment>
                        {datas.parent}
                    </Fragment>
                )
            }
        }
    }


    const RightBlock = ({ type, btnType, data, datas }) => {
        if (type == "reply") {

            console.log(data)
            console.log(btnType)
            if (btnType == "list") {
                return (
                    <Fragment>
                        <div className="right_meg_box_new">
                            <div className="repley_box">
                                <div className="repley_data">
                                    <p>{datas.extra.body}</p>
                                </div>
                                <div className="repley_anser">
                                    <p>{data.row_title}</p>
                                    <p>{data.row_desc}</p>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            } else if (btnType == "button") {
                return (
                    <Fragment>
                        <div className="right_meg_box_new">
                            <div className="repley_box">
                                <div className="repley_data">
                                    <p>{datas.extra.body}</p>
                                </div>
                                <div className="repley_anser">
                                    <p>{data.title}</p>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }


        else if(type == "on_message"){
            return (
                <Fragment>
                    <div className="right_meg_box_new">
                        <div className="repley_box">
                            <div className="repley_anser">
                                <p>{data}</p>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    const MainViewByParent = ({ parent, allNodes, type }) => {
        return allNodes.map(nds => {
            if (nds.data.value.parent == parent) {
                console.log(type)
                if (type == "right") {
                    return (
                        <Fragment>
                            <ViewFunction type="right" datas={nds.data.value} nodeId={nds.id} allNodes={allNodes} />
                            <MainViewByParent allNodes={allNodes} parent={nds.id} type="left" />
                        </Fragment>
                    )
                } else if (type == "left") {
                    return (
                        <Fragment>
                            <ViewFunction type="left" datas={nds.data.value} nodeId={nds.id} allNodes={allNodes} />
                        </Fragment>
                    )
                }
            }
        })
    }

    const MainViewByChild = ({ child, allNodes, type }) => {
        console.log(child)
        return allNodes.map(nds => {
            if (nds.id == child) {
                console.log(nds)
                if (type == "right") {
                    return (
                        <Fragment>
                            <ViewFunction type="right" datas={nds.data.value} nodeId={nds.id} allNodes={allNodes} />
                        </Fragment>
                    )
                } else if (type == "left") {
                    return (
                        <Fragment>
                            <ViewFunction type="left" datas={nds.data.value} nodeId={nds.id} allNodes={allNodes} />
                        </Fragment>
                    )
                }
            }
        })
    }




    return (
        <div ref={myInputRef}>
            <div>
                {
                    flow.nodes.map(nds => {
                        if (nds.data.value.parent == 0) {
                            return (
                                <Fragment>
                                    <ViewFunction type="right" datas={nds.data.value} nodeId={nds.id} allNodes={flow.nodes} />
                                    <MainViewByParent allNodes={flow.nodes} parent={nds.id} type="left" />
                                </Fragment>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}