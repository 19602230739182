import React, { useState, useRef, Fragment, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { useForm, useFieldArray, Controller } from "react-hook-form"
import Papa from "papaparse";
import { ToastContainer, toast } from 'react-toastify';

import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';


const wa_authKey = localStorage.getItem('wa_auth_key');

const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};



const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}

function corrector(obj) {
    const outputObject = Object.values(obj).reduce((acc, curr, index) => {
        acc[index] = curr;
        return acc;
    }, {});

    return outputObject
}

export function AddListTemp() {

    const myInputRef = useRef(null);
    const [listObj, setListObj] = useState({
        "1": {
            "title": "",
            "1": {
                "row_title": "",
                "row_description": "",
            }
        }
    })
    let [btnCount, setbtnCount] = useState([1, 1])


    let [details, setDetails] = useState({
        btn_title: "",
        template_name: "",
        header_data: "",
        body: "",
        footer: "",
    })

    const add_sec = () => {
        setbtnCount([btnCount[0] + 1, btnCount[1] + 1])
        const vals = btnCount[1]

        let userSettings = {};
        userSettings[btnCount[1] + 1] = {
            "title": "",
            "1": {
                "row_title": "",
                "row_description": "",
            }
        }
        setListObj({ ...listObj, ...userSettings })
    }
    const add_row = (sec_id) => {
        let i = 0;
        Object.keys(listObj[sec_id]).map(key => {
            if (key != "title") i++;
        })


        listObj[sec_id][i + 1] = {
            "row_title": "",
            "row_description": "",
        }
        setListObj(listObj)
        setbtnCount([btnCount[0] + 1, btnCount[1]])
    }
    const rm_sec = (sec_id) => {
        let i = 0
        Object.keys(listObj[sec_id]).map(k => {
            if (k != "title") i++;
        })
        delete listObj[sec_id];
        setListObj(listObj)
        setbtnCount([btnCount[0] - i, btnCount[1] - 1])
    }
    const rm_row = (sec_id, row_id) => {
        if (btnCount[0] <= 1) {
            console.log("nope")
        } else {
            delete listObj[sec_id][row_id];
            setListObj(listObj)
            setbtnCount([btnCount[0] - 1, btnCount[1]])
        }
    }
    const secTitleChg = (sec_id, title) => {
        listObj[`${sec_id}`]['title'] = title
        setListObj(listObj)
        myInputRef.current.querySelector('#btns').value = JSON.stringify(listObj)
    }
    const rowTitleChg = (sec_id, row_id, title) => {
        listObj[`${sec_id}`][`${row_id}`]['row_title'] = title
        setListObj(listObj)
        myInputRef.current.querySelector('#btns').value = JSON.stringify(listObj)
    }
    const rowDesChg = (sec_id, row_id, title) => {
        listObj[`${sec_id}`][`${row_id}`]['row_description'] = title
        setListObj(listObj)
        myInputRef.current.querySelector('#btns').value = JSON.stringify(listObj)
    }

    const preChanger = (type, val) => {
        if (type == "btn_title") {
            setDetails(pre => ({ ...pre, 'btn_title': val }))
        } else if (type == "template_name") {
            setDetails(pre => ({ ...pre, 'template_name': val }))
        } else if (type == "header_data") {
            setDetails(pre => ({ ...pre, 'header_data': val }))
        } else if (type == "body") {
            setDetails(pre => ({ ...pre, 'body': val }))
        } else if (type == "footer") {
            setDetails(pre => ({ ...pre, 'footer': val }))
        }
    }


    const new_preview_function = () => {
        if (details.btn_title != "" || details.header_data != "" || details.body != "" || details.footer != "") {
            return (
                <div className="col-md-6">
                    <div className="chat-container">
                        <div className="message-box friend-message">
                            <div className="ashish_div">
                                <h3>{details.header_data}</h3>
                                <span id="bodyText1">{details.body}</span>
                                <span id="footerText1"> {details.footer}</span>
                                <hr />
                                <a className="btn btn-as" type="button" data-toggle="modal" data-target="#myModal">
                                    <i className="las la-bars"></i>
                                    {details.btn_title}
                                </a>
                            </div>
                        </div>
                        <div className="message-box">
                            <div className="btn_box_div"> </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    return (
        <div ref={myInputRef}>
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12">
                            <input type="text" name="template_name" value={details.template_name}
                                onChange={(e) => preChanger("template_name", e.target.value)} placeholder="Template Name" className="form-control mt-2" />
                        </div>
                        <div className="col-md-12">
                            <input type="text" name="header_data"
                                value={details.header_data}
                                onChange={(e) => preChanger("header_data", e.target.value)}
                                placeholder="Header" className="form-control mt-2" />
                            <a class="btn" type="button" data-toggle="modal" data-target="#myModal"><i class="las la-bars"></i>Choose</a>
                        </div>


                        <div className="col-md-12">
                            <input type="text" name="body" value={details.body}
                                onChange={(e) => preChanger("body", e.target.value)}

                                placeholder="Body" className="form-control mt-2" />
                        </div>

                        <div className="col-md-6">
                            <input type="text" name="footer"
                                value={details.footer}
                                onChange={(e) => preChanger("footer", e.target.value)}

                                placeholder="Footer" className="form-control mt-2" />
                        </div>

                        <div className="col-md-6">
                            <input type="text" name="btn_title"
                                value={details.btn_title}
                                onChange={(e) => preChanger("btn_title", e.target.value)}

                                placeholder="Enter Button Title" className="form-control mt-2" />
                        </div>

                    </div>

                    <input type="hidden" name="header_type" placeholder="Header Type" defaultValue="text" className="form-control mt-2" />
                    {
                        Object.keys(listObj).map((key) => {
                            return (
                                <div className='border border-solid border-[#2a96a5] m-2 p-2'>
                                    <input
                                        type="text" className="form-control mt-2"
                                        defaultValue={listObj[`${key}`]['title']}
                                        onChange={(e) => secTitleChg(key, e.target.value)} placeholder='title'
                                    />

                                    <div className="row">
                                        {
                                            Object.keys(listObj[key]).map((keys) => {
                                                if (keys != "title") {
                                                    return (
                                                        <div className="col-md-6">
                                                            <input
                                                                type="text"
                                                                className="form-control mt-2"
                                                                placeholder='row'
                                                                defaultValue={listObj[`${key}`][`${keys}`]['row_title']}
                                                                onChange={(e) => rowTitleChg(key, keys, e.target.value)}
                                                            />
                                                            <input
                                                                type="text"
                                                                className="form-control mt-2"
                                                                placeholder='desc'
                                                                defaultValue={listObj[`${key}`][`${keys}`]['row_description']}
                                                                onChange={(e) => rowDesChg(key, keys, e.target.value)}
                                                            />
                                                            <div className="text-right">
                                                                <button className="btn-sm btn-danger btn-circle ml-1 mt-2" type='button' onClick={(e) => rm_row(key, keys)} >
                                                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>

                                    <div className="text-center">
                                        <hr />
                                        <button className="btn btn-info btn-sm mr-3 mt-2" type='button' onClick={(e) => add_row(key)} >Add Row</button>
                                        <button className="btn btn-danger btn-sm mt-2" type='button' onClick={(e) => rm_sec(key)} >Remove Section</button>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="text-right"><button className="btn btn-success" type='button' onClick={(e) => add_sec()}>Add Section</button> </div>
                    <textarea className="d-none" name="listObj" id="btns" value={JSON.stringify(listObj)}></textarea>
                </div>
                {new_preview_function()}
            </div>
        </div>
    )

}

export function AddButtonTemp() {

    const myInputRef = useRef(null);
    let [btnCount, setbtnCount] = useState(1)
    const [listObj, setListObj] = useState({
        "0": ""
    })
    const add_btn = () => {

        if (btnCount <= 2) {
            setbtnCount(btnCount + 1)
            let userSettings = {};
            userSettings[btnCount + 1] = ""
            setListObj({ ...listObj, ...userSettings })
        } else {
            // toast.success(response.data.message)
        }



    }
    const rm_btn = (key) => {
        setbtnCount(btnCount - 1)
        delete listObj[`${key}`];
        setListObj(listObj)
    }

    const btnTitleChg = (key, value) => {

        let new_flow_obj = {}
        new_flow_obj[`${key}`] = value
        console.log(new_flow_obj)
        setListObj(pre => ({ ...pre, ...new_flow_obj }))
    }
    return (
        <div ref={myInputRef}>

            <div className="row">
                <div className="col-md-6">
                    <input type="text" name="template_name" placeholder="Template Name" className="form-control mt-2" />

                    <select className="form-select " name="header_type">
                        <option value="" >Select Header type</option>
                        <option value="text" >Text</option>

                    </select>
                    <input type="text" name="header_data" placeholder="Header" className="form-control mt-2" />
                    <input type="text" name="body" placeholder="Body" className="form-control mt-2" />
                    <input type="text" name="footer" placeholder="Footer" className="form-control mt-2" />

                    
                    {
                        Object.keys(listObj).map((key) => {
                            return (
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <input
                                            type="text" className="form-control mt-2"
                                            defaultValue={listObj[`${key}`]}
                                            onChange={(e) => btnTitleChg(key, e.target.value)}
                                            placeholder='title'
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <button className="  btn-danger btn-circle ml-1 mt-2" type='button' onClick={(e) => rm_btn(key)} >
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            )

                        })
                    }

                    <div className="full_btn_new">
                        <button className="btn btn-success" type='button' onClick={(e) => add_btn()}>Add Button</button>
                    </div>


                    <textarea className="d-none" name="listObj" id="btns" value={JSON.stringify(listObj)}></textarea>

                </div>

                <div className="col-md-6">
                    <div className="chat-container">
                        <div className="message-box friend-message">
                            <div className="ashish_div">
                                <h3>Hello</h3>
                                <span id="bodyText1">dtrfg</span>
                                <span id="footerText1">dsth</span>
                                <hr />
                                <a className="btn btn-as" type="button" data-toggle="modal" data-target="#myModal">
                                    <i className="las la-bars"></i>
                                    sdethg
                                </a>
                            </div>
                        </div>
                        <div className="message-box">
                            <div className="btn_box_div"> </div>
                        </div>
                    </div>
                </div>
            </div>






        </div>
    )

}

export function AddNewButtonTemp({ onSave, nodeId }) {

    const myInputRef = useRef(null);
    const [listObj, setListObj] = useState({ "0": "" })


    const { register: interAdd, handleSubmit: interAddSubmit, control, reset: interAddReset, formState: { errors: interAddErr } } = useForm()
    const { fields, append, remove } = useFieldArray({ control, name: "button" });



    const onSubmit = (data) => {

        interAdd('listObj', { value: JSON.stringify(newFun(fields)) })
        console.log(data)
        onSave(data);
        interAddReset();
        // setListObj({ "0": "" })
    };


    const newFun = (array) => {
        const formattedObject = {};

        array.forEach((item, index) => {
            formattedObject[index.toString()] = item.values;
        });

        return formattedObject
    }

    const btnView = () => {
        if (fields.length <= 2) {
            return (
                <div className="line_sep">



                    <div class="row">
                        <div class="col-md-12">
                            <div class="text-center">
                                <div class="top_ashish_mrg">

                                    <button className="Button_div_box_plu" type='button' onClick={() => append({ title: '', button_id: "6548a4fed2f1a" })}>
                                        <i className="fa-solid fa-plus"></i>
                                    </button>


                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            )
        }
    }
    return (
        <div ref={myInputRef}>
            <form onSubmit={interAddSubmit(onSubmit)}>
                <input {...interAdd("nodeId", { required: true })} value={nodeId} type='hidden' />

                <div className="header_teml">
                    <label className="header_heading"> <i class="fa-regular fa-address-card"></i> Tempalte Name </label>
                    <div className="header_enter_text_box">
                        <input {...interAdd("template_name", { required: true })} className="form-control" placeholder="Enter Tempalte Name " />
                        {
                            interAddErr.template_name && <span>Template name is required</span>
                        }
                    </div>
                </div>

                <input {...interAdd("waInterType", { required: true })} value="button" type='hidden' />
                <div className="header_teml">
                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                    <div className="header_enter_text_box">
                        <input {...interAdd("header")} className="form-control" placeholder="Enter Header" />
                    </div>
                </div>

                <div className="body_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                    <div className="body_enter_text_box">
                        <input {...interAdd("body", { required: true })} className="form-control" placeholder="Enter Body" />
                        {interAddErr.body && <span>Body field is required</span>}
                    </div>
                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                    <div className="footer_enter_text_box">
                        <input {...interAdd("footer")} className="form-control" placeholder="Enter Footer" />
                    </div>
                </div>


                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Button Title</label>
                    <div className="footer_enter_text_box">
                        <input {...interAdd("btn_title", { required: true })} className="form-control" placeholder="Enter Button Title" />
                        {
                            interAddErr.btn_title && <span>Button Title is required</span>
                        }
                    </div>
                </div>
                <div className="line_ashish"> </div>
                {
                    fields.map((item, index) => {
                        return (
                            <div className='row' key={item.id}>
                                <div className='col-md-10'>
                                    <input
                                        type="hidden"
                                        value={index}
                                        {...interAdd(`button.${index}.button_no`)}
                                    />

                                    <input
                                        type="hidden"
                                        {...interAdd(`button.${index}.button_id`)}
                                    />
                                    <input
                                        key={item.id}
                                        {...interAdd(`button.${index}.title`)}
                                        className="form-control mt-2"
                                        placeholder='Enter Button Text'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <button className="btn-danger btn-circle ml-1 mt-2" type='button' onClick={() => remove(index)} >
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
                {btnView()}


                <div class="row">
                    <div class="col-md-12 mb-3">
                        <div class="text-center">

                            <button className="btn btn-success btn-full" type='submit'>Save</button>
                        </div>
                    </div>
                </div>






            </form>
        </div>
    )

}

export function AddNewListTemp({ onSave, nodeId, showError }) {
    const { register, control, handleSubmit, clearErrors, formState: { errors } } = useForm({});
    const { fields, append, remove } = useFieldArray({ control, name: "action" });
    const [btnCount, setBtnCount] = useState(0);



    const onSubmit = (formData) => {
        if (btnCount != 0) {
            onSave(formData)
        } else {
            showError("There should be atleast one Button")
        }
    }


    const addParent = () => {
        const newCount = btnCount + 1
        if (newCount <= 10) {
            append({ title: '', rows: [{ row_title: '', row_description: '' }] })
            setBtnCount(newCount);
        } else {
            showError("You Cannot add button more than 10")
        }
    };

    const removeParent = (parentIndex) => {
        const newCount = btnCount - fields[parentIndex].rows.length
        if (newCount != 0) {
            remove(parentIndex)
            setBtnCount(newCount);
        } else {
            showError("There should be atleast one Button")
        }
    };
    const addChild = (parentIndex) => {
        const newCount = btnCount + 1
        if (newCount <= 10) {
            fields[parentIndex].rows.push({ row_title: '', row_description: '' });
            setBtnCount(newCount);
        } else {
            showError("You Cannot add button more than 10")
        }
    };


    const removeChild = (parentIndex, childIndex) => {
        const newCount = btnCount - 1
        if (newCount != 0) {
            fields[parentIndex].rows.splice(childIndex, 1);
            setBtnCount(newCount);
        } else {
            showError("There should be atleast one Button")
        }
    };

    const errFun = (type, msg) => {
        showError(msg)
        clearErrors(type)
    }




    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>


                <input {...register("nodeId", { required: true })} value={nodeId} type='hidden' />
                <input {...register("waInterType", { required: true })} value="button" type='hidden' />



                <div className="header_teml">

                    <label className="header_heading">
                        <i className="fa-regular fa-address-card"></i> Tempalte Name </label>

                    <div className="header_enter_text_box">

                        <input {...register("template_name", { required: true })} className="form-control" placeholder="Enter Tempalte Name " />
                        {
                            errors.template_name && errFun("template_name", "Template Name is required")
                        }

                    </div>

                </div>

                

                <div className="header_teml">
                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                    <div className="header_enter_text_box">
                        <input {...register("header")} className="form-control" placeholder="Enter Header" />
                    </div>
                </div>

                <div className="body_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                    <div className="body_enter_text_box">
                        <input {...register("body", { required: true })} className="form-control" placeholder="Enter Body" />
                        {
                            errors.body && errFun("body", "Body field is required")
                        }
                    </div>
                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                    <div className="footer_enter_text_box">
                        <input {...register("footer")} className="form-control" placeholder="Enter Footer" />
                    </div>
                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Button Title</label>
                    <div className="footer_enter_text_box">
                        <input {...register("btn_title", { required: true })} className="form-control" placeholder="Enter Button Title" />
                        {
                            errors.btn_title && errFun("btn_title", "Button Title is required")
                        }
                    </div>
                </div>


                {fields.map((parent, parentIndex) => (
                    <div className="card-body mt-0 p-2" key={parent.id}>
                        <div className="fst_div_box b_b">
                            <div className="new_cro1">
                                <button type="button" onClick={() => removeParent(parentIndex)} className="btn btn-sm btn-danger ">
                                    <i className="fa-regular fa-circle-xmark"></i>
                                </button>
                            </div>
                            <input
                                className='form-control' placeholder='Section Title'
                                {...register(`action[${parentIndex}].title`)}
                            />
                            {parent.rows.map((child, childIndex) => (


                                <div className="p-2" key={`${parent.id}-${childIndex}`}>
                                    <div className="one_div_box_new_add ">
                                        <div className="new_cro2">
                                            <button
                                                type="button"
                                                onClick={() => removeChild(parentIndex, childIndex)}
                                                className="btn btn-sm btn-danger new_cro3">
                                                <i className="fa-regular fa-circle-xmark"></i>
                                            </button>
                                        </div>
                                        <input
                                            className='form-control' placeholder='Row Title'
                                            {...register(`action[${parentIndex}].rows[${childIndex}].row_title`)}
                                        />

                                        <input
                                            className='form-control' placeholder='Row Description'
                                            {...register(`action[${parentIndex}].rows[${childIndex}].row_description`)}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className='text-center'>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-info new_cro4"
                                    onClick={() => addChild(parentIndex)}
                                >Add Rows</button>
                            </div>
                        </div>
                    </div>
                ))}




                <div className="line_ashish"> </div>

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="text-center">
                            <button className="btn btn-success btn-full" type='submit'>Save</button>
                        </div>
                    </div>
                </div>





                <div className="Button_teml">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <div className="top_ashish_mrg2">
                                    <button className="Button_div_box_plu " type="button" onClick={() => addParent()}>
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>







            </form>

        </div>
    )

}

export function AddNewMsg({ onSave, nodeId }) {
    const { register: interAdd, handleSubmit: interAddSubmit, reset: interAddReset, formState: { errors: interAddErr } } = useForm()

    const onSubmit = (data) => {
        const inputFile = data.file[0];
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            console.log(csv)
            // const parsedData = csv?.data;
            // const header = Object.keys(parsedData[0])

        };
        reader.readAsText(inputFile);
        console.log(data)
        // onSave(data);
        // interAddReset();
        // setListObj({ "0": "" })
    }
    return (
        <div>
            <form onSubmit={interAddSubmit(onSubmit)}>
                <input {...interAdd("nodeId", { required: true })} value={nodeId} type='hidden' />
                <input {...interAdd("waInterType", { required: true })} value="button" type='hidden' />


                <input type="file" {...interAdd('file')} />
                <div className="body_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                    <div className="body_enter_text_box">
                        <input {...interAdd("body", { required: true })} className="form-control" placeholder="Enter Body" />
                        {interAddErr.body && <span>Body field is required</span>}
                    </div>
                </div>
                <div className="text-center">
                    <button className="btn btn-success" type='submit'>Save</button>
                </div>
            </form>
        </div>
    )
}

export function CondComponet2({ allDatas, parent, condSave }) {
    const myInputRef = useRef(null);
    const { register, control, handleSubmit, reset, formState: { errors }, watch } = useForm({});
    const { fields, append, remove } = useFieldArray({ control, name: "condition" });
    const [rerender, setRerender] = useState(false);


    const allCond = [
        { "cond": "=", "cond_name": "Is" },
        { "cond": '!=', "cond_name": 'Is Not' },
        { "cond": '<', "cond_name": 'Less Than' },
        { "cond": '<=', "cond_name": 'Less or Equal' },
        { "cond": '>=', "cond_name": 'Greater or Equal' },
        { "cond": '>', "cond_name": 'Greater than' },


        { "cond": 'start_with', "cond_name": 'Start With' },
        { "cond": 'end_with', "cond_name": 'End With' },
        { "cond": 'contain', "cond_name": 'Contain' },
        { "cond": 'not_contain', "cond_name": 'Not contain' },
        { "cond": 'is_range', "cond_name": 'Is Range' },
        { "cond": 'is_not_range', "cond_name": 'Not in Range' },
        { "cond": 'before', "cond_name": 'Before' },
        { "cond": 'after', "cond_name": 'After' },
        { "cond": 'today', "cond_name": 'Today' },
        { "cond": 'is_date', "cond_name": 'Is date' },
        { "cond": 'is_not_date', "cond_name": 'Is Not Date' },
        { "cond": 'between_date', "cond_name": 'Is Date Between' },
        { "cond": 'not_between', "cond_name": 'Not Between' }
    ];





    const Column = ({ val, parentIndex, childIndex }) => {
        if (parent.data.value.columns.source.type == "on_funnel") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0 "> <i className="fa-solid fa-file-lines"></i> Column</label>
                        <div className="header_enter_text_box">
                            <select className="form-select"
                                {...register(`condition[${parentIndex}].children[${childIndex}].column`)}
                            >
                                <option selected value="" disabled>Select Condition Column</option>
                                {parent.data.value.columns.column.map(i => <option value={i.column_name}>{i.column_name}</option>)}
                            </select>
                        </div>
                    </div>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <div className="col-md-6">
                    <div className="header_teml_1">
                        <label className="header_heading m-0 "> <i className="fa-solid fa-file-lines"></i> Column</label>
                        <div className="header_enter_text_box">
                            <select className="form-select"
                                {...register(`condition[${parentIndex}].children[${childIndex}].column`)}
                            >
                                <option selected value="" disabled>Select Condition Column</option>
                                {parent.data.value.columns.column.map(i => <option value={i.field_name}>{i.field_name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <Condition parentIndex={parentIndex} childIndex={childIndex} />
            </Fragment>
        )
    }

    const Condition = ({ parentIndex, childIndex }) => {
        const valId = `#cond_val_${parentIndex}_${childIndex}`

        const cond_typeChange = (vals) => {
            const main_box = myInputRef.current
            main_box.querySelector(valId).className = "col-md-12 mb-3"

            ReactDOM
                .createRoot(main_box.querySelector(valId))
                .render(
                    <CondVal
                        values={vals}
                        parentIndex={parentIndex}
                        childIndex={childIndex}
                    />
                );
        }

        return (
            <div className="col-md-6">
                <div className="header_teml_1">
                    <label className="header_heading m-0 "> <i class="fa-solid fa-network-wired"></i> Condition </label>
                    <div className="header_enter_text_box">
                        <select className="form-select"
                            {...register(`condition[${parentIndex}].children[${childIndex}].condition`)}
                            onChange={(e) => cond_typeChange(e.target.value)}
                        >
                            <option value="" selected disabled> Select Condition</option>
                            {allCond.map(i => (<option value={i.cond}>{i.cond_name}</option>))}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    const CondVal = ({ values, typo, parentIndex, childIndex }) => {
        if (values == "between_date" || values == "not_between") {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-regular fa-calendar-days"></i> From Date</label>
                            <div className="header_enter_text_box">
                                <Controller
                                    name={`condition[${parentIndex}].children[${childIndex}].value.range.0`}
                                    control={control}
                                    value={new Date()}
                                    defaultValue={new Date()}
                                    render={({ field }) => {
                                        return (<DatePicker
                                            className="form-control"
                                            selected={field.value}
                                            dateFormat="MMMM d, yyyy"
                                            onChange={(date) => field.onChange(date)}
                                        />
                                        )
                                    }}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">

                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-regular fa-calendar-days"></i> To Date</label>
                            <div className="header_enter_text_box">

                                <Controller
                                    name={`condition[${parentIndex}].children[${childIndex}].value.range.1`}
                                    control={control}
                                    value={new Date()}
                                    defaultValue={new Date()}
                                    render={({ field }) => {
                                        return (<DatePicker
                                            className="form-control"
                                            selected={field.value}
                                            dateFormat="MMMM d, yyyy"
                                            onChange={(date) => field.onChange(date)}
                                        />
                                        )
                                    }}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (values == "before" || values == "after") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0"> <i class="fa-solid fa-calendar-day"></i> Enter Day</label>
                        <div className="header_enter_text_box">
                            <input
                                className="form-control"
                                placeholder="Enter Day"
                                {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                            />
                        </div>
                    </div>
                </Fragment>
            )

        } else if (values == "is_date" || values == "is_not_date") {
            return (
                <div className="header_teml_1">
                    <label className="header_heading m-0"> <i class="fa-solid fa-calendar-days"></i> Enter Date</label>
                    <div className="header_enter_text_box">
                        <Controller
                            name={`condition[${parentIndex}].children[${childIndex}].value.date`}
                            control={control}
                            value={new Date()}
                            defaultValue={new Date()}
                            render={({ field }) => {
                                return (<DatePicker
                                    className="form-control"
                                    selected={field.value}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    timeInputLabel="Time:"
                                    showTimeInput
                                    onChange={(date) => field.onChange(date)}
                                />
                                )
                            }}
                            rules={{ required: true }}
                        />
                    </div>
                </div>
            )
        } else if (values == "is_range" || values == "is_not_range") {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-solid fa-list-ol"></i> Enter Value</label>
                            <div className="header_enter_text_box">
                                <input
                                    className="form-control"
                                    placeholder="Enter Value"
                                    {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-solid fa-list-ol"></i> Enter Value</label>
                            <div className="header_enter_text_box">
                                <input
                                    className="form-control"
                                    placeholder="Enter Value"
                                    {...register(`condition[${parentIndex}].children[${childIndex}].value.1`)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else if (values == "today" || values == "not_today") {
        } else {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0"> <i class="fa-solid fa-list-ol"></i> Enter Value</label>
                        <div className="header_enter_text_box">
                            <input
                                className="form-control"
                                placeholder="Enter Value"
                                {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                            />
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    const onSubmit = (formDatas) => {
        const newArray = formDatas.condition.filter(item => item.children.length > 0).map(item => item.children);
        console.log(newArray)
        condSave(newArray)
    }



    const addParent = () => {
        append({ children: [{ name: '' }] });
    };

    const removeChild = (parentIndex, childIndex) => {
        fields[parentIndex].children.splice(childIndex, 1);
        setRerender(!rerender);
    };

    const addChild = (parentIndex) => {
        fields[parentIndex].children.push({ column: '', condition: '' });
        setRerender(!rerender);
    };

    if (fields.length == 0) {
        return (
            <div className="edge_button">
                <button className="btn btn-sm text-whait" type='button' onClick={() => append({ children: [{ column: '', condition: '' }] })}>
                    <i className="fa-solid fa-plus"></i>
                </button>
            </div>
        )
    } else {
        return (
            <div className="card position-relative box_width" ref={myInputRef}>
                <div className="card-header p-2 Condition_bg">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="mt-2 font-weight-bold  temp_text_color"> <i class="fa-solid fa-network-wired"></i> Condition
                            </h6>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="card-body mt-0 p-2"  >
                        {fields.map((parent, parentIndex) => (

                            <div className="fst_div_box ">
                                <div className="new_cro1">
                                    <button type="button" onClick={() => remove(parentIndex)} className="btn btn-sm btn-danger ">
                                        <i class="fa-solid fa-xmark"></i>
                                    </button>
                                </div>

                                {parent.children.map((child, childIndex) => (
                                    <Fragment>

                                        {childIndex != 0 ? (<div className="or_div"><div className="line_top_or"></div><p>Or</p><div className="line_top_or"></div></div>) : ""}

                                        <div className="p-2use" key={`${parent.id}-${childIndex}`}>
                                            <div className="one_div_box_new_add">
                                                <div className="new_cro2">
                                                    <button
                                                        type="button"
                                                        onClick={() => removeChild(parentIndex, childIndex)}
                                                        className="btn btn-sm btn-danger new_cro3">
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                    </button>
                                                </div>
                                                <div className="row">
                                                    <Column val={child} parentIndex={parentIndex} childIndex={childIndex} />
                                                    <div id={`cond_val_${parentIndex}_${childIndex}`}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-center ashish_flex_box">
                                            <div className="line_h_Ashish"> </div>
                                            <button type="button" className="btn_ashish" onClick={() => addChild(parentIndex)}> OR </button>
                                            <div className="line_h_Ashish"> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="text-center">
                                    <button className="btn btn-success btn-full" type='submit'>Save</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <div className="butt_po_As">
                                        <button className="Button_div_box_plu " type="button" onClick={() => append({ children: [{ name: '' }] })}>
                                            <i className="fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cl_ashish"></div>
                    </div>
                </form>
            </div>
        );
    }




}



export function CondComponet({ allDatas, parent, condSave }) {
    const myInputRef = useRef(null);
    const { register, control, handleSubmit, reset, formState: { errors }, watch } = useForm({});
    const { fields, append, remove } = useFieldArray({ control, name: "condition" });
    const [rerender, setRerender] = useState(false);




    const Column = ({ val, parentIndex, childIndex }) => {
        const rule = (vals) => {
            let types;

            if (parent.data.value.columns.source.type == "on_funnel") {
                parent.data.value.columns.column.map(i => {
                    if (i.column_name == vals) {
                        types = i.column_type
                    }
                })
            } else {
                parent.data.value.columns.column.map(i => {
                    if (i.field_name == vals) {
                        types = i.field_type
                    }
                })
            }


            const main_box = myInputRef.current
            const condId = `#cond_${parentIndex}_${childIndex}`
            ReactDOM.createRoot(main_box.querySelector(condId)).render(<Condition
                typo={types}
                parentIndex={parentIndex}
                childIndex={childIndex}
            />);
        }

        if (parent.data.value.columns.source.type == "on_funnel") {
            return (
                <Fragment>
                    <div className="header_teml_1">

                        <label className="header_heading m-0 "> <i className="fa-solid fa-file-lines"></i> Column</label>
                        <div className="header_enter_text_box">
                            <select
                                className="form-select"
                                {...register(`condition[${parentIndex}].children[${childIndex}].column`)}
                                onChange={(e) => rule(e.target.value)}
                            >
                                <option selected value="" disabled>Select Condition Column</option>
                                {parent.data.value.columns.column.map(i => <option value={i.column_name}>{i.column_name}</option>)}
                            </select>
                        </div>
                    </div>
                </Fragment>
            )
        }


        return (
            <Fragment>
                <div className="header_teml_1">
                    <label className="header_heading m-0 "> <i className="fa-solid fa-file-lines"></i> Column</label>
                    <div className="header_enter_text_box">
                        <select
                            className="form-select"
                            {...register(`condition[${parentIndex}].children[${childIndex}].column`)}
                            onChange={(e) => rule(e.target.value)}
                        >
                            <option selected value="" disabled>Select Condition Column</option>
                            {parent.data.value.columns.column.map(i => <option value={i.field_name}>{i.field_name}</option>)}
                        </select>
                    </div>
                </div>
            </Fragment>
        )
    }

    const Condition = ({ typo, parentIndex, childIndex }) => {
        const valId = `#cond_val_${parentIndex}_${childIndex}`

        const cond_typeChange = (vals) => {
            const main_box = myInputRef.current
            main_box.querySelector(valId).className = "col-md-12 mb-3"

            ReactDOM.createRoot(main_box.querySelector(valId)).render(<CondVal
                values={vals}
                typo={typo}
                parentIndex={parentIndex}
                childIndex={childIndex}
            />);
        }


        if (typo == "datetime" || typo == "date") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0 "> <i class="fa-solid fa-network-wired"></i> Condition </label>
                        <div className="header_enter_text_box">
                            <select
                                className="form-select"
                                {...register(`condition[${parentIndex}].children[${childIndex}].condition`)}
                                onChange={(e) => cond_typeChange(e.target.value)}
                            >
                                <option value="" selected disabled> Select Condition</option>
                                <option value="=">equal</option>
                                <option value="=">not equal</option>
                                <option value="before">Before</option>
                                <option value="after">After</option>
                                <option value="today">Today</option>
                                <option value="not_today">not Today</option>
                                <option value="range">Range</option>
                                <option value="not_range">not Range</option>
                                <option value="will_be">will be</option>
                                <option value="wait_till">Wait Till</option>
                            </select>
                        </div>
                    </div>
                </Fragment>
            )
        } else if (typo == "number") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0 "> <i class="fa-solid fa-network-wired"></i> Condition </label>
                        <div className="header_enter_text_box">
                            <select
                                className="form-select"
                                {...register(`condition[${parentIndex}].children[${childIndex}].condition`)}
                                onChange={(e) => cond_typeChange(e.target.value)}
                            >
                                <option value="" selected disabled> Select Condition</option>
                                <option value="=">equal</option>
                                <option value="=">not equal</option>
                                <option value="<">Less than</option>
                                <option value="<="> Less than and equal to</option>
                                <option value=">">Greater Than</option>
                                <option value=">=">Greater than and equal to</option>
                            </select>
                        </div>
                    </div>
                </Fragment>
            )
        } else if (typo == "text") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0 "> <i class="fa-solid fa-network-wired"></i> Condition </label>
                        <div className="header_enter_text_box">
                            <select
                                className="form-select"
                                {...register(`condition[${parentIndex}].children[${childIndex}].condition`)}
                                onChange={(e) => cond_typeChange(e.target.value)}
                            >
                                <option value="" selected disabled> Select Condition</option>
                                <option value="=">equal</option>
                                <option value="!=">not equal</option>
                                <option value="start_with">Start with</option>
                                <option value="not_start_with">Not Start with</option>
                                <option value="end_with">End With</option>
                                <option value="not_end_with">Not End With</option>
                                <option value="contain">Contain</option>
                                <option value="not_contain">Not Contain</option>
                            </select>
                        </div>
                    </div>
                </Fragment>
            )
        } else if (typo == "text_number") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0 "> <i class="fa-solid fa-network-wired"></i> Condition </label>
                        <div className="header_enter_text_box">
                            <select
                                className="form-select"
                                {...register(`condition[${parentIndex}].children[${childIndex}].condition`)}
                                onChange={(e) => cond_typeChange(e.target.value)}
                            >
                                <option value="" selected disabled> Select Condition</option>
                                <option value="=">equal</option>
                                <option value="!=">not equal</option>
                                <option value="start_with">Start with</option>
                                <option value="not_start_with">Not Start with</option>
                                <option value="end_with">End With</option>
                                <option value="not_end_with">Not End With</option>
                                <option value="contain">Contain</option>
                                <option value="not_contain">Not Contain</option>
                                <option value="<">Less than</option>
                                <option value="<="> Less than and equal to</option>
                                <option value=">">Greater Than</option>
                                <option value=">=">Greater than and equal to</option>
                            </select>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    const CondVal = ({ values, typo, parentIndex, childIndex }) => {
        if (values == "range" || values == "not_range") {
            if (typo == "date") {
                return (
                    <div className="row">
                        <div className="col-md-6">
                            <div className="header_teml_1">
                                <label className="header_heading m-0"> <i class="fa-regular fa-calendar-days"></i> From Date</label>
                                <div className="header_enter_text_box">
                                    <Controller
                                        name={`condition[${parentIndex}].children[${childIndex}].value.range.0`}
                                        control={control}
                                        value={new Date()}
                                        defaultValue={new Date()}
                                        render={({ field }) => {
                                            return (<DatePicker
                                                className="form-control"
                                                selected={field.value}
                                                dateFormat="MMMM d, yyyy"
                                                onChange={(date) => field.onChange(date)}
                                            />
                                            )
                                        }}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="col-md-6">

                            <div className="header_teml_1">
                                <label className="header_heading m-0"> <i class="fa-regular fa-calendar-days"></i> To Date</label>
                                <div className="header_enter_text_box">

                                    <Controller
                                        name={`condition[${parentIndex}].children[${childIndex}].value.range.1`}
                                        control={control}
                                        value={new Date()}
                                        defaultValue={new Date()}
                                        render={({ field }) => {
                                            return (<DatePicker
                                                className="form-control"
                                                selected={field.value}
                                                dateFormat="MMMM d, yyyy"
                                                onChange={(date) => field.onChange(date)}
                                            />
                                            )
                                        }}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (typo == "datetime") {
                return (
                    <div className="row">
                        <div className="col-md-6">
                            <div className="header_teml_1">
                                <label className="header_heading m-0"> <i class="fa-regular fa-calendar-days"></i> From Date</label>
                                <div className="header_enter_text_box">
                                    <Controller
                                        name={`condition[${parentIndex}].children[${childIndex}].value.range.0`}
                                        control={control}
                                        value={new Date()}
                                        defaultValue={new Date()}
                                        render={({ field }) => {
                                            return (<DatePicker
                                                className="form-control"
                                                selected={field.value}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeInputLabel="Time:"
                                                showTimeInput
                                                onChange={(date) => field.onChange(date)}
                                            />
                                            )
                                        }}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="col-md-6">
                            <div className="header_teml_1">
                                <label className="header_heading m-0"> <i class="fa-regular fa-calendar-days"></i> To Date</label>

                                <div className="header_enter_text_box">
                                    <Controller
                                        name={`condition[${parentIndex}].children[${childIndex}].value.range.1`}
                                        control={control}
                                        value={new Date()}
                                        defaultValue={new Date()}
                                        render={({ field }) => {
                                            return (<DatePicker
                                                className="form-control"
                                                selected={field.value}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeInputLabel="Time:"
                                                showTimeInput
                                                onChange={(date) => field.onChange(date)}
                                            />
                                            )
                                        }}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


        } else if (values == "before" || values == "after") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0"> <i class="fa-solid fa-calendar-day"></i> Enter Day</label>
                        <div className="header_enter_text_box">
                            <input
                                className="form-control"
                                placeholder="Enter Day"
                                {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                            />
                        </div>
                    </div>
                </Fragment>
            )

        } else if (values == "=" || values == "!=") {
            if (typo == "datetime") {
                return (
                    <Fragment>
                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-solid fa-calendar-days"></i> Enter Date</label>
                            <div className="header_enter_text_box">
                                <Controller
                                    name={`condition[${parentIndex}].children[${childIndex}].value.date`}
                                    control={control}
                                    value={new Date()}
                                    defaultValue={new Date()}
                                    render={({ field }) => {
                                        return (<DatePicker
                                            className="form-control"
                                            selected={field.value}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            timeInputLabel="Time:"
                                            showTimeInput
                                            onChange={(date) => field.onChange(date)}
                                        />
                                        )
                                    }}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <div className="header_teml_1">

                            <label className="header_heading m-0"> <i class="fa-solid fa-list-ol"></i> Enter Value</label>
                            <div className="header_enter_text_box">
                                <input
                                    className="form-control"
                                    placeholder="Enter Value"
                                    {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                                />
                            </div>
                        </div>
                    </Fragment>
                )
            }


        }
        else if (values == "today" || values == "not_today") { }

        else if (values == "wait_till") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0"> <i class="fa-solid fa-calendar-days"></i> Enter Date</label>
                        <div className="header_enter_text_box">
                            <Controller
                                name={`condition[${parentIndex}].children[${childIndex}].value.0`}
                                control={control}
                                value={new Date()}
                                defaultValue={new Date()}
                                render={({ field }) => {
                                    console.log(field)
                                    return (<DatePicker
                                        className="form-control"
                                        selected={field.value}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        timeInputLabel="Time:"
                                        showTimeInput
                                        onChange={(date) => field.onChange(date)}
                                    />
                                    )
                                }}
                                rules={{ required: true }}
                            />
                        </div>
                    </div>
                </Fragment>
            )


        } else {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0"> <i class="fa-solid fa-list-ol"></i> Enter Value</label>
                        <div className="header_enter_text_box">
                            <input
                                className="form-control"
                                placeholder="Enter Value"
                                {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                            />
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    const onSubmit = (formDatas) => {

        const newArray = formDatas.condition.filter(item => item.children.length > 0).map(item => item.children);
        console.log(newArray)
        condSave(newArray)
    }



    const addParent = () => {
        append({ children: [{ name: '' }] });
    };

    const removeChild = (parentIndex, childIndex) => {
        fields[parentIndex].children.splice(childIndex, 1);
        setRerender(!rerender);
    };

    const addChild = (parentIndex) => {
        fields[parentIndex].children.push({ name: '' });
        setRerender(!rerender);
    };

    if (fields.length == 0) {
        return (
            <div className="edge_button">
                <button className="btn btn-sm text-whait" type='button' onClick={() => append({ children: [{ name: '' }] })}>
                    <i className="fa-solid fa-plus"></i>
                </button>
            </div>
        )
    } else {
        return (
            <div className="card position-relative box_width" ref={myInputRef}>
                <div className="card-header p-2 Condition_bg">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="mt-2 font-weight-bold  temp_text_color"> <i class="fa-solid fa-network-wired"></i> Condition
                            </h6>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="card-body mt-0 p-2"  >
                        {fields.map((parent, parentIndex) => (

                            <div className="fst_div_box ">
                                <div className="new_cro1">
                                    <button type="button" onClick={() => remove(parentIndex)} className="btn btn-sm btn-danger ">
                                        <i class="fa-solid fa-xmark"></i>
                                    </button>
                                </div>

                                {parent.children.map((child, childIndex) => (
                                    <Fragment>

                                        {childIndex != 0 ? (<div className="or_div"><div className="line_top_or"></div><p>Or</p><div className="line_top_or"></div></div>) : ""}

                                        <div className="p-2use" key={`${parent.id}-${childIndex}`}>
                                            <div className="one_div_box_new_add">
                                                <div className="new_cro2">
                                                    <button
                                                        type="button"
                                                        onClick={() => removeChild(parentIndex, childIndex)}
                                                        className="btn btn-sm btn-danger new_cro3">
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                    </button>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Column val={child} parentIndex={parentIndex} childIndex={childIndex} />
                                                    </div>
                                                    <div className="col-md-6" id={`cond_${parentIndex}_${childIndex}`}></div>
                                                    <div id={`cond_val_${parentIndex}_${childIndex}`}></div>
                                                </div>
                                            </div>



                                        </div>



                                    </Fragment>
                                ))}


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-center ashish_flex_box">
                                            <div className="line_h_Ashish"> </div>
                                            <button type="button" className="btn_ashish" onClick={() => addChild(parentIndex)}> OR </button>
                                            <div className="line_h_Ashish"> </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        ))}


                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="text-center">
                                    <button className="btn btn-success btn-full" type='submit'>Save</button>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <div className="butt_po_As">
                                        <button className="Button_div_box_plu " type="button" onClick={() => append({ children: [{ name: '' }] })}>
                                            <i className="fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="cl_ashish">
                        </div>

                    </div>


                </form>
            </div>
        );
    }




}



export function JsonCols() {

    const myInputRef = useRef(null);
    let [btnCount, setbtnCount] = useState(1)
    const [listObj, setListObj] = useState({ "0": "" })

    function fixObjectIndexing(obj) {
        const result = {};
        let newIndex = 0;
        for (const key in obj) result[newIndex++] = obj[key];
        return result;
    }


    const add_btn = () => {
        setbtnCount(btnCount + 1)
        let userSettings = {};
        userSettings[btnCount + 1] = ""
        setListObj(fixObjectIndexing({ ...listObj, ...userSettings }))
    }
    const rm_btn = (key) => {
        setbtnCount(btnCount - 1)
        delete listObj[`${key}`];
        setListObj(fixObjectIndexing(listObj))
    }

    const btnTitleChg = (key, value) => {
        let new_flow_obj = {}
        new_flow_obj[`${key}`] = value
        console.log(new_flow_obj)
        setListObj(pre => (fixObjectIndexing({ ...pre, ...new_flow_obj })))
    }





    return (
        <div ref={myInputRef}>
            <div className="row">
                <div className="col-md-6">
                    {
                        Object.keys(listObj).map((key) => {
                            return (
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <input
                                            type="text" className="form-control mt-2"
                                            defaultValue={listObj[`${key}`]}
                                            onChange={(e) => btnTitleChg(key, e.target.value)}
                                            placeholder='title'
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <button className="btn-danger btn-circle ml-1 mt-2" type='button' onClick={(e) => rm_btn(key)} >
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="full_btn_new">
                        <button className="btn btn-success" type='button' onClick={(e) => add_btn()}>Add Button</button>
                    </div>

                    <textarea className="d-none" name="listObj" id="btns" value={JSON.stringify(fixObjectIndexing(listObj))}></textarea>
                </div>

                <div className="col-md-6">
                    <pre>{JSON.stringify(fixObjectIndexing(listObj))}</pre>
                </div>
            </div>
        </div>
    )
}

export function EmpDetails(props) {
    const empDataQuery = useQuery({
        queryKey: ['emp_details'],
        queryFn: async () => {
            try {
                const response = axios.post("http://whatsapp.bulk24sms.com/api/get-tags", {
                    "auth_key": wa_authKey,
                    "employee_id": props.emp_id,
                }, headers);
                return response.data;
            } catch (error) {
                throw error;
            }
        }
    })

    return (
        <select name='changeToTag' className='form-select' required>
            <option value="" disabled selected>Select Tag</option>
            {
                empDataQuery.data.map(i => {
                    return (<option value={i.id}>{i.name}</option>)
                })
            }
        </select>
    )
}