import React, { useState, useRef } from 'react';
import axios from 'axios'
import { useQuery } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

const token = localStorage.getItem('token');

const host = "https://oiomni.com/api"




const headers = {
	'headers': {
		'Authorization': `Bearer ${token}`,
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		'Accept': "application/json",
		'mode': 'no-cors',
	}
}
const SpreadsheetList = ({ setNodes, id }) => {
	const myInputRef = useRef(null);

	const sheetList = useQuery({
		queryKey: ['spreedsheets'],
		queryFn: async () => {
			try {
				const response = await axios.post(host + '/spreed_sheet_list', {}, headers);
				if (response.data.status == "expired") {
					window.open(response.data.route, '', 'width=800, height=600');
				} else if (response.data.status == "success") {
					return response.data.results
				}
			} catch (error) {
				throw error;
			}
		}
	})




	if (sheetList.isLoading) {
		return ("Loading...")
	}


	const SheetName = ({ sheetId }) => {
		const allsheets = useQuery({
			queryKey: ['sheets'],
			queryFn: async () => {
				try {
					const response = await axios.post(host + '/spreed_sheet_detail', { "id": sheetId }, headers);
					if (response.data.status == "expired") {
						window.open(response.data.route, '', 'width=800, height=600');
					} else if (response.data.status == "success") {
						return response.data.sheets
					}
				} catch (error) {
					throw error;
				}
			}
		})

		if (allsheets.isLoading) {
			return ("Loading...")
		}


		const all_sheet_fun = async (sheetname, sheetId) => {
			const main_box = myInputRef.current
			const queryClient2 = new QueryClient();
			main_box.querySelector('#phone_number').innerHTML = '';

			setNodes(nds => nds.map(node => {
				if (node.id == id) {
					node.data.value.api.sheetName = sheetname
				}
				return node;
			}))


			ReactDOM
				.createRoot(main_box.querySelector("#phone_number"))
				.render(<QueryClientProvider client={queryClient2}><PhoneNumCol sheetId={sheetId} sheetname={sheetname} /></QueryClientProvider>);
		}


		return (
			<select className='form-select' onChange={(e) => all_sheet_fun(e.target.value, sheetId)}>
				<option value="select"> Select Sheet Number</option>
				{allsheets.data.map(i => (<option value={i}>{i}</option>))}
			</select>
		)

	}

	const PhoneNumCol = ({ sheetId, sheetname }) => {

		const allCol = useQuery({
			queryKey: ['ss_column'],
			queryFn: async () => {
				try {
					const response = await axios.post(host + '/sheet_detail', { "id": sheetId, "sheetname": sheetname }, headers);
					if (response.data.status == "expired") {
						window.open(response.data.route, '', 'width=800, height=600');
					} else if (response.data.status == "success") {
						return response.data.values
					}
				} catch (error) {
					throw error;
				}
			}
		})

		if (allCol.isLoading) {
			return ("Loading...")
		}
		const colFun = (vals) => {
			const result = allCol.data[0].map((cur) => ({ "column_name": cur,"column_type": "text","id": ""}))
			setNodes(nds => nds.map(node => {
				if (node.id == id) {
					node.data.value.api.phone_number_col = vals

					console.log(result)


						node.data.value.columns = [{
							"type": "on_spreedsheet",
							"details": {
								"sheetName": node.data.value.api.sheetName,
								"sheetId": node.data.value.api.sheetId
							},
							"column": result,
						}]
				}
				return node;
			}))

		}
		return (
			<select className='form-select' onChange={(e) => colFun(e.target.value)}>
				<option value=""> Select Phone Number</option>
				{allCol.data[0].map(i => (<option value={i}>{i}</option>))}
			</select>
		)
	}

	const new_function = async (e) => {
		const queryClient2 = new QueryClient();
		const main_box = myInputRef.current


		setNodes(nds => nds.map(node => {
			if (node.id == id) {
				node.data.value.api.sheetId = e.target.value
			}
			return node;
		}))


		main_box.querySelector('#sheets').innerHTML = '';
		main_box.querySelector('#phone_number').innerHTML = '';

		ReactDOM
			.createRoot(main_box.querySelector("#sheets"))
			.render(<QueryClientProvider client={queryClient2}><SheetName sheetId={e.target.value} /> </QueryClientProvider>);
	}
	return (
		<div className="card-body mt-0" ref={myInputRef}>
			<div className="contact_add_div">
				<div className="mx-auto">
					<select className='form-select' onChange={(e) => new_function(e)}>
						<option value=""> Select Spreed Sheet</option>
						{sheetList.data.map(i => (<option value={i.spid}>{i.spname}</option>))}
					</select>
					<div id='sheets'></div>
					<div id='phone_number'></div>
					<button
						className='btn btn-info'
						onClick={(e) => window.open('http://oiomni.com:8000/api/oauthCallback', '', 'width=800, height=600')}
					>New Account</button>
				</div>
			</div>
		</div>
	);
};

export default SpreadsheetList;