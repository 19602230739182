
/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef, useMemo, lazy, Fragment } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Menu, Dialog } from '@headlessui/react'

import 'react-toastify/dist/ReactToastify.css';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useForm, useFieldArray } from "react-hook-form"

import Spinner from '../cutomComponent/spinner'

const host = "https://oiomni.com/api"
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');
const wa_authKey = localStorage.getItem('wa_auth_key');

const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    localStorage.removeItem('wa_auth_key');
    window.location.href = '/';
};



const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}



export default function WebhookSetup() {
    const [isEdit, setEdit] = useState("");

    const { register, control, handleSubmit, reset, formState: { errors } } = useForm({});
    const { fields, append, remove } = useFieldArray({ control, name: "webhook" });

    const webhookQuery = useQuery({
        queryKey: ['webhook'],
        queryFn: async () => {
            try {
                const response = await axios.get(host + "/webhookGet", headers);
                if (response.data && response.data.data && response.data.data != []) {
                    return response.data.data;
                } else {
                    return []
                }
            } catch (error) {
                throw error;
            }
        },
    })

    if (!token) noAuth()

    if (webhookQuery.isLoading) return <Spinner />;
    if (webhookQuery.isError) {
        if (webhookQuery.error != null) {
            if (webhookQuery.error.response.status == '401') noAuth()
        }
    }

    const onSave = (formDatas) => {
        try {
            axios.post(host + "/webhookSave", formDatas, headers).then(response => {
                reset()
                remove()
                webhookQuery.refetch()
                toast.success(response.data.message)
            })
        } catch (error) {
            console.log(error)
            throw new Error('Failed to fetch day from API');
        }
    };


    const webhookList = () => {
        if (webhookQuery.data && webhookQuery.data != []) {
            return webhookQuery.data.map(item => {
                return (
                    <tr>
                        <td>{item.webhook_name}</td>
                        <td>{item.webhook_type}</td>
                        <td>{item.webhook_url}</td>
                        <td>
                            <button type='button'> Del</button><br />
                            <button type='button' onClick={() => setEdit(item.id)}> edit</button>
                        </td>
                    </tr>
                )
            })
        }
    }

    const Updateform = ({editId}) => {

       

        const webhookDetail = useQuery({
            queryKey: ['webhook_with_id'],
            queryFn: async () => {
                try {
                    const response = await axios.get(host + "/webhookDetail/" + editId, headers);
                    if (response.data && response.data.data && response.data.data != []) {
                        return response.data.data;
                    } else {
                        return []
                    }
                } catch (error) {
                    throw error;
                }
            },
        })

        if (webhookDetail.isLoading) return <Spinner />;
        if (webhookDetail.isError) {
            if (webhookDetail.error != null) {
                if (webhookDetail.error.response.status == '401') noAuth()
            }
        }


        const { register: editReg, control: editControl, handleSubmit: editSubmit, formState: { errors: editErr } } = useForm({
            defaultValues: {
                webhook_name: webhookDetail.data.webhookData.webhook_name,
                webhook_type: webhookDetail.data.webhookData.webhook_type,
                webhook: webhookDetail.data.webhook_feild
            }

        });
        const { fields: editFields, append: editAppend, remove: editRemove } = useFieldArray({ control: editControl, name: "webhook" });
        console.log(editFields)


        const actionEdgeEdit = (formDatas) => {
            console.log(formDatas)
        }

        return (
            <div>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="p-3 rounded-lg border border-gray-200 shadow-lg relative flex flex-col w-full bg-white translate-y-2 transition-all duration-700 ease-in-out">
                            <form onSubmit={editSubmit(actionEdgeEdit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input {...editReg(`webhook_name`, { required: true })} placeholder={`Enter Webhook Name`} className='form-control' />
                                        {editErr.webhook_name && (<span className="font-sans text-xs text-red-800 p-1">Webhook Name is required</span> )}

                                    </div>

                                    <div className="col-md-6">
                                        <select {...editReg(`webhook_type`, { required: true })}
                                            className='form-select'>
                                            <option value="" disabled>Select Webhook Type</option>
                                            <option value="get">GET</option>
                                            <option value="post">POST</option>
                                        </select>

                                        {editErr.webhook_type && (<span className="font-sans text-xs text-red-800 p-1">Webhook Type is required</span>)}
                                    </div>
                                </div>


                                <div className="mx-auto">
                                    {
                                        editFields.map((item, index) => {
                                            return (
                                                <div className="row mb-2" key={item.id}>
                                                    <div className="col-md-5">
                                                        <input key={item.id} {...editReg(`webhook.${index}.field_name`, { required: 'Field is required' })}
                                                            placeholder={`Enter the Feild Name`} className='form-control' />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <select key={item.id} {...editReg(`webhook.${index}.field_type`, { required: 'Field is required' })}
                                                            className='form-select'>
                                                            <option value="text" selected>Text</option>
                                                            <option value="number">Number</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 text-center">
                                                        <button type="button" onClick={() => editRemove(index)} className="btn  btn-danger"> <i
                                                            className="fa-solid fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="row_add_btn_new">
                                        <button type="button" onClick={() => editAppend()} className="btn btn-success  "> <i
                                            className="fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                </div>



                               
                                <div className="flex items-center justify-end rounded-b">
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600"
                                        onClick={() => setEdit("")}
                                        type="button"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                    </button>
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600"
                                        type="submit"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </div>
        )

    }

    return (
        <Fragment>
            <div className="container-fluid">
                <h1 className="h3_ashish mb-4 text-gray-800"> <i className="fa-brands fa-slack"></i> Webhook Setting </h1>
                <div className="row">
                    <div className="col-md-6">
                        <form onSubmit={handleSubmit(onSave)}>
                            <div className="card position-relative">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-primary">
                                        <i className="fa-solid fa-file-signature"></i>Webhook Name
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="">
                                        <div className="mx-auto">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input {...register(`webhook_name`, { required: true })} placeholder={`Enter Webhook Name`} className='form-control' />
                                                    {errors.webhook_name && (
                                                        <span className="font-sans text-xs text-red-800 p-1">
                                                            Webhook Name is required
                                                        </span>
                                                    )}

                                                </div>

                                                <div className="col-md-6">
                                                    <select {...register(`webhook_type`, { required: true })}
                                                        className='form-select'>
                                                        <option value="" disabled selected>Select Webhook Type</option>
                                                        <option value="get">GET</option>
                                                        <option value="post">POST</option>
                                                    </select>

                                                    {errors.webhook_type && (<span className="font-sans text-xs text-red-800 p-1">Webhook Type is required</span>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card position-relative mt-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-primary">
                                        <i className="fa-solid fa-table-columns"></i>
                                        Add Custom Feilds
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="">
                                        <div className="mx-auto">
                                            {
                                                fields.map((item, index) => {
                                                    return (
                                                        <div className="row mb-2" key={item.id}>
                                                            <div className="col-md-5">
                                                                <input key={item.id} {...register(`webhook.${index}.field_name`, { required: 'Field is required' })}
                                                                    placeholder={`Enter the Feild Name`} className='form-control' />
                                                            </div>
                                                            <div className="col-md-5">
                                                                <select key={item.id} {...register(`webhook.${index}.field_type`, { required: 'Field is required' })}
                                                                    className='form-select'>
                                                                    <option value="text" selected>Text</option>
                                                                    <option value="number">Number</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-2 text-center">
                                                                <button type="button" onClick={() => remove(index)} className="btn  btn-danger"> <i
                                                                    className="fa-solid fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="row_add_btn_new">
                                                <button type="button" onClick={() => append()} className="btn btn-success  "> <i
                                                    className="fa-solid fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row_add_btn_save mt-2">
                                <button className="btn btn-success  " type="submit" > Save</button>
                            </div>
                        </form>
                    </div>




                    <div className="col-md-6">
                        <div className="card position-relative">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary"> <i className="fa-solid fa-list"></i> Webhook List
                                </h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive ">
                                    <table className="table table-header-bg">
                                        <thead>
                                            <tr className="table_my_th">
                                                <th scope="col">Webhook Name</th>
                                                <th scope="col">Webhook Type</th>
                                                <th scope="col">webhook Url</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {webhookList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isEdit === "" ? ("") : (<Updateform editId={isEdit} />)}

            <ToastContainer />
        </Fragment>
    );
}