import axios from 'axios';
import { useState } from 'react';
const host = "https://oiomni.com/api"
const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}

export default function WebhookUrls({ setNodes, apisData, id, webhookQuery }) {
    const [refresher, setRefresher] = useState(false)
    const hasWebhookDetail = apisData.webhookDetail && apisData.webhookDetail !== "";
    const hasPhoneNumberCol = apisData.phone_number_col && apisData.phone_number_col !== "" && apisData.Columns;

    function webhookNumberSet(values) {
        setNodes(nds => nds.map(node => {
            if (node.id == id) {
                node.data.value.api.phone_number_col = values
            }
            return node;
        }))
        setRefresher(!refresher)
    }

    function webhookSet(values) {
        setNodes(nds => nds.map(node => {
            if (node.id == id) {
                node.data.value.api.webhookDetail = values
                try {
                    axios.get(`${host}/webhookFeild/${values}`, headers).then(response => {
                        console.log(response.data)
                        const apiNew = { ...node.data.value.api, ...{ Columns: response.data.data } }

                        node.data.value.api = apiNew
                        // node.data.value.api.Columns = response.data[0].data
                        node.data.value.columns = [{
                            "column": response.data.data,
                            "type": "on_webhook",
                            "details": { "webhook_id": values }
                        }]

                        console.log(node.data.value)
                    })
                } catch (error) {
                    console.log(error)
                }
            }
            return node;
        }))
        setRefresher(!refresher)
    }


    console.log(webhookQuery.data)
    return (
        <div className="card-body mt-0">
            <div className="contact_add_div">
                <div className="mx-auto">
                    {hasWebhookDetail && webhookQuery.data.map(x =>
                        x.id === apisData.webhookDetail ? <h6 key={x.id}>{x.webhook_name} Webhook is Setted</h6> : null
                    )}

                    <select
                        className="form-select"
                        onChange={(e) => webhookSet(e.target.value)}
                        defaultValue={hasWebhookDetail ? apisData.webhookDetail : ""}
                    >
                        <option value="" disabled>Select Webhook</option>
                        {webhookQuery.data.map(x => <option key={x.id} value={x.id}>{x.webhook_name}</option>)}
                    </select>
                    {hasWebhookDetail && apisData.Columns && <select
                        className="form-select"
                        onChange={(e) => webhookNumberSet(e.target.value)}
                        defaultValue={hasPhoneNumberCol ? `webhookCol-${apisData.phone_number_col}` : ""}
                    >
                        <option value="" disabled>Select Phone Number Column</option>
                        {apisData.Columns.map(x => <option key={x.id} value={`webhookCol-${x.id}`}>{x.field_name}</option>)}
                    </select>}
                </div>
            </div>
        </div>
    );
}

