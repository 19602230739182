/* eslint-disable */
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios'


import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { useQuery } from '@tanstack/react-query';


const host = "https://oiomni.com/api";
const token = localStorage.getItem('token');
const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};

const axios_header = {
    'headers': {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}

export default function Profile() {

    const [data, setData] = useState([]);
    const [isView, setView] = useState(false);
    const [isEdit, setEdit] = useState(false);


    if (!token) noAuth()
    const { register, handleSubmit, formState: { errors } } = useForm();



    const profileData = useQuery({
        queryKey: ['profileData'],
        queryFn: async () => {
            try {
                const response = await axios.post(host + '/getWebhook', {}, axios_header);
                return response.data.data;
            } catch (error) {
                throw error;
            }
        }
    })



    if (profileData.isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="relative inline-flex">
                    <div className="w-16 h-16 bg-[#1286a8] rounded-full"></div>
                    <div className="w-16 h-16 bg-[#1286a8] rounded-full absolute top-0 left-0 animate-ping"></div>
                    <div className="w-16 h-16 bg-[#1286a8] rounded-full absolute top-0 left-0 animate-pulse"></div>
                </div>
            </div>
        );
    }
    if (profileData.isError) {
        if (profileData.error.response) {
            let errs = profileData.error.response
            if (errs.status == 401) {
                toast.error(errs.data.message)
                noAuth()
            } else if (errs.status == 403) {
                Object.values(errs.data.message).map(val => { toast.error(val[0]) })
            }
        } else {
            toast.error(error.message)
        }
    }


    async function genrate() {
        const res = await fetch(host + "/generateWebhook", {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });
        const res_data = await res.json();
        window.location.reload(false);

        if (res_data.status == 201) {
            setView(true);
        } else {
            setData(res_data.data);
        }
        setLoad(true)
    }



    const actionEdgeEdit = async (formData) => {
        try {
            const response = await axios.post(host + "/edit_user", formData, axios_header);
            toast.success(response.data.message);
            setEdit(false)
            profileData.refetch()
        } catch (error) {
            if (error.response) {
                let errs = error.response
                if (errs.status == 401) {
                    toast.error(errs.data.message)
                    noAuth()
                } else if (errs.status == 403) {
                    Object.values(errs.data.message).map(val => {toast.error(val[0]) })
                }
            } else {
                toast.error(error.message)
            }
        }
    }

    const updateform = (datas) => {
        return (
            <div>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="p-3 rounded-lg border border-gray-200 shadow-lg relative flex flex-col w-full bg-white translate-y-2 transition-all duration-700 ease-in-out">
                            <form onSubmit={handleSubmit(actionEdgeEdit)}>
                                <div className="flex flex-row">
                                    <div>
                                        <input
                                            className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                            {...register("name", { required: true })}
                                            placeholder="Enter Value Name"
                                            defaultValue={datas.name}
                                        />
                                        {errors.name && <span>Name field is required</span>}
                                    </div>
                                    <div>
                                        <input
                                            className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                            {...register("email", { required: true })}
                                            placeholder="Enter Value Name"
                                            defaultValue={datas.email}
                                        />
                                        {errors.email && <span>Email field is required</span>}
                                    </div>
                                </div>

                                <input name="auth_key" type="hidden"
                                    {...register("auth_key", { required: true })}
                                    defaultValue={datas.auth_key}
                                />

                                <div className="flex items-center justify-end rounded-b">
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600"
                                        onClick={() => setEdit(false)}
                                        type="button"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                    </button>
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600"
                                        type="submit"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </div>
        )
    }


    return (
        <div className="ashish_card">
            <div className="top-container">
                <div className="name_pos"> <div className="name_initials">{profileData.data.name.charAt(0)}</div> </div>
                <div className="profile_div">
                    <div className="ml-3">
                        <h5 className="name">{profileData.data.name}</h5>
                        <p className="mail">{profileData.data.email}</p>
                    </div>
                </div>
            </div>

            <div className="text-center">
                <div className="flex align-center_ashish">
                    <div className="recent-border mt-4">
                        <span className="recent-orders"> <i class="fa-regular fa-circle-user"></i> {profileData.data.username}</span>
                    </div>
                    <div className="recent-border mx-4 mt-4">
                        <span className="recent-orders"> <i className="fa fa-mobile" aria-hidden="true"></i> {profileData.data.contact_number}</span>
                    </div>
                    <div className="recent-border mt-4">
                        <span className="recent-orders"><i className="fa fa-key" aria-hidden="true"></i>{profileData.data.auth_key}</span>
                    </div>
                </div>
            </div>
            {
                profileData.data.webhook_key == "" ? (
                    <button className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-0 font-medium border-[#1286a8] bg-[#d2e8ee] text-[#1286a8]" onClick={genrate}>
                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                        <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Genrate</span>
                    </button>
                ) : (
                    <div className="recent-border mt-4">
                        <span className="recent-orders">
                            <i className="fa fa-chain-broken" aria-hidden="true"></i>
                            {host + "/webhook/" + profileData.data.webhook_key}
                        </span>
                    </div>
                )
            }
            <button className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-0 font-medium border-[#1286a8] bg-[#d2e8ee] text-[#1286a8]" onClick={() => setEdit(true)}>
                <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
            </button>
            {isEdit === false ? ("") : (updateform(profileData.data))}
            <ToastContainer />
        </div>
    )
}