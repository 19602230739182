import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

import { GetFetcher,  PostFetcher} from '../Flow/api';

const host = "http://oiomni.com:8000/api";
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');



const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};


const headers = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
    'Accept': "application/json",
    'mode': 'no-cors',
}

export default function CampStatistics() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [data_num, setDataNum] = useState([]);
    const [data_title, setDataTitle] = useState([]);


    const [isLoad, setLoad] = useState(true);
    const [isChartView, setChartView] = useState(false);


    useEffect(() => {
        if (!token) {
            noAuth()
        }else {
            getReport();
        }
    },[isLoad])


    async function getReport() {

        const res_data = await PostFetcher(host + '/camp_statistics/', headers,{run_id: id})

        console.log(res_data)


        var datas = res_data.data


       
        
        setLoad(false)
        setData(datas);
        // setDataTitle(a);
        // setDataNum(b);
    }

    const data_table = () => {
        if(data.length != 0){
            return data.map(item => {
                return (
                    <tr className="table_my_td border-gray-700">
                        <td className="py-3 px-6 font-semibold  text-sm ">
                            {item.campaign_name}<br/>
                            <p className="text-gray-500">
                              <Link to={"/log_instant/" + item.camp_extra_detail}> {item.camp_extra_detail}</Link>
                               
                            </p>
                        </td>
                        <td className="py-3 px-6 font-semibold text-center">
                            {item.type}
                        </td>
                        
                       
                    </tr>
                )
            })
        }else{
            return ""
        }
    }


   

    

    return (
        <>
           {/* <div className="flex mx-auto max-w-6xl pt-6">
                 <button 
                    onClick={(e) => setChartView(!isChartView)}
                    type="button"
                    className="text-[#1286a8] bg-white border border-[#1286a8] font-semibold py-2 px-4  my-8 mr-4  rounded-md hover:bg-[#1286a8] hover:text-[#fff]"
                >View Chart</button>
                    
            </div>
            {
                isChartView == false ? ("") : (
                    <div className="flex mx-auto max-w-5xl pt-6">
                        <Chart
                            chartType="PieChart"
                            data={data_title}
                            options={{title: "Button Click", is3D: true}}
                            width={"100%"}
                            height={"400px"}
                        />
                        <Chart
                            chartType="PieChart"
                            data={data_num}
                            options={{title: "Click By Number", is3D: true}}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>  

                )
            }*/}


            <div className="flex mx-auto max-w-6xl pt-6">
                <table className="w-full table_my">
                    <thead className="table_my_th">
                        <tr>
                            <th scope="col" className="py-3 px-6">Campaign ID</th>
                            <th scope="col" className="py-3 px-6">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data_table()}
                    </tbody>
                </table>
            </div>
        </>
    );
}