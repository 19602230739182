/* eslint-disable */ 
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { GetFetcher,  PostFetcher} from '../Flow/api';


const host = "http://oiomni.com:8000/api"; const token = localStorage.getItem('token');


const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};

const headers_axios = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
    'Accept': "application/json",
    'mode': 'no-cors',
}



export default function Report() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [isLoad, setLoad] = useState(true);

    useEffect(() => {
        if (!token) {
            noAuth()
        }else {
            getReport(host + '/report');
        }
    },[isLoad])

    

    async function getReport(el) {
        const get_report = await PostFetcher(el, headers_axios, {flow_id: id})

        get_report.data.links.shift();
        get_report.data.links.pop();

        const arr = get_report.data.links.map(item => item.url)

        setTotal(arr);
        setData(get_report.data.data)
    }

    const date_time = (timestamp) => {
        function addZero(num) {
            return (num < 10 ? '0' : '') + num;
        }


        const date = new Date(timestamp * 1000);
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options);

        
        return(formattedDate);
    }

    const timeDiff = (st, et, running_status, run_id) => {
        if( running_status != 0){
            return(
                <div>
                    <p className="mail font-bold text-xl">Running Right Now</p><br/>
                    <Link to={"/temp_data/"+run_id} className="btn_as_shc">
                        <i class="fa fa-database"></i>Temp Data
                    </Link>
                </div>
            );
        }
        var diffMs = (et - st); 
        var diffDays = Math.floor(diffMs / 86400); 
        var diffHrs = Math.floor((diffMs % 86400) / 3600); 
        var diffMins = Math.round(((diffMs % 86400) % 3600) / 60); 
        var diffSec = Math.round((((diffMs % 86400) % 3600) % 60) / 60); 
        if(diffDays === 0){
            if(diffHrs === 0){
                if(diffMins === 0){
                    return(diffSec + " sec ");
                }
                return(diffMins + " mins " + diffSec + " sec ");
            }
            return(diffHrs + " hrs, " + diffMins + " mins " + diffSec + " sec ");
        }
        return(diffDays + " days, " + diffHrs + " hrs, " + diffMins + " mins " + diffSec + " sec");
    }
   
    

    return (
        <>
           <div className="flex mx-auto max-w-6xl pt-6">
                <table className="w-full table_my">
                    <thead className="table_my_th">
                        <tr>
                            <th scope="col" className="py-3 px-6">Run ID</th>
                            <th scope="col" className="py-3 px-6">Start Time</th>
                            <th scope="col" className="py-3 px-6">End Time</th>
                            <th scope="col" className="py-3 px-6">Running Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item,index) => {
                            if(item.status == 2){
                                return (
                                    <tr className="table_my_td border-gray-700">
                                        <td className="py-3 px-6 text-center font-semibold"  >
                                            <Link to={"/camp_statistics/" + item.run_id}> {item.run_id}</Link>
                                        </td>
                                        <td className="py-4 px-6 text-center">
                                            <p className="mail font-bold text-xl">{date_time(item.start_time)}</p>
                                        </td>
                                        <td className="py-4 px-6 text-center">
                                            <p className="mail font-bold text-xl">{date_time(item.end_time)}</p>
                                        </td>
                                        <td className="py-4 px-6 text-center">
                                            {timeDiff(item.start_time, item.end_time, item.running_status, item.run_id)}
                                        </td>
                                    </tr>
                                )
                            }else{
                                return (
                                    <tr className="table_my_td border-gray-700">
                                        <td className="py-3 px-6 text-center font-semibold"  >
                                            <Link to={"/campaign/" + item.run_id}> {item.run_id}</Link>
                                        </td>
                                        <td className="py-4 px-6 text-center">
                                            {date_time(item.start_time)}
                                        </td>
                                        <td className="py-4 px-6 text-center">
                                            {date_time(item.end_time)}
                                        </td>
                                        <td className="py-4 px-6 text-center">
                                            {timeDiff(item.start_time, item.end_time, item.running_status, item.run_id)}
                                        </td>
                                    </tr>
                                )  
                            }
                        })
                   
                    }
                    </tbody>
                </table>
            </div>
            <div className="mx-auto max-w-screen-xl pt-6 flex justify-center"> 
                <button 
                    onClick={(e) => getReport(total[0])}
                    value={total[0]}
                    className="bg-blue-200 text-blue-500 font-semibold py-2 px-4 rounded-md"
                ><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                {
                    total.map((el, i) =>
                        <button 
                            onClick={(e) => getReport(el)}
                            value={el}
                            className="bg-blue-200 text-blue-500 font-semibold mx-2 py-2 px-4 rounded-md"
                        >{i+1}</button>
                    )
                }
                <button 
                    onClick={(e) => getReport(total[total.length - 1])}
                    value={total[total.length - 1]}
                    className="bg-blue-200 text-blue-500 font-semibold py-2 px-4 rounded-md"
                ><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
            </div>
        </>
    );
}
