const SelectOptions = ({ colObj }) => {
    if (colObj.length != "0") {
        return colObj.map(item => {
            if (item.type == "on_attribute" || item.type == "on_message" || item.type == "on_spreedsheet" || item.type == "on_funnel" ) {
                return item.column.map((i, idx) => <option key={idx} value={i.column_name}>{i.column_name}</option>)
            } else if (item.type == "on_webhook") {
                return item.column.map((i, idx) => <option key={idx} value={i.field_name}>{i.field_name}</option>)
            }
        })
    }
}


export default SelectOptions;