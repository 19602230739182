/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef, useMemo, lazy, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Menu, Dialog } from '@headlessui/react'

import { Button, Modal, Dropdown, DropdownButton, NavDropdown } from 'react-bootstrap';
import ReactFlow, {
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    addEdge,
    Background,
    Controls,
    Handle,
    useNodes,
} from 'reactflow';
import 'reactflow/dist/style.css';

import Spinner from '../cutomComponent/spinner'
import Handles from '../cutomComponent/handles'
import BoxHeader from '../cutomComponent/boxHeader'
import Icons from '../cutomComponent/icons'
import DescriptionBox from '../cutomComponent/descriptionBox'
import SpreadsheetList from './testing.components';
import { AddListTemp, AddButtonTemp, JsonCols } from '../cutomComponent/addListTemplate.jsx';
import { WaTemplates, WaInterBtn } from '../cutomComponent/wa_custom.jsx';





import 'react-toastify/dist/ReactToastify.css';

import { useWaTemp, useWaInter, useTemp, useEmp } from './api/waTemp';


import { useQuery, QueryClientProvider, QueryClient } from '@tanstack/react-query';
import axios from 'axios';



const host = "http://oiomni.com:8000/api"
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');
const wa_authKey = localStorage.getItem('wa_auth_key');


const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    localStorage.removeItem('wa_auth_key');
    window.location.href = '/';
};



const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}


let new_body = {}



const queryClient = new QueryClient();

export default function NewBot() {


    return (

        


                <div >
                    <h3>Create New Bot</h3>
                    <form>
                        <input placeholder='enter the bot name' className='form-control' />
                        <button className='btn btn-info'>Save</button>
                    </form>
                </div>
     
    )
}