import { Handle } from 'reactflow';

export default function Handles(props) {
    const getRandomNumber = () => Math.floor(Math.random() * 99999) * Math.floor(Math.random() * 99999);


    if (props.type == "source") {
        return <Handle
            type="source"
            position="right"
            id={`Sourcehandle-${getRandomNumber()}`}
            style={{
                'width': '150px',
                'height': '150px',
                'border': '10px',
                'backgroundColor': '#000',
                "right": "-24px",
                
            }}
        />
    } else if (props.type == "target") {
        if (props.parent != 0) {
            return <Handle
                type="target"
                className="ashish_handle-left"
                position="left"
                id={`Targethandle-${getRandomNumber()}`}

            />
        }
        
    }
}