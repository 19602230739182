import { Menu } from '@headlessui/react'
import React, { Fragment } from 'react';



const SideModal = ({ items_ul2, event, cond, id, typo, modelType }) => {

    let t = ["All"]
    if (event.data.value.type == "sms") {
        t = ["All", "Sms Deliver", "Sms Fail"]
    } else if (event.data.value.type == "email") {
        t = ["All", "Email Deliver", "Email Fail"]
    } else if (event.data.value.type == "voice") {
        t = ["All", "Voice Deliver", "Voice Fail"]
    } else if (event.data.value.type == "whatsapp") {
        t = ["All", "WhatsApp Deliver", "WhatsApp Read", "WhatsApp Fail"]
    }
    if (modelType == "normal") {

        return (
            <div className='start_btn_pos h-lg-100'>
                <div className={typo}>
                    <Menu>
                        <Menu.Button><i className="fa fa-plus" aria-hidden="true"></i></Menu.Button>
                        <Menu.Items>
                            <ul className="as_new1">

                                {
                                    t.map(i => {
                                        return (
                                            <Fragment>
                                                <Menu.Item>
                                                    <Fragment>
                                                        <Menu>
                                                            <Menu.Button as="li" className="w-full text-left flex items-center outline-none as_btn_new">
                                                                {i}<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                            </Menu.Button>
                                                            <Menu.Items>
                                                                <ul className="as_new1">
                                                                    {items_ul2(event, i, id)}
                                                                </ul>
                                                            </Menu.Items>
                                                        </Menu>
                                                    </Fragment>
                                                </Menu.Item>
                                            </Fragment>
                                        )
                                    })
                                }
                            </ul>
                        </Menu.Items>
                    </Menu>
                </div>
            </div>
        )
    } else {
        return (
            <div className='start_btn_pos h-lg-100'>
                <div className={typo}>
                    <Menu>
                        <Menu.Button><i className="fa fa-plus" aria-hidden="true"></i></Menu.Button>
                        <Menu.Items>
                            <ul className="as_new1">{items_ul2(event, cond, id)}</ul>
                        </Menu.Items>
                    </Menu>
                </div>
            </div>
        )
    }

}

export default SideModal;