// eslint-disable-next-line 
/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef, useMemo, lazy, Fragment } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Menu } from '@headlessui/react'
import ReactFlow, {
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    addEdge,
    Background,
    Controls,
    Handle,
} from 'reactflow';
import 'reactflow/dist/style.css';

import Spinner from '../cutomComponent/spinner'
import Handles from '../cutomComponent/handles'
import BoxHeader from '../cutomComponent/boxHeader'
import Icons from '../cutomComponent/icons'
import DescriptionBox from '../cutomComponent/descriptionBox'


import 'react-toastify/dist/ReactToastify.css';
import { GetFetcher, PostFetcher } from './api';

import { create } from 'zustand';

import { useShallow } from 'zustand/react/shallow'

const host = "http://oiomni.com:8000/api"
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');
const wa_authKey = "52301fe4128e78a8eb85428e83c99b71"

let test = [];
let temp_data = []
let funnel = []
let group = []
let wa_temp = []
let waAllTemp = {}
let waAllInter = {}

const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};



const headers = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Accept': "application/json",
    'mode': 'no-cors',
}



const useStore = create((set, get) => ({
    allWaTemplates: {},
    allWaInteractive: {},

    setAllWaTemplates: (newData) => set({ allWaTemplates: newData }),
    setAllWaInteractive: (newData) => set({ allWaInteractive: newData }),
}))



export default function GetFlow() {
    const { id } = useParams();
    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [rfInstance, setRfInstance] = useState(null);
    const [isfetch, setFetch] = useState(true);
    const [isupdate, setUpdate] = useState(false);
    const [viewupdate, setViewUpdate] = useState(["", {}]);
    const [rule, setRule] = useState([])
    const [edgeEdit, setEdgeEdit] = useState([false, {}])
    const [filter, setFilter] = useState([false, {}, ""])
    const [checked, setChecked] = useState(false);
    const [tempPopUp, setTempPopUp] = useState(["", "", ""]);
    const [customCol, setCustomCol] = useState([[], false]);
    const [funnelList, setFunnelList] = useState([]);
    const [GroupList, setGroupList] = useState([]);




    const [colCondition, setColCondition] = useState([
        { "value": "=", "cond": "Is" },
        { "value": "!=", "cond": "Is Not" },
        { "value": ">", "cond": "Greater Than" },
        { "value": ">=", "cond": "Greater Than or equal" },
        { "value": "<", "cond": "Less Than" },
        { "value": "<=", "cond": "Less Than or equal" }
    ]);
    const myInputRef = useRef(null);
    const cusAddRef = useRef(null);

    const navigate = useNavigate();

    const [campTemplate, setCampTemplate] = useState([]);
    const [waTemplate, setWaTemplate] = useState([]);
    const [updateValue, setUpdateValue] = useState({
        "id": null,
        "label": null,
        "sms_route": null,
        "voice_route": null,
        "sms_coding": null,
        "type": null,
        "sms_campaign_name": null,
        "sms_sender": null,
        "sms_template_id": null,
        "sms_content": null,
        "auth_key": null,
        "pe_id": null,
        "voice_campaign_name": null,
        "voice_sender": null,
        "voice_content": null,
        "email_campaign_name": null,
        "email_sender": null,
        "email_content": null,
        "email_subject": null,
        "email_sender_name": null,
        "description": null,
        "parent": null,
    });




    const { allWaTemplates, allWaInteractive } = useStore(
        useShallow((state) => ({
            allWaTemplates: state.allWaTemplates,
            allWaInteractive: state.allWaInteractive,
        }))
    )

    const { setAllWaTemplates, setAllWaInteractive } = useStore(
        useShallow((state) => ({
            setAllWaTemplates: state.setAllWaTemplates,
            setAllWaInteractive: state.setAllWaInteractive,
        }))
    )

    if (!token) noAuth()



    useMemo(() => {
        if (isfetch == true) myFunction()
    }, [isfetch])



    async function myFunction() {
        const wa_all_temps = await PostFetcher('https://whatsapp.bulk24sms.com/api/get-templates', headers, {
            "auth_key": wa_authKey,
            "template_type": "template"
        })
        setAllWaTemplates(wa_all_temps.data)

        const wa_all_inter = await PostFetcher('https://whatsapp.bulk24sms.com/api/get-templates', headers, {
            "auth_key": wa_authKey,
            "template_type": "interactive"
        })

        setAllWaInteractive(wa_all_inter.data)


        const get_wa_temp = await GetFetcher(host + '/wa_templates/' + id, headers)
        const all_temps = await PostFetcher(host + '/get_all_template_api/', headers, {})
        const all_flow = await GetFetcher(host + '/get_flow/' + id, headers)







        // const all_col = await PostFetcher(host + '/col_list/', headers, {})
        // const all_segment = await PostFetcher(host + '/segment_list/', headers, {})
        // const all_group = await PostFetcher(host + '/group_list/', headers, {})
        setCampTemplate(all_temps.data)
        setWaTemplate(get_wa_temp)
        setNodes(all_flow.nodes)
        setEdges(all_flow.connection)


        // setCustomCol(all_col.data)
        // setFunnelList(all_segment.data)
        // setGroupList(all_group.data)

        test = [all_flow]
        temp_data = all_temps.data
        // funnel = all_segment.data
        // group = all_group.data
        wa_temp = get_wa_temp

        waAllTemp = wa_all_temps.data
        waAllInter = wa_all_inter.data

        setFetch(false)

        return true
    };

    const CustomNode = (event) => {
        const id = event.id;
        const type = event.data.value.type

        const box_btn = (e, id) => {
            return (
                <div className="p_20">
                    <div className="new_btn_hover flex">
                        <button className="delete_btn basis-1/2 mx-2" onClick={(event) => OndeleteNode(event, id)}>
                            <i className="mr-2 fa fa-trash" aria-hidden="true"></i> Delete
                        </button>
                        <button className="Update_btn basis-1/2 mx-2" onClick={(event) => OnUpdateNode(event, id)}>
                            <i className="mr-2 fa fa-pencil-square-o" aria-hidden="true"></i> Update
                        </button>
                    </div>
                </div>
            )
        }
        const box_header = (type, e) => {
            return (
                <div className="p_20 flex">
                    <div className="basis-1/5">
                        <div className="icon_box_n"><Icons icon_type={type} /></div>
                    </div>
                    <div className="basis-3/5 grow">
                        <div className="hedd_p">
                            <p className="truncate"> {e.data.label} </p>
                        </div>
                    </div>
                    <div className="basis-1/5">
                        <Menu>
                            <Menu.Button className="rounded-lg  header_dot_btn">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </Menu.Button>
                            <Menu.Items>
                                <ul className="as_new1">
                                    <Menu.Item>
                                        <li>
                                            <button
                                                className="m-2 text-2xl text-[#a94442]"
                                                onClick={(event) => OndeleteNode(event, id)}
                                            >
                                                <i className="mr-2 fa fa-trash" aria-hidden="true"></i> Delete
                                            </button>
                                        </li>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <li>
                                            <button
                                                className="m-2 text-2xl  text-[#3c763d]"
                                                onClick={(event) => OnUpdateNode(event, id)}
                                            >
                                                <i className="mr-2 fa fa-pencil-square-o" aria-hidden="true"></i> Update
                                            </button>
                                        </li>
                                    </Menu.Item>
                                </ul>
                            </Menu.Items>
                        </Menu>
                    </div>
                </div>

            )
        }




        if (type === 'start') {
            // return (
            //     <Fragment>
            //         <div className="box_star_as">
            //             <BoxHeader type='start' label={event.data.label} />
            //             <div className="line_as" />
            //             <DescriptionBox desc={event.data.value.description} />
            //             {template_select_non(type, id, event)}
            //         </div>
            //         <Handles type='source' />
            //     </Fragment>
            // );

            return (

                <Fragment>
                    {template_select_start(id)}
                </Fragment>
            )
        }
        else if (type === "group" || type === "funnel") {
            return (
                <Fragment>
                    <Handles type='target' />
                    <div className="box_star_as">
                        {box_header(type, event)}
                        <div className="line_as" />
                        {/*<DescriptionBox desc={event.data.value.description} />*/}
                        {box_btn(event, id)}
                        <div className="line_as"></div>
                        {template_select_non(type, id, event)}
                    </div>
                    <Handles type='source' />
                </Fragment>
            );
        }
        else {
            return (
                <Fragment>
                    <Handles type='target' />
                    <div className="box_star_as">
                        {box_header(type, event)}








                        {/*<div className="line_as" /><DescriptionBox desc={event.data.value.description} />*/}
                        <div className="p_20">
                            <div className="w-as-100">
                                {wa_desgin(event)}
                            </div>
                        </div>
                        <div className="line_as" />
                        {box_btn(event, id)}
                        <div className="line_as" />
                        {template_select(type, id, event)}
                    </div>
                    <Handles type='source' />
                </Fragment>
            );
        }
    }


    const openFormWA = (type, parent, parent_conn, i, whatsapp_type, phone_no) => {
        let api = {
            'campaign_name': i.title,
            'sender': phone_no.phone_no_id,
        }
        let extra = {}
        let obj = []
        let node_extra = {}
        
        

        

        if (type === 'whatsapp') {
            if (whatsapp_type == "interactive") {
                fetch("http://whatsapp.bulk24sms.com/api/get-int-template-by-id", { method: "POST", headers: headers,
                    body: JSON.stringify({
                        "auth_key": wa_authKey,
                        "template_id": i.template_id,
                        "interactive_type": i.int_type,
                    })
                })
                .then(res => {
                    if (res.status == "401") noAuth()
                    return res.json()
                }).then(d => {
                    if (i.int_type == "list") {
                        api.interactive_btn = JSON.stringify(d.data)
                    } else {
                        d.data.map(t => {
                            extra.button_no = t.button_no
                            extra.button_id = t.button_id
                            extra.title = t.title
                            api.interactive_btn = JSON.stringify({ "button_no": t.button_no, "button_id": t.button_id, "title": t.title })
                        })
                    }
                })
                
                api.auth_key = wa_authKey;
                api.template_id = i.template_id;
                api.whatsapp_type = whatsapp_type;
                api.interactive_type = i.int_type;
                api.whatsapp_var = "";
               
                
               
                console.log(api)
            }
            

            const node_id = type + `-channel-${+Date.now()}`
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'data': {
                    'label': i.title,
                    'value': {
                        'parent': parent,
                        'type': type,
                        'api': api,
                        'description': "",
                        'extra': node_extra,
                    }
                },
                'position': {
                    'x': Math.random() * window.innerWidth + 100,
                    'y': Math.random() * window.innerHeight
                }
            };

            const edge1 = {
                "id": 'reactflow__edge-' + parent + '-' + node_id,
                "animated": false,
                "type": "customedge",
                'data': {
                    "delay": {
                        "days": 0,
                        "hours": 0,
                        "minutes": 0
                    },
                    "label": parent_conn,
                    "custom_condition": "",
                    "extra": extra
                },
                'source': parent,
                'target': node_id,
            }


            console.log(node1)
            console.log(edge1)

            // setNodes((nds) => nds.concat(node1));
            // test[0].nodes = test[0].nodes.concat(node1)


            // test[0].connection = test[0].connection.concat(edge1)
            // onConnect({
            //     'source': parent,
            //     'target': node_id,
            //     'sourceHandle': null,
            //     'targetHandle': null,
            //     'data': {
            //         "delay": {
            //             "days": 0,
            //             "hours": 0,
            //             "minutes": 0
            //         },
            //         "extra": extra,
            //         "label": parent_conn,
            //         "custom_condition": ""
            //     }
            // })
            // newSave()
            

        }
    }



    const template_select_start = (parent) => {
        // console.log(parent)
        const temp = (type, parent_conn) => {
            if (type == "funnel") {
                return funnel.map(i => {
                    return <li className="text-left">
                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.segment_name}</button>
                    </li>
                })
            }

            else if (type == "group") {
                return group.map(i => (
                    <li className="text-left">
                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)}>{i.group_name}</button>
                    </li>
                ))
            }

            else if (type == "whatsapp") {
                // console.log(waAllTemp)


                // whatsapp_type, phone_no
                // console.log(waAllInter)

               
                const WA_templates = (typo) => {
                    if (typo == 'interactive') {
                        return temp_data.map(i => {
                            if (i.type == "whatsapp" && i.whatsapp_type == 'interactive') {
                                return <li className="g text-left">
                                    <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)}>{i.template_name}</button>
                                </li>

                            }
                        })
                    } else if (typo == 'template') {
                        return temp_data.map(i => {
                            if (i.type == "whatsapp" && i.whatsapp_type == 'template') {
                                return <li className="text-left">
                                    <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.template_name}</button>
                                </li>
                            }
                        })
                    }
                }
                return (
                    <Fragment>
                        <span>Interactive</span>{WA_templates('interactive')}
                        <hr />
                        <span>Template</span>{WA_templates('template')}
                    </Fragment>
                )

               

                




            }
            return temp_data.map(i => {
                if (i.type == type) {
                    return <li className="text-left">
                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.template_name}</button>
                    </li>
                }
            })
        }

        return (
            <div className="w-as-100">
                <div className="group text-center p_20 bold_data">

                    <Menu>

                        <Menu.Button className="rounded-lg items-center">
                            <Handle
                                type="source"
                                position="right"
                                className="box_start_btn"
                            >
                                <div className='icon_btn'>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </div>

                            </Handle>
                        </Menu.Button>


                        <Menu.Items>
                            <ul className="as_new1">
                                <Menu.Item>
                                    <Fragment>
                                        <Menu>
                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                <Icons icon_type="sms" />Sms<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                            </Menu.Button>
                                            <Menu.Items>
                                                <ul className="as_new">{temp("sms", "All")}</ul>
                                            </Menu.Items>
                                        </Menu>
                                    </Fragment>
                                </Menu.Item>

                                <Menu.Item>
                                    <Fragment>
                                        <Menu>
                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                <Icons icon_type="voice" />Voice<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                            </Menu.Button>
                                            <Menu.Items>
                                                <ul className="as_new">{temp("voice", "All")}</ul>
                                            </Menu.Items>
                                        </Menu>
                                    </Fragment>
                                </Menu.Item>

                                <Menu.Item>
                                    <Fragment>
                                        <Menu>
                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                <Icons icon_type="whatsapp" />WhatsApp<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                            </Menu.Button>
                                            <Menu.Items>
                                                <ul className="as_new">{temp("whatsapp", "All")}</ul>
                                            </Menu.Items>
                                        </Menu>
                                    </Fragment>
                                </Menu.Item>

                                <Menu.Item>
                                    <Fragment>
                                        <Menu>
                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                <Icons icon_type="email" />Email<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                            </Menu.Button>
                                            <Menu.Items>
                                                <ul className="as_new">{temp("email", "All")}</ul>
                                            </Menu.Items>
                                        </Menu>
                                    </Fragment>
                                </Menu.Item>
                            </ul>
                        </Menu.Items>
                    </Menu>
                </div>
            </div>
        )
    }






    const wa_desgin = (datas) => {
        // console.log(datas.data.value)
        if (datas.data.value.type == "whatsapp") {
            const item = datas.data.value.extra
            if (item && item != "") {
                if (datas.data.value.api.whatsapp_type == "template") {
                    const temp_button_fun = (vals) => {
                        if (vals.length == 0) {
                            return ""
                        }
                        return (
                            <div className='int_header'>
                                <h5 className='int_heading'>Button</h5>
                                {vals.map((x, sec_ind) => {
                                    if (x.type == "QUICK_REPLY") {
                                        return (
                                            <div className='flex flex-row pb-2 items-stretch'>
                                                <div className='w-full'>
                                                    <p className='int_btn'>{x.text}</p>
                                                </div>

                                                <div className='relative'>
                                                    <Handle
                                                        type="source"
                                                        position="right"
                                                        id={`temp_btn-${x.text}-${sec_ind}`}
                                                        className="new_icon_box_n ml-3"
                                                        onClick={(e) => popups_fun(x.text + " Button", datas.id, `temp_btn-${x.text}-${sec_ind}`)}
                                                    >
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </Handle>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        )


                    }

                    return JSON.parse(item.companents).map(value => {

                        if (value.type == "HEADER") {

                            if (value.format == "TEXT") {
                                return (
                                    <Fragment>
                                        <div className='int_header'>
                                            <h5 className='int_heading'>Header</h5>
                                            <p>{value.text}</p>
                                        </div>
                                    </Fragment>
                                )
                            } else if (value.format == "IMAGE") {
                                // console.log(value.example.header_handle[0])
                                return (<div className='int_header'>
                                    <h5 className='int_heading'>Header</h5>
                                    <img src={value.example.header_handle[0]} />
                                </div>)

                            } else if (value.format == "DOCUMENT") {

                            }
                        } else if (value.type == "BODY") {
                            return (
                                <Fragment>
                                    <div className='int_header'>
                                        <h5 className='int_heading'>Body</h5>
                                        <p>{value.text}</p>
                                    </div>
                                </Fragment>
                            )
                        } else if (value.type == "BUTTONS") {
                            return temp_button_fun(value.buttons)
                        }
                    })
                } else if (datas.data.value.api.whatsapp_type == "interactive") {
                    const header_fun = (i) => {
                        if (i.header_data != "" && i.header_data != null) {
                            if (i.header_type == "text") {
                                return (
                                    <div className='int_header'>
                                        <h5 className='int_heading'>Header</h5>
                                        <p>{i.header_data}</p>
                                    </div>
                                )
                            } else if (i.header_type == "image") {
                                return (
                                    <div className='int_header'>
                                        <h5 className='int_heading'>Header</h5>
                                        <img src={i.header_data} alt="WA Header Image" />
                                    </div>
                                )
                            }

                        }
                    }

                    const footer_fun = (i) => {
                        if (i.footer != "" && i.footer != null) {
                            return (
                                <div className='int_header'>
                                    <h5 className='int_heading'>Footer</h5>
                                    <p>{i.footer}</p>
                                </div>

                            )
                        }
                    }

                    const button_fun = (i) => {
                        const main_arr = JSON.parse(datas.data.value.api.interactive_btn)
                        if (datas.data.value.api.interactive_type == "list") {
                            const list_fun = () => {
                                return main_arr.map((x, sec_ind) => {
                                    return (
                                        <div className='flex flex-row pb-2 items-stretch'>
                                            <div className='w-full'>
                                                <p className='int_btn'>{x.row_title}</p>
                                            </div>


                                            <div className='relative'>
                                                <Handle
                                                    type="source"
                                                    position="right"
                                                    id={`list_btn-${x.row_title}-${sec_ind}`}
                                                    className="new_icon_box_n ml-3"
                                                    onClick={(e) => popups_fun(x.row_title + " List(Button)", datas.id, `list_btn-${x.row_title}-${sec_ind}`)}
                                                >
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </Handle>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            return (
                                <div className="int_header">
                                    <h5 className='int_heading'>Button</h5>
                                    {list_fun()}
                                </div>
                            )
                        } else if (datas.data.value.api.interactive_type == "button") {

                            const btn_fun = () => {
                                return main_arr.map((x, sec_ind) => {
                                    return (
                                        <div className='flex flex-row pb-2 items-stretch'>
                                            <div className='w-full'>
                                                <p className='int_btn'>{x.title}</p>
                                            </div>

                                            <div className='relative'>
                                                <Handle
                                                    type="source"
                                                    position="right"
                                                    id={`button_btn-${x.title}-${sec_ind}`}
                                                    className="new_icon_box_n ml-3"
                                                    onClick={(e) => popups_fun(x.title + " Button", datas.id, `button_btn-${x.title}-${sec_ind}`)}
                                                >
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </Handle>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            return (
                                <div className="int_header">
                                    <h5 className='int_heading'>Button</h5>
                                    {btn_fun()}
                                </div>
                            )
                        }
                    }

                    return (
                        <Fragment>
                            {header_fun(item)}
                            <div className='int_header'>
                                <h5 className='int_heading'>Body</h5>
                                <p>{item.body}</p>
                            </div>
                            {footer_fun(item)}
                            {button_fun(item)}
                        </Fragment>
                    )
                }
            }

        }
    }


    const popups_fun = (condition, node_id, handle) => {
        // console.log([node_id, condition, handle])
        setTempPopUp([node_id, condition, handle])
    }


    const onConnectStart = useCallback((_, { nodeId }) => {

        if (_.target.attributes['data-handleid']) {
            const text = _.target.attributes['data-handleid'].value
            const slices = text.split("-")
            let condition = ""
            if (slices[0] == "temp_btn" || slices[0] == "button_btn") {
                condition = slices[1] + " Button"
            } else if (slices[0] == "list_btn") {
                condition = slices[1] + " List(Button)"
            }

            connectingNodeId.current = nodeId + "#$#" + condition + "#$#" + text
        }


    }, []);



    const onConnectEnd = useCallback(
        (event) => {
            if (!connectingNodeId.current) return;
            const targetIsPane = event.target.classList.contains('react-flow__pane');
            if (targetIsPane) {
                setTempPopUp(connectingNodeId.current.split("#$#"))
            }
        },
        [],
    );


    const waNodeAdd = (type, parent, cond, template_id, handle) => {
        // console.log(handle)
        if (type) {
            if (type === "funnel") {
                let api = {
                    'campaign_name': template_id.segment_name,
                    'sender': template_id.id,
                }
                let extra = { "srcHandle": handle }
                const node_id = type + `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.segment_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api': api,
                            'description': "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id": 'reactflow__edge-' + parent + '-' + node_id,
                    "animated": false,
                    "type": "customedge",
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    },
                    'source': parent,
                    'target': node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)

                test[0].connection = test[0].connection.concat(edge1)

                onConnect({
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': handle,
                    'targetHandle': null,
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    }
                })


                newSave()
            } else if (type === "group") {
                let api = {
                    'campaign_name': template_id.group_name,
                    'sender': template_id.id,
                }
                let extra = { "srcHandle": handle }
                const node_id = type + `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.group_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api': api,
                            'description': "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id": 'reactflow__edge-' + parent + '-' + node_id,
                    "animated": false,
                    "type": "customedge",
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    },
                    'source': parent,
                    'target': node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)

                test[0].connection = test[0].connection.concat(edge1)

                onConnect({
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': handle,
                    'targetHandle': null,
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    }
                })

                newSave()
            } else {
                let api = {
                    'campaign_name': template_id.template_name,
                    'sender': template_id.sender,
                }
                let extra = { "srcHandle": handle }


                if (type === 'sms') {
                    api.route = template_id.route;
                    api.template_id = template_id.template_id;
                    api.coding = template_id.coding;
                    api.pe_id = template_id.pe_id;
                    api.content = template_id.message;
                    api.auth_key = template_id.auth_key;
                } else if (type === 'email') {
                    api.subject = template_id.subject;
                    api.content = template_id.message;
                    api.sender_name = template_id.sender_name;
                } else if (type === 'voice') {
                    api.content = template_id.message;
                    api.route = template_id.route;
                    api.voice_type = 'text';
                    api.voice_duration = '30';
                    api.auth_key = template_id.auth_key;
                } else if (type === 'whatsapp') {
                    api.whatsapp_var = template_id.whatsapp_var;
                    api.template_id = template_id.template_id;
                    api.auth_key = template_id.auth_key;
                    api.whatsapp_type = template_id.whatsapp_type;
                    api.interactive_btn = template_id.interactive_btn;
                    api.interactive_type = template_id.interactive_type;
                    if (template_id.whatsapp_type == "interactive") {
                        if (template_id.interactive_type == "button") {
                            JSON.parse(template_id.interactive_btn).map(t => {
                                if (t.title == cond.split(" ")[0]) {
                                    extra.button_no = t.button_no
                                    extra.button_id = t.button_id
                                    extra.title = t.title
                                }
                            })
                        }
                    }
                }

                const node_id = type + `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.template_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api': api,
                            'description': "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id": 'reactflow__edge-' + parent + '-' + node_id,
                    "animated": false,
                    "type": "customedge",
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    },
                    'source': parent,
                    'target': node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)


                test[0].connection = test[0].connection.concat(edge1)
                onConnect({
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': handle,
                    'targetHandle': null,
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "extra": extra,
                        "label": cond,
                        "custom_condition": ""
                    }
                })
                newSave()
            }
        }
    }
    const temp_pop_up = (parent, param, handle) => {

        const temp = (type, parent_conn) => {
            if (type == "funnel") {
                return funnel.map(i => {
                    return <li className="text-left">
                        <button type="button" onClick={(e) => waNodeAdd(type, parent, parent_conn, i, handle)} >{i.segment_name}</button>
                    </li>
                })
            }

            else if (type == "group") {
                return group.map(i => (
                    <li className="text-left">
                        <button type="button" onClick={(e) => waNodeAdd(type, parent, parent_conn, i, handle)}>{i.group_name}</button>
                    </li>
                ))
            }

            else if (type == "whatsapp") {

                const WA_templates = (typo) => {
                    if (typo == 'interactive') {
                        return temp_data.map(i => {
                            if (i.type == "whatsapp" && i.whatsapp_type == 'interactive') {
                                return <li className="text-left">
                                    <button type="button" onClick={(e) => waNodeAdd(type, parent, parent_conn, i, handle)}>{i.template_name}</button>
                                </li>

                            }
                        })
                    } else if (typo == 'template') {
                        return temp_data.map(i => {
                            if (i.type == "whatsapp" && i.whatsapp_type == 'template') {
                                return <li className="text-left">
                                    <button type="button" onClick={(e) => waNodeAdd(type, parent, parent_conn, i, handle)} >{i.template_name}</button>
                                </li>
                            }
                        })
                    }
                }

                return (
                    <Fragment>
                        <span>Interactive</span>{WA_templates('interactive')}
                        <hr />
                        <span>Template</span>{WA_templates('template')}
                    </Fragment>
                )

            }
            return temp_data.map(i => {
                if (i.type == type) {
                    return <li className="text-left">
                        <button type="button" onClick={(e) => waNodeAdd(type, parent, parent_conn, i, handle)} >{i.template_name}</button>
                    </li>
                }
            })
        }

        return (
            <Fragment>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold"> Edge Edit </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setTempPopUp(["", ""])}
                                >
                                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <div className="relative p-3 flex-auto">
                                <form onSubmit={editEdgeForm}>

                                    <div className="w-as-100">
                                        <div className="group text-center p_20 bold_data">
                                            <Menu>
                                                <Menu.Button className="rounded-lg bg-white items-center">
                                                    Add Channel<i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                                                </Menu.Button>
                                                <Menu.Items>
                                                    <ul className="as_new1">
                                                        <Menu.Item>
                                                            <Fragment>
                                                                <Menu>
                                                                    <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                        <Icons icon_type="sms" />Sms<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                    </Menu.Button>
                                                                    <Menu.Items>
                                                                        <ul className="as_new">{temp("sms", param)}</ul>
                                                                    </Menu.Items>
                                                                </Menu>
                                                            </Fragment>
                                                        </Menu.Item>

                                                        <Menu.Item>
                                                            <Fragment>
                                                                <Menu>
                                                                    <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                        <Icons icon_type="voice" />Voice<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                    </Menu.Button>
                                                                    <Menu.Items>
                                                                        <ul className="as_new">{temp("voice", param)}</ul>
                                                                    </Menu.Items>
                                                                </Menu>
                                                            </Fragment>
                                                        </Menu.Item>

                                                        <Menu.Item>
                                                            <Fragment>
                                                                <Menu>
                                                                    <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                        <Icons icon_type="whatsapp" />WhatsApp<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                    </Menu.Button>
                                                                    <Menu.Items>
                                                                        <ul className="as_new">{temp("whatsapp", param)}</ul>
                                                                    </Menu.Items>
                                                                </Menu>
                                                            </Fragment>
                                                        </Menu.Item>

                                                        <Menu.Item>
                                                            <Fragment>
                                                                <Menu>
                                                                    <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                        <Icons icon_type="email" />Email<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                    </Menu.Button>
                                                                    <Menu.Items>
                                                                        <ul className="as_new">{temp("email", param)}</ul>
                                                                    </Menu.Items>
                                                                </Menu>
                                                            </Fragment>
                                                        </Menu.Item>
                                                    </ul>
                                                </Menu.Items>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-end border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                            onClick={() => setTempPopUp(["", ""])}
                                            type="button"
                                        >
                                            <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                            <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </Fragment>
        )
    }





    const template_select_non = (parent_type, parent, datas) => {
        // console.log(parent)
        const temp = (type, parent_conn) => {
            if (type == "funnel") {
                return funnel.map(i => {
                    return <li className="text-left">
                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.segment_name}</button>
                    </li>
                })
            }

            else if (type == "group") {
                return group.map(i => (
                    <li className="text-left">
                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)}>{i.group_name}</button>
                    </li>
                ))
            }

            else if (type == "whatsapp") {

                const WA_templates = (typo) => {
                    if (typo == 'interactive') {
                        return temp_data.map(i => {
                            if (i.type == "whatsapp" && i.whatsapp_type == 'interactive') {
                                return <li className="g text-left">
                                    <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)}>{i.template_name}</button>
                                </li>

                            }
                        })
                    } else if (typo == 'template') {
                        return temp_data.map(i => {
                            if (i.type == "whatsapp" && i.whatsapp_type == 'template') {
                                return <li className="text-left">
                                    <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.template_name}</button>
                                </li>
                            }
                        })
                    }
                }

                return (
                    <Fragment>
                        <span>Interactive</span>{WA_templates('interactive')}
                        <hr />
                        <span>Template</span>{WA_templates('template')}
                    </Fragment>
                )

            }
            return temp_data.map(i => {
                if (i.type == type) {
                    return <li className="text-left">
                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.template_name}</button>
                    </li>
                }
            })
        }

        return (
            <div className="w-as-100">
                <div className="group text-center p_20 bold_data">
                    <Menu>
                        <Menu.Button className="rounded-lg bg-white items-center">
                            Add Channel<i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                        </Menu.Button>
                        <Menu.Items>
                            <ul className="as_new1">
                                <Menu.Item>
                                    <Fragment>
                                        <Menu>
                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                <Icons icon_type="sms" />Sms<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                            </Menu.Button>
                                            <Menu.Items>
                                                <ul className="as_new">{temp("sms", "All")}</ul>
                                            </Menu.Items>
                                        </Menu>
                                    </Fragment>
                                </Menu.Item>

                                <Menu.Item>
                                    <Fragment>
                                        <Menu>
                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                <Icons icon_type="voice" />Voice<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                            </Menu.Button>
                                            <Menu.Items>
                                                <ul className="as_new">{temp("voice", "All")}</ul>
                                            </Menu.Items>
                                        </Menu>
                                    </Fragment>
                                </Menu.Item>

                                <Menu.Item>
                                    <Fragment>
                                        <Menu>
                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                <Icons icon_type="whatsapp" />WhatsApp<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                            </Menu.Button>
                                            <Menu.Items>
                                                <ul className="as_new">{temp("whatsapp", "All")}</ul>
                                            </Menu.Items>
                                        </Menu>
                                    </Fragment>
                                </Menu.Item>

                                <Menu.Item>
                                    <Fragment>
                                        <Menu>
                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                <Icons icon_type="email" />Email<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                            </Menu.Button>
                                            <Menu.Items>
                                                <ul className="as_new">{temp("email", "All")}</ul>
                                            </Menu.Items>
                                        </Menu>
                                    </Fragment>
                                </Menu.Item>
                            </ul>
                        </Menu.Items>
                    </Menu>
                </div>
            </div>
        )
    }


    const template_select = (parent_type, parent, datas) => {
        const temp = (type, parent_conn) => {
            if (type == "funnel") {
                return funnel.map(i => (
                    <li className="text-left">
                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.segment_name}</button>
                    </li>
                ))
            }
            else if (type == "group") {
                return group.map(i => (
                    <li className="text-left">
                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.group_name}</button>
                    </li>
                ))
            }

            else if (type == "whatsapp") {

                const WA_templates2 = (typo) => {
                    if (typo == 'interactive') {
                        return waAllInter.interactive.map(i => {
                            return <li className="g text-left">
                                <button type="button" onClick={(e) => openFormWA(type, parent, parent_conn, i, typo, waAllInter.phone_details[0])} >{i.title}</button>
                            </li>
                        })
                    } else if (typo == 'template') {
                        return waAllTemp.template.map(i => {
                            return <li className="text-left">
                                <button type="button" onClick={(e) => openFormWA(type, parent, parent_conn, i, typo, waAllTemp.phone_details[0])} >{i.template_name}</button>
                            </li>
                        })
                    }
                }

                const WA_templates = (typo) => {
                    if (typo == 'interactive') {
                        return temp_data.map(i => {
                            if (i.type == "whatsapp" && i.whatsapp_type == 'interactive') {
                                return (
                                    <li className="text-left">
                                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)}>{i.template_name}</button>
                                    </li>
                                )
                            }
                        })
                    } else if (typo == 'template') {
                        return temp_data.map(i => {
                            if (i.type == "whatsapp" && i.whatsapp_type == 'template') {
                                return (
                                    <li className="text-left">
                                        <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.template_name}</button>
                                    </li>
                                )
                            }
                        })
                    }
                }

                return (
                    <Fragment>
                        <span>Interactive</span>{WA_templates2('interactive')}
                        <hr />
                        <span>Template</span>{WA_templates2('template')}
                    </Fragment>
                )
            }

            return temp_data.map(i => {
                if (i.type == type) {
                    return (
                        <li className="text-left">
                            <button type="button" onClick={(e) => openForm(type, parent, parent_conn, i)} >{i.template_name}</button>
                        </li>
                    )
                }
            })
        }

        let temp_type = "";
        let temp_wa_type = "";
        let temp_datas = "";

        if (parent_type == "whatsapp") {
            if (datas.data.value.api.interactive_type == "button" || datas.data.value.api.interactive_type == "list") {
                temp_type = datas.data.value.api.whatsapp_type
                temp_wa_type = datas.data.value.api.interactive_type
                temp_datas = datas.data.value.api.interactive_btn
            }
        }

        const parent_conn = childCondition(parent_type, parent, temp_type, temp_wa_type, temp_datas)
        return (
            <Fragment>
                <div className="w-as-100">
                    <div className="group text-center p_20 bold_data">
                        <Menu>
                            <Menu.Button className="rounded-lg bg-white items-center">
                                Add Channel<i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                            </Menu.Button>
                            <Menu.Items>
                                <ul className="as_new1">
                                    {
                                        parent_conn.map(i => {
                                            return (
                                                <Menu.Item>
                                                    <Fragment>
                                                        <Menu>
                                                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                {i}<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                            </Menu.Button>
                                                            <Menu.Items>
                                                                <ul className="as_new">
                                                                    <Menu.Item>
                                                                        <Fragment>
                                                                            <Menu>
                                                                                <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                                    <Icons icon_type="sms" />Sms<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                                </Menu.Button>
                                                                                <Menu.Items>
                                                                                    <ul className="as_new">{temp("sms", i)}</ul>
                                                                                </Menu.Items>
                                                                            </Menu>
                                                                        </Fragment>
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        <Fragment>
                                                                            <Menu>
                                                                                <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                                    <Icons icon_type="voice" />Voice<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                                </Menu.Button>
                                                                                <Menu.Items>
                                                                                    <ul className="as_new">{temp("voice", i)}</ul>
                                                                                </Menu.Items>
                                                                            </Menu>
                                                                        </Fragment>
                                                                    </Menu.Item>

                                                                    <Menu.Item>
                                                                        <Fragment>
                                                                            <Menu>
                                                                                <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                                    <Icons icon_type="email" />Email<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                                </Menu.Button>
                                                                                <Menu.Items>
                                                                                    <ul className="as_new">{temp("email", i)}</ul>
                                                                                </Menu.Items>
                                                                            </Menu>
                                                                        </Fragment>
                                                                    </Menu.Item>

                                                                    <Menu.Item>
                                                                        <Fragment>
                                                                            <Menu>
                                                                                <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                                    <Icons icon_type="whatsapp" />Whatsapp<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                                </Menu.Button>
                                                                                <Menu.Items>
                                                                                    <ul className="as_new">{temp("whatsapp", i)}</ul>
                                                                                </Menu.Items>
                                                                            </Menu>
                                                                        </Fragment>
                                                                    </Menu.Item>


                                                                    <Menu.Item>
                                                                        <Fragment>
                                                                            <Menu>
                                                                                <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                                    <Icons icon_type="funnel" />funnel<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                                </Menu.Button>
                                                                                <Menu.Items>
                                                                                    <ul className="as_new">{temp("funnel", i)}</ul>
                                                                                </Menu.Items>
                                                                            </Menu>
                                                                        </Fragment>
                                                                    </Menu.Item>

                                                                    <Menu.Item>
                                                                        <Fragment>
                                                                            <Menu>
                                                                                <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                                                                    <Icons icon_type="group" />group<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                                                </Menu.Button>
                                                                                <Menu.Items>
                                                                                    <ul className="as_new">{temp("group", i)}</ul>
                                                                                </Menu.Items>
                                                                            </Menu>
                                                                        </Fragment>
                                                                    </Menu.Item>
                                                                </ul>
                                                            </Menu.Items>
                                                        </Menu>
                                                    </Fragment>
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </ul>
                            </Menu.Items>
                        </Menu>
                    </div>
                </div>


            </Fragment>
        )
    }


    // <li>
    //     <button className="w-full text-left flex items-center outline-none">
    //         <Icons icon_type="funnel" />Funnel<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
    //     </button>
    //     <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
    //         {temp("funnel", i)}
    //     </ul>
    // </li>

    // <li>
    //     <button className="w-full text-left flex items-center outline-none">
    //         <Icons icon_type="group" />Group<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
    //     </button>
    //     <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
    //         {temp("group", i)}
    //     </ul>
    // </li>



    const childCondition = (parent, parent_id, temp_type, temp_wa_type, temp_datas) => {
        if (parent === "start") {
            return ["All"]
        } else if (parent === "sms") {
            return ["All", "Sms Deliver", "Sms Sent", "Sms Fail", "Sms Reject"]
        } else if (parent === "email") {
            return ["All", "Email Deliver", "Email Sent", "Email Fail"]
        } else if (parent === "voice") {
            return [
                "All",
                "Voice Deliver",
                "Voice Sent",
                "Voice Fail",
                "Voice Reject",
                "Response 1",
                "Response 2",
                "Response 3",
                "Response 4",
                "Response 5",
                "Response 6",
                "Response 7",
                "Response 8",
                "Response 9",
            ]
        } else if (parent === "whatsapp") {
            let t = [
                "All",
                "WhatsApp Deliver",
                "WhatsApp Sent",
                "WhatsApp Fail",
            ]
            if (temp_type == "interactive") {
                if (temp_wa_type == "button") {
                    JSON.parse(temp_datas).map(x => {
                        t.push(x.title + " Button")
                    })
                }

                else if (temp_wa_type == "list") {
                    JSON.parse(temp_datas).map(x => {
                        t.push(x.row_title + " List(Button)")
                    })
                }
            } else if (temp_type == "template") {
                if (temp_wa_type == "button") {
                    JSON.parse(temp_datas).map(x => {
                        t.push(x.text + " Button")
                    })
                }
            }
            return t
        } else if (parent === "funnel" || parent === "group") {
            return ["All"];
        }
        else {
            return [];
        }
    }


    const CustomEdge = (event) => {
        const id = event.id;
        let OgsourceX = event.sourceX
        let sourceX = event.sourceX
        let sourceY = event.sourceY
        let sourcePosition = event.sourcePosition
        let targetX = event.targetX
        let targetY = event.targetY
        let targetPosition = event.targetPosition


        // console.log({ OgsourceX, sourceX})






        const [edgePath, labelX, labelY, offsetX, offsetY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });



        const cond = (con) => {
            if (con === "" || con === null) {
                return <p></p>
            } else {
                const conn_des = () => {
                    function addZero(num) {
                        return (num < 10 ? '0' : '') + num;
                    }

                    if (con.split(" ")[1] == "=") {
                        return <p>When {event.data.custom_condition.split(" ")[0]} is {event.data.custom_condition.split(" ")[2]}</p>
                    }

                    else if (con.split(" ")[1] == "!=") {
                        return <p>When {event.data.custom_condition.split(" ")[0]} is not equal to  {event.data.custom_condition.split(" ")[2]}</p>
                    }

                    else if (con.split(" ")[1] == ">") {
                        return <p>When {event.data.custom_condition.split(" ")[0]} is Greater Than to  {event.data.custom_condition.split(" ")[2]}</p>
                    }

                    else if (con.split(" ")[1] == ">=") {
                        return <p>When {event.data.custom_condition.split(" ")[0]} is Greater Than and equal to  {event.data.custom_condition.split(" ")[2]}</p>
                    }

                    else if (con.split(" ")[1] == "<") {
                        return <p>When {event.data.custom_condition.split(" ")[0]} is Less Than to  {event.data.custom_condition.split(" ")[2]}</p>
                    }

                    else if (con.split(" ")[1] == "<=") {
                        return <p>When {event.data.custom_condition.split(" ")[0]} is Less Than and equal to  {event.data.custom_condition.split(" ")[2]}</p>
                    }

                    else if (con.split(" ")[1] == "before") {
                        return <p>Send Before {event.data.custom_condition.split(" ")[2]} Days of {event.data.custom_condition.split(" ")[0]} </p>
                    }

                    else if (con.split(" ")[1] == "after") {
                        return <p>Send After {event.data.custom_condition.split(" ")[2]} Days of {event.data.custom_condition.split(" ")[0]} </p>
                    }

                    else if (con.split(" ")[1] == "today") {
                        return <p>Send if Today has {event.data.custom_condition.split(" ")[0]} </p>
                    }

                    else if (con.split(" ")[1] == "not_today") {
                        return <p>Send if Today has not {event.data.custom_condition.split(" ")[0]} </p>
                    }

                    else if (con.split(" ")[1] == "range" || con.split(" ")[1] == "not_range") {
                        const f_date = event.data.custom_condition.split(" ")[2]
                        const f_new_date = new Date(f_date.split("-")[0] * 1000)
                        const final_f_date = f_new_date.getFullYear() + '-' + addZero(f_new_date.getMonth() + 1) + '-' + addZero(f_new_date.getDate())

                        const t_date = event.data.custom_condition.split(" ")[2]
                        const t_new_date = new Date(t_date.split("-")[1] * 1000)
                        const final_t_date = t_new_date.getFullYear() + '-' + addZero(t_new_date.getMonth() + 1) + '-' + addZero(t_new_date.getDate())

                        if (con.split(" ")[1] == "range") {
                            return <p>If  {event.data.custom_condition.split(" ")[0]} is between {final_f_date} to {final_t_date}  </p>
                        } else if (con.split(" ")[1] == "not_range") {
                            return <p>If  {event.data.custom_condition.split(" ")[0]} is not between {final_f_date} to {final_t_date}  </p>
                        }
                    }
                }
                return <div className="p_20"><div className="w-as-100">{conn_des()}</div></div>
            }
        }

        const time_pause = (time) => {
            if (time.days == "0" && time.hours == "0" && time.minutes == "0") {
                return ""
            } else {
                return (
                    <div className="p_20_0px">
                        <div className="w-as-100">
                            <p>
                                <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                                {time.days} Days {time.hours} hrs {time.minutes} mins
                            </p>
                        </div>
                    </div>
                )
            }
        }


        const base_edge = () => {
            if (event.sourceHandleId != null) {
                return <BaseEdge path={edgePath} />
            } else {
                return <BaseEdge path={edgePath} label={event.data.label} />
            }
        }

        return (
            <Fragment>
                {base_edge()}

                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            fontSize: 12,
                            pointerEvents: 'all',
                        }}
                    >
                        <div className="Condition_bx_as">
                            <div className="Condition_bx_as_hedding">
                                <h4><i className=" fa fa-code-fork" aria-hidden="true"></i> Condition - {event.data.label} </h4>
                            </div>

                            <div className="Condition_bx_as_data">
                                {time_pause(event.data.delay)}
                                <div className="cl"></div>
                                <div className="line_as"></div>
                                <div className="cl"></div>
                                {event.data.custom_condition === "" ? ("") : (cond(event.data.custom_condition))}
                                <div className="cl"></div>
                                <div className="line_as"></div>
                                <div className="cl"></div>
                                <div className="p_20_0px">
                                    <div className="w-as-100">
                                        <div className="new_btn_hover flex">
                                            <button className="Filter_btn basis-1/3 mx-2" onClick={() => setFilter([true, event, "filter"])}>
                                                <i className="fa fa-filter" aria-hidden="true"></i>Filter
                                            </button>
                                            <button className="Delay_btn basis-1/3 mx-2" onClick={() => setFilter([true, event, "time"])}>
                                                <i className="fa fa-clock-o" aria-hidden="true"></i> Delay
                                            </button>
                                            <button className="Update_btn basis-1/3 mx-2" onClick={() => setEdgeEdit([true, event])}>
                                                <i className=" fa fa-pencil-square-o" aria-hidden="true"></i> Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </EdgeLabelRenderer>
            </Fragment>
        );
    }

    const onConnect = useCallback((params) => {
        const arr = params.source.split("-");
        if (arr[0] === 'sms') {
            setRule(["Sms Deliver", "Sms Sent", "Sms Fail", "Sms Reject"])
        } else if (arr[0] === 'email') {
            setRule(["Email Sent", "Email Deliver", "Email Fail"])
        } else if (arr[0] === 'voice') {
            setRule(["Voice Deliver", "Voice Sent", "Voice Fail", "Voice Reject"])
        } else if (arr[0] === 'whatsapp') {
            setRule(["WhatsApp Deliver", "WhatsApp Sent", "WhatsApp Fail"])
        }
        setEdges((eds) => addEdge({
            ...params,
            id: 'reactflow__edge-' + params.source + '-' + params.target,
            animated: false,
            type: "customedge"
        }, eds))
    }, [setEdges]);


    const nodeTypes = useMemo(() => ({ customnode: CustomNode }), []);
    const edgeTypes = useMemo(() => ({ customedge: CustomEdge }), []);


    const onSave = useCallback(() => {
        if (rfInstance) {
            const flow = rfInstance.toObject();

            PostFetcher(host + "/save_flow", headers, {
                flow_data: { flow: flow, flow_id: id },
            }).then((result) => {
                if (result.status === 200) {
                    return toast.success(result.message)
                } else {
                    return toast.success(result.message)
                }
            })
        }
    }, [rfInstance]);


    const OndeleteNode = (event, node_id) => {
        var nodes = test[0].nodes;
        const getRecursiveChildren = (id) => {
            let chiled = [];
            nodes.forEach((x, i) => {
                if (x.data.value.parent === id) {
                    chiled.push(x.id)
                    chiled.push(...getRecursiveChildren(x.id))
                }
            });
            return chiled;
        }
        console.log(node_id)
        nodes.filter((el) => {
            if (el.data.value.type !== "start") {
                if (el.id === node_id) {
                    let t = [el.id]
                    t.push(...getRecursiveChildren(el.id))
                    t.forEach((x) => {
                        nodes.filter((val, i) => {
                            if (x === val.id) {
                                nodes.splice(i, 1);
                            }
                        });
                    });
                    PostFetcher(host + "/node_del", headers, {
                        node_id: el.id, flow_id: id
                    })
                        .then(result => {
                            if (result.status === 200) {
                                return toast.success(result.message)
                            } else {
                                return toast.success(result.message)
                            }
                        })
                    return el;
                }
            }
        });
        test[0].nodes = nodes
        setNodes(nodes)
    }
    const OnUpdateNode = (eve, node_id) => {
        var nodes = test[0].nodes;
        var ele = nodes.filter((x) => {
            if (x.id === node_id) {
                return x;
            }
        });
        setViewUpdate([node_id, ele[0]])
        setUpdate(true)
    }


    const newSave = () => {
        const flow_d = {
            nodes: test[0].nodes,
            edges: test[0].connection,
            viewport: test[0].details,
        }

        PostFetcher(host + "/save_flow", headers, {
            flow_data: { flow: flow_d, flow_id: id },
        })
            .then(result => {
                if (result.status === 200) {
                    return toast.success(result.message)
                } else {
                    return toast.success(result.message)
                }
            })
    }
    const openForm = (type, parent, cond, template_id) => {
        if (type) {
            if (type === "funnel") {
                let api = {
                    'campaign_name': template_id.segment_name,
                    'sender': template_id.id,
                }
                let extra = {}
                const node_id = type + `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.segment_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api': api,
                            'description': "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id": 'reactflow__edge-' + parent + '-' + node_id,
                    "animated": false,
                    "type": "customedge",
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    },
                    'source': parent,
                    'target': node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)

                test[0].connection = test[0].connection.concat(edge1)

                onConnect({
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': null,
                    'targetHandle': null,
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    }
                })


                newSave()
            } else if (type === "group") {
                let api = {
                    'campaign_name': template_id.group_name,
                    'sender': template_id.id,
                }
                let extra = {}
                const node_id = type + `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.group_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api': api,
                            'description': "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id": 'reactflow__edge-' + parent + '-' + node_id,
                    "animated": false,
                    "type": "customedge",
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    },
                    'source': parent,
                    'target': node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)

                test[0].connection = test[0].connection.concat(edge1)

                onConnect({
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': null,
                    'targetHandle': null,
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    }
                })

                newSave()
            } else {
                let api = {
                    'campaign_name': template_id.template_name,
                    'sender': template_id.sender,
                }
                let extra = {}

                if (type === 'sms') {
                    api.route = template_id.route;
                    api.template_id = template_id.template_id;
                    api.coding = template_id.coding;
                    api.pe_id = template_id.pe_id;
                    api.content = template_id.message;
                    api.auth_key = template_id.auth_key;
                } else if (type === 'email') {
                    api.subject = template_id.subject;
                    api.content = template_id.message;
                    api.sender_name = template_id.sender_name;
                } else if (type === 'voice') {
                    api.content = template_id.message;
                    api.route = template_id.route;
                    api.voice_type = 'text';
                    api.voice_duration = '30';
                    api.auth_key = template_id.auth_key;
                } else if (type === 'whatsapp') {
                    api.whatsapp_var = template_id.whatsapp_var;
                    api.template_id = template_id.template_id;
                    api.auth_key = template_id.auth_key;
                    api.whatsapp_type = template_id.whatsapp_type;
                    api.interactive_btn = template_id.interactive_btn;
                    api.interactive_type = template_id.interactive_type;
                    if (template_id.whatsapp_type == "interactive") {
                        if (template_id.interactive_type == "button") {
                            JSON.parse(template_id.interactive_btn).map(t => {
                                if (t.title == cond.split(" ")[0]) {
                                    extra.button_no = t.button_no
                                    extra.button_id = t.button_id
                                    extra.title = t.title
                                }
                            })
                        }
                    }
                }

                let node_extra = ""
                if (type === 'whatsapp') {
                    wa_temp.map((iteams) => {
                        if (template_id.template_id == iteams.template_id) {
                            node_extra = iteams
                        }
                    })
                }
                console.log(wa_temp)

                const node_id = type + `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.template_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api': api,
                            'description': "",
                            'extra': node_extra,
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id": 'reactflow__edge-' + parent + '-' + node_id,
                    "animated": false,
                    "type": "customedge",
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "label": cond,
                        "custom_condition": "",
                        "extra": extra
                    },
                    'source': parent,
                    'target': node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)


                test[0].connection = test[0].connection.concat(edge1)
                onConnect({
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': null,
                    'targetHandle': null,
                    'data': {
                        "delay": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0
                        },
                        "extra": extra,
                        "label": cond,
                        "custom_condition": ""
                    }
                })
                newSave()
            }
        }
    }

    const customCondition = () => {
        return (
            <Fragment>
                <div className="flex-1 p-2" >
                    <select name="custom_col" className="w-full" required="required">
                        <option selected disabled className="font-semibold">Select Variable </option>
                        {customCol[0] === [] ? ("") : (customCol[0].map((item) => <option className="font-semibold" value={item.column_name}>{item.column_name}</option>))}
                    </select>
                </div>
                <div className="flex-1 p-2">
                    <select name="custom_condition" className="w-full" required="required">
                        <option selected disabled required="required" className="font-semibold">Select Operator</option>
                        <option className="font-semibold" value="=">Is</option>
                        <option className="font-semibold" value="!=">Is Not</option>
                        <option className="font-semibold" value=">">Greater Than</option>
                        <option className="font-semibold" value=">=">Greater Than or equal</option>
                        <option className="font-semibold" value="<">Less Than</option>
                        <option className="font-semibold" value="<=">Less Than or equal</option>
                    </select>
                </div>

                <div className="flex-1  p-2 ">
                    <input className="w-full" name="custom_val" type="text" placeholder="Enter Value" required="required" />
                </div>
            </Fragment>
        )
    }

    const actionEdgeEdit = (e) => {
        e.preventDefault();

        if (test.length !== 0) {
            var edge = test[0].connection;
            const edge_id = e.target.edge_id.value
            if (e.target.type.value == "time") {
                const data = {
                    "days": e.target.days.value,
                    "hours": e.target.hours.value,
                    "minutes": e.target.minutes.value
                }
                edge.filter((el, index) => {
                    if (el.id === edge_id) {
                        el.data.delay = data;
                    }
                });
            } else if (e.target.type.value == "filter") {
                const col_v = e.target.custom_col.value
                const conn_v = e.target.custom_condition.value
                const type_v = e.target.column_type.value

                let custom = ""

                if (type_v == "date") {
                    if (conn_v == "range" || conn_v == "not_range") {
                        custom = col_v + " " + conn_v + " " + e.target.custom_val_from.valueAsNumber / 1000 + "-" + e.target.custom_val_to.valueAsNumber / 1000
                    } else if (conn_v == "today" || conn_v == "not_today") {
                        custom = col_v + " " + conn_v
                    } else if (conn_v == "=" || conn_v == "!=") {
                        custom = col_v + " " + conn_v + " " + e.target.custom_val.valueAsNumber / 1000
                    } else {
                        custom = col_v + " " + conn_v + " " + e.target.custom_val.value
                    }
                } else {
                    if (conn_v == "range" || conn_v == "not_range") {
                        custom = col_v + " " + conn_v + " " + e.target.custom_val_from.value + "-" + e.target.custom_val_to.value
                    } else {
                        custom = col_v + " " + conn_v + " " + e.target.custom_val.value
                    }
                }

                edge.filter((el, index) => {
                    if (el.id === edge_id) {
                        el.data.custom_condition = custom;
                    }
                });
            }
            test[0].connection = edge
            setEdges(edge)
            onSave()
        }
        setFilter([false, {}, ""])
    }

    useEffect(() => {
        if (viewupdate[0] !== '') {
            var api = {
                "id": viewupdate[1].id,
                "description": viewupdate[1].data.value.description,
                "label": viewupdate[1].data.label,
                "type": viewupdate[1].data.value.type,
                "parent": viewupdate[1].data.value.parent,

            };
            if (viewupdate[1].data.value.type === 'sms') {
                api.sms_campaign_name = viewupdate[1].data.value.api.campaign_name
                api.sms_sender = viewupdate[1].data.value.api.sender
                api.sms_template_id = viewupdate[1].data.value.api.template_id
                api.sms_content = viewupdate[1].data.value.api.content
                api.sms_route = viewupdate[1].data.value.api.route
                api.sms_coding = viewupdate[1].data.value.api.coding
                api.pe_id = viewupdate[1].data.value.api.pe_id

                api.auth_key = viewupdate[1].data.value.api.auth_key


            } else if (viewupdate[1].data.value.type === 'voice') {
                api.voice_campaign_name = viewupdate[1].data.value.api.campaign_name
                api.voice_sender = viewupdate[1].data.value.api.sender
                api.voice_content = viewupdate[1].data.value.api.content
                api.voice_route = viewupdate[1].data.value.api.route
                api.auth_key = viewupdate[1].data.value.api.auth_key

            } else if (viewupdate[1].data.value.type === 'email') {
                api.email_campaign_name = viewupdate[1].data.value.api.campaign_name
                api.email_sender = viewupdate[1].data.value.api.sender
                api.email_content = viewupdate[1].data.value.api.content
                api.email_subject = viewupdate[1].data.value.api.subject
                api.email_sender_name = viewupdate[1].data.value.api.sender_name
            }

            setUpdateValue(pre => ({ ...pre, ...api }))
        }
    }, [viewupdate])

    const updateNodeForm = (e) => {
        e.preventDefault();

        let node_id = viewupdate[0];
        var api = {};
        if (updateValue.type === "sms") {
            api.campaign_name = updateValue.sms_campaign_name
            api.sender = updateValue.sms_sender
            api.content = updateValue.sms_content


            api.template_id = updateValue.sms_template_id
            api.coding = updateValue.sms_coding
            api.route = updateValue.sms_route
            api.pe_id = updateValue.pe_id

            api.auth_key = updateValue.auth_key


        } else if (updateValue.type === "voice") {
            api.campaign_name = updateValue.voice_campaign_name
            api.sender = updateValue.voice_sender
            api.content = updateValue.voice_content
            api.auth_key = updateValue.auth_key


            api.voice_type = "text"
            api.voice_duration = 30
            api.route = updateValue.voice_route
        } else if (updateValue.type === "email") {
            api.campaign_name = updateValue.email_campaign_name
            api.sender = updateValue.email_sender
            api.content = updateValue.email_content

            api.subject = updateValue.email_subject
            api.sender_name = updateValue.email_sender_name
        }

        var nodes = test[0].nodes;

        nodes.filter((el, index) => {
            if (el.data.value.type !== "start") {
                if (el.id === node_id) {
                    el.data.value.api = api;
                    el.data.label = updateValue.label;
                    el.data.value.description = updateValue.description;
                    el.data.value.parent = updateValue.parent;
                }
            }
        });
        test[0].nodes = nodes
        // console.log(nodes)
        setNodes(nodes)
        onSave()
        setUpdate(false)
    }
    const updateform = () => {
        const FullForm = (el) => {
            const type = el.data.value.type;
            if (type === 'sms') {
                return (
                    <Fragment>
                        <Fragment>
                            <div className="flex my_form p-3">
                                <input
                                    id="node_id"
                                    value={updateValue.id}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'id': e.target.value }))}
                                    type="hidden" name="id" />

                                <div className="flex-1  p-2 ">
                                    <label htmlFor="campaign_name">Campaign Name </label><br />
                                    <input id="campaign_name"
                                        value={updateValue.sms_campaign_name}
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'sms_campaign_name': e.target.value }))}
                                        className="w-full" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required" />
                                </div>
                                <div className="flex-1 p-2">
                                    <label htmlFor="sender">sender</label><br />
                                    <input id="sender"
                                        value={updateValue.sms_sender}
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'sms_sender': e.target.value }))}
                                        className="w-full" type="text" name="sender" required="required" placeholder="Enter sender" />
                                </div>

                                <div className="flex-1  p-2 ">
                                    <label htmlFor="pe_id">pe_id</label><br />
                                    <input id="pe_id"
                                        value={updateValue.pe_id}
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'pe_id': e.target.value }))}
                                        name="pe_id" type="text" className="w-full" placeholder="Enter pe_id" required="required" />
                                </div>
                            </div>
                            <div className="flex my_form p-3">
                                <div className="flex-1  p-2 ">
                                    <label htmlFor="template_id">Template Id </label><br />
                                    <input id="template_id"
                                        value={updateValue.sms_template_id}
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'sms_template_id': e.target.value }))}
                                        name="template_id" type="text" className="w-full" placeholder="Enter Template ID" required="required" />
                                </div>
                                <div className="flex-1  p-2 ">
                                    <label htmlFor="content">Content</label><br />
                                    <textarea id="content"
                                        value={updateValue.sms_content}
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'sms_content': e.target.value }))}
                                        name="content" type="text" className="w-full" placeholder="Enter Content" required="required" />
                                </div>
                            </div>
                            <div className="flex  p-3">
                                <div className="flex-1  p-2 ">
                                    <input type="radio" value="TR"
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'sms_route': e.target.value }))}
                                        checked={updateValue.sms_route === 'TR'} name="route" /> <label className="font-semibold"> Tranactional </label>
                                </div>
                                <div className="flex-1  p-2 ">
                                    <input type="radio" value="PR"
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'sms_route': e.target.value }))}
                                        checked={updateValue.sms_route === 'PR'}
                                        name="route" /><label className="font-semibold">Promotional  </label>
                                </div>
                                <div className="flex-1  p-2 ">
                                    <input type="radio" value="1"
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'sms_coding': e.target.value }))}
                                        checked={updateValue.sms_coding === 1}
                                        name="coding" /><label className="font-semibold">English </label>
                                </div>
                                <div className="flex-1  p-2 ">
                                    <input type="radio" value="2"
                                        onChange={e => setUpdateValue(pre => ({ ...pre, 'sms_coding': e.target.value }))}
                                        checked={updateValue.sms_coding === 2} name="coding" /> <label className="font-semibold">Unicode </label>
                                </div>
                            </div>
                        </Fragment>
                    </Fragment>
                )
            }
            else if (type === 'voice') {
                return (
                    <Fragment>
                        <input id="node_id"
                            value={updateValue.id}
                            onChange={e => setUpdateValue(pre => ({ ...pre, 'id': e.target.value }))}
                            type="hidden" name="id" />
                        <div className="flex my_form p-3">
                            <div className="flex-1  p-2 ">
                                <label htmlFor="campaign_name">Campaign Name </label><br />
                                <input id="campaign_name" value={updateValue.voice_campaign_name}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'voice_campaign_name': e.target.value }))}
                                    className="w-full" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required" />
                            </div>
                            <div className="flex-1 p-2">
                                <label htmlFor="sender">sender</label><br />
                                <input id="sender" value={updateValue.voice_sender}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'voice_sender': e.target.value }))}
                                    className="w-full" type="text" name="sender" required="required" placeholder="Enter sender" />
                            </div>
                        </div>


                        <div className="flex  p-3">
                            <div className="flex-1 my_form p-2 basis-1/2">
                                <label htmlFor="content">Voice Text Content</label><br />
                                <textarea id="content" name="content"
                                    value={updateValue.voice_content}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'voice_content': e.target.value }))}
                                    type="text" className="w-full" placeholder="Enter Content" required="required" />
                            </div>
                            <div className="flex-1  p-2 basis-1/4">
                                <input type="radio" value="TR"
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'voice_route': e.target.value }))}
                                    checked={updateValue.voice_route === 'TR'} name="route" className="mr-2" /><label className="font-semibold"> Tranactional </label>
                            </div>
                            <div className="flex-1  p-2 basis-1/4">
                                <input type="radio" value="PR"
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'voice_route': e.target.value }))}
                                    checked={updateValue.voice_route === 'PR'}
                                    name="route" className="mr-2" /><label className="font-semibold">Promotional  </label>
                            </div>
                        </div>
                    </Fragment>
                )
            }
            else if (type === 'email') {
                return (
                    <Fragment>
                        <div className="flex my_form p-3">
                            <div className="flex-1  p-2 ">
                                <label htmlFor="campaign_name">Campaign Name </label><br />
                                <input id="campaign_name"
                                    value={updateValue.email_campaign_name}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'email_campaign_name': e.target.value }))}
                                    className="w-full" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required" />
                            </div>
                            <div className="flex-1 p-2">
                                <label htmlFor="sender">sender</label><br />
                                <input id="sender"
                                    value={updateValue.email_sender}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'email_sender': e.target.value }))}
                                    className="w-full" type="text" name="sender" required="required" placeholder="Enter sender" />
                            </div>
                        </div>
                        <div className="flex my_form p-3">
                            <div className="flex-1  p-2 ">
                                <label htmlFor="content">Email Content</label><br />
                                <textarea id="content" name="content"
                                    value={updateValue.email_content}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'email_content': e.target.value }))}
                                    className="w-full"
                                    type="text" placeholder="Enter Content" required="required" />
                            </div>
                            <div className="flex-1 p-2">
                                <label htmlFor="subject">Subject </label><br />
                                <input id="subject" name="subject"
                                    value={updateValue.email_subject}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'email_subject': e.target.value }))}
                                    className="w-full"
                                    type="text" placeholder="Enter Subject" required="required" />
                            </div>
                            <div className="flex-1 p-2">
                                <label htmlFor="sender_name">Sender Name</label><br />
                                <input id="sender_name"
                                    value={updateValue.email_sender_name}
                                    onChange={e => setUpdateValue(pre => ({ ...pre, 'email_sender_name': e.target.value }))}
                                    className="w-full"
                                    name="sender_name" type="text" placeholder="Enter Sender Name" required="required" />
                            </div>
                        </div>
                    </Fragment>
                )
            } else if (type == 'whatsapp') {
            }
        }
        return (
            <Fragment>
                <div className="justify-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-3/5 h-min  mx-auto max-w-5xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h4 className="text-2xl font-semibold">{updateValue.label}</h4>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => {
                                        setUpdate(false)
                                        setViewUpdate(["", {}])
                                    }}
                                >
                                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <div className="relative p-2 flex-auto">
                                <form onSubmit={(e) => updateNodeForm(e)}>
                                    <input id="label" name="label" value={updateValue.label} type="hidden" />
                                    <input type="hidden" id="type" name="type" value={updateValue.type} />

                                    {viewupdate[0] === "" ? ("") : (FullForm(viewupdate[1]))}

                                    <input type="hidden" name="description" value={updateValue.description} />

                                    {/*<div className="flex my_form p-3">
                                        <div className="flex-1">      
                                            <label htmlFor="description">Description</label><br />
                                            <textarea id="description" name="description"
                                                value={updateValue.description} 
                                                onChange={e => setUpdateValue(pre => ({...pre,'description' : e.target.value}))}
                                                type="text" className="w-full" placeholder="Enter description" required="required"
                                            />
                                       </div>
                                    </div> */}

                                    <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setUpdate(false)}
                                        >Close</button>
                                        <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                        >Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </Fragment>
        )
    }

    const editEdgeForm = (e) => {
        e.preventDefault();
        if (test.length !== 0) {
            const edge_id = e.target.edge_id.value
            var edge = test[0].connection;
            edge.filter((el, index) => {
                if (el.id === edge_id) {
                    let extra = {}

                    if (el.source.split("-")[0] == "whatsapp") {

                        test[0].nodes.map(t => {
                            if (t.id == el.source) {
                                if (t.data.value.api.whatsapp_type == "interactive") {
                                    if (t.data.value.api.interactive_type == "button") {


                                        JSON.parse(t.data.value.api.interactive_btn).map(x => {
                                            if (x.title == e.target.condition.value.split(" ")[0]) {
                                                extra.title = x.title
                                                extra.button_no = x.button_no
                                                extra.button_id = x.button_id
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }


                    el.data.label = e.target.condition.value;
                    el.data.extra = extra;
                }
            });
            test[0].connection = edge
            setEdges(edge)
            onSave()
        }
        setEdgeEdit([false, {}])
    }

    const edge_edge = (data) => {
        let temp_type = ""
        let temp_wa_type = ""
        let temp_datas = ""

        if (data.source.split("-")[0] == "whatsapp") {
            nodes.map(i => {
                if (i.id == data.source) {

                    if (i.data.value.api.whatsapp_type == "interactive") {
                        if (i.data.value.api.interactive_type == "button") {

                            temp_type = i.data.value.api.whatsapp_type

                            temp_wa_type = i.data.value.api.interactive_type
                            temp_datas = i.data.value.api.interactive_btn
                        }
                    }
                }
            })
        }




        const rules = childCondition(data.source.split("-")[0], data.source, temp_type, temp_wa_type, temp_datas)
        const label = data.data.label

        const test2 = (r) => {
            return r.map(item => {
                if (item == label) {
                    return <option className="font-semibold" selected value={item}>{item}</option>
                } else {
                    return <option className="font-semibold" value={item}>{item}</option>
                }
            })
        }

        return (
            <Fragment>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold"> Edge Edit </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setEdgeEdit([false, {}])}
                                >
                                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <div className="relative p-3 flex-auto">
                                <form onSubmit={editEdgeForm}>
                                    <div className="flex my_form">
                                        <div className="flex-1 p-2">
                                            <label htmlFor="label" > Condition </label><br />
                                            <select name="condition" className="w-full">
                                                <option disabled required="required" className="font-semibold">Select Condition</option>
                                                {rules === [] ? ("") : test2(rules)}
                                            </select>
                                        </div>
                                        <input type="hidden" value={data.id} name="edge_id" />
                                    </div>
                                    <div className="flex items-center justify-end border-t border-solid border-slate-200 rounded-b">

                                        <button
                                            className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                            onClick={() => setEdgeEdit([false, {}])}
                                            type="button"
                                        >
                                            <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                            <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                        </button>
                                        <button
                                            className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600 text-white"
                                            type="submit"
                                        >
                                            <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                            <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </Fragment>
        )
    }
    const addTimeFilter = (data) => {
        const delay = data.data.delay
        return (
            <Fragment>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="p-3 rounded-lg border border-gray-200 shadow-lg relative flex flex-col w-full bg-white translate-y-2 transition-all duration-700 ease-in-out">
                            <form onSubmit={actionEdgeEdit}>

                                <input type="hidden" name="type" value="time" />
                                <div className="flex border-b border-solid border-slate-200">
                                    <h3 className="text-lg font-semibold"> Time Pause </h3>
                                    <button
                                        className="ml-auto text-3xl font-semibold"
                                        type="button"
                                        onClick={() => setFilter([false, {}, ""])}
                                    >
                                        <span className="text-black text-2xl block outline-none">×</span>
                                    </button>
                                </div>
                                <div className="flex justify-around px-6">
                                    <div className="flex flex-col p-4">
                                        <select name="days" className="appearance-none outline-none no-scrollbar bg-white text-2xl font-semibold text-slate-800 text-center">
                                            {
                                                Array.from(Array(30).keys()).map(item => {
                                                    if (delay.days == item) {
                                                        return <option className="text-sm outline-none" selected value={item}>{item}</option>
                                                    } else {
                                                        return <option className="text-sm outline-none" value={item}>{item}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                        <span className="text-sm font-semibold text-slate-500">DAYS</span>
                                    </div>
                                    <span className="h-12 w-px my-4 bg-slate-500"></span>
                                    <div className="flex flex-col p-4">
                                        <select name="hours" className="appearance-none outline-none no-scrollbar bg-white text-2xl font-semibold text-slate-800 text-center">
                                            {
                                                Array.from(Array(24).keys()).map(item => {
                                                    if (delay.hours == item) {
                                                        return <option className="text-sm outline-none" selected value={item}>{item}</option>
                                                    } else {
                                                        return <option className="text-sm outline-none" value={item}>{item}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                        <span className="text-sm  font-semibold text-slate-500"> HOURS </span>
                                    </div>
                                    <span className="h-12 w-px my-4 bg-slate-500"></span>
                                    <div className="flex flex-col p-4">
                                        <select name="minutes" className="appearance-none outline-none no-scrollbar bg-white text-2xl font-semibold text-slate-800 text-center">
                                            {Array.from(Array(60).keys()).map((item) => {
                                                if (delay.minutes == item) {
                                                    return <option className="text-sm outline-none" selected value={item}>{item}</option>
                                                } else {
                                                    return <option className="text-sm outline-none" value={item}>{item}</option>
                                                }

                                            })}
                                        </select>
                                        <span className="text-sm font-semibold text-slate-500">MINUTES</span>
                                    </div>
                                </div>

                                <input type="hidden" value={data.id} name="edge_id" />

                                <div className="flex items-center justify-end rounded-b">
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                        onClick={() => setFilter([false, {}, ""])}
                                        type="button"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                    </button>
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600 text-white"
                                        type="submit"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </Fragment>
        )
    }


    const filter_col_value = (opt, col_name, col_type) => {
        const main_box = myInputRef.current

        const inputElement = main_box.querySelector('#Values');

        inputElement.innerHTML = '';
        inputElement.className = 'flex-1 p-2';
        if (col_type == "date") {
            if (opt == "range" || opt == "not_range") {
                var form_date = document.createElement("input");

                // form_date.id = "notSaveOpertor";
                form_date.className = "m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full";
                form_date.name = "custom_val_from";
                form_date.type = "date";
                form_date.placeholder = "Enter From Date";
                form_date.required = "required";

                var to_date = document.createElement("input");

                // to_date.id = "notSaveOpertor";
                to_date.className = "m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full";
                to_date.name = "custom_val_to";
                to_date.type = "date";
                to_date.placeholder = "Enter To Date";
                to_date.required = "required";


                main_box.querySelector('#Values').appendChild(form_date);
                main_box.querySelector('#Values').appendChild(to_date);
            } else if (opt == "before" || opt == "after") {
                var after_before = document.createElement("input");

                // form_date.id = "notSaveOpertor";
                after_before.className = "m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full";
                after_before.name = "custom_val";
                after_before.type = "number";
                after_before.value = "0";
                after_before.placeholder = "Enter The Days";
                after_before.required = "required";

                main_box.querySelector('#Values').appendChild(after_before);
            } else if (opt == "=" || opt == "!=") {
                var dates = document.createElement("input");

                // form_date.id = "notSaveOpertor";
                dates.className = "m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full";
                dates.name = "custom_val";
                dates.type = "date";
                dates.placeholder = "Enter Value";
                dates.required = "required";

                main_box.querySelector('#Values').appendChild(dates);
            }

            else if (opt == "today" || opt == "not_today") {
                // var dates = document.createElement("input");

                // // form_date.id = "notSaveOpertor";
                // dates.className = "m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full";
                // dates.name = "custom_val";
                // dates.type = "date";
                // dates.placeholder = "Enter Value";
                // dates.required = "required";

                // main_box.querySelector('#Values').appendChild(dates);
            }

            else {
                var dates = document.createElement("input");

                // form_date.id = "notSaveOpertor";
                dates.className = "m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full";
                dates.name = "custom_val";
                dates.type = "text";
                dates.placeholder = "Enter Value";
                dates.required = "required";

                main_box.querySelector('#Values').appendChild(dates);
            }
        } else {
            var cus_value = document.createElement("input");

            // form_date.id = "notSaveOpertor";
            cus_value.className = "m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full";
            cus_value.name = "custom_val";
            cus_value.type = "text";
            cus_value.placeholder = "Enter Value";
            cus_value.required = "required";

            main_box.querySelector('#Values').appendChild(cus_value);
        }

    }
    const filter_col_type = (col_data) => {
        const main_box = myInputRef.current
        main_box.querySelector('#Opertor').innerHTML = ""
        main_box.querySelector('#Values').innerHTML = ""
        main_box.querySelector('#Values').className = ""


        const selectElement = main_box.querySelector('#notSaveOpertor');

        let newOptions = [
            { "cond": "Select Operator" },
            { "value": "=", "cond": "Equal to" },
            { "value": "!=", "cond": "Not Equal to" },
            { "value": ">", "cond": "Greater Than" },
            { "value": ">=", "cond": "Greater Than or equal" },
            { "value": "<", "cond": "Less Than" },
            { "value": "<=", "cond": "Less Than or equal" }

        ];


        customCol[0].map(item => {
            if (item.column_name == col_data) {
                main_box.querySelector('#column_type').value = item.column_type
                if (item.column_type == "date") {
                    newOptions = [
                        { "cond": "Select Operator" },
                        { "value": "=", "cond": "Equal to" },
                        { "value": "!=", "cond": "Not Equal to" },
                        { "value": "range", "cond": "In Range" },
                        { "value": "not_range", "cond": "Not In Range" },
                        { "value": "before", "cond": "Before Days" },
                        { "value": "after", "cond": "After Days" },
                        { "value": "today", "cond": "Today" },
                        { "value": "not_today", "cond": "Not Today" },
                    ];
                }
                if (selectElement) {
                    selectElement.innerHTML = '';
                    newOptions.forEach((opt, index) => {
                        const newOption = document.createElement('option');
                        newOption.text = opt.cond;

                        if (index == 0) {
                            newOption.selected = "selected";
                            newOption.disabled = "disabled";
                        } else {
                            newOption.value = opt.value;
                        }
                        selectElement.add(newOption);
                    });
                } else {
                    var select = document.createElement("select");

                    select.id = "notSaveOpertor";
                    select.className = "m-2 p-2 bg-transparent outline-0 text-grey-500 border-2 border-grey-500 shadow-md rounded-md w-full";
                    select.name = "custom_condition";
                    select.required = "required";
                    select.onchange = function () {

                        filter_col_value(select.value, col_data, item.column_type)
                    }


                    newOptions.forEach((opt, index) => {
                        const newOption = document.createElement('option');
                        newOption.text = opt.cond;

                        if (index == 0) {
                            newOption.selected = "selected";
                            newOption.disabled = "disabled";
                        } else {
                            newOption.value = opt.value;
                        }
                        select.appendChild(newOption);
                    });
                    main_box.querySelector('#Opertor').className = "flex-1 p-2"
                    main_box.querySelector('#Opertor').appendChild(select);
                }

            }
        })
    }


    const editFunnelFilter = (data, cols) => {
        const conn = data.data.custom_condition

        const main_cond = () => {
            const cus_con = conn.split(" ")
            let col_typo;

            let newOptions = [
                { "value": "", "cond": "Select Operator" },
                { "value": "=", "cond": "Equal to" },
                { "value": "!=", "cond": "Not Equal to" }
            ]
            const filter_setted_col = (typo) => {
                col_typo = typo
                if (typo == "date") {
                    newOptions = [
                        { "value": "", "cond": "Select Operator" },
                        { "value": "=", "cond": "Equal to" },
                        { "value": "!=", "cond": "Not Equal to" },
                        { "value": "range", "cond": "In Range" },
                        { "value": "not_range", "cond": "Not In Range" },
                        { "value": "before", "cond": "Before Days" },
                        { "value": "after", "cond": "After Days" },
                        { "value": "today", "cond": "Today" },
                        { "value": "not_today", "cond": "Not Today" },
                    ];
                } else {
                    newOptions = [
                        { "value": "", "cond": "Select Operator" },
                        { "value": "=", "cond": "Equal to" },
                        { "value": "!=", "cond": "Not Equal to" },
                        { "value": ">", "cond": "Greater Than" },
                        { "value": ">=", "cond": "Greater Than or equal" },
                        { "value": "<", "cond": "Less Than" },
                        { "value": "<=", "cond": "Less Than or equal" }
                    ];
                }
            }
            function addZero(num) {
                return (num < 10 ? '0' : '') + num;
            }

            const filter_setted_val = () => {
                return customCol[0].map(item => {
                    if (cus_con[0] === item.column_name) {
                        filter_setted_col(item.column_type)
                        if (item.column_type == "date") {
                            return newOptions.map(i => {
                                if (cus_con[1] == i.value) {
                                    if (i.value == "range" || i.value == "not_range") {
                                        let from_d = new Date(cus_con[2].split("-")[0] * 1000);
                                        let to_d = new Date(cus_con[2].split("-")[1] * 1000);

                                        let f_year = from_d.getFullYear();
                                        let f_month = from_d.getMonth() + 1;
                                        let f_day = from_d.getDate();

                                        let t_year = to_d.getFullYear();
                                        let t_month = to_d.getMonth() + 1;
                                        let t_day = to_d.getDate();

                                        const f_date = f_year + '-' + addZero(f_month) + '-' + addZero(f_day)
                                        const t_date = t_year + '-' + addZero(t_month) + '-' + addZero(t_day)

                                        return (
                                            <Fragment>
                                                <input
                                                    className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                                    name="custom_val_from"
                                                    type="date"
                                                    placeholder="Enter From Date"
                                                    required="required"
                                                    defaultValue={f_date}
                                                />

                                                <input
                                                    className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                                    name="custom_val_to"
                                                    type="date"
                                                    placeholder="Enter To Date"
                                                    required="required"
                                                    defaultValue={t_date}
                                                />
                                            </Fragment>
                                        )
                                    } else if (i.value == "before" || i.value == "after") {
                                        return (
                                            <Fragment>

                                                <input
                                                    className="m-2 p-1.5 placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                                    name="custom_val"
                                                    type="number"
                                                    defaultValue={cus_con[2]}
                                                    placeholder="Enter The Days"
                                                    required="required"
                                                />
                                            </Fragment>
                                        )
                                    } else if (i.value == "=" || i.value == "!=") {

                                        let val_d = new Date(cus_con[2].split("-")[0] * 1000);

                                        let v_year = val_d.getFullYear();
                                        let v_month = val_d.getMonth() + 1;
                                        let v_day = val_d.getDate();

                                        const val_date = v_year + '-' + addZero(v_month) + '-' + addZero(v_day)
                                        return (
                                            <Fragment>
                                                <input
                                                    className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                                    name="custom_val"
                                                    type="date"
                                                    defaultValue={val_date}
                                                    placeholder="Enter The Days"
                                                    required="required"
                                                />
                                            </Fragment>
                                        )
                                    } else if (i.value == "today" || i.value == "not_today") {

                                        // let val_d = new Date(cus_con[2].split("-")[0] * 1000);

                                        // let v_year = val_d.getFullYear();
                                        // let v_month = val_d.getMonth() + 1; 
                                        // let v_day = val_d.getDate();

                                        // const val_date = v_year + '-' + addZero(v_month) + '-' + addZero(v_day)
                                        // return (
                                        //     <Fragment>
                                        //         <input 
                                        //             className = "m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                        //             name = "custom_val"
                                        //             type = "date"
                                        //             defaultValue = {val_date}
                                        //             placeholder = "Enter The Days"
                                        //             required = "required"
                                        //         />
                                        //     </Fragment>
                                        // )
                                    } else {
                                        return (
                                            <Fragment>
                                                <input
                                                    className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                                    name="custom_val"
                                                    type="text"
                                                    defaultValue={cus_con[2]}
                                                    placeholder="Enter Value"
                                                    required="required"
                                                />
                                            </Fragment>
                                        )
                                    }
                                }
                            })
                        } else {
                            return (
                                <Fragment>
                                    <input
                                        className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                        name="custom_val"
                                        type="text"
                                        defaultValue={cus_con[2]}
                                        placeholder="Enter Value"
                                        required="required"
                                    />
                                </Fragment>
                            )
                        }
                    }
                })
            }

            return (
                <div className="flex" ref={myInputRef}>
                    <div className="flex-1 p-2" >
                        <select
                            name="custom_col"
                            required="required"
                            className="m-2 p-2 outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow-md rounded-md w-full"
                            onChange={e => filter_col_type(e.target.value)}
                        >
                            <option value="" >Select Variable </option>
                            {
                                customCol[0] === [] ? ("") : (customCol[0].map(item => {
                                    if (cus_con[0] === item.column_name) {
                                        filter_setted_col(item.column_type)
                                        return <option selected="selected" value={item.column_name}>{item.column_name}</option>
                                    } else {
                                        return <option value={item.column_name}>{item.column_name}</option>
                                    }
                                }))
                            }
                        </select>
                    </div>

                    <input type="hidden" id="column_type" name="column_type" value={col_typo} />

                    <div className="flex-1 p-2" id="Opertor">
                        <select
                            name="custom_condition"
                            className="m-2 p-2 bg-transparent outline-0 text-grey-500 border-2 border-grey-500 shadow-md rounded-md w-full"
                            required="required"
                            onChange={e => filter_col_value(e.target.value, "", col_typo)}

                        >
                            {
                                newOptions === [] ? ("") : (newOptions.map(item => {
                                    if (item.value == "") {
                                        return <option disabled value={item.value}>{item.cond}</option>
                                    }

                                    if (cus_con[1] == item.value) {
                                        return <option selected="selected" value={item.value}>{item.cond}</option>
                                    } else {
                                        return <option value={item.value}>{item.cond}</option>

                                    }
                                }))
                            }
                        </select>
                    </div>
                    <div className="flex-1 p-2" id="Values">
                        {filter_setted_val()}
                    </div>
                </div>
            )

        }
        return (
            <Fragment>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="p-3 rounded-lg border border-gray-200 shadow-lg relative flex flex-col w-full bg-white translate-y-2 transition-all duration-700 ease-in-out">
                            <form onSubmit={actionEdgeEdit}>
                                <input type="hidden" name="type" value="filter" />
                                <div className="flex border-b border-solid border-slate-200">
                                    <h3 className="text-lg font-semibold"> Add Filter </h3>
                                    <button
                                        className="ml-auto text-3xl font-semibold"
                                        type="button"
                                        onClick={() => setFilter([false, {}, ""])}
                                    >
                                        <span className="text-black text-2xl block outline-none">×</span>
                                    </button>
                                </div>
                                {main_cond()}
                                <input type="hidden" value={data.id} name="edge_id" />

                                <div className="flex items-center justify-end rounded-b">
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                        onClick={() => setFilter([false, {}, ""])}
                                        type="button"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                    </button>
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600 text-white"
                                        type="submit"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </Fragment>
        )
    }

    const addFunnelFilter = (data, cols) => {
        const conn = data.data.custom_condition

        const main_cond = () => {

            return (
                <div className="flex" ref={myInputRef}>
                    <div className="flex-1 p-2" >
                        <select
                            name="custom_col"
                            required="required"
                            onChange={e => filter_col_type(e.target.value)}
                            className="m-2 p-2 outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow-md rounded-md w-full"
                        >
                            <option selected disabled>Select Variable </option>
                            {
                                customCol[0] === [] ? ("") : (customCol[0].map((item) =>
                                    <option value={item.column_name}>
                                        {item.column_name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <input type="hidden" id="column_type" name="column_type" />
                    <div id="Opertor"></div>
                    <div id="Values"></div>
                </div>
            )

        }

        return (
            <Fragment>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="p-3 rounded-lg border border-gray-200 shadow-lg relative flex flex-col w-full bg-white translate-y-2 transition-all duration-700 ease-in-out">
                            <form onSubmit={actionEdgeEdit}>
                                <input type="hidden" name="type" value="filter" />
                                <div className="flex border-b border-solid border-slate-200">
                                    <h3 className="text-lg font-semibold"> Add Filter </h3>
                                    <button
                                        className="ml-auto text-3xl font-semibold"
                                        type="button"
                                        onClick={() => setFilter([false, {}, ""])}
                                    >
                                        <span className="text-black text-2xl block outline-none">×</span>
                                    </button>
                                </div>
                                {main_cond()}
                                <input type="hidden" value={data.id} name="edge_id" />

                                <div className="flex items-center justify-end rounded-b">
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                        onClick={() => setFilter([false, {}, ""])}
                                        type="button"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                    </button>
                                    <button
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600 text-white"
                                        type="submit"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </Fragment>
        )
    }

    const testing_fun = () => {
        const main_fun_col = async () => {
            const all_col = await PostFetcher(host + '/col_list/', headers, {})
            setCustomCol([all_col.data, true])
        }
        if (customCol[1] == false) main_fun_col()
    }







    const final = () => {

        if (nodes.length == 0) {
            return <Spinner />

        } else {
            const filtersFun = () => {
                if (filter[0] !== false) {
                    if (filter[2] == "time") {
                        return addTimeFilter(filter[1])
                    } else if (filter[2] == "filter") {
                        testing_fun()
                        const conns = filter[1].data.custom_condition
                        if (conns == "" || conns == null) {
                            return addFunnelFilter(filter[1], colCondition)
                        } else {
                            return editFunnelFilter(filter[1], colCondition)
                        }
                    }
                }
            }


            return (
                <div className="dndflow w-full" style={{ height: '100vh' }}>
                    <div className="reactflow-wrapper h-full" ref={reactFlowWrapper} >
                        <div className="flex flex-row-reverse zindex_as">
                            <button
                                className="btn_as_my_wew"
                                onClick={onSave}
                            ><i className="fa fa-floppy-o mr-1" aria-hidden="true"></i> Save</button>
                            <button
                                className="btn_as_my_wew"
                                onClick={() => navigate(-1)}
                            ><i className="fa  fa-arrow-left" aria-hidden="true"></i></button>
                        </div>
                        {isupdate === false ? ("") : (updateform())}
                        {edgeEdit[0] === false ? ("") : (edge_edge(edgeEdit[1]))}
                        {tempPopUp[0] === "" ? ("") : (temp_pop_up(tempPopUp[0], tempPopUp[1], tempPopUp[2]))}



                        {filtersFun()}

                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}

                            onConnect={onConnect}


                            onConnectStart={onConnectStart}
                            onConnectEnd={onConnectEnd}


                            onInit={setRfInstance}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            fitView
                        >
                            <Background
                                variant="cross"
                                gap={20}
                                size={4}
                                color='#c4c4c4'
                                style={{ 'backgroundColor': '#fafafa' }}
                            />
                            <Controls position="bottom-left" ></Controls>
                        </ReactFlow>
                    </div>
                </div>
            )
        }
    }

    return (
        <ReactFlowProvider>
            {final()}
            <ToastContainer />
        </ReactFlowProvider>
    );
};
// ReactFlowProvider