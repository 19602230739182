/* eslint-disable */ 
import  { useState, useEffect, useRef } from "react";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import what_logo from '../image/what-img.png';
import sms_logo from '../image/sms-img.png';
import voice_logo from '../image/voice-img.png';
import email_logo from '../image/email-img.png';

import { GetFetcher,  PostFetcher} from '../Flow/api';

import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow'

const host = "http://oiomni.com:8000/api";
const funnelHost = 'http://people.oiomni.com:8001/api';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};

const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');


const headers_axios = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
    'Accept': "application/json",
    'mode': 'no-cors',
}
const axios_header = { 'headers':headers_axios }
let new_body = {}

export default function Template() {
    const [Data, setData] = useState([]);
    const [channelData, setChannelData] = useState([]);
    const [whatsappData, setWhatsappData]= useState({})
    const [tempVar, setTempVar]= useState({})
    const [colData, setColData] = useState([])
    const [whatsappDetails, setWhatsappDetails] = useState([])
    const [isLoad, setLoad] = useState(true);
    const [isview, setView] = useState([false,null]);
    const [isedit, setEdit] = useState([false,{}]);
    const [tempType, setTempType] = useState("");
    const [authKey, setAuthKey] = useState("");
    const [btnData, setBtnData] = useState(["",{}]);
    const [total, setTotal] = useState([]);


    const [isViewMsg, setViewMsg] = useState(null)





    if (!token) noAuth()

    useEffect(() => {
        if (!token) {
            noAuth()
        }else {
            get_segments();
        }
    }, [isLoad])


   

    const headers = {
        Accept: "application/json",
        'Content-type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }
    async function get_segments() {
        const get_all_template = await PostFetcher(host + '/get_all_template', headers_axios)

        get_all_template.data.links.shift();
        get_all_template.data.links.pop();

        const arr = get_all_template.data.links.map(item => item.url)

        setTotal(arr);
        setData(get_all_template.data.data)

        const getChannel = await PostFetcher(host + '/getChannel', headers_axios)
        setChannelData(getChannel.data)

        const all_col = await PostFetcher(host + '/col_list/', headers,{})
        setColData(all_col.data)

        // const get_all_segment = await GetFetcher(host + '/get_all_segment', headers_axios)
        // setColData(get_all_segment[0].data)
    };

    async function handleSubmit(e) {
        e.preventDefault();
        let body;

        if(isview[1] =="sms"){
            body = {
                type : isview[1],
                auth_key : e.target.auth_key.value,
                template_name : e.target.template_name.value,
                sender : e.target.sender.value,
                content : e.target.content.value,
                route : e.target.route.value,
                template_id : e.target.template_id.value,
                pe_id : e.target.pe_id.value,
                coding : e.target.coding.value,
            }
        }else if(isview[1] =="voice"){
            body = {
                type : isview[1],
                auth_key : e.target.auth_key.value,
                template_name : e.target.template_name.value,
                sender : e.target.sender.value,
                content : e.target.content.value,
                route : e.target.route.value,
            }
        }else if(isview[1] == "email"){
            body = {
                type : isview[1],
                template_name : e.target.template_name.value,
                sender : e.target.sender.value,
                content : e.target.content.value,
                subject : e.target.subject.value,
                sender_name : e.target.sender_name.value,
            }
        }else if(isview[1] == "whatsapp"){
            body = {
                "data" : {...new_body},
                "template_name" : e.target.template_name.value,
                "auth_key" : e.target.auth_key.value,
                "template" : e.target.template.value,
                "phone_no_id" : e.target.phone_no_id.value,
                "type" : "whatsapp",
                "new_data" : {...btnData},
                "template_type" : e.target.template_type.value
            }
        }
        const res = await fetch(host+"/templateSave", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(body),
        });
        const data = await res.json();
        if(data.status === 401){
            noAuth()
        }else if(data.status === 200){
            if(isLoad === true){
                setLoad(false)
            }else{
                setLoad(true)
            }
            setView([false,null])
            setAuthKey("")
            toast.success(data.message)
            Array.from(e.target).forEach((eve) => (eve.value = ""));
        }else if(data.status === 403){
            const arr = Object.values(data.message)
            arr.forEach(i => {
                toast.error(i[0])
            });
        }
        return true
    };

   


    async function delTemp(e, item) {
        e.preventDefault();
        const res = await fetch(host+'/templateDel', {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ id: item }),
        });
        const datas = await res.json();
        toast.success(datas.message)
        if(isLoad === true){
            setLoad(false)
        }else{
            setLoad(true)
        }
        return true
    }


    const template_list = (val) => {
        if(val == "template"){
            if(whatsappData.template.length != 0){
                return whatsappData.template.map(iteam => <option value={iteam.template_id} > {iteam.template_name} </option>)
            }
        }
        else if(val == "interactive"){
            if(whatsappData.interactive.length != 0){
                return whatsappData.interactive.map(iteam => {
                    if(iteam.template_id != null && iteam.template_id != ""){
                        return <option value={iteam.template_id} > {iteam.title} </option>
                    }
                })
            }
        }
    }

    const phone_no_list = () => {
        if(whatsappData.phone_details.length != 0){
            return whatsappData.phone_details.map(iteam => <option value={iteam.phone_no_id}>{iteam.display_phone_no}</option>)
        }
    }


    const settingTempVar = (type, data_types, index, val) => {
        const i = `index${index}`
        const value = `col_${val}`
        let t = {}
        t[`${i}`] = {"col" : value, "type" : data_types} 

        if(type == "body"){
            new_body.body = {...new_body.body, ...t}
        }else if(type == "header"){
            new_body.header = {...new_body.header, ...t}
        }else if(type == "button"){
            new_body.button = {...new_body.button, ...t}
        }
        setTempVar(new_body)
    }

    const details = (comp, type) => {
        if(type == "template"){
            return (
                <>
                    <div className="grid grid-cols-3 gap-2">{
                        comp.map(i => {
                            if(i.type == "BODY" && i.example){
                                if(i.example.length != 0){
                                    return i.example.body_text[0].map((x,index) => {
                                        return (
                                            <select
                                                name={"body" + index}
                                                id={"body" + index}
                                                onChange={e => settingTempVar("body", "text", index, e.target.value)}
                                            >
                                                <option value selected disabled>Select Body Var {index+1}</option>
                                                {
                                                    colData.map(col => {
                                                        return (
                                                            <option value={col.column_id}>{col.column_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        )
                                    })
                                }
                            }else if(i.type == "HEADER"){
                                if(i.example){
                                    if(i.example.length != 0){
                                        if(i.format == "TEXT"){
                                            console.log(i.example.header_text)
                                            return i.example.header_text.map((x,index) => {
                                                return (
                                                    <select 
                                                        name={"header" + index}
                                                        onChange={e => settingTempVar("header", i.format, index, e.target.value)}
                                                    >
                                                        <option value selected disabled>Select Header var</option>
                                                        {
                                                            colData.map(col => {
                                                                return (
                                                                    <option value={col.column_id}>{col.column_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                )
                                            })
                                        }else{
                                            return i.example.header_handle.map((x,index) => {
                                                return (
                                                    <select 
                                                        name={"header" + index}
                                                        onChange={e => settingTempVar("header", i.format, index, e.target.value)}
                                                    >
                                                        <option value selected disabled>Select Header var</option>
                                                        {
                                                            colData.map(col => {
                                                                return (
                                                                    <option value={col.column_id}>{col.column_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                )
                                            })
                                        }
                                        
                                    }
                                }  
                            }else if(i.type == "BUTTONS"){
                                if(i.buttons.length != 0){
                                    return i.buttons.map((x, index) => {
                                        if(x.type == "URL"){
                                            if(x.example){
                                                if(x.example.length != 0){
                                                    return x.example.map((j,indexs) => {
                                                        return (
                                                            <select
                                                                name={"button" + indexs}
                                                                onChange={e => settingTempVar("button", x.type ,index, e.target.value)}
                                                            >
                                                                <option value selected disabled>Select Button URL Var</option>
                                                                {
                                                                    colData.map(col => {
                                                                        return (
                                                                            <option value={col.column_id}>{col.column_name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        )
                                                    })
                                                }
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    }
                    </div>  
                </>
            )
        }else if(type == "interactive"){
            const headerVar = () => {
                const headVarCount = (comp[0].header_data.match(/\{{\d+\}}/g) || []);
                if(comp[0].header_type == "text"){
                    if(headVarCount.length != 0){
                        return headVarCount.map((i,index) => {
                            return (
                                <select
                                    name={"header" + index}
                                    id={"header" + index}
                                    onChange={e => settingTempVar("header", "text", index, e.target.value)}
                                >
                                    <option value selected disabled>Select Header Var {index+1}</option>
                                    {
                                        colData.map(col => {
                                            return (
                                                <option value={col.column_id}>{col.column_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            )
                        })
                    }else{
                        return ""
                    }
                }else{
                    return ""
                }
            }

            const bodyVar = () => {        
                const bodyVarCount = (comp[0].body.match(/\{{\d+\}}/g) || []);
                if(bodyVarCount.length != 0){
                    return bodyVarCount.map((i,index) => {
                        return (
                            <select
                                name={"body" + index}
                                id={"body" + index}
                                onChange={e => settingTempVar("body", "text", index, e.target.value)}
                            >
                                <option value selected disabled>Select Body Var {index+1}</option>
                                {
                                    colData.map(col => {
                                        return (
                                            <option value={col.column_id}>{col.column_name}</option>
                                        )
                                    })
                                }
                            </select>
                        )
                    })
                }else{
                    return ""
                }
            }

            if(comp[0].is_variable == 1){
                return (
                    <> {headerVar()}{bodyVar()} </>
                )
            }else{
                return ""
                new_body = {}
                setTempVar(new_body)
            }
        }
    }


    const getWhatsappDetail = (type, val) => {
        new_body = {}
        setTempVar(new_body)
        if(type == "interactive"){
            whatsappData.interactive.map(iteam => {
                if(val == iteam.template_id){
                    setWhatsappDetails([iteam])
                    fetch("http://whatsapp.bulk24sms.com/api/get-int-template-by-id", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            'Content-type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                        body : JSON.stringify({
                            "auth_key" : authKey,
                            "template_id" : iteam.template_id,
                            "interactive_type" : iteam.int_type,
                        })
                    })
                    .then(res => {
                        if(res.status == "401") noAuth()
                        return res.json()
                    }).then(d => {
                        setBtnData([iteam.int_type, d.data])
                    })
                }
            })
        }else if(type == "template"){
            whatsappData.template.map(iteam => {
                if(val == iteam.template_id){
                    
                    let a =[]
                    JSON.parse(iteam.companents).map(i => {
                        if(i.type == "BUTTONS"){
                            if(i.buttons.length != 0){
                                return i.buttons.map((x, index) => {
                                    if(x.type == 'QUICK_REPLY'){
                                        a.push(x)
                                    }
                                })
                            }
                        }
                    })

                    if(a.length != 0) setBtnData(['button', a])

                    setWhatsappDetails(JSON.parse(iteam.companents))
                }
            })
        }
    }
    const getTempType = (val) => {
        setTempType(val);
        const channel = fetch("https://whatsapp.bulk24sms.com/api/get-templates", {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body : JSON.stringify({
                "auth_key" : authKey,
                "template_type" : val
            })
        })
        .then(res => {
            if(res.status == "401"){
                localStorage.removeItem('token');
                window.location.href = '/';
            }
            return res.json()
        })
        .then(d => {
            setWhatsappData(d.data)
            return d.data
        }) 

    }

    const tempalte = (type, val) => {
        if(type == "auth_key"){
            return (
                <select
                    className="w-full"
                    name="template_type"
                    required="required"
                    onChange={e => getTempType(e.target.value)}
                >
                    <option value="" selected disabled > Select Template Type</option>
                    <option value="template">Template</option>
                    <option value="interactive">Interactive</option>
                </select>
            )
        }else if(type == "temp_type"){
            const test = () => {
                if(val == "interactive"){
                    return (
                        <>
                            <option value="" selected disabled > Select interactive Message</option>
                            {whatsappData.interactive == undefined ? ("") : (template_list(val))}
                        </>
                    )
                }else if(val == "template"){
                    return (
                        <>
                            <option value="" selected disabled > Select Template Message</option>
                            {whatsappData.template == undefined ? ("") : (template_list(val))}
                        </>
                    )
                }
            }
            return (
                <>
                    <select
                        className="w-full"
                        name="phone_no_id"
                        required="required"
                    >
                        <option value="" selected disabled > Select PhoneNumber Id</option>
                        {whatsappData.phone_details == undefined ? ("") : (phone_no_list())}
                    </select>

                    <select
                        className="w-full"
                        name="template"
                        required="required"
                        onChange={e => getWhatsappDetail(val, e.target.value)}
                    >
                        {test()}
                    </select>
                </>
            )
        }
    }
    
    

    const newform = () => {
        function apiCondition(type){

            const new_fun = (channel_type, val) => {
                if(channel_type == "sms"){
                    return (
                        <>
                            <div className="p-2">
                                <input id="template_id" className="w-full" name="template_id" type="text" placeholder="Enter Template ID" required="required"/>
                            </div>                                      
                            <div className="flex">
                                <div className="p-2">
                                    <input id="sender" className="w-full" type="text" name="sender" required="required" placeholder="Enter sender"  />
                                </div>  
                                <div className="p-2">
                                    <input id="pe_id" className="w-full" type="text" name="pe_id" required="required" placeholder="Enter PE Id"  />
                                </div> 
                            </div>

                            <div className="p-2">
                                <textarea id="content" className="w-full" name="content" type="text" placeholder="Enter Content" required="required"/>
                            </div>


                            <div className="flex">
                                <div className="flex flex-wrap p-2 basis-1/4">
                                    <div className="flex items-center me-4">
                                        <input 
                                            id="TR-radio" 
                                            type="radio" 
                                            value="TR" 
                                            name="route" 
                                            className="w-4 h-4 text-teal-600 bg-gray-700 border-gray-600 ring-offset-gray-800 mr-1"
                                        />
                                        <label htmlFor="TR-radio" className="ms-2 text-sm font-semibold text-gray-700">Tranactional</label>
                                    </div>
                                </div>

                                <div className="flex flex-wrap p-2 basis-1/4">
                                    <div className="flex items-center me-4">
                                        <input 
                                            id="PR-radio" 
                                            type="radio" 
                                            value="PR" 
                                            name="route" 
                                            className="w-4 h-4 text-teal-600 bg-gray-700 border-gray-600 ring-offset-gray-800 mr-1"
                                        />
                                        <label htmlFor="PR-radio" className="ms-2 text-sm font-semibold text-gray-700">Promotional</label>
                                    </div>
                                </div>

                                <div className="flex flex-wrap p-2 basis-1/4">
                                    <div className="flex items-center me-4">
                                        <input 
                                            id="en-radio" 
                                            type="radio" 
                                            value="1" 
                                            name="coding" 
                                            className="w-4 h-4 text-teal-600 bg-gray-700 border-gray-600 ring-offset-gray-800 mr-1"
                                        />
                                        <label htmlFor="en-radio" className="ms-2 text-sm font-semibold text-gray-700">English</label>
                                    </div>
                                </div>

                                <div className="flex flex-wrap p-2 basis-1/4">
                                    <div className="flex items-center me-4">
                                        <input 
                                            id="un-radio" 
                                            type="radio" 
                                            value="2" 
                                            name="coding" 
                                            className="w-4 h-4 text-teal-600 bg-gray-700 border-gray-600 ring-offset-gray-800 mr-1"
                                        />
                                        <label htmlFor="un-radio" className="ms-2 text-sm font-semibold text-gray-700">Unicode</label>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }else if(channel_type == "voice"){
                    return (<>
                        <div className="flex">
                            <div className="p-2 basis-1/2">
                                <input id="sender" className="w-full" className="border-orange-300 border-2 rounded-sm" type="text" name="sender" required="required" placeholder="Enter sender"  />
                            </div>                      
                            <div className="p-2 basis-1/4">
                                <div className="flex">
                                    <input type="radio" value="TR" name="route" className="mr-1"/>
                                    <div className="mt-2">
                                        <label className="font-semibold"> Tranactional </label>
                                    </div>
                                </div>
                            </div> 
                            <div className="p-2 basis-1/4">
                                <div className="flex">
                                    <input type="radio" value="PR" name="route" className="mr-1"/>
                                    <div className="mt-2">
                                        <label className="font-semibold"> Promotional </label>
                                    </div>
                                </div>
                            </div>                  
                        </div>

                        <div className="p-2">
                            <textarea id="content" name="content" className="w-full" type="text" placeholder="Enter Content" required="required"/>
                        </div>
                    </>)
                }
            }
            if(type === 'sms'){
                return (
                    <>
                        <div className="flex">
                            <div className="p-2 basis-1/2">
                                <input id="campaign_name" className="w-full" name="template_name" type="text" placeholder="Enter Campaign Name" required="required"/>           
                            </div>
                            <div className="p-2 basis-1/2">
                                <select
                                    className="w-full p-2"
                                    name="auth_key"
                                    required="required"
                                    onChange={e => setAuthKey(e.target.value)}
                                >
                                    <option value="" selected disabled > Select Auth Key</option>
                                    {
                                        channelData.map((iteam) => {
                                            if(iteam.type == "sms"){
                                                return (<option value={iteam.auth_key} > {iteam.name} </option>)
                                            }
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {authKey === "" ? ("") : new_fun("sms", authKey)}                                        
                    </>
                )
            }else if(type === 'voice'){
                return (
                    <>
                        <div className="flex">
                            <div className="p-2 basis-1/2">
                                <input id="template_name" className="w-full" className="border-grey-900 border-2 rounded-sm" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required"/>
                            </div>
                            <div className="p-2 basis-1/2">
                                <select
                                    className="w-full p-2"
                                    name="auth_key"
                                    required="required"
                                    onChange={e => setAuthKey(e.target.value)}
                                >
                                    <option value="" selected disabled > Select Auth Key</option>
                                    {
                                        channelData.map((iteam) => {
                                            if(iteam.type == "voice"){
                                                return (<option value={iteam.auth_key} > {iteam.name} </option>)
                                            }
                                        })
                                    }
                                </select>
                            </div>                               
                        </div>
                        {authKey === "" ? ("") : new_fun("voice", authKey)}
                    </>
                )
            }else if(type === 'email'){
                return (
                    <>
                        <div className="flex">
                            <div className="p-2">
                                <input id="template_name" className="w-full" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required"/>
                            </div>                           
                        </div>
                        <div className="p-2">
                            <input id="sender" className="w-full" type="text" name="sender" required="required" placeholder="Enter sender"  />
                        </div>   
                        <div className="flex">
                            <div className="p-2">
                                <input id="subject" name="subject" className="w-full" type="text" placeholder="Enter Subject" required="required"/>
                            </div> 
                            <div className="p-2">
                                <input id="sender_name" name="sender_name" className="w-full" type="text" placeholder="Enter Sender Name" required="required"/>
                            </div>                                     
                        </div>

                        <div className="p-2">
                            <textarea id="content" name="content" className="w-full" type="text" placeholder="Enter Content" required="required"/>
                        </div>
                    </>
                )
            }else if(type === 'whatsapp'){
                return (
                    <>
                        <div className="grid grid-cols-2 gap-2">
                            <input id="template_name" name="template_name" className="w-full" type="text" placeholder="Enter Template Name" required="required"/>
                            <select
                                className="w-full"
                                name="auth_key"
                                required="required"
                                onChange={e => setAuthKey(e.target.value)}
                            >
                                <option value="" selected disabled > Select Auth Key</option>
                                {
                                    channelData.map((iteam) => {
                                        if(iteam.type == "whatsapp"){
                                            return (<option value={iteam.auth_key} > {iteam.name} </option>)
                                        }
                                    })
                                }
                            </select>
                            {authKey === "" ? ("") : tempalte("auth_key", authKey)}
                            {tempType === "" ? ("") : tempalte("temp_type", tempType)} 
                        </div>
                        {whatsappDetails.length === 0 ? ("") : (details(whatsappDetails, tempType))}
                    </>
                )
            }
        }

        return (
            <>
                <div className="flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50">
                    <div className="relative w-auto my-6 mx-auto">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h4 className="text-xl font-semibold">Add Channel Details for {isview[1].toUpperCase()}</h4>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={
                                        () => {
                                            setView([false,null])
                                            setAuthKey("")
                                        }
                                    }
                                >
                                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <form className="relative p-2 flex-auto" onSubmit={handleSubmit}>            
                                <input type="hidden" name="type" value={isview[1]}  required="required"/>    
                                <input type="hidden" name="description" value=""/>   
                                
                                <div className="my_form">
                                   {apiCondition(isview[1])}
                                </div>

                                <div className="flex items-center justify-end">
                                    <button
                                        className="bg-white text-red-500 border border-red-500 font-semibold text-sm px-3 py-2 mr-2 rounded"
                                        type="button"
                                        onClick={
                                            () => {
                                                setView([false,null])
                                                setAuthKey("")
                                            }
                                        }
                                    >Close</button>
                                    <button
                                        className="bg-white text-emerald-500 border border-emerald-500 font-semibold text-sm px-3 py-2 rounded"
                                        type="submit"
                                    >Add</button>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"/>
            </>
        )
    }


    const editTemp = (e, item_id) => {
        e.preventDefault();
        console.log(Data)
        Data.map(item => {
            if(item_id === item.id) setEdit([true, item])
        })
    }


    const editform = ( ) => {
        console.log(isedit[1])
        function apiCondition(type){

            const new_fun = (channel_type, val) => {
                if(channel_type == "sms"){
                    return (
                        <>
                            <div className="p-2">
                                <input id="template_id" className="w-full" name="template_id" type="text" placeholder="Enter Template ID" required="required"/>
                            </div>                                      
                            <div className="flex">
                                <div className="p-2">
                                    <input id="sender" className="w-full" type="text" name="sender" required="required" placeholder="Enter sender"  />
                                </div>  
                                <div className="p-2">
                                    <input id="pe_id" className="w-full" type="text" name="pe_id" required="required" placeholder="Enter PE Id"  />
                                </div> 
                            </div>

                            <div className="p-2">
                                <textarea id="content" className="w-full" name="content" type="text" placeholder="Enter Content" required="required"/>
                            </div>


                            <div className="flex">
                                <div className="flex flex-wrap p-2 basis-1/4">
                                    <div className="flex items-center me-4">
                                        <input 
                                            id="TR-radio" 
                                            type="radio" 
                                            value="TR" 
                                            name="route" 
                                            className="w-4 h-4 text-teal-600 bg-gray-700 border-gray-600 ring-offset-gray-800 mr-1"
                                        />
                                        <label htmlFor="TR-radio" className="ms-2 text-sm font-semibold text-gray-700">Tranactional</label>
                                    </div>
                                </div>

                                <div className="flex flex-wrap p-2 basis-1/4">
                                    <div className="flex items-center me-4">
                                        <input 
                                            id="PR-radio" 
                                            type="radio" 
                                            value="PR" 
                                            name="route" 
                                            className="w-4 h-4 text-teal-600 bg-gray-700 border-gray-600 ring-offset-gray-800 mr-1"
                                        />
                                        <label htmlFor="PR-radio" className="ms-2 text-sm font-semibold text-gray-700">Promotional</label>
                                    </div>
                                </div>

                                <div className="flex flex-wrap p-2 basis-1/4">
                                    <div className="flex items-center me-4">
                                        <input 
                                            id="en-radio" 
                                            type="radio" 
                                            value="1" 
                                            name="coding" 
                                            className="w-4 h-4 text-teal-600 bg-gray-700 border-gray-600 ring-offset-gray-800 mr-1"
                                        />
                                        <label htmlFor="en-radio" className="ms-2 text-sm font-semibold text-gray-700">English</label>
                                    </div>
                                </div>

                                <div className="flex flex-wrap p-2 basis-1/4">
                                    <div className="flex items-center me-4">
                                        <input 
                                            id="un-radio" 
                                            type="radio" 
                                            value="2" 
                                            name="coding" 
                                            className="w-4 h-4 text-teal-600 bg-gray-700 border-gray-600 ring-offset-gray-800 mr-1"
                                        />
                                        <label htmlFor="un-radio" className="ms-2 text-sm font-semibold text-gray-700">Unicode</label>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }else if(channel_type == "voice"){
                    return (<>
                        <div className="flex">
                            <div className="p-2 basis-1/2">
                                <input id="sender" className="w-full" className="border-orange-300 border-2 rounded-sm" type="text" name="sender" required="required" placeholder="Enter sender"  />
                            </div>                      
                            <div className="p-2 basis-1/4">
                                <div className="flex">
                                    <input type="radio" value="TR" name="route" className="mr-1"/>
                                    <div className="mt-2">
                                        <label className="font-semibold"> Tranactional </label>
                                    </div>
                                </div>
                            </div> 
                            <div className="p-2 basis-1/4">
                                <div className="flex">
                                    <input type="radio" value="PR" name="route" className="mr-1"/>
                                    <div className="mt-2">
                                        <label className="font-semibold"> Promotional </label>
                                    </div>
                                </div>
                            </div>                  
                        </div>

                        <div className="p-2">
                            <textarea id="content" name="content" className="w-full" type="text" placeholder="Enter Content" required="required"/>
                        </div>
                    </>)
                }
            }
            if(type === 'email'){
                return (
                    <>
                        <div className="flex">
                            <div className="p-2">
                                <input id="template_name" defaultValue={isedit[1].template_name} className="w-full" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required"/>
                            </div>                           
                        </div>
                        <div className="p-2">
                            <input id="sender" defaultValue={isedit[1].sender} className="w-full" type="text" name="sender" required="required" placeholder="Enter sender"  />
                        </div>   
                        <div className="flex">
                            <div className="p-2">
                                <input id="subject" name="subject" defaultValue={isedit[1].subject} className="w-full" type="text" placeholder="Enter Subject" required="required"/>
                            </div> 
                            <div className="p-2">
                                <input id="sender_name" name="sender_name" defaultValue={isedit[1].sender_name} className="w-full" type="text" placeholder="Enter Sender Name" required="required"/>
                            </div>                                     
                        </div>

                        <div className="p-2">                
                            <textarea id="content" name="content"className="w-full" type="text" placeholder="Enter Content" required="required"/>
                        </div>
                    </>
                )
            }else if(type == "voice"){
                return (
                    <>
                        <div className="flex">
                            <div className="p-2 basis-1/2">
                                <input 
                                    id="template_name"
                                    defaultValue={isedit[1].template_name}
                                    className="w-full" 
                                    className="border-grey-900 border-2 rounded-sm" 
                                    name="campaign_name" 
                                    type="text" 
                                    placeholder="Enter Campaign Name" 
                                    required="required"
                                />
                            </div>
                            <div className="p-2 basis-1/2">
                                <select
                                    className="w-full p-2"
                                    name="auth_key"
                                    required="required"
                                    onChange={e => setAuthKey(e.target.value)}
                                >
                                    <option value=""  disabled > Select Auth Key</option>
                                    {
                                        channelData.map((iteam) => {
                                            if(iteam.type == "voice"){
                                                if(isedit[1].auth_key == iteam.auth_key){
                                                    return (<option value={iteam.auth_key} selected > {iteam.name} </option>)
                                                }else{
                                                    return (<option value={iteam.auth_key} > {iteam.name} </option>)
                                                }
                                                
                                            }
                                        })
                                    }
                                </select>
                            </div>                               
                        </div>


                        <div className="flex">
                            <div className="p-2 basis-1/2">
                                <input id="sender" defaultValue={isedit[1].sender} className="w-full"
                                className="border-orange-300 border-2 rounded-sm" type="text" 
                                name="sender" required="required" placeholder="Enter sender"  />
                            </div>                      
                            <div className="p-2 basis-1/4">
                                <div className="flex">
                                    <input type="radio" 
                                     {...isedit[1].route == "TR" ? ("checked") : ("")}

                                    value="TR" name="route" className="mr-1"/>
                                    <div className="mt-2">
                                        <label className="font-semibold"> Tranactional </label>
                                    </div>
                                </div>
                            </div> 
                            <div className="p-2 basis-1/4">
                                <div className="flex">
                                    <input type="radio" value="PR" name="route"
                                    {...isedit[1].route == "PR" ? ("checked") : ("")}

                                    className="mr-1"/>
                                    <div className="mt-2">
                                        <label className="font-semibold"> Promotional </label>
                                    </div>
                                </div>
                            </div>                  
                        </div>

                        <div className="p-2">
                            <textarea id="content" name="content" defaultValue={isedit[1].message} className="w-full" type="text" placeholder="Enter Content" required="required"/>
                        </div>
                    </>
                )
            }
        }
        return (
            <>
                <div className="flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50">
                    <div className="relative w-auto my-6 mx-auto">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h4 className="text-xl font-semibold">Add Channel Details for {isedit[1].type.toUpperCase()}</h4>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={
                                        () => {
                                            setEdit([false, {}])
                                        }
                                    }
                                >
                                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <form className="relative p-2 flex-auto" onSubmit={handleSubmit}>            
                                <input type="hidden" name="type" value={isedit[1].type}  required="required"/>    
                                <input type="hidden" name="description" value=""/>   
                                
                                <div className="my_form">
                                   {apiCondition(isedit[1].type)}
                                </div>

                                <div className="flex items-center justify-end">
                                    <button
                                        className="bg-white text-red-500 border border-red-500 font-semibold text-sm px-3 py-2 mr-2 rounded"
                                        type="button"
                                        onClick={
                                            () => {
                                                setEdit([false, {}])
                                            }
                                        }
                                    >Close</button>
                                    <button
                                        className="bg-white text-emerald-500 border border-emerald-500 font-semibold text-sm px-3 py-2 rounded"
                                        type="submit"
                                    >Add</button>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"/>
            </>
        )
    }

    const msg_view = (item_id) => {
        let msg = ""
        Data.map(item => {
            if(item_id === item.id) {
                if(item.type == 'sms'){
                    msg = item.message
                }else if(item.type == 'voice'){
                    msg = item.message
                }else if(item.type == 'email'){
                    msg = item.message
                }
            }
        })
        console.log(msg)

        return (
            <>
                <div className="justify-center  flex  fixed inset-0 z-50 ">
                    <div className="relative w-auto overflow-auto my-6 mx-auto max-w-6xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                                <button className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => {                                        
                                        setViewMsg(null)
                                    }}
                                ><span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span></button>
                            </div>
                            <div className="relative flex-auto">
                                <div className="m-4 p-4">

                                 
                                    <div dangerouslySetInnerHTML={{ __html: msg.replace(/\n/g, '<br />')}}></div>
                                </div>
                                <div className="flex flex-row-reverse p-2 w-full border-t border-solid border-slate-200">
                                    <button className="btn_as_delete" onClick={() => {
                                        setViewMsg(null)
                                    }}>
                                       <i className="fa fa-times-circle" aria-hidden="true"></i>Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
        )
    }
    


    const Datas = (arr) => {
        return arr.map(item => {
            if(item.type == "sms"){
                return (
                    <tr className="table_my_td border-gray-700">

                        <td className="py-2 px-4">

                            <div className="flex flex-row ">
                                <div className="temp_Chnnel_icon my-auto mr-4">
                                   <img src={sms_logo} width="50" height="50" />
                                </div>
                                <div>
                                    <div className="grid grid-cols-1 mb-1 Template_name">               
                                        <h2>
                                            <i className="fa fa-id-card" aria-hidden="true"></i> 
                                            Template Name:  <b>{item.template_name}</b>
                                        </h2>
                                    </div>
                                    
                                    <div className="grid grid-cols-2 tem_detail">
                                        <p> <i className="fa fa-cubes  mr-1" aria-hidden="true"></i> Channel: <b> SMS </b> </p>
                                        <p> <i className="fa fa-address-card  mr-1" aria-hidden="true"></i> Sender: <b> {item.sender} </b> </p>
                                        <p>
                                            <i className="fa fa-check  mr-1" aria-hidden="true"></i> 
                                            Route: <b> {item.route == "TR" ? ("Transactional") :("Promostional")} </b>
                                        </p>

                                        <p> <i className="fa fa-id-badge mr-1" aria-hidden="true"></i> Template ID: <b> {item.template_id} </b> </p>
                                        <p> <i className="fa fa-id-badge mr-1" aria-hidden="true"></i> PE ID: <b> {item.pe_id} </b> </p>

                                    </div>
                                </div>
                            </div>   
    
                        </td>
                       
                       {/* <td className=" tem_message text-center">
                            <p>{item.message}</p>
                        </td>*/}
                       
                        
                       
                        <td className="py-2 px-4 font-semibold text-center">


                        <div className="mx-4 my-2">

                            <button
                                value={item.id}
                                onClick={(e) => setViewMsg(item.id)}
                                className="btn_as_report"
                            >
                               <i className="fa fa-eye mr-1" aria-hidden="true"></i> View Message
                            </button>
                        </div>

                        <div>

                            <button
                                value={item.id}
                                onClick={(e) => delTemp(e, item.id)}
                                className="btn_as_delete"
                            >
                                <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                onClick={(e) => editTemp(e, item.id)}
                                className="btn_as_shc"
                            >
                                <i className="fa fa-pencil-square" aria-hidden="true"></i>
                            </button>


                        </div>

                               


                            
                        </td>
                    </tr>
                )
            }else if(item.type == "voice"){
                return (
                    <tr className="table_my_td border-gray-700">
                        <td className="py-2 px-4">


                            <div className="flex flex-row ">
                                <div className="temp_Chnnel_icon my-auto mr-4">
                            	   <img src={voice_logo} width="40" height="40" />
                                </div>
                                <div>
                                    <div className="grid grid-cols-1 mb-1 Template_name">				
                                    	<h5>
                                            <i className="fa fa-id-card" aria-hidden="true"></i> 
                                            Template Name:  <b>{item.template_name}</b>
                                        </h5>
                                    </div>
                                	
                                	<div className="grid grid-cols-2 tem_detail">
                                        <p> <i className="fa fa-cubes  mr-1" aria-hidden="true"></i> Channel: <b> Voice </b> </p>
                                        <p> <i className="fa fa-address-card  mr-1" aria-hidden="true"></i> Sender: <b> {item.sender} </b> </p>
                                        <p>
                                            <i className="fa fa-check  mr-1" aria-hidden="true"></i> 

                                            Route: <b> {item.route == "TR" ? ("Transactional") :("Promostional")} </b>
                                        </p>
                                    </div>
                                </div>
                            </div>   
	
                        </td>
                       
                       {/* <td className=" tem_message text-center">
                            <p>{item.message}</p>
                        </td>*/}
                        <td className="py-2 px-4 font-semibold text-center">

                        <div className="mx-4 my-2">

                            <button
                                value={item.id}
                                onClick={(e) => setViewMsg(item.id)}
                                className="btn_as_report"
                            >
                               <i className="fa fa-eye mr-1" aria-hidden="true"></i> View Message
                            </button>
                        </div>
                            <button
                                value={item.id}
                                onClick={(e) => delTemp(e, item.id)}
                                className="btn_as_delete"
                            >
                                <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                onClick={(e) => editTemp(e, item.id)}
                                className="btn_as_shc"
                            >
                               <i className="fa fa-pencil-square" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                )  
            }else if(item.type == "email"){
                return (
                    <tr className="table_my_td border-gray-700">
                        <td className="py-2 px-4">
                        


                            <div className="flex flex-row ">
                                <div className="temp_Chnnel_icon my-auto mr-4">
                                   <img src={email_logo} width="40" height="40" />
                                </div>
                                <div>
                                    <div className="grid grid-cols-1 mb-1 Template_name">               
                                        <h5>
                                            <i className="fa fa-id-card mr-1" aria-hidden="true"></i> 
                                            Template Name:  <b>{item.template_name}</b>
                                        </h5>
                                    </div>
                                    
                                    <div className="grid grid-cols-2 gap-2 tem_detail">
                                        <p> <i className="fa fa-cubes  mr-1" aria-hidden="true"></i> Channel: <b> Email </b> </p>
                                        <p> <i className="fa fa-address-card  mr-1" aria-hidden="true"></i> Sender: <b> {item.sender} </b> </p>
                                        <p> <i className="fa fa-check  mr-1" aria-hidden="true"></i> Sender Name: <b> {item.sender_name} </b> </p>
                                    </div>
                                </div>
                            </div> 


                        </td>
                      
                       {/* <td className="py-2 px-4 font-bold text-center">
                            {item.message}
                        </td>*/}
                        <td className="py-2 px-4 font-semibold text-center">
                        <div className="mx-4 my-2">

                            <button
                                value={item.id}
                                onClick={(e) => setViewMsg(item.id)}
                                className="btn_as_report"
                            >
                               <i className="fa fa-eye mr-1" aria-hidden="true"></i> View Message
                            </button>
                        </div>
                            <button
                                value={item.id}
                                onClick={(e) => delTemp(e, item.id)}
                                className="btn_as_delete"
                            >
                                <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                            <button
                                type="button"
                                onClick={(e) => setEdit([true, item])}
                                className="btn_as_shc"
                            >
                                <i className="fa fa-pencil-square" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                )
            }else if(item.type == "whatsapp"){
                return (
                    <tr className="table_my_td border-gray-700">
                        <td className="py-2 px-4">
                            

                            <div className="flex flex-row ">
                                <div className="temp_Chnnel_icon my-auto mr-4">
                                   <img src={what_logo} width="40" height="40" />
                                </div>
                                <div>
                                    <div className="grid grid-cols-1 mb-1 Template_name">               
                                        <h5>
                                            <i className="fa fa-id-card mr-1" aria-hidden="true"></i> 
                                            Template Name:  <b>{item.template_name}</b>
                                        </h5>
                                    </div>
                                    
                                    <div className="grid grid-cols-2 gap-2 tem_detail">
                                        <p> <i className="fa fa-cubes  mr-1" aria-hidden="true"></i> Channel: <b> WhatsApp </b> </p>
                                        <p> <i className="fa fa-calendar-check-o  mr-1" aria-hidden="true"></i> WhatsApp Type: <b> {item.whatsapp_type} </b> </p>
                                        <p> <i className="fa fa-address-card  mr-1" aria-hidden="true"></i> Sender: <b> {item.sender} </b> </p>
                                        <p> <i className="fa fa-check  mr-1" aria-hidden="true"></i> Template Id: <b> {item.template_id} </b> </p>
                                    </div>
                                </div>
                            </div> 
                        </td>
                       
                        {/*<td className="py-2 px-4 font-semibold text-center"></td>*/}
                        <td className="py-2 px-4 font-semibold text-center">
                            <button
                                value={item.id}
                                onClick={(e) => delTemp(e, item.id)}
                                className="btn_as_delete"
                            >
                                <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                onClick={(e) => setEdit([true, item])}
                                className="btn_as_shc"
                            >
                                <i className="fa fa-pencil-square" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                ) 
            }
        })
    }

    async function test(e) {
        axios.post(e.target.value, {}, {headers})
        .then((res) => {
            res.data.data.links.shift();
            res.data.data.links.pop();
            const arr = res.data.data.links.map(item => item.url)
            setTotal(arr);
            setData(res.data.data.data)
        })
        .catch(err => {
            if(err.response.status == 401) noAuth()
        })
    }


    
    return (
        <>
            <div className="relative px-5 flex-auto max-w-6xl mx-auto">
                <button 
                    onClick={(e) => setView([true, "sms"]) }
                    type="button"
                    className="text-[#1286a8] border border-[#1286a8] font-semibold py-2 px-4 m-4 rounded-md hover:bg-[#1286a8] hover:text-[#fff]"
                ><i className="fa fa-comments mr-1 font-bold" aria-hidden="true"></i>SMS</button>

                <button 
                    onClick={(e) => setView([true, "email"]) }
                    type="button"
                    className="text-[#1286a8] border border-[#1286a8] font-semibold py-2 px-4 m-4  rounded-md hover:bg-[#1286a8] hover:text-[#fff]"
                ><i className="fa fa-envelope mr-1" aria-hidden="true"></i>Email</button>

                <button 
                    onClick={(e) => setView([true, "voice"]) }
                    type="button"
                    className="text-[#1286a8] border border-[#1286a8] font-semibold py-2 px-4  m-4 rounded-md hover:bg-[#1286a8] hover:text-[#fff]"
                ><i className="fa fa-phone mr-1" aria-hidden="true"></i>voice</button>

                <button 
                    onClick={(e) => setView([true, "whatsapp"]) }
                    type="button"
                    className="text-[#1286a8] border border-[#1286a8] font-semibold py-2 px-4  m-4 rounded-md hover:bg-[#1286a8] hover:text-[#fff]"
                ><i className="fa fa-comments mr-1" aria-hidden="true"></i>WhatsApp</button>
                
                {/* <button
                    type="button"
                    className="btn_as_my_wew"
                    onClick={(e) => setView([true, "call_api"])}
                >Call Api</button>*/}
            </div>
            {isview[0] === false ? ("") : newform()}
            {isedit[0] === false ? ("") : editform()}
            {isViewMsg == null ? ("") : msg_view(isViewMsg)}

            <div className=" mx-auto max-w-6xl pt-6">
                <table className="w-full py-2 table_my">
                    <thead className="table_my_th">
                        <tr>
                            <th scope="col" className="py-2 px-4">Template Details</th>
                            {/*<th scope="col" className="py-2 px-4">Message</th>*/}
                            <th scope="col" className="py-2 px-4">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Data == undefined ? ("") : Datas(Data)}
                    </tbody>
                </table>
            </div>

            <div className="mx-auto max-w-5xl pt-6 flex justify-center"> 
                <button 
                    onClick={(e) => test(e)}
                    value={total[0]}
                    className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee]"
                ><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                {
                    total.map((el, i) =>{
                        return (
                            <button 
                                onClick={(e) => test(e)}
                                value={el}
                                className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee] mx-2"
                            >{i+1}</button>
                        )
                    })
                }
                <button 
                    onClick={(e) => test(e)}
                    value={total[total.length - 1]}
                    className="bg-[#d2e8ee] text-[#1286a8] font-semibold py-2 px-4 rounded-md hover:bg-[#1286a8] hover:text-[#d2e8ee]"
                ><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
            </div>
            <ToastContainer />
        </>
    );
}
