/* eslint-disable */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import axios from 'axios';
import { useForm } from "react-hook-form";

import { Cookies,useCookies } from "react-cookie";
const host = "https://oiomni.com/api"
// const host = "https://13.200.85.235/api"

const headers = {
    "headers": {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'cors',
    }
}





export default function Login() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [cookies, setCookie] = useCookies(['token', 'user_id', 'auth_key', 'name', 'username', 'wa_auth_key'])
    const onSubmit = async (data) => {
        await axios.post(host + "/login", data, headers).then(function (response) {
            if (response.status == 200) {
                console.log(response.data)
                localStorage.setItem('token', response.data.token.original.access_token)
                localStorage.setItem('user_id', response.data.user_details.id)
                localStorage.setItem('auth_key', response.data.user_details.auth_key)
                localStorage.setItem('name', response.data.user_details.name)
                localStorage.setItem('username', response.data.user_details.username)
                localStorage.setItem('wa_auth_key', response.data.user_details.wa_auth_key)



                let expires = new Date()
                expires.setTime(expires.getTime() + (response.data.token.original.expires_in * 1000))



                setCookie('token', response.data.token.original.access_token, { path: '/', expires: expires })
                setCookie('user_id', response.data.user_details.id, { path: '/', expires: expires })
                setCookie('auth_key', response.data.user_details.auth_key, { path: '/', expires: expires })
                setCookie('name', response.data.user_details.name, { path: '/', expires: expires })
                setCookie('username', response.data.user_details.username, { path: '/', expires: expires })
                setCookie('wa_auth_key', response.data.user_details.wa_auth_key, { path: '/', expires: expires })



               
                toast.success(response.data.message)
                
                window.location.href = '/new'
            }
        })
            .catch(function (error) {
                console.log(error)
                const err = error.response.data
                if (err.status == 401) {
                    toast.error(err.message)
                } else if (err.status == 403) {
                    Object.values(err.message).map(val => {
                        toast.error(val[0])
                    })
                }
            });
    };

    return (
        <div className="body_color">
            <div className="form_box drop-shadow-xl">
                <div className="flex min-h-full items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md space-y-8">
                        <h2 className=" text-center text-3xl font-bold tracking-tight text-gray-900">LOGIN</h2>
                        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                            <div className="-space-y-px rounded-md shadow-sm">
                                <div>
                                    <input placeholder="Enter Username" {...register("username", { required: true })} type="text"
                                        className="relative block w-full appearance-none rounded-md border_new px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-gray-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />

                                    {errors.username && <span className="font-sans text-xs text-red-800 p-1"> Username is required</span>}
                                </div>
                                <div>
                                    <input type="password" placeholder="Enter Username" {...register("password", { required: true })}
                                        className="relative my-2 block w-full appearance-none rounded-md border_new px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-gray-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                    {errors.password && <span className="font-sans text-xs text-red-800 p-1">Password is required</span>}

                                </div>
                            </div>
                            <div>
                                <button type="submit" className="group relative btn_bg flex w-full justify-center  border border-transparent  py-2 px-4 text-sm font-medium">
                                    <i className="fa fa-user-circle pr-1" aria-hidden="true"></i> Log in
                                </button>

                                {/* <div className="login_btn my-3"> <Link to="/signup">  <i className="fa fa-user-plus pr-1" aria-hidden="true"></i> signup</Link> </div> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000} />
        </div>
    );
}
