/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef, useMemo, lazy, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { useParams, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Menu, Dialog } from '@headlessui/react'

import { Button, Modal, Dropdown, DropdownButton, NavDropdown } from 'react-bootstrap';
import ReactFlow, {
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    addEdge,
    Background,
    Controls,
    Handle,
    useNodes,
} from 'reactflow';
import 'reactflow/dist/style.css';

import Spinner from '../cutomComponent/spinner'
import Handles from '../cutomComponent/handles'
import BoxHeader from '../cutomComponent/boxHeader'
import Icons from '../cutomComponent/icons'
import DescriptionBox from '../cutomComponent/descriptionBox'
import SpreadsheetList from './testing.components';
import { AddListTemp, AddButtonTemp, JsonCols } from '../cutomComponent/addListTemplate.jsx';
import { WaTemplates, WaInterBtn } from '../cutomComponent/wa_custom.jsx';





import 'react-toastify/dist/ReactToastify.css';

import { useWaTemp, useWaInter, useTemp, useEmp } from './api/waTemp';


import { useQuery, QueryClientProvider, QueryClient } from '@tanstack/react-query';
import axios from 'axios';



const host = "http://oiomni.com:8000/api"
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');
const wa_authKey = localStorage.getItem('wa_auth_key');


const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    localStorage.removeItem('wa_auth_key');
    window.location.href = '/';
};



const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}


let new_body = {}



const queryClient = new QueryClient();

export default function NewGetFlow() {
    const { id } = useParams();
    const reactFlowWrapper = useRef(null);
    const waTempQuery = useWaTemp();
    const waInterQuery = useWaInter();
    const tempQuery = useTemp();
    const waEmployee = useEmp();








    const [rfInstance, setRfInstance] = useState(null);


    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [tempVar, setTempVar] = useState({});

    let [isWaOpen, setIsWaOpen] = useState([false, ""])
    let [actionPopup, setActionPopup] = useState([false, "", "", "", ""])
    let [keywords, setKeywords] = useState({ "1": "" });



    const [listObj, setListObj] = useState({
        "1": {
            "title": "ftghy",
            "1": {
                "row": "ftgyh",
                "desc": "ftghjf",
            }
        }
    })
    let [btnCount, setbtnCount] = useState([1, 1])

    const [spreadsheetList, setSpreadsheetList] = useState([]);
    const [allSheet, setAllSheet] = useState([]);
    const [newData, setNewData] = useState([]);


    const [sheet_id, setSheetID] = useState(["", ""]);

    const flowQuery = useQuery({
        queryKey: ['node', 'conn', 'details'],
        queryFn: async () => {
            try {
                const response = await axios.get(host + '/get_flow/' + id, headers);
                setNodes(response.data.nodes)
                setEdges(response.data.connection)
                return response.data;
            } catch (error) {
                throw error;
            }
        },
    })





    const OndeleteNode = (node_id) => {
        nodes.filter((el) => {
            if (el.data.value.type !== "start") {
                if (el.id === node_id) {
                    try {
                        axios.post(host + "/node_del", {
                            node_id: el.id, flow_id: id
                        }, headers).then(response => {
                            toast.success(response.data.message)
                            flowQuery.refetch()
                        })
                    } catch (error) {
                        throw new Error('Failed to fetch day from API');
                    }
                }
            }
        })
        console.log(node_id)
    }





    const CustomEdge = (event) => {
        let sourceX = event.sourceX
        let sourceY = event.sourceY
        let sourcePosition = event.sourcePosition
        let targetX = event.targetX
        let targetY = event.targetY
        let targetPosition = event.targetPosition

        const [edgePath, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });

        const new_edge_fun = () => {
            // return (
            //     <div className="con_as_div">
            //         <div className="con_as_div">
            //             <h4>{event.data.label}</h4>
            //         </div>

            //         <div className="con_as_dro_box">
            //             <Dropdown>
            //                 <Dropdown.Toggle as='a' className=" ">
            //                     <i className="fa fa-plus" aria-hidden="true"></i>
            //                 </Dropdown.Toggle>
            //                 <Dropdown.Menu as="div" className="shadow">
            //                     <Dropdown.Header as="h5">
            //                         <i className="fa fa-arrow-pointer"></i> Add Action
            //                     </Dropdown.Header>
            //                     <Dropdown.Item as="li"> Send Interactive </Dropdown.Item>
            //                     <Dropdown.Item as="li"> Send Interactive </Dropdown.Item>
            //                     <Dropdown.Item as="li"> Send Interactive </Dropdown.Item>
            //                 </Dropdown.Menu>
            //             </Dropdown>
            //         </div>
            //     </div>
            // )
        }
        return (
            <Fragment>
                <BaseEdge path={edgePath} label={event.data.label} />

                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            fontSize: 12,
                            pointerEvents: 'all',
                        }}
                    >
                        {new_edge_fun()}
                    </div>
                </EdgeLabelRenderer>
            </Fragment>
        );
    }

    const addKeywords = (values, node_id) => {
        let all_nodes = nodes.map(i => {
            if (i.id == node_id) {
                if (i.data.value.api != null) {
                    const key_words = i.data.value.api.keywords
                    let x = Object.keys(key_words).length;
                    let new_objs = {};
                    let t = 0;
                    Object.keys(key_words).map(f => {
                        new_objs[`${t}`] = key_words[f]
                        t++
                    })
                    new_objs[`${x}`] = reactFlowWrapper.current.querySelector(`#add_keyword-${node_id}`).value
                    i.data.value.api.keywords = new_objs
                } else {
                    let new_objs = {};
                    new_objs[0] = reactFlowWrapper.current.querySelector(`#add_keyword-${node_id}`).value
                    i.data.value.api = { "keywords": new_objs }
                }

            }
            console.log(i)
            return i
        })
        try {
            axios.post(host + "/save_flow", {
                flow_data: {
                    flow: {
                        "nodes": all_nodes,
                        "edges": edges,
                    }, flow_id: id
                }
            }, headers).then(res => {
                reactFlowWrapper.current.querySelector(`#add_keyword-${node_id}`).value = ""
                flowQuery.refetch()
            })
        } catch (err) {
            console.log(err)
            throw new Error('Failed to fetch day from API');
        }
    }


    const attr_Change = (value, nodes_id) => {
        let all_nodes = nodes.map(i => {
            if (i.id == nodes_id) {
                let api = {
                    "values": value
                }
                i.data.value.api = api
            }
            return i
        })

        try {
            axios.post(host + "/save_flow", {
                flow_data: {
                    flow: {
                        "nodes": all_nodes,
                        "edges": edges,
                    }, flow_id: id
                }
            }, headers).then(res => {
                setActionPopup([false, "", "", "", ""])
                flowQuery.refetch()
            })
        } catch (err) {
            console.log(err)
            throw new Error('Failed to fetch day from API');
        }
    }

    const CustomNode = (event) => {
        const id = event.id;
        const type = event.data.value.type


        if (type == "on_message") {
            function keywords() {
                if (event.data.value.api != null) {
                    if (event.data.value.api.keywords) {
                        if (Object.keys(event.data.value.api.keywords).length != 0) {
                            return Object.keys(event.data.value.api.keywords).map(i => {
                                return (
                                    <Fragment>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="list_name_cro" style={{ backgroundColor: '#612752' }}>
                                                    <span>{event.data.value.api.keywords[i].charAt(0).toUpperCase()}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <h6 className="mt-2"> {event.data.value.api.keywords[i]} </h6>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="d-flex">
                                                    <button type="button" className="btn-sm btn-success btn-circle mt-2">
                                                        <i className="fa fa-user-pen" aria-hidden="true"></i>
                                                    </button>
                                                    <button type="button" className="btn-sm btn-danger btn-circle ml-1 mt-2">
                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })
                        }

                    }
                }

            }
            const keyword_id = `add_keyword-${id}`
            return (
                <Fragment>



                    <div className="card position-relative p-0 box_width">
                        <div className="card-header temp_bg">
                            <div className="row">
                                <div className="col-md-7">
                                    <h5 className="mt-2 font-weight-bold temp_text_color">
                                        <i className="fa-regular fa-rectangle-list"></i>  On Messsage
                                    </h5>
                                </div>

                                <div className="col-md-5 text-right">
                                    <button type="button" className="btn btn-success">
                                        <i className="fa-solid fa-user-pen"></i> </button>
                                    <button type="button" className="btn btn-danger" onClick={(e) => OndeleteNode(id)}>
                                        <i className="fa-solid fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div className="card-body mt-0 ">
                            <div className="contact_add_div">
                                <div className="mx-auto">

                                    <div className="row">
                                        <div className="col-md-8">
                                            <input type="text" name="keywords" id={keyword_id} className="form-control" placeholder="Enter Group Name" />
                                        </div>
                                        <div className="col-md-4 text-right">
                                            <button
                                                type="button"
                                                className="btn" style={{ backgroundColor: '#1286a8', color: "#fff" }}
                                                onClick={(e) => addKeywords(e.target.value, id)}
                                            ><i className="fa fa-user-plus" aria-hidden="true"></i> Add</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="list_cot">
                                {keywords()}
                            </div>
                        </div>



                    </div>




                    <Handle
                        className="main_point"
                        type="source"
                        position="right"
                        id={`Sourcehandle-${id}`}

                    >
                        <div className="star_btn">
                            <div className="">

                                <Dropdown>
                                    <Dropdown.Toggle as='a' className=" ">
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as="div" className="shadow">
                                        <Dropdown.Header as="h5">
                                            <i className="fa fa-arrow-pointer"></i> Add Action
                                        </Dropdown.Header>
                                        {items_ul(id, "all", `Sourcehandle-${id}`)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Handle>
                </Fragment>
            )
        } else if (type == "whatsapp") {
            return wa_desgin(event)
        }

        else if (type == "on_atrribute") {

            const all_value = (vals) => {
                if (vals == "support-status-0") return "Open"
                else if (vals == "support-status-1") return "Complete"
                else if (vals == "support-status-2") return "In Progress"
                else if (vals == "support-priority-0") return "Low"
                else if (vals == "support-priority-1") return "Medium"
                else if (vals == "support-priority-2") return "High"
                else if (vals == "sales-status-Not Interested") return "Not Interested"
                else if (vals == "sales-status-Interested") return "Interested"
                else if (vals == "sales-lead-Hot") return "Hot"
                else if (vals == "sales-lead-Warm") return "Warm"
                else if (vals == "sales-lead-Cold") return "Cold"
            }
            const selectedFun = (v) => {
                if (event.data.value.api != null && event.data.value.api.values) {
                    if (v == event.data.value.api.values) {
                        return (<option selected value={v}>{all_value(v)}</option>)
                    } else {
                        return (<option value={v}>{all_value(v)}</option>)
                    }
                } else {
                    return (<option value={v}>{all_value(v)}</option>)
                }
            }
            const all_options = [
                "support-status-0",
                "support-status-1",
                "support-status-2",
                "support-priority-0",
                "support-priority-1",
                "support-priority-2",
                "sales-status-Not Interested",
                "sales-status-Interested",
                "sales-lead-Hot",
                "sales-lead-Warm",
                "sales-lead-Cold"
            ]


            return (
                <Fragment>
                    <div className="card position-relative p-0 box_width">
                        <div className="card-header temp_bg">
                            <div className="row">
                                <div className="col-md-7">
                                    <h5 className="mt-2 font-weight-bold temp_text_color">
                                        <i className="fa-regular fa-rectangle-list"></i>  Attribute
                                    </h5>
                                </div>

                                <div className="col-md-5 text-right">
                                    <button type="submit" className="btn btn-success">
                                        <i className="fa-solid fa-user-pen"></i> </button>
                                    <button type="submit" className="btn  btn-danger" onClick={(e) => OndeleteNode(id)}>
                                        <i className="fa-solid fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body mt-0 ">
                            <div className="Attribute_box">
                                <select className="form-control" onChange={(e) => attr_Change(e.target.value, id)}>
                                    <option value="" disabled  >Select Attribute </option>
                                    {
                                        all_options.map(i => selectedFun(i))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <Handle className="main_point" type="source" position="right" id={`Sourcehandle-${id}`}>
                        <div className="star_btn">
                            <div className="">
                                <Dropdown>
                                    <Dropdown.Toggle as='a' className=" ">
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as="div" className="shadow">
                                        <Dropdown.Header as="h5">
                                            <i className="fa fa-arrow-pointer"></i> Add Action
                                        </Dropdown.Header>
                                        {items_ul(id, "all", `Sourcehandle-${id}`)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Handle>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <Handles type='target' />
                <div className="card position-relative p-0 box_width">
                    <div className="card-header temp_bg">
                        <div className="row">
                            <div className="col-md-7">
                                <h5 className="mt-2 font-weight-bold temp_text_color">
                                    <i className="fa-regular fa-rectangle-list"></i>  Webhook
                                </h5>
                            </div>
                            <div className="col-md-5 text-right">
                                <button type="submit" className="btn btn-success">
                                    <i className="fa-solid fa-user-pen"></i> </button>
                                <button type="submit" className="btn  btn-danger"> <i className="fa-solid fa-trash"></i> </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body mt-0 ">
                        <div className="Attribute_box">
                            <select className="form-control">
                                <option>Product1 : Electronics </option>
                                <option>Product2 : Sports </option>
                            </select>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    const nodeTypes = useMemo(() => ({ customnode: CustomNode }), []);
    const edgeTypes = useMemo(() => ({ customedge: CustomEdge }), []);


    const wa_desgin = (datas) => {
        if (datas.data.value.type == "whatsapp") {
            const item = datas.data.value.extra
            if (item && item != "") {
                if (datas.data.value.api.whatsapp_type == "template") {
                    const temp_button_fun = (vals) => {
                        if (vals.length == 0) {
                            return ""
                        }
                        return (
                            <Fragment>
                                <div className="line_ashish"> </div>
                                <div className="Button_teml">
                                    {vals.map((x, sec_ind) => {
                                        if (x.type == "QUICK_REPLY") {
                                            return (
                                                <div className="row">
                                                    <div className="col-md-11">
                                                        <div className="Button_div_box ">
                                                            <p> {x.text} </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <Handle type="source" position="right" id={`temp_btn-${x.text}-${sec_ind}`} className="right_main main_point">
                                                            <div className="star_btn">
                                                                <div className="">
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle as='a' className=" ">
                                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu as="div" className="shadow">
                                                                            <Dropdown.Header as="h5">
                                                                                <i className="fa fa-arrow-pointer"></i> Add Action
                                                                            </Dropdown.Header>
                                                                            {items_ul(datas.id, x.text + " Button", `temp_btn-${x.text}-${sec_ind}`)}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </Handle>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </Fragment>
                        )
                    }
                    const new_function = () => {
                        return JSON.parse(item.companents).map(value => {
                            if (value.type == "HEADER") {
                                if (value.format == "TEXT") {
                                    return (
                                        <Fragment>
                                            <div className="header_teml">
                                                <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                                <div className="header_enter_text_box">
                                                    <p> {value.text}</p>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                } else if (value.format == "IMAGE") {
                                    return (
                                        <div className="header_teml">
                                            <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                            <div className="header_enter_text_box">
                                                <div className="img-fluid">   <img src={value.example.header_handle[0]} /> </div>
                                            </div>
                                        </div>
                                    )
                                } else if (value.format == "DOCUMENT") { }
                            } else if (value.type == "BODY") {
                                return (
                                    <div className="body_teml mt-2">
                                        <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                                        <div className="body_enter_text_box">
                                            <p>{value.text}</p>
                                        </div>
                                    </div>
                                )
                            } else if (value.type == "BUTTONS") {
                                return temp_button_fun(value.buttons)
                            }
                        })
                    }


                    return (
                        <Fragment>
                            <Handles type='target' />
                            <div className="card position-relative p-0 box_width">
                                <div className="card-header temp_bg">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h5 className="mt-2 font-weight-bold temp_text_color">
                                                <i className="fa-regular fa-rectangle-list"></i> Template
                                            </h5>
                                        </div>
                                        <div className="col-md-5 text-right">
                                            <button type="submit" className="btn btn-success">
                                                <i className="fa-solid fa-user-pen"></i> </button>
                                            <button type="submit" className="btn  btn-danger" onClick={(e) => OndeleteNode(datas.id)}>
                                                <i className="fa-solid fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body mt-0">{new_function()}</div>
                            </div>
                        </Fragment>
                    )




                } else if (datas.data.value.api.whatsapp_type == "interactive") {
                    const header_fun = (i) => {
                        if (i.header_data != "" && i.header_data != null) {
                            if (i.header_type == "text") {
                                return (
                                    <div className="header_teml">
                                        <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                        <div className="header_enter_text_box">
                                            <p>{i.header_data}</p>
                                        </div>
                                    </div>
                                )
                            } else if (i.header_type == "image") {
                                return (
                                    <div className="header_teml">
                                        <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                        <div className="header_enter_text_box">
                                            <div className="img-fluid">  <img src={i.header_data} alt="WA Header Image" /> </div>
                                        </div>
                                    </div>
                                )
                            }

                        }
                    }

                    const footer_fun = (i) => {
                        if (i.footer != "" && i.footer != null) {
                            return (
                                <div className="footer_teml mt-2">
                                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                                    <div className="footer_enter_text_box">
                                        <p>{i.footer}</p>
                                    </div>
                                </div>
                            )
                        }
                    }

                    const button_fun = (i) => {
                        const main_arr = JSON.parse(datas.data.value.api.interactive_btn)

                        return main_arr.map((x, sec_ind) => {
                            if (datas.data.value.api.interactive_type == "list") {
                                return (
                                    <div className="row">
                                        <div className="col-md-11">
                                            <div className="Button_div_box ">
                                                <p> {x.row_title}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <Handle type="source" position="right" id={`list_btn-${x.text}-${sec_ind}`} className="right_main main_point">
                                                <div className="star_btn">
                                                    <div className="">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as='a' className=" ">
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu as="div" className="shadow">
                                                                <Dropdown.Header as="h5">
                                                                    <i className="fa fa-arrow-pointer"></i> Add Action
                                                                </Dropdown.Header>
                                                                {items_ul(datas.id, x.row_title + " List(Button)", `list_btn-${x.row_title}-${sec_ind}`)}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </Handle>
                                        </div>
                                    </div>
                                )

                            } else if (datas.data.value.api.interactive_type == "button") {
                                return (
                                    <div className="row">
                                        <div className="col-md-11">
                                            <div className="Button_div_box ">
                                                <p> {x.title}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-1">

                                            <Handle type="source" position="right" id={`button_btn-${x.text}-${sec_ind}`} className="right_main main_point">
                                                <div className="star_btn">
                                                    <div className="">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as='a' className=" ">
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu as="div" className="shadow">
                                                                <Dropdown.Header as="h5">
                                                                    <i className="fa fa-arrow-pointer"></i> Add Action
                                                                </Dropdown.Header>
                                                                {items_ul(datas.id, x.title + " Button", `button_btn-${x.title}-${sec_ind}`)}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </Handle>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                    return (
                        <Fragment>
                            <Handles type='target' />
                            <div className="card position-relative p-0 box_width">
                                <div className="card-header temp_bg">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h5 className="mt-2 font-weight-bold temp_text_color">
                                                <i className="fa-regular fa-rectangle-list"></i> Template
                                            </h5>
                                        </div>
                                        <div className="col-md-5 text-right">
                                            <button type="submit" className="btn btn-success">
                                                <i className="fa-solid fa-user-pen"></i> </button>
                                            <button type="submit" className="btn  btn-danger" onClick={(e) => OndeleteNode(datas.id)}>
                                                <i className="fa-solid fa-trash"></i> </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body mt-0 ">
                                    {header_fun(item)}
                                    <div className="body_teml mt-2">
                                        <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                                        <div className="body_enter_text_box">
                                            <p>{item.body}</p>
                                        </div>
                                    </div>
                                    {footer_fun(item)}
                                    <div className="line_ashish"> </div>
                                    <div className="Button_teml">{button_fun(item)}</div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            }
        }
    }

    const items_ul = (node_id, cond, handles) => {
        return (
            <Fragment>
                <Dropdown.Item as="li" onClick={() => setActionPopup([true, "button", node_id, cond, handles])}> Send Interactive </Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setActionPopup([true, "list", node_id, cond, handles])}> Send Interactive List</Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setActionPopup([true, "template", node_id, cond, handles])}> Send Template </Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setActionPopup([true, "spreedsheet_temp", node_id, cond, handles])}> Send  Template Via Spreedsheet</Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setActionPopup([true, "spreedsheet_inte_list", node_id, cond, handles])}> Send Interactive List via Spreedsheet </Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setActionPopup([true, "spreedsheet_inte_btn", node_id, cond, handles])}> Send Interactive via Spreedsheet </Dropdown.Item>


                <Dropdown.Item as="li" onClick={() => setIsWaOpen([true, "attr_change"])}> Attribute Change </Dropdown.Item>

                <Dropdown.Item as="li" onClick={() => setIsWaOpen([true, "assign"])}>  Chat Assign</Dropdown.Item>

                <Dropdown.Item as="li" onClick={() => setIsWaOpen([true, "call_api"])}> Call Api </Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setIsWaOpen([true, "spreedsheet"])}> Send Promotional SMS</Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setIsWaOpen([true, "spreedsheet"])}> Send Transational SMS</Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setIsWaOpen([true, "spreedsheet"])}> Send Promotional Voice</Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setIsWaOpen([true, "spreedsheet"])}> Send Transational Voice</Dropdown.Item>
                <Dropdown.Item as="li" onClick={() => setIsWaOpen([true, "spreedsheet"])}> Save in a Group</Dropdown.Item>
            </Fragment>
        )
    }




    const popup_action_fun = (params) => {
        const act_val = params[1]
        const cond = params[3]
        let parent = 0

        const main_box = reactFlowWrapper.current

        let src_handle = null
        if (params[4] != "") src_handle = params[4]
        if (params[2] != "") parent = params[2]


        console.log(src_handle)


        const settingInterVar = (type, data_types, index, val) => {
            let i = "index" + String(index)
            let t = {}
            t[`${i}`] = { "col": val, "type": data_types }

            if (type == "body") {
                console.log(type, t)
                new_body.body = { ...new_body.body, ...t }
            } else if (type == "header") {
                console.log(type, t)
                new_body.header = { ...new_body.header, ...t }
            } else if (type == "button") {
                console.log(type, t)
                new_body.button = { ...new_body.button, ...t }
            }
            setTempVar(new_body)
        }


        const templates_wa_new = () => {
            const template_select = (e) => {
                e.preventDefault();

                const main_arr = ["number", "profile_name"]

                waTempQuery.data.data.template.map(i => {
                    if (i.template_id == e.target.value) {
                        ReactDOM.createRoot(main_box.querySelector('#templates')).render(<WaTemplates waComponent={i.companents} mainArr={main_arr} />);
                    }
                })

            }


            return (
                <div>
                    <select className="form-control" name="phone_no_id">
                        <option value="" >Select Sending Number</option>
                        {
                            waTempQuery.data.data.phone_details.map(i => {
                                return <option value={i.phone_no_id}>{i.display_phone_no}</option>
                            })
                        }
                    </select>
                    <select className="form-control" name='template_id' onChange={(e) => template_select(e)}>
                        <option value="" >Select Templates</option>
                        {
                            waTempQuery.data.data.template.map(i => {
                                return <option value={i.template_id}>{i.template_name}</option>
                            })
                        }
                    </select>
                    <div id='templates'></div>
                </div>
            )

        }

        const wa_list_temp = () => {
            const interactive_select = (e) => {
                e.preventDefault();

                const main_arr = ["number", "profile_name"]

                if (e.target.value == "new") {
                    ReactDOM.createRoot(main_box.querySelector('#templates')).render(<AddListTemp />);
                } else {
                    waInterQuery.data.data.interactive.map(i => {
                        if (i.template_id == e.target.value) {

                            ReactDOM.createRoot(main_box.querySelector('#templates')).render(
                                <QueryClientProvider client={queryClient}>
                                    <WaInterBtn waComponent={i} mainArr={main_arr} />
                                </QueryClientProvider>
                            );
                        }
                    })
                }
            }

            return (
                <div>

                    <div className="row my-2">

                        <div className="col-md-6">
                            <select className="form-control" name="phone_no_id">
                                <option value="" >Select Sending Number</option>
                                {
                                    waInterQuery.data.data.phone_details.map(i => {
                                        return <option value={i.phone_no_id}>{i.display_phone_no}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div className="col-md-6">
                            <select className="form-control " name='template_id' onChange={(e) => interactive_select(e)}>
                                <option value="" >Select Templates</option>
                                <option value="new">new</option>


                                {
                                    waInterQuery.data.data.interactive.map(i => {
                                        if (i.int_type == "list") {
                                            return <option value={i.template_id}>{i.title}</option>
                                        }
                                    })
                                }
                            </select>
                        </div>

                    </div>

                    <hr />

                    <div id='templates' className="my-2"></div>
                </div>
            )

        }

        const wa_button_temp = () => {
            const interactive_select = (e) => {
                e.preventDefault();

                const main_arr = ["number", "profile_name"]

                if (e.target.value == "new") {
                    ReactDOM.createRoot(main_box.querySelector('#templates')).render(<AddButtonTemp />);
                } else {
                    waInterQuery.data.data.interactive.map(i => {
                        if (i.template_id == e.target.value) {
                            ReactDOM.createRoot(main_box.querySelector('#templates')).render(
                                <QueryClientProvider client={queryClient}>
                                    <WaInterBtn waComponent={i} mainArr={main_arr} />
                                </QueryClientProvider>
                            );

                        }
                    })

                }
            }

            return (
                <div className='row'>
                    <div className='col-md-6'>
                        <select className="form-control " name="phone_no_id">
                            <option value="" >Select Sending Number</option>
                            {
                                waInterQuery.data.data.phone_details.map(i => {
                                    return <option value={i.phone_no_id}>{i.display_phone_no}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-6'>
                        <select className="form-control " name='template_id' onChange={(e) => interactive_select(e)}>
                            <option value="" >Select Templates</option>
                            <option value="new" >new</option>
                            {
                                waInterQuery.data.data.interactive.map(i => {
                                    if (i.int_type == "button") {
                                        return <option value={i.template_id}>{i.title}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                    <div id='templates'></div>
                </div>
            )

        }



        const fetchSpreadsheetList = async () => {
            await axios.post(host + '/spreed_sheet_list', {}, {
                'headers': {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Accept': "application/json",
                    'mode': 'no-cors',
                }
            })
                .then(function (response) {
                    if (response.data.status == "expired") {
                        window.open(response.data.route, '', 'width=800, height=600');
                    } else {
                        if (response.status == 200) {
                            setSpreadsheetList(response.data.results)
                        }
                    }
                })
                .catch(function (error) {
                    // const err = error.response
                    // if (err.status == 401) {
                    // 	noAuth()
                    // }
                });
        };

        const newAcc = () => { window.open('http://oiomni.com:8000/api/oauthCallback', '', 'width=800, height=600') }
        const new_function = async (e) => {
            await axios.post(host + '/spreed_sheet_detail', { "id": e.target.value }, headers)
                .then(function (response) {
                    if (response.data.status == "expired") {
                        window.open(response.data.route, '', 'width=800, height=600');
                    } else {
                        setSheetID([e.target.value, sheet_id[1]])
                        if (response.status == 200) setAllSheet(response.data.sheets)
                    }
                })
        }

        const all_sheet_fun = async (e) => {
            setSheetID([sheet_id[0], e.target.value])

            await axios.post(host + '/sheet_detail', { "id": sheet_id[0], "sheetname": sheet_id[1] }, headers)
                .then(function (response) {
                    if (response.data.status == "expired") {
                        window.open(response.data.route, '', 'width=800, height=600');
                    } else {
                        if (response.status == 200) setNewData(response.data.values)
                    }
                })
        }

        const ss_template_select = (e) => {
            e.preventDefault();
            if (newData.length == 0) {
                ReactDOM.createRoot(main_box.querySelector('#ss_templates')).render(<span>Firstly select the Sheets of SpredSheet</span>);
            } else {
                const main_arr = newData[0]
                waTempQuery.data.data.template.map(i => {
                    if (i.template_id == e.target.value) {
                        ReactDOM.createRoot(main_box.querySelector('#ss_templates')).render(<WaTemplates waComponent={i.companents} mainArr={main_arr} />);
                    }
                })
            }
        }

        const ssNumberCols = (num_values) => {
            const main_box = reactFlowWrapper.current
            main_box.querySelector('#num_col').innerHTML = JSON.stringify({ "num_col": num_values })
        }



        const main_fun = () => {
            if (act_val == "list") return wa_list_temp()
            else if (act_val == "button") return wa_button_temp()
            else if (act_val == "template") return templates_wa_new()
            else if (act_val == "spreedsheet_temp") {
                if (spreadsheetList.length == 0) fetchSpreadsheetList();
                return (
                    <div className='mx-12 px-12'>
                        <h2>Spreadsheet List</h2>
                        <div>
                            <select className='form-control' name="sheet_id" onChange={(e) => new_function(e)}>
                                <option value=""> Select</option>
                                {spreadsheetList.map(i => (<option value={i.spid}> {i.spname}</option>))}
                            </select>

                            <select name="sheet_name" className='form-control' onChange={(e) => all_sheet_fun(e)}>
                                <option value="select"> Select</option>
                                {allSheet.map(i => (<option value={i}> {i}</option>))}
                            </select>

                            <button onClick={(e) => newAcc()} className='btn btn-info'> New Account</button>
                        </div>
                        <hr />
                        <div>
                            <select className="form-control" name="phone_no_id">
                                <option value="" >Select Sending Number</option>
                                {waTempQuery.data.data.phone_details.map(i => <option value={i.phone_no_id}>{i.display_phone_no}</option>)}
                            </select>

                            <select className="form-control" name='template_id' onChange={(e) => ss_template_select(e)}>
                                <option value="" >Select Templates</option>
                                {waTempQuery.data.data.template.map(i => <option value={i.template_id}>{i.template_name}</option>)}
                            </select>


                            <div id='ss_templates'></div>
                        </div>
                    </div>
                )
            } else if (act_val == "on_webhook") {
                const webhook_type = (vals) => {
                    main_box.querySelector('#extra').innerHTML = ""
                    if (vals == "post") ReactDOM.createRoot(main_box.querySelector('#extra')).render(<JsonCols />);
                    else ReactDOM.createRoot(main_box.querySelector('#extra')).render(<Fragment></Fragment>);
                }
                return (
                    <Fragment>
                        <input name="webhook_url" className='form-control' placeholder='enter your webhook url' />
                        <select name="webhook_type" className='form-control' onChange={(e) => webhook_type(e.target.value)}>
                            <option value="" disabled selected >Select Webhook Type </option>
                            <option value="get">Get</option>
                            <option value="post">Post</option>
                        </select>
                        <div id='extra'></div>
                    </Fragment>
                )
            } else if (act_val == "on_atrribute") {
                const node_id = `on_atrribute-channel-${+Date.now()}`
                const api = {}
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': "On Atrribute Change",
                        'value': { 'parent': parent, 'type': "on_atrribute", 'api': api, 'description': "" }
                    },
                    'position': { 'x': Math.random() * window.innerWidth + 100, 'y': Math.random() * window.innerHeight }
                };

                const edge1 = {
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': src_handle,
                    'targetHandle': null,
                    'id': `reactflow__edge-${parent}-${node_id}`,
                    'animated': false,
                    'type': "customedge",
                    'data': {
                        "delay": { "days": 0, "hours": 0, "minutes": 0 },
                        "label": cond,
                        "custom_condition": "",
                        "extra": {}
                    }
                }


                setNodes((nds) => nds.concat(node1));
                setEdges((eds) => eds.concat(edge1));

                try {
                    axios.post(host + "/save_flow", {
                        flow_data: {
                            flow: {
                                "nodes": nodes.concat(node1),
                                "edges": edges.concat(edge1),
                            }, flow_id: id
                        }
                    }, headers).then(res => {
                        setActionPopup([false, "", "", "", ""])
                        flowQuery.refetch()
                    })
                } catch (err) {
                    console.log(err)
                    throw new Error('Failed to fetch day from API');
                }
            }

            else if (act_val == "spreedsheet_inte_btn") {
                const ss_interactive_select = (e) => {
                    e.preventDefault();

                    // main_box.querySelector('#ss_templates').innerHTML = ""
                    // var div_ele = document.createElement("div");
                    // setTempVar({})
                    if (e.target.value == "new") {
                        ReactDOM.createRoot(main_box.querySelector('#ss_templates')).render(<AddButtonTemp />);
                    } else {
                        if (newData.length == 0) {
                            main_box.querySelector('#ss_templates').innerHTML = "Firstly select the Sheets of SpredSheet"
                        } else {
                            const main_arr = newData[0]
                            waInterQuery.data.data.interactive.map(i => {
                                if (i.template_id == e.target.value) {
                                    ReactDOM.createRoot(main_box.querySelector('#ss_templates')).render(
                                        <QueryClientProvider client={queryClient}>
                                            <WaInterBtn waComponent={i} mainArr={main_arr} />
                                            <label>Select Number Colume</label>
                                            <select className='form-control' name='number' required onChange={(e) => ssNumberCols(e.target.value)}>
                                                {
                                                    main_arr.map((item, key) => <option value={key}>{item}</option>)
                                                }
                                            </select>
                                        </QueryClientProvider>
                                    );
                                }
                            })
                        }
                    }

                }
                if (spreadsheetList.length == 0) fetchSpreadsheetList();
                return (
                    <div>

                        <h2>Spreadsheet List</h2>
                        <div>
                            <select className='form-control' name="sheet_id" onChange={(e) => new_function(e)}>
                                <option value=""> Select</option>
                                {spreadsheetList.map(i => (<option value={i.spid}> {i.spname}</option>))}
                            </select>

                            <select name="sheet_name" className='form-control' onChange={(e) => all_sheet_fun(e)}>
                                <option value="select"> Select</option>
                                {allSheet.map(i => (<option value={i}> {i}</option>))}
                            </select>

                            <button onClick={(e) => newAcc()} className='btn btn-info'> New Account</button>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <select className="form-control " name="phone_no_id">
                                    <option value="" >Select Sending Number</option>
                                    {
                                        waInterQuery.data.data.phone_details.map(i => <option value={i.phone_no_id}>{i.display_phone_no}</option>)
                                    }
                                </select>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-control " name='template_id' onChange={(e) => ss_interactive_select(e)}>
                                    <option value="" >Select Templates</option>
                                    <option value="new" >new</option>
                                    {
                                        waInterQuery.data.data.interactive.map(i => {
                                            if (i.int_type == "button") {
                                                return <option value={i.template_id}>{i.title}</option>
                                            }
                                        })
                                    }
                                </select>
                            </div>
                            <textarea id='num_col'></textarea>
                            <div id='ss_templates'></div>
                        </div>
                    </div>

                )
            }
        }

        const action_new_save = (e) => {
            e.preventDefault();
            let type = e.target.type.value

            const node_id = type + `-channel-${+Date.now()}`

            if (type == "template") {

                let api = {
                    'campaign_name': "testing",
                    'sender': e.target.phone_no_id.value,
                    'whatsapp_var': e.target.tempVar.value,
                    'template_id': e.target.template_id.value,
                    "auth_key": wa_authKey,
                    "whatsapp_type": "template",
                }
                if (e.target.temp_btn.value && e.target.temp_btn.value != "") {
                    api.interactive_btn = e.target.temp_btn.value
                    api.interactive_type = "button"
                }
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': "Template",
                        'value': {
                            'parent': parent,
                            'type': "whatsapp",
                            'api': api,
                            'description': "",
                        }
                    },
                    'position': { 'x': Math.random() * window.innerWidth + 100, 'y': Math.random() * window.innerHeight }
                };

                const edge1 = {
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': src_handle,
                    'targetHandle': null,
                    'id': `reactflow__edge-${parent}-${node_id}`,
                    'animated': false,
                    'type': "customedge",
                    'data': {
                        "delay": { "days": 0, "hours": 0, "minutes": 0 },
                        "label": cond,
                        "custom_condition": "",
                        "extra": {
                            'srcHandle': src_handle,
                            'trgHandle': null,
                        },
                    }
                }


                setEdges((eds) => eds.concat(edge1));
                setNodes((nds) => nds.concat(node1));

                try {
                    axios.post(host + "/save_flow", {
                        flow_data: {
                            flow: {
                                "nodes": nodes.concat(node1),
                                "edges": edges.concat(edge1),
                            }, flow_id: id
                        }
                    }, headers).then(res => {
                        setActionPopup([false, "", "", "", ""])
                        flowQuery.refetch()
                    })
                } catch (err) {
                    console.log(err)
                    throw new Error('Failed to fetch day from API');
                }
            } else if (type == "list") {
                if (e.target.template_id.value == "new") {
                    let testing = {
                        template_name: e.target.template_name.value,
                        int_type: "list",
                        header_type: e.target.header_type.value,
                        header_data: e.target.header_data.value,
                        body: e.target.body.value,
                        footer: e.target.footer.value,
                        btn_title: e.target.btn_title.value,
                        action: JSON.parse(e.target.listObj.value)
                    }
                } else {
                    let api = {
                        'campaign_name': "testing",
                        'sender': e.target.phone_no_id.value,
                        'whatsapp_var': e.target.tempVar.value,
                        'template_id': e.target.template_id.value,
                        "auth_key": wa_authKey,
                        "whatsapp_type": "interactive",
                    }
                    if (e.target.temp_btn.value && e.target.temp_btn.value != "") {
                        api.interactive_btn = e.target.temp_btn.value
                        api.interactive_type = "list"
                    }
                    let extra = {}
                    waInterQuery.data.data.interactive.map(i => {
                        if (i.template_id == e.target.template_id.value) {
                            extra = i
                        }
                    })

                    const node1 = {
                        'id': node_id,
                        'type': 'customnode',
                        'data': {
                            'label': "Template",
                            'value': {
                                'parent': parent,
                                'type': "whatsapp",
                                'api': api,
                                'description': "",
                                "extra": extra
                            }
                        },
                        'position': { 'x': Math.random() * window.innerWidth + 100, 'y': Math.random() * window.innerHeight }
                    };
                    const edge1 = {
                        'source': parent,
                        'target': node_id,
                        'sourceHandle': src_handle,
                        'targetHandle': null,
                        'id': `reactflow__edge-${parent}-${node_id}`,
                        'animated': false,
                        'type': "customedge",
                        'data': {
                            "delay": { "days": 0, "hours": 0, "minutes": 0 },
                            "label": cond,
                            "custom_condition": "",
                            "extra": {
                                'srcHandle': src_handle,
                                'trgHandle': null,
                            },
                        }
                    }
                    setEdges((eds) => eds.concat(edge1));
                    setNodes((nds) => nds.concat(node1));

                    try {
                        axios.post(host + "/save_flow", {
                            flow_data: {
                                flow: {
                                    "nodes": nodes.concat(node1),
                                    "edges": edges.concat(edge1),
                                }, flow_id: id
                            }
                        }, headers).then(res => {
                            setActionPopup([false, "", "", "", ""])
                            flowQuery.refetch()
                        })
                    } catch (err) {
                        console.log(err)
                        throw new Error('Failed to fetch day from API');
                    }
                }


            } else if (type == "button") {
                if (e.target.template_id.value == "new") {

                    let full_obj = JSON.parse(e.target.listObj.value)
                    let t = 0
                    let neww_objss = {}

                    Object.keys(full_obj).map(i => {
                        if (full_obj[`${i}`] != "") {
                            neww_objss[`${t}`] = full_obj[`${i}`]
                            t++
                        }
                    })
                    try {
                        axios.post("http://whatsapp.bulk24sms.com/api/save-interactive-template", {
                            template_name: e.target.template_name.value,
                            int_type: "button",
                            header_type: e.target.header_type.value,
                            header_data: e.target.header_data.value,
                            body: e.target.body.value,
                            footer: e.target.footer.value,
                            btn_title: "",
                            action: neww_objss,
                            auth_key: wa_authKey
                        }, headers).then(response => {
                            console.log(response.data)
                            if (response.data.status) {
                                let tempVar = {}
                                try {
                                    axios.post("http://whatsapp.bulk24sms.com/api/get-int-template-by-id", {
                                        "auth_key": wa_authKey,
                                        "template_id": response.data.data.template_id,
                                        "interactive_type": "button",
                                    }, headers).then(resp => {
                                        if (resp.data.status == "success") {
                                            let api = {
                                                'campaign_name': e.target.template_name.value,
                                                'sender': e.target.phone_no_id.value,
                                                'whatsapp_var': JSON.stringify(tempVar),
                                                'template_id': response.data.data.template_id,
                                                "auth_key": wa_authKey,
                                                "whatsapp_type": "interactive",
                                                "interactive_btn": JSON.stringify(resp.data.data),
                                                "interactive_type": "button",
                                            }
                                            const node1 = {
                                                'id': node_id,
                                                'type': 'customnode',
                                                'data': {
                                                    'label': "Template",
                                                    'value': {
                                                        'parent': 0,
                                                        'type': "whatsapp",
                                                        'api': api,
                                                        'description': "",
                                                    }
                                                },
                                                'position': {
                                                    'x': Math.random() * window.innerWidth + 100, 'y': Math.random() * window.innerHeight
                                                }
                                            };
                                            const edge1 = {
                                                'source': parent,
                                                'target': node_id,
                                                'sourceHandle': src_handle,
                                                'targetHandle': null,
                                                'id': `reactflow__edge-${parent}-${node_id}`,
                                                'animated': false,
                                                'type': "customedge",
                                                'data': {
                                                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                                                    "label": cond,
                                                    "custom_condition": "",
                                                    "extra": {}
                                                }
                                            }
                                            setNodes((nds) => nds.concat(node1));
                                            setEdges((eds) => eds.concat(edge1));

                                            try {
                                                axios.post(host + "/save_flow", {
                                                    flow_data: {
                                                        flow: { "nodes": nodes.concat(node1), "edges": edges.concat(edge1) },
                                                        flow_id: id
                                                    }
                                                }, headers).then(res => {
                                                    console.log(res.data.message)
                                                    toast.success(res.data.message)
                                                    setActionPopup([false, "", "", "", ""])
                                                    flowQuery.refetch()
                                                })
                                            } catch (err) {
                                                console.log(err)
                                                throw new Error('Failed to fetch day from API');
                                            }
                                            new_save()
                                        }
                                    })
                                } catch (errors) {
                                    console.log(errors)
                                }
                            }
                        })
                    } catch (error) {
                        console.log(error)
                        throw new Error('Failed to fetch day from API');
                    }
                } else {
                    let api = {
                        'campaign_name': "testing",
                        'sender': e.target.phone_no_id.value,
                        'whatsapp_var': e.target.tempVar.value,
                        'template_id': e.target.template_id.value,
                        "auth_key": wa_authKey,
                        "whatsapp_type": "interactive",
                    }
                    if (e.target.temp_btn.value && e.target.temp_btn.value != "") {
                        api.interactive_btn = e.target.temp_btn.value
                        api.interactive_type = "button"
                    }

                    const node1 = {
                        'id': node_id,
                        'type': 'customnode',
                        'data': {
                            'label': "Template",
                            'value': { 'parent': parent, 'type': "whatsapp", 'api': api, 'description': "" }
                        },
                        'position': { 'x': Math.random() * window.innerWidth + 100, 'y': Math.random() * window.innerHeight }
                    };

                    const edge1 = {
                        'source': parent,
                        'target': node_id,
                        'sourceHandle': src_handle,
                        'targetHandle': null,
                        'id': `reactflow__edge-${parent}-${node_id}`,
                        'animated': false,
                        'type': "customedge",
                        'data': {
                            "delay": { "days": 0, "hours": 0, "minutes": 0 },
                            "label": cond,
                            "custom_condition": "",
                            "extra": {
                                'srcHandle': src_handle,
                                'trgHandle': null,
                            },
                        }
                    }
                    setEdges((eds) => eds.concat(edge1));
                    setNodes((nds) => nds.concat(node1));

                    try {
                        axios.post(host + "/save_flow", {
                            flow_data: {
                                flow: {
                                    "nodes": nodes.concat(node1),
                                    "edges": edges.concat(edge1),
                                }, flow_id: id
                            }
                        }, headers).then(res => {
                            setActionPopup([false, "", "", "", ""])
                            flowQuery.refetch()
                        })
                    } catch (err) {
                        console.log(err)
                        throw new Error('Failed to fetch day from API');
                    }

                }

            } else if (type == "spreedsheet_temp") {
                let api = {
                    'campaign_name': "testing",
                    'sender': e.target.phone_no_id.value,
                    'whatsapp_var': e.target.tempVar.value,
                    'template_id': e.target.template_id.value,
                    "auth_key": wa_authKey,
                    "whatsapp_type": "template",
                    "extra_api": {
                        // "extra_api_data": e.target.num_col.value,
                        "sub_type": "spreadsheet",
                        "spreadsheet_id": e.target.sheet_id.value,
                        "sheet_id": e.target.sheet_name.value
                    }

                }
                if (e.target.temp_btn.value && e.target.temp_btn.value != "") {
                    api.interactive_btn = e.target.temp_btn.value
                    api.interactive_type = "button"
                }

                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': "Spreedsheet Template",
                        'value': {
                            'parent': parent,
                            'type': "whatsapp",
                            'api': api,
                            'description': "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };

                const edge1 = {
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': src_handle,
                    'targetHandle': null,
                    'id': `reactflow__edge-${parent}-${node_id}`,
                    'animated': false,
                    'type': "customedge",
                    'data': {
                        "delay": { "days": 0, "hours": 0, "minutes": 0 },
                        "label": cond,
                        "custom_condition": "",
                        "extra": {
                            'srcHandle': src_handle,
                            'trgHandle': null,
                        },
                    }
                }
                setNodes((nds) => nds.concat(node1));
                setEdges((eds) => eds.concat(edge1));
                try {
                    axios.post(host + "/save_flow", {
                        flow_data: {
                            flow: {
                                "nodes": nodes.concat(node1),
                                "edges": edges.concat(edge1),
                            }, flow_id: id
                        }
                    }, headers).then(res => {
                        setActionPopup([false, "", "", "", ""])
                        flowQuery.refetch()
                    })
                } catch (err) {
                    console.log(err)
                    throw new Error('Failed to fetch day from API');
                }
            } else if (type == "on_webhook") {
                let api = { "url": e.target.webhook_url.value, "type": e.target.webhook_type.value, "json_obj": {} }

                if (e.target.webhook_type.value == "post") {
                    api.json_obj = e.target.listObj.value
                }



                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': "Template",
                        'value': {
                            'parent': parent,
                            'type': "on_webhook",
                            'api': api,
                            'description': "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };

                const edge1 = {
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': src_handle,
                    'targetHandle': null,
                    'id': `reactflow__edge-${parent}-${node_id}`,
                    'animated': false,
                    'type': "customedge",
                    'data': {
                        "delay": { "days": 0, "hours": 0, "minutes": 0 },
                        "label": cond,
                        "custom_condition": "",
                        "extra": {
                            'srcHandle': src_handle,
                            'trgHandle': null,
                        },
                    }
                }

                setNodes((nds) => nds.concat(node1));
                setEdges((eds) => eds.concat(edge1));

                try {
                    axios.post(host + "/save_flow", {
                        flow_data: {
                            flow: {
                                "nodes": nodes.concat(node1),
                                "edges": edges.concat(edge1),
                            }, flow_id: id
                        }
                    }, headers).then(res => {
                        setActionPopup([false, "", "", "", ""])
                        flowQuery.refetch()
                    })
                } catch (err) {
                    console.log(err)
                    throw new Error('Failed to fetch day from API');
                }
            }


            else if (type == "spreedsheet_inte_btn") {

                let api = {
                    'campaign_name': "testing",
                    'sender': e.target.phone_no_id.value,
                    'whatsapp_var': e.target.tempVar.value,
                    'template_id': e.target.template_id.value,
                    "auth_key": wa_authKey,
                    "whatsapp_type": "interactive",
                    "extra_api": {
                        "extra_api_data": JSON.parse(e.target.num_col.value),
                        "sub_type": "spreadsheet",
                        "spreadsheet_id": e.target.sheet_id.value,
                        "sheet_id": e.target.sheet_name.value
                    }

                }
                if (e.target.temp_btn.value && e.target.temp_btn.value != "") {
                    api.interactive_btn = e.target.temp_btn.value
                    api.interactive_type = "button"
                }

                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': "Interactive Btn",
                        'value': { 'parent': parent, 'type': "whatsapp", 'api': api, 'description': "" }
                    },
                    'position': { 'x': Math.random() * window.innerWidth + 100, 'y': Math.random() * window.innerHeight }
                };

                const edge1 = {
                    'source': parent,
                    'target': node_id,
                    'sourceHandle': src_handle,
                    'targetHandle': null,
                    'id': `reactflow__edge-${parent}-${node_id}`,
                    'animated': false,
                    'type': "customedge",
                    'data': {
                        "delay": { "days": 0, "hours": 0, "minutes": 0 },
                        "label": cond,
                        "custom_condition": "",
                        "extra": {}
                    }
                }

                try {
                    axios.post(host + "/save_flow", {
                        flow_data: {
                            flow: {
                                "nodes": nodes.concat(node1),
                                "edges": edges.concat(edge1),
                            }, flow_id: id
                        }
                    }, headers).then(res => {
                        setActionPopup([false, "", "", "", ""])
                        flowQuery.refetch()
                    })
                } catch (err) {
                    console.log(err)
                    throw new Error('Failed to fetch day from API');
                }

            } else if (type == "spreedsheet_inte_list") {

            }



        }


        return (
            <Fragment>
                <div className="flex fixed inset-0 z-50">
                    <div className="ashish_w">
                        <div className="border-0  rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-xl font-semibold"> {act_val}  </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setActionPopup([false, "", "", "", ""])}
                                >
                                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <div className="relative p-3 flex-auto ">
                                <form onSubmit={(e) => action_new_save(e)}>
                                    <input type='hidden' value={act_val} name="type" />

                                    <div id="my_new_fun" ></div>

                                    <div className="pop_box_scroo"> {main_fun()} </div>

                                    <div className="flex items-center justify-end border-t border-solid border-slate-200 rounded-b">
                                        <button type="button"
                                            className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                            onClick={() => setActionPopup([false, "", "", "", ""])}
                                        >
                                            <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                            <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                        </button>
                                        <button type="submit"
                                            className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                        >
                                            <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Save</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </Fragment>
        )
    }



    const new_Channel = (typo) => {
        if (typo == "on_atrribute") {
            const node_id = `on_atrribute-channel-${+Date.now()}`
            const api = {}
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'data': {
                    'label': "On Atrribute Change",
                    'value': { 'parent': 0, 'type': "on_atrribute", 'api': api, 'description': "" }
                },
                'position': {
                    'x': Math.random() * window.innerWidth + 100,
                    'y': Math.random() * window.innerHeight
                }
            };

            setNodes((nds) => nds.concat(node1));

            try {
                axios.post(host + "/save_flow", {
                    flow_data: {
                        flow: {
                            "nodes": nodes.concat(node1),
                            "edges": edges,
                        }, flow_id: id
                    }
                }, headers).then(res => {
                    flowQuery.refetch()
                })
            } catch (err) {
                console.log(err)
                throw new Error('Failed to fetch day from API');
            }

            return ""
        } else if (typo == "on_message") {
            const node_id = `on_message-channel-${+Date.now()}`
            const api = {}
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'data': {
                    'label': "On Atrribute Change",
                    'value': { 'parent': 0, 'type': "on_message", 'api': api, 'description': "" }
                },
                'position': {
                    'x': Math.random() * window.innerWidth + 100,
                    'y': Math.random() * window.innerHeight
                }
            };

            setNodes((nds) => nds.concat(node1));

            try {
                axios.post(host + "/save_flow", {
                    flow_data: {
                        flow: {
                            "nodes": nodes.concat(node1),
                            "edges": edges,
                        }, flow_id: id
                    }
                }, headers).then(res => {
                    flowQuery.refetch()
                })
            } catch (err) {
                console.log(err)
                throw new Error('Failed to fetch day from API');
            }

            return ""
        }
    }
    const new_save = () => {
        try {
            axios.post(host + "/save_flow", {
                flow_data: {
                    flow: {
                        "nodes": nodes,
                        "edges": edges,
                    }, flow_id: id
                }
            }, headers).then(response => {
                toast.success(response.data.message)
            })
        } catch (error) {
            console.log(error)
            throw new Error('Failed to fetch day from API');
        }
    }

    const onSave = useCallback(() => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            try {
                axios.post(host + "/save_flow", {
                    flow_data: { flow: flow, flow_id: id }
                }, headers).then(response => {
                    toast.success(response.data.message)
                })
            } catch (error) {
                console.log(error)
                throw new Error('Failed to fetch day from API');
            }
        }
    }, [rfInstance]);
    if (!token) noAuth()
    if (waTempQuery.isLoading || waInterQuery.isLoading || flowQuery.isLoading || tempQuery.isLoading) return <Spinner />;
    if (waTempQuery.isError || waInterQuery.isError || flowQuery.isError || tempQuery.isError) {
        if (tempQuery.error != null) {
            if (tempQuery.error.response.status == '401') {
                noAuth()
            }
        }

        if (flowQuery.error != null) {
            if (flowQuery.error.response.status == '401') {
                noAuth()
            }
        }
    }




    // <NavDropdown as="li" title="Triggers" id="basic-nav-dropdown" className="nav-item btn_as_my_wew">
    //     <NavDropdown.Item className="collapse-item" onClick={() => new_Channel("on_message")}>On Message</NavDropdown.Item>
    //     <NavDropdown.Item className="collapse-item" onClick={() => setActionPopup([true, "on_webhook", "", "", ""])}>On Webhook</NavDropdown.Item>
    //     <NavDropdown.Item className="collapse-item" onClick={() => new_Channel("on_atrribute")}>On Atribute Change</NavDropdown.Item>
    //     <NavDropdown.Item className="collapse-item" onClick={() => setActionPopup([true, "on_message", "", "", ""])}>On new row add in SpreedSheet - (Comming Soon)</NavDropdown.Item>
    //     <NavDropdown.Item className="collapse-item" onClick={() => setActionPopup([true, "on_message", "", "", ""])}>On Close Converstion - (Comming Soon)</NavDropdown.Item>
    //     <NavDropdown.Item className="collapse-item" onClick={() => setActionPopup([true, "on_message", "", "", ""])}>On Deal Change - (Comming Soon)</NavDropdown.Item>
    // </NavDropdown>
    const final = () => {
        return (
            <div className="dndflow w-full" style={{ height: '100vh' }} ref={reactFlowWrapper}>
                <div className="reactflow-wrapper h-full"  >
                    <div className="flex flex-row-reverse zindex_as">
                        <button
                            className="btn_as_my_wew"
                            onClick={onSave}
                        ><i className="fa fa-floppy-o mr-1" aria-hidden="true"></i> Save</button>

                      
                    </div>


                    {actionPopup[0] === false ? ("") : (popup_action_fun(actionPopup))}


                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onInit={setRfInstance}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}

                    >
                        <Background variant="cross" gap={20} size={4} color='#c4c4c4' style={{ 'backgroundColor': '#f0f2f5' }} />
                        <Controls position="bottom-left" ></Controls>
                    </ReactFlow>
                </div>
            </div>
        )
    }


    const HeaderNav2 = () => {
        const navigate = useNavigate();
        return (

            <Fragment>

                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">



                    <li className="nav-item">
                        <Link to="/testflow/69" className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>Back to home</span>
                        </Link>
                    </li>



                    <li className="nav-item">
                        <Link className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>All Trigger</span>
                        </Link>
                    </li>



                  


                    <li className="nav-item">
                        <button type='button' className="nav-link" onClick={() => new_Channel("on_message")}>
                            <i className="fa-solid fa-users-line"></i>
                            <span>On Message</span>
                        </button>
                    </li>


                    <li className="nav-item">

                        
                        <button type='button' className="nav-link" onClick={() => setActionPopup([true, "on_webhook", "", "", ""])}>
                            <i className="fa-solid fa-users-line"></i>
                            <span>APIs - Webhhok</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button type='button' className="nav-link" onClick={() => new_Channel("on_atrribute")}>
                            <i className="fa-solid fa-users-line"></i>
                            <span>On Attribute Change</span>
                        </button>
                    </li>


                    <li className="nav-item">
                        <Link to="/testflow/69" className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>Ads - Coming Soon</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/testflow/69" className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>Spreed Sheet - Coming Soon</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/testflow/69" className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>On Voice Call - Coming Soon</span>
                        </Link>
                    </li>

                   

                    <li className="nav-item">
                        <Link to="/testflow/69" className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>On Close Converstion - Coming Soon</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/testflow/69" className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>Deal Converstion - Coming Soon</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/testflow/69" className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>On New Row add in Spreedsheet - Coming Soon</span>
                        </Link>
                    </li>






                    <li className="nav-item">
                        <Link to="/testflow/69" className="nav-link">
                            <i className="fa-solid fa-users-line"></i>
                            <span>New Bot</span>
                        </Link>
                    </li>




                </ul>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <HeaderNav2/>
            <div id="content-wrapper" className="d-flex flex-column">

                <div id="content">

                    <ReactFlowProvider>
                        {final()}
                        <ToastContainer />
                    </ReactFlowProvider>
                </div>
            </div>

        </Fragment>
    );
}