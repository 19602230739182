import React, { useState, useRef } from 'react';
import { useForm, useFieldArray } from "react-hook-form"
import axios from 'axios';

const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}




export default function EditButtonTemp({ nodeId, datas, showError, setNodes, setLoad, load }) {

    const myInputRef = useRef(null);
    const {
        register: interAdd,
        handleSubmit: interAddSubmit,
        clearErrors,
        control,
        formState: { errors: interAddErr }
    } = useForm({
        defaultValues: {
            "button": JSON.parse(datas.api.interactive_btn),
            "template_name": datas.extra.title,
            "header": datas.extra.header_data,
            "body": datas.extra.body,
            "footer": datas.extra.footer
        }
    })




    const { fields } = useFieldArray({ control, name: "button" });

    const onSubmit = (data) => {

        const newForm = {
            "int_type": "button",
            "header_type": "text",
            "header_data": data.header,
            "body": data.body,
            "footer": data.footer,
            "template_name": data.template_name,
            "auth_key": localStorage.getItem('wa_auth_key'),
            "action": data.button,
            "btn_title": "",
            "template_id": datas.api.template_id,
        }
        console.log(newForm)

        try {


            axios.post("https://work.secureip.in/api/edit-interactive-template", newForm, headers).then(response => {
                console.log(response.data)
                if (response.data.status == "success") {



                    try {
                        axios.post("https://tickmaker.io/api/get-int-template-by-id", {
                            "auth_key": localStorage.getItem('wa_auth_key'),
                            "template_id": response.data.data.template_id,
                            "interactive_type": "button",
                        }, headers).then(res => {
                            if (response.data.status == "success") {
                                setNodes(nds => nds.map(node => {
                                    if (node.id == nodeId) {
                                        const nodeVal = node.data.value
                                        node.data.label = data.template_name
                                        nodeVal.type = "whatsapp"
                                        nodeVal.api.ndsType = "edit"
                                        let extras = {
                                            "template_id": response.data.data.template_id,
                                            "title": data.template_name,
                                            "int_type": "button",
                                            "header_type": "text",
                                            "header_data": data.header,
                                            "file_name": null,
                                            "body": data.body,
                                            "footer": data.footer,
                                            "is_variable": "0"
                                        }

                                        nodeVal.api.interactive_btn = JSON.stringify(res.data.data)
                                        nodeVal.extra = extras
                                    }
                                    return node;
                                }))
                                setLoad(!load)
                            } else {
                                console.log(response)
                            }
                            // toast.success(response.data.message)
                        })
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    console.log(response)
                }
                // toast.success(response.data.message)
            })
        } catch (error) {
            console.log(error)
            // throw new Error('Failed to fetch day from API');
        }
    };




    const errorFun = (row_index) => {
        if (interAddErr && interAddErr.button) {
            const btnErr = interAddErr.button;
            if (btnErr[`${row_index}`].title) {
                return <span>Button text is required</span>
            }
        }
    }

    const newErr = (type) => {
        if (type == "body") {
            if (interAddErr.body.type == "required") {
                showError("The Body field is required")
            } else if (interAddErr.body.type == "maxLength") {
                return ("Max Length of Body is 1000")
            }
        } else if (type == "template_name") {
            if (interAddErr.template_name.type == "required") {
                showError("The Template Name is required")
            } else if (interAddErr.template_name.type == "maxLength") {
                return ("Max Length of Template Name is 512")
            }
        }
        else if (type == "footer") {
            if (interAddErr.footer.type == "maxLength") {
                return ("Max Length of Footer is 50")
            }
        }
    }

    const cancelForm = () => {
        setNodes(nds => nds.map(node => {
            if (node.id == nodeId) {
                node.data.value.type = "whatsapp"
            }
            return node;
        }))

        setLoad(!load)
    }


    return (
        <div ref={myInputRef}>
            <form onSubmit={interAddSubmit(onSubmit)}>
                <input {...interAdd("nodeId", { required: true })} value={nodeId} type='hidden' />
                <input {...interAdd("waInterType", { required: true })} value="button" type='hidden' />
                <input {...interAdd("btn_title", { required: true })} value="btn_title" type='hidden' />

                <div className="header_teml">
                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                    <div className="header_enter_text_box">
                        <input  {...interAdd("header")} className="form-control" placeholder="Enter Header" />
                    </div>
                </div>

                <div className="body_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                    <div className="body_enter_text_box">

                        <textarea maxLength={1000} {...interAdd("body", { required: true, maxLength: 1000 })} className="form-control" placeholder="Enter Body" />
                        {interAddErr.body && newErr("body")}
                    </div>
                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                    <div className="footer_enter_text_box">
                        <input maxLength={50} {...interAdd("footer", { maxLength: 50 })} className="form-control" placeholder="Enter Footer" />
                        {interAddErr.footer && newErr("footer")}
                    </div>
                </div>

                <div className="line_ashish" />

                {
                    fields.map((item, index) => {
                        return (
                            <div className='row' key={item.id}>
                                <div className='col-md-10'>
                                    <input type="hidden" value={index} {...interAdd(`button.${index}.button_no`)} />
                                    <input type="hidden" {...interAdd(`button.${index}.button_id`)} />
                                    <input maxLength={20} key={item.id} {...interAdd(`button.${index}.title`, { required: true, maxLength: 20 })} className="form-control mt-2" placeholder='Enter Button Text' />
                                    {errorFun(index)}
                                </div>
                            </div>
                        )
                    })
                }
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <div class="text-center">
                            <button className="btn btn-success btn-full" type='submit'>Save</button>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="text-center">
                            <button className="btn btn-danger btn-full" type='button' onClick={(e) => cancelForm()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )

}