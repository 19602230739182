import { useForm } from "react-hook-form"
import { useState } from 'react';

export default function Keywords({ setNodes, apisData, id }) {
    const [refresher, setRefresher] = useState(false)
    const { register: keyword, handleSubmit: keywordsSubmit, reset: keywordReset, formState: { errors: keywordErr } } = useForm()


    const addKeywords = (datas) => {
        setNodes(nds => nds.map(node => {
            if (node.id == datas.nodeId) {
                const key_words = node.data.value.api.keywords
                const key_word_count = Object.keys(key_words).length;
                let new_objs = {};

                let t = 0;
                Object.keys(key_words).map(f => {
                    new_objs[`${t}`] = key_words[f]
                    t++
                })
                new_objs[`${key_word_count}`] = datas.keyword
                node.data.value.api.keywords = new_objs
            }
            return node;
        }))
        setRefresher(!refresher)
        keywordReset()
    }
    function rmKeyWord(key_id, nodeId) {
        setNodes(nds => nds.map(node => {
            if (node.id == nodeId) {
                const key_words = node.data.value.api.keywords
                let new_objs = {};
                let t = 0;
                Object.keys(key_words).map(f => {
                    if (f == key_id) {
                        delete key_words[f]
                    }
                })
                Object.keys(key_words).map(f => {
                    new_objs[`${t}`] = key_words[f]
                    t++
                })
                node.data.value.api.keywords = new_objs
            }
            return node;
        }))
        setRefresher(!refresher)
    }
    const keywordFun = () => {
        if (apisData != null && apisData.keywords && Object.keys(apisData.keywords).length != 0) {
            return Object.keys(apisData.keywords).map(i => {
                return (
                    <div className="list_cot">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="list_name_cro" style={{ backgroundColor: '#612752' }}>
                                    <span>{apisData.keywords[i].charAt(0).toUpperCase()}</span>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <h6> {apisData.keywords[i]} </h6>
                            </div>
                            <div className="col-md-3">
                                <div className="d-flex">
                                    {
                                        //     <button type="button" className="btn-sm btn-success btn-circle mt-2">
                                        //     <i className="fa fa-user-pen" aria-hidden="true"></i>
                                        // </button>
                                    }
                                    <button type="button" onClick={(e) => rmKeyWord(i, id)} className="btn-sm btn-danger btn-circle">
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                )
            })
        }
    }
    return (
        <div className="card-body mt-0 list_cot_scrol_Ashish_new1">
            <div className="contact_add_div">
                <div className="mx-auto">
                    <form onSubmit={keywordsSubmit(addKeywords)}>
                        <div className="row">
                            <div className="col-md-8">
                                <input {...keyword("keyword", { required: true })} className="form-control" placeholder="Enter Keyword" />
                                <input {...keyword("nodeId", { required: true })} value={id} type='hidden' />
                                {keywordErr.keyword && <span>Keyword field is required</span>}
                            </div>
                            <div className="col-md-4 text-right">
                                <button type="submit" className="btn btn-sm" style={{ backgroundColor: '#1286a8', color: "#fff" }}>
                                    <i className="fa fa-user-plus" aria-hidden="true"></i> Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {keywordFun()}
        </div>
    )
}

