import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

const host = "http://oiomni.com:8000/api";
export default function Log() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [isLoad, setLoad] = useState(true);

        const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            localStorage.removeItem('token');
            window.location.href = '/';
        }else {
            getReport();
        }
         // eslint-disable-next-line
    },[isLoad])


    async function getReport() {
        const res = await fetch(host+"/log", {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ campaign_id: id }),
        });
        const res_data = await res.json();
        const length = res_data.data.links.length - 2
        const arr = []
        for (var i=1; i <= length; i++) {
            arr.push(host + "/log?page=" + i);
        }

        setTotal(arr);
        setLoad(false)
        setData(res_data.data.data);
    }

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchValue !== '') {
            const filteredData = data.filter((item) => {
                return [item.receiver,item.msg_id ].join('').toLowerCase().includes(searchValue.toLowerCase())
            })
            console.log(filteredData)
            setFilteredResults(filteredData)
        } else {
            setFilteredResults(data)
        }
    }

    async function test(e) {
        const res = await fetch(e.target.value, {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
             body: JSON.stringify({ campaign_id: id }),
        });
        const res_data = await res.json();
        const length = res_data.data.links.length - 2

        
        const arr = []
        for (var i=1; i <= length; i++) {
            arr.push(host + "/log?page=" + i);
        }

        setTotal(arr);
        setData(res_data.data.data);
    }

    const tableContain = (datas) => {
        return datas.map((item) => {
            console.log(item)
            return (
                <tr className="table_my_td border-gray-700">
                    <td className="py-3 px-6 font-semibold text-center">
                        

                        {item.msg_id}
                    </td>
                    <td className="py-3 px-6 font-semibold text-center">
                        {item.receiver}
                    </td>
                    <td className="py-4 px-6 text-center">
                        {item.dlr_time}
                    </td>
                    <td className="py-4 px-6 text-center">
                        {item.status}
                    </td>
                    <td className="py-4 px-6 text-center">
                        {item.description}
                    </td>
                </tr>
            )
        })
    }

    return (
        <>
            <div className="flex flex-row-reverse py-4 w-full my-4 ">
                <label htmlFor="simple-search" className="sr-only">Search</label>
                <div className="relative ">
                    <div className="flex absolute  inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                    </div>
                    <input onChange={(e) => searchItems(e.target.value)}
                        className="search_bg placeholder-gray-800 border border-gray-300 text-black text-sm rounded-lg  block w-full pl-10 p-2.5"
                        placeholder="Search..."
                    />
                </div>
            </div>

            <div className="flex mx-auto max-w-6xl pt-6">
                <table className="w-full table_my">
                    <thead className="table_my_th">
                        <tr>
                            <th scope="col" className="py-3 px-6">Message ID</th>
                            <th scope="col" className="py-3 px-6">Mobiles No</th>
                            <th scope="col" className="py-3 px-6">Done Date</th>
                            <th scope="col" className="py-3 px-6">Status</th>
                            <th scope="col" className="py-3 px-6">Description</th>
                        </tr>
                    </thead>
                    <tbody>

                    {
                        searchInput.length > 1 ? tableContain(filteredResults) : tableContain(data)
                    }
                   
                    </tbody>
                </table>
            </div>  
            <div className="mx-auto max-w-6xl pt-6 flex justify-center"> 
                <button 
                    onClick={(e) => test(e)}
                    value={total[0]}
                    className="bg-blue-200 text-blue-500 font-semibold py-2 px-4 rounded-md"
                ><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                {
                    total.map((el, i) =>
                        <button 
                            onClick={(e) => test(e)}
                            value={el}
                            className="bg-blue-200 text-blue-500 font-semibold mx-2 py-2 px-4 rounded-md"
                        >{i+1}</button>
                    )
                }
                <button 
                    onClick={(e) => test(e)}
                    value={total[total.length - 1]}
                    className="bg-blue-200 text-blue-500 font-semibold py-2 px-4 rounded-md"
                ><i className="fa fa-arrow-right" aria-hidden="true"></i></button> 
            </div>
        </>
    );
}
