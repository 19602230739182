import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Chart } from "react-google-charts";


const host = "http://oiomni.com:8000/api"; const token = localStorage.getItem('token');

export default function Statistics() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [isLoad, setLoad] = useState(true);


    useEffect(() => {
        if (!token) {
            localStorage.removeItem('token');
            window.location.href = '/';
        }else {
            getReport();
        }
    },[isLoad])


    async function getReport() {
        const res = await fetch(host+"/statistics", {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ campaign_id: id }),
        });
        const res_data = await res.json();
        
        setLoad(false)

        let a = [];

        a.push(["Button Title", "Count"])
        res_data.data.map((i) => {
            a.push([i.btn_title, i.counts])
        })
        console.log(a)
        setData(a);
    }


    const options = {
        title: "Button Click",
        is3D: true,
    };

   

    

    return (
        <>
            <div className="flex mx-auto max-w-6xl pt-6">
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                />
            </div>  
        </>
    );
}
