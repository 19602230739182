// eslint-disable-next-line 
/* eslint-disable */ 
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const host = "http://oiomni.com:8000/api";

const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');
export default function TempData() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [col, setCol] = useState([]);
    const [isLoad, setLoad] = useState(true);
    const [isView, setView] = useState([false, {}]);
    const [inputValues, setInputValues] = useState({});
    const [detail, setDetail] = useState({});





    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            localStorage.removeItem('token');
            window.location.href = '/';
        }else {
            getReport();
        }
    },[isLoad])


    async function getReport() {
        const res = await fetch(host + "/temp_data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
                run_id: id,
            }),
        });
        const res_data = await res.json();
       


        const resp = await fetch("http://oiomni.com:8001/api/get_custom_col", {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
                auth_key: auth_key,
            }),
        });
        const resp_data = await resp.json();



         console.log(Object.values(res_data))
        
        setLoad(false)
        setData(Object.values(res_data));
        setCol(resp_data.data)
        
       
    }

    async function final(e) {
        e.preventDefault();
        let data =  {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
                run_id: id,
                update_data: isView[1]
            })
        }
        const res = await fetch(host+'/data_update', data);
        const r = await res.json()


        toast.success(r.message)
        if(isLoad === true){
            setLoad(false);
        }else{
            setLoad(true);
        }
        setView([false,{}]);

        console.log(r)
       
    }

    const handleInputChange = (e, id, value) => {
        e.preventDefault();
        
        if(value != ""){
            const updatedValues = {...isView[1]};
            updatedValues[id] = value;
            setView([true,updatedValues]);
        }

        console.log(isView[1])
    }

    const editForm = (iteam) => {
        const col = Object.keys(iteam)


        return (
            <>
                <div className="justify-center fixed inset-0 z-50 ">
                    <div className="relative w-auto my-6 mx-auto max-w-5xl">
                        <div className="rounded-lg shadow-lg relative  w-full bg-white">
                            <div className="flex flex-row  py-2 px-4 border-b border-solid border-slate-200">
                                <div class="basis-5/6 pop_headding">
                                    <h4 class="modal-title"> <i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit Data</h4>
                                </div>

                                <div class="basis-1/6">
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => {setView([false, {}])}}
                                    >
                                        <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                    </button>
                                </div>
                            </div>
                            <div className="">
                                <form onSubmit={(e) => final(e)}>
                                    <div className="grid grid-cols-3 gap-4 p-2 my_form">
                                    {
                                        col.map(i => {
                                            if(i == "number" || i == "email"){
                                                return (
                                                    <div className="flex-1 p-1">
                                                        <label htmlFor="label"> {i} </label><br />
                                                        <input defaultValue={iteam[i]} name={i} className="w-full" required />
                                                    </div>   
                                                )
                                            }else{
                                                return (
                                                    <div className="flex-1 p-1">
                                                        <label htmlFor="label"> {i} </label><br />
                                                        <input defaultValue={iteam[i]} className="w-full" required onChange={(e) => handleInputChange(e, i, e.target.value)} />
                                                    </div>   
                                                )
                                            }
                                        })
                                    }
                                    </div>
                                       
                                    <div className="flex flex-row-reverse p-2 w-full border-t border-solid border-slate-200">
                                        <button className="btn_as_delete" onClick={() => {setView([false, {}])}}>
                                           <i class="fa fa-times-circle" aria-hidden="true"></i>Close
                                        </button>
                                        <button type="submit" className="btn_as_run"> <i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
        ); 
    }

    return (
        <>
            {isView[0] == false ? ("") :  editForm(isView[1])}
            <div className="flex mx-auto max-w-6xl pt-6">
                <table className="w-full table_my shadow-md shadow-[#1286a8]/20">
                    <thead className="table_my_th">
                        <tr>
                            {
                                col.map(i => <th scope="col" className="py-3 px-6 text-center">{i.column_name}</th>)
                            }
                            <th scope="col" className="py-3 px-6 text-center">Action</th>  
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item,index) => {
                            return (
                                <tr className="table_my_td border-gray-700">
                                    {
                                        col.map(i => <td className="py-3 px-6 font-semibold text-center ">{item[i.column_name]}</td>)
                                    }
                                    <button type="button" onClick={(e) => setView([true, item])} >Edit</button>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </>
    );
}