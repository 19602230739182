export default function Icons(props) {
    if (props.icon_type === "start") {
        return <i className="fa fa-plus" aria-hidden="true"></i>
    } else if (props.icon_type === "sms") {
        return <i className="fa fa-comments" aria-hidden="true"></i>
    } else if (props.icon_type == "voice") {
        return <i className="fa fa-phone" aria-hidden="true"></i>
    } else if (props.icon_type == "email") {
        return <i className="fa fa-envelope" aria-hidden="true"></i>
    } else if (props.icon_type == "whatsapp") {
        return <i className="fa fa-comments" aria-hidden="true"></i>
    } else if (props.icon_type == "funnel") {
        return <i className="fa fa-filter" aria-hidden="true"></i>
    } else if (props.icon_type == "group") {
        return <i className="fa fa-users" aria-hidden="true"></i>
    } else {
        return <i className="fa fa-comments" aria-hidden="true"></i>
    }
}
