


import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'

export default function DemoPage() {
    return (
        <div className="fixed top-16 w-56 text-right">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                        Options
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>

                                <Fragment>
                                    <Menu>
                                        <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                            Send Interactive
                                        </Menu.Button>
                                        <Menu.Items>
                                            <Menu.Item>
                                                <Fragment>
                                                    <button
                                                        className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                        Edit
                                                    </button>
                                                </Fragment>
                                            </Menu.Item>


                                            <Menu.Item>
                                                <button className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                >
                                                    Duplicate
                                                </button>
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Menu>
                                </Fragment>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

