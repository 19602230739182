import axios from 'axios';
import { useState, useRef, useEffect, Fragment } from 'react';
import { useForm } from "react-hook-form";

import SelectOptions from '../components/SelectOptions';

const host = "https://oiomni.com/api"
const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}

let searchTerm = /\{#var#\}/g;

export default function SmsBox({ setNodes, apisData, id, bsspSender, bsspTemp, eventData }) {
    const [refresher, setRefresher] = useState(false)
    const [sender, setSender] = useState(apisData.sender)
    const [temp, setTemp] = useState(apisData.template_id)
    const [tempContent, setTempContent] = useState(apisData.content)
    const textareaRef = useRef(null);

    const { register } = useForm();



    const senderSet = (val) => {
        setSender(val)
        bsspSender.data.map(x => {
            if (x.sender == val) {
                setNodes(nds => nds.map(node => {
                    if (node.id == id) {
                        node.data.value.api.sender = x.sender
                        node.data.value.api.pe_id = x.pe_id
                    }
                    return node;
                }))
            }
        })
        setRefresher(!refresher)
    }

    const tempSet = (val) => {
        setTemp(val)
        bsspTemp.data.map(x => {
            if (x.template_id == val) {
                setTempContent(x.content)
                setNodes(nds => nds.map(node => {
                    if (node.id == id) {
                        node.data.value.api.template_id = x.template_id
                        node.data.value.api.content = x.content
                    }
                    return node;
                }))
                adjustTextareaHeight();
            }
        })
        setRefresher(!refresher)
    }

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        // textarea.style.height = 'auto'; 
        // textarea.style.height = `${textarea.scrollHeight}px`;
    };





    const VariableBox = () => {
        // console.log(eventData)
        let matches = tempContent.match(searchTerm)

        const varSet = (index, val) => {
            console.log(index, val)

        }
        return (
            <Fragment>
                {matches && matches.length > 0 ? (
                    <Fragment>
                        <label className="header_heading m-0">
                            <i className="fa-solid fa-file-lines"></i> Variables
                        </label>

                        {
                            matches.map((varValue, key) => (
                                <select
                                    // value={vars && vars.header[`index${key}`] ? vars.header[`index${key}`]['col'].split("-")[1] : ""}
                                    className="form-select form-select-sm" key={key}
                                    onChange={(e) => varSet(`index${key}`, e.target.value)}
                                >
                                    <option value="" disabled>Select Variable {key + 1}</option>
                                    <SelectOptions colObj={eventData.value.columns} />
                                </select>
                            ))
                        }


                    </Fragment>
                ) : (<p></p>)}
            </Fragment>
        )


    }

    const routeSet = (val) => {
        setNodes(nds => nds.map(node => {
            if (node.id == id) {
                node.data.value.api.route = val
            }
            return node;
        }))

        setRefresher(!refresher)
    }

    const codingSet = (val) => {
        setNodes(nds => nds.map(node => {
            if (node.id == id) {
                node.data.value.api.coding = val
            }
            return node;
        }))
        setRefresher(!refresher)
    }

    useEffect(() => {
        adjustTextareaHeight();
    }, [tempContent]);

    return (
        <div className="card-body mt-0">
            <div className="contact_add_div">
                <div className="mx-auto">
                    <select className="form-select" defaultValue={sender} {...register("sender", { onChange: (e) => senderSet(e.target.value) })} >
                        <option value="" disabled selected>Select Sender</option>
                        {bsspSender.data.map(x => <option value={x.sender}>{x.sender}</option>)}
                    </select>

                    <select className="form-select" defaultValue={temp}  {...register("template_id", { onChange: (e) => tempSet(e.target.value) })}>
                        <option value="" disabled selected>Select Sender</option>
                        {bsspTemp.data.map(x => <option value={x.template_id} className='truncate'>{x.content}</option>)}
                    </select>



                    <label><input {...register("route", { onChange: (e) => routeSet(e.target.value) })} type="radio" value="TR" />Trans</label>
                    <label><input {...register("route", { onChange: (e) => routeSet(e.target.value) })} type="radio" value="PR" />Promo</label>

                    <label><input {...register("coding", { onChange: (e) => codingSet(e.target.value) })} type="radio" value="English" />English</label>
                    <label><input {...register("coding", { onChange: (e) => codingSet(e.target.value) })} type="radio" value="Unicode" />Unicode</label>

                    <p>Tempalte Id - {temp}</p>
                    <textarea className="form-control" ref={textareaRef} {...register("content", { value: tempContent })} style={{ overflow: 'hidden', resize: 'none' }} value={tempContent} />
                    <VariableBox />
                </div>
            </div>
        </div>
    );
}