
/* eslint-disable */
import React, { useState, Fragment, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

const host = "https://oiomni.com/api"
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');
const wa_authKey = localStorage.getItem('wa_auth_key');
const bssp_authKey = "46a57c629565ac350bae4264c3e82d9e"

const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    localStorage.removeItem('wa_auth_key');
    window.location.href = '/';
};



const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}

const queryClient = new QueryClient();
export default function NewReport() {
    const { id } = useParams();
    const [page, setPage] = useState(host + '/new_report?page=0')
    const myInputRef = useRef(null);
    const fetchProjects = async (page = host + '/new_report?page=0') => {
        try {
            const response = await axios.post(page, { "flow_id": id }, headers);
            console.log(response.data.data)
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }


    const { isPending, isError, error, data, isFetching, isPlaceholderData } = useQuery({
        queryKey: ['reportData', page],
        queryFn: () => fetchProjects(page),
        placeholderData: keepPreviousData,
    })
    if (!token) noAuth()



    if (isPending) {
        return (
            <p>Loading....</p>
            // <Spinner />
        )
    }
    if (isError) {
        // if (error != null) {
        //     if (error.response.status == '401') noAuth()
        // }
    }


    const PaginationBtn = () => {

        console.log(data)
        // data.links.shift();
        // data.links.pop();
        return (
            <div className="mx-auto max-w-5xl pt-6 flex justify-center">

                {
                    data.links.map(i => {
                        return (
                            <button type='button' onClick={() => setPage(i.url)}
                                className="bg-blue-200 text-blue-500 font-semibold mx-2 py-2 px-4 rounded-md"
                            >{i.label.replace(/&raquo;/g, '»').replace(/&laquo;/g, '«')}</button>
                        )
                    })
                }

                {isFetching ? <span> Loading...</span> : null}
            </div>
        )

    }

    const ExtraBox = ({ detailData }) => {

        let channelCount = { 'template': 0, 'list': 0, 'button': 0, 'wa_message': 0 }




        const fetchPreview = async () => {
            try {
                const response = await axios.post(host + '/report_preview', { "flow_id": id, "run_id": detailData.run_id }, headers);
                console.log(response.data.data)
                return response.data.data;
            } catch (error) {
                throw error;
            }
        }


        const reportPre = useQuery({
            queryKey: ['reportPre', detailData.run_id],
            queryFn: () => fetchPreview(),
            placeholderData: keepPreviousData,
        })

        if (reportPre.isPending) {
            return (
                <p>Loading....</p>
                // <Spinner />
            )
        }
        if (reportPre.isError) {
            // if (error != null) {
            //     if (error.response.status == '401') noAuth()
            // }
        }




        const LeftBlock = ({ leftDatas }) => {
            const item = JSON.parse(leftDatas.extra_api)


            if (leftDatas.whatsapp_type == "interactive") {


                const ButtonFunc = ({ i }) => {
                    const main_arr = JSON.parse(leftDatas.interactive_btn)
                    if (!main_arr || main_arr == null) {
                        console.log(leftDatas)
                        return;
                    }


                    return main_arr.map((x, sec_ind) => {
                        if (leftDatas.interactive_type == "list") {
                            return (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='flex mb-2'>
                                            <div className="Button_div_box ">
                                                <p> {x.row_title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else if (leftDatas.interactive_type == "button") {
                            return (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='flex mb-2'>
                                            <div className="Button_div_box ">
                                                <p> {x.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }


                const header_fun = (i) => {
                    if (i.header_data != "" && i.header_data != null) {
                        if (i.header_type == "text") {
                            return (
                                <div className="header_teml">
                                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                    <div className="header_enter_text_box">
                                        <p>{i.header_data}</p>
                                    </div>
                                </div>
                            )
                        } else if (i.header_type == "image") {
                            return (
                                <div className="header_teml">
                                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                    <div className="header_enter_text_box">
                                        <div className="img-fluid">  <img src={i.header_data} alt="WA Header Image" /> </div>
                                    </div>
                                </div>
                            )
                        }
                    }
                }

                const footer_fun = (i) => {
                    if (i.footer != "" && i.footer != null) {
                        return (
                            <div className="footer_teml mt-2">
                                <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                                <div className="footer_enter_text_box">
                                    <p>{i.footer}</p>
                                </div>
                            </div>
                        )
                    }
                }

                return (
                    <div className="left_box_main">
                        <div className="main_flex_div">
                            <div className="card position-relative p-0 box_width h-lg-100">
                                <div className="card-body mt-0 ">
                                    {header_fun(item)}
                                    <div className="body_teml mt-1 mb-1">
                                        <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                                        <div className="body_enter_text_box">
                                            <p>{item.body}</p>
                                        </div>
                                    </div>
                                    {footer_fun(item)}
                                    <div className="line_ashish"> </div>
                                    <div className="Button_teml">
                                        <ButtonFunc i={item} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (leftDatas.whatsapp_type == "template") {
                return (
                    <Fragment>
                        {leftDatas.parent}
                    </Fragment>
                )
            }

        }



        const RightBlock = ({ rightDatas }) => {
            const item = JSON.parse(rightDatas.extra_api)

            const newItem = JSON.parse(rightDatas.interactive_btn)

            if (rightDatas.replies != null) {
                return rightDatas.replies.split(",").map(i => {
                    if (rightDatas.interactive_type == "list") {
                        return newItem.map(j => {
                            if (j.row_id == i) {
                                return (
                                    <Fragment>
                                        <div className="right_meg_box_new">
                                            <div className="repley_box">
                                                <div className="repley_data">
                                                    <p>{item.body}</p>
                                                </div>
                                                <div className="repley_anser">
                                                    <p>{j.row_title}</p>
                                                    <p>{j.row_desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            }
                        })

                    } else if (rightDatas.interactive_type == "button") {
                        return newItem.map(j => {
                            if (j.button_id == i) {
                                return (
                                    <Fragment>
                                        <div className="right_meg_box_new">
                                            <div className="repley_box">
                                                <div className="repley_data">
                                                    <p>{item.body}</p>
                                                </div>
                                                <div className="repley_anser">
                                                    <p>{j.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            }
                        })
                    }
                })
            }

            // return reportPre.data[1].map(i => {
            //     if (rightDatas.msg_id == i.msg_id) {
            //         console.log(i.msg_id)
            //         console.log(rightDatas)
            //         if (rightDatas.interactive_type == "button") {
            //             return (
            //                 <Fragment>
            //                     <div className="right_meg_box_new">
            //                         <div className="repley_box">
            //                             <div className="repley_data">
            //                                 <p>{item.body}</p>
            //                             </div>
            //                             <div className="repley_anser">
            //                                 <p>{i.btn_title}</p>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </Fragment>
            //             )
            //         } else if (rightDatas.interactive_type == "list") {
            //             return (
            //                 <Fragment>
            //                     <div className="right_meg_box_new">
            //                         <div className="repley_box">
            //                             <div className="repley_data">
            //                                 <p>{item.body}</p>
            //                             </div>
            //                             <div className="repley_anser">
            //                                 <p>{i.btn_title}</p>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </Fragment>
            //             )
            //         }
            //     }
            // })
        }

        return (
            <Fragment>



                {
                    reportPre.data[0].map(i => {
                        if (i.type == "whatsapp") {
                            if (i.whatsapp_type == "template") {

                                channelCount = { ...channelCount, "template": channelCount.template + 1 }
                            } else if (i.whatsapp_type == "interactive") {
                                if (i.interactive_type == "list") {

                                    channelCount = { ...channelCount, "list": channelCount.list + 1 }


                                } else if (i.interactive_type == "button") {

                                    channelCount = { ...channelCount, "button": channelCount.button + 1 }


                                }
                            }
                            return (
                                <Fragment>
                                    <LeftBlock leftDatas={i} />
                                    <RightBlock rightDatas={i} />
                                </Fragment>
                            )
                        } else if (i.type == "wa_message") {
                            channelCount = { ...channelCount, "wa_message": channelCount.wa_message + 1 }

                            return (
                                <div className="left_box_main">
                                    <div className="main_flex_div">
                                        <div className="card position-relative p-0 box_width h-lg-100">
                                            <div className="card-body mt-0 ">
                                                <div className="body_teml mt-1 mb-1">
                                                    <div className="body_enter_text_box">
                                                    <p>{JSON.parse(i.extra_api).data}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            
                            
                        }
                    })
                }

<p>List - {channelCount.list}</p>
<p>Button - {channelCount.button}</p>
<p>Template -  {channelCount.template}</p>
<p>WhatsApp Message  {channelCount.wa_message}</p>
              
            </Fragment>
        )
    }


    const details = (detailData) => {
        const main_box = myInputRef.current
        ReactDOM.createRoot(main_box.querySelector("#extra_box")).render(<Fragment>
            <QueryClientProvider client={queryClient}>
                <ExtraBox detailData={detailData} />
            </QueryClientProvider>
        </Fragment>);
    }




    const TableContain = ({ datas }) => {
        return (
            <Fragment>
                <div className="flex" ref={myInputRef}>
                    <div className="flex flex-col">
                        {
                            datas.map(item => {
                                return (
                                    <div>
                                        <div onClick={e => details(item)}>
                                            <p>{item.receiver} - {item.total}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div id="extra_box"></div>
                </div>
            </Fragment>
        )
    }





    return (
        <div>
            <div className="flex mx-auto max-w-5xl pt-6">
                <TableContain datas={data.data} />
            </div>
            <PaginationBtn />
        </div>
    )
}