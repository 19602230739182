
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const wa_authKey = localStorage.getItem('wa_auth_key');

const host = "https://oiomni.com/api"
// const host = "https://13.200.85.235/api"

const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}




export const useWaTemp = () => {
    const waTempQuery = useQuery({
        queryKey: ['wa_temp'],
        queryFn: async () => {
           
                try {
                    const response = await axios.post('https://work.secureip.in/api/get-templates', {
                        "auth_key": wa_authKey,
                        "template_type": "template"
                    }, headers);
                    return response.data;
                } catch (error) {
                    console.log(error)
                    throw error;
                }
            
            
        },
    })

    return waTempQuery
};

export const useEmp = () => {
    const waEmployee = useQuery({
        queryKey: ['wa_emp'],
        queryFn: async () => {

           
                try {
                    const response = await axios.post("https://tickmaker.io/api/get-role-employee", {
                        "auth_key": wa_authKey,
                    }, headers);
                    return response.data;
                } catch (error) {
                    throw error;
                }
            


            
        },
    })

    return waEmployee
};



export const useTemp = () => {
    const tempQuery = useQuery({
        queryKey: ['all_temp'],
        queryFn: async () => {

                try {
                    const response = await axios.post(host + '/get_all_template_api/', {}, headers);
                    return response.data;
                } catch (error) {
                    throw error;
                }
            


            
        },
    })

    return tempQuery
};



export const useWaInter = () => {
    const waInterQuery = useQuery({
        queryKey: ['wa_inter'],
        queryFn: async () => {

          
                try {
                    const response = await axios.post('https://tickmaker.io/api/get-templates', {
                        "auth_key": wa_authKey,
                        "template_type": "interactive"
                    }, headers);
                    return response.data;
                } catch (error) {
                    throw error;
                }
            


            
        },
    })

    return waInterQuery
};

export const useWebhook = () => {
    const webhookQuery = useQuery({
        queryKey: ['webhookDetails'],
        queryFn: async () => {


            try {
                const response = await axios.get(host + "/webhookGet", headers);
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },
    })

    return webhookQuery
};


export const useFunnel = () => {
    const funnelQuery = useQuery({
        queryKey: ['funnel'],
        queryFn: async () => {
            try {
                const response = await axios.post(host + '/funnel_data/', {}, headers);
                return response.data;
            } catch (error) {
                throw error;
            }
        },
    })

    return funnelQuery
}
