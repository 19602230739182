


const Header = ({ setShowConfirmation, label, id , type}) => {

    const icon = () => {
        if (type == "on_attribute") {
            return (<i class="fa-solid fa-user-pen"></i>)
        } else if (type == "agent_assign"){
            return (<i class="fa-solid fa-user-check"></i>)
        } else if (type == "on_webhook") {
            return (<i class="fa-solid fa-sliders"></i>)
        } else if (type == "on_new_funnel" || type == "on_funnel") {
            return (<i class="fa-solid fa-filter"></i>)
        } else if (type == "wa_message" || type == "on_message") {
            return (<i class="fa-solid fa-comments"></i>)
        } 
    }

    
    return (
        <div className="card-header temp_bg">

            <div className="row">
                <div className="col-md-9">
                    <h5 className="mt-2 font-weight-bold temp_text_color mss">
                        {icon()} {label}
                    </h5>
                </div>
                <div className="col-md-3 text-right">
                    <button type="button" className="btn-sm btn-danger" onClick={(e) => setShowConfirmation([true, id])}><i className="fa-solid fa-trash"></i></button>
                </div>
            </div>
        </div>
    )
}

export default Header;