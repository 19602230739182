import Icons from './icons'

export default function BoxHeader(props) {
    return (
        <div className='nodes_header'>
            <div className="p_20">


                <div className="w-as-20">
                    <div className="icon_box_n"><Icons icon_type={props.type} /></div>
                </div>
                <div className="w-as-80">
                    <div className="hedd_p">
                        <p className="truncate"> {props.label} </p>
                    </div>
                </div>
            </div>
        </div>
    )
}