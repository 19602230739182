import React, { useState, useRef, Fragment } from 'react';
import axios from 'axios'
import { useQuery } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Cookies, useCookies } from "react-cookie";
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

const token = localStorage.getItem('token');

const host = "https://oiomni.com/api"


const access_token = 'EAAUm5rR7TtYBO1ZAev6EYA7b0YZA9CZBOF3cSGk49wEACx5tXCxWRJ4p3vN2tpM0X6bUalmbIiPRCjfd04V4QMwrfSIqIb8S7p4ngdrFfgduSWcaZC3Ngs3JcI7b0sZCZAQ73MLZCzhbKuiAoloOpU1Nj28qvy0UM4igu0glCWZAeAEeNZAmu3pT6tC2OZAcnP2SIG'

const headers = {
    'headers': {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}

const StepOne = ({ account }) => {
    const { register, formState: { errors } } = useFormContext(); // Retrieve form context

    return (
        <div className="card">
            <div className="card-header">Step 1: Campaign Details</div>
            <div className="card-body">
                <div className="mb-3">
                    <label className="form-label">Campaign Name</label>

                    <select className="form-select" {...register('ad_account', { required: 'Ad Account is required' })}>
                    <option value="" selected disabled>Select Ad Account</option>
                    {
                            account.map(i => {
                                return (<option value={ i.account_id }>{i.account_id}</option>)
                            })
                    }
                    </select>
                    {errors.ad_account && <div className="form-text">{errors.ad_account.message}</div>}
                </div>
                
                <div className="mb-3">
                    <label className="form-label">Campaign Name</label>
                    <input className="form-control" placeholder="Campaign Name" {...register('campaigns_name', { required: 'Campaign Name is required' })} />
                    {errors.campaigns_name && <div className="form-text">{errors.campaigns_name.message}</div>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Ad Name</label>
                    <input className="form-control" placeholder="Ad Name" {...register('ad_name', { required: 'Ad Name is required' })} />
                    {errors.ad_name && <div className="form-text">{errors.ad_name.message}</div>}
                </div>
            </div>
        </div>
    );
};

const StepTwo = () => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <div className="card">
            <div className="card-header">Step 2: Ad Sets</div>
            <div className="card-body">
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input className="form-control" placeholder="Name" {...register('adset_name', { required: 'Ad Set Name is required' })} />
                    {errors.adset_name && <div className="form-text">{errors.adset_name.message}</div>}
                </div>
                

                <div className="mb-3">
                    <label className="form-label">Budget</label>
                    <input className="form-control" placeholder="Budget" {...register('adset_daily_budget', { required: 'Ad Set Budget is required' })} />
                    {errors.adset_daily_budget && <div className="form-text">{errors.adset_daily_budget.message}</div>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Billing Event</label>
                    <input className="form-control" placeholder="Billing Event" {...register('adset_billing_event', { required: 'Billing Event is required' })} />
                    {errors.adset_billing_event && <div className="form-text">{errors.adset_billing_event.message}</div>}
                </div>

                <div className="mb-3">
                    <label className="form-label">Optimization Goal</label>
                    <input className="form-control" placeholder="Optimization Goal" {...register('adset_optimization_goal', { required: 'Optimization Goal is required' })} />
                    {errors.adset_optimization_goal && <div className="form-text">{errors.adset_optimization_goal.message}</div>}
                </div>

                <div className="mb-3">
                    <label className="form-label">Amount</label>
                    <input className="form-control" placeholder="Amount" {...register('adset_bid_amount', { required: 'Bid Amount is required' })} />
                    {errors.adset_bid_amount && <div className="form-text">{errors.adset_bid_amount.message}</div>}
                </div>
            </div>
        </div>
    );
};

const StepThree = () => {
    const { register, formState: { errors } } = useFormContext();
    return (
        <div className="card">
            <div className="card-header">Step 3: Ad Creatives</div>
            <div className="card-body">
                
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input className="form-control" placeholder="Name" {...register('adcreatives_name', { required: 'Name is required' })} />
                    {errors.adcreatives_name && <div className="form-text">{errors.adcreatives_name.message}</div>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input className="form-control" placeholder="Title" {...register('adcreatives_title', { required: 'Title is required' })} />
                    {errors.adcreatives_title && <div className="form-text">{errors.adcreatives_title.message}</div>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Body</label>
                    <input className="form-control" placeholder="Body" {...register('adcreatives_body', { required: 'Body is required' })} />
                    {errors.adcreatives_body && <div className="form-text">{errors.adcreatives_body.message}</div>}
                </div>

                <div className="mb-3">
                    <label className="form-label">Object URL</label>
                    <input className="form-control" placeholder="Object URL" {...register('adcreatives_object_url', { required: 'Object URL is required' })} />
                    {errors.adcreatives_object_url && <div className="form-text">{errors.adcreatives_object_url.message}</div>}
                </div>

                <div className="mb-3">
                    <label className="form-label">Link URL</label>
                    <input className="form-control" placeholder="Link URL" {...register('adcreatives_link_url', { required: 'Link URL is required' })} />
                    {errors.adcreatives_link_url && <div className="form-text">{errors.adcreatives_link_url.message}</div>}
                </div>

                <div className="mb-3">
                    <label className="form-label">Image URL</label>
                    <input className="form-control" placeholder="Image URL" {...register('adcreatives_image_url', { required: 'Image URL is required' })} />
                    {errors.adcreatives_image_url && <div className="form-text">{errors.adcreatives_image_url.message}</div>}
                </div>
            </div>
        </div>
    );
};


const FaceBook = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const methods = useForm({
        defaultValues: {
            ad_account: "",
            campaigns_name: "",
            ad_name: "",
            adset_name: "",
            adset_daily_budget: "",
            adset_billing_event: "",
            adset_optimization_goal: "",
            adset_bid_amount: "",
            adcreatives_name: "",
            adcreatives_title: "",
            adcreatives_body: "",
            adcreatives_object_url: "",
            adcreatives_link_url: "",
            adcreatives_image_url: ""
        }
    });

    const facebookData = useQuery({
        queryKey: ['facebookData'],
        queryFn: async () => {
            try {
                const response = await axios.post(host + '/getAds', { "access_token": access_token }, headers);
                console.log(response.data)
                return response.data
            } catch (error) {
                throw error;
            }
        }
    })
    if (facebookData.isLoading) {
        return ("Loading...")
    }




    const nextStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const onSubmit = (data) => {
        if (currentStep < steps.length - 1) {
            nextStep();
        } else {
            console.log('Form Data:', data);
            alert('Form submitted successfully!');
        }
    };

    const steps = [<StepOne account={facebookData.data.adaccounts.data} />, <StepTwo />, <StepThree />];

    return (
        <div className='container'>
            <p>Name - {facebookData.data.name}</p>
            <h4>Ad Accounts</h4>
            {
                facebookData.data.adaccounts.data.map(i => (<p>accountID - {i.account_id}</p>))
            }

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {steps[currentStep]}
                    {currentStep > 0 && <button type="button" onClick={prevStep} className='btn btn-primary'>Back</button>}
                    <button type="submit" className='btn btn-primary'>{currentStep === steps.length - 1 ? 'Submit' : 'Next'}</button>
                </form>
            </FormProvider>


        </div>
    )
};
export default FaceBook;