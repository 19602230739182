// eslint-disable-next-line 
/* eslint-disable */ 
import React, {  useState,useCallback,useEffect,useRef,useMemo ,lazy, Fragment} from 'react';
import { useParams, useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Menu, Transition } from '@headlessui/react'


import useSWR from 'swr'


import ReactFlow, { 
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    addEdge,
    Background,
    Controls,
    Handle,
    useReactFlow,

    Panel,
    BackgroundVariant,
    EdgeProps,
} from 'reactflow';

import 'react-toastify/dist/ReactToastify.css';

const host = "http://oiomni.com:8000/api"
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');


let test = [];
let temp_data = []
let funnel = []
let group = []
let port = [{x:0 , y:0 , zoom: 0.5}]





export default function GetFlowTemplate(){
    const { id } = useParams();
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [rfInstance, setRfInstance] = useState(null);
    const [isfetch, setFetch] = useState(true);
    const [isupdate, setUpdate] = useState(false);
    const [viewupdate, setViewUpdate] = useState(["", {}]);
    const [rule, setRule] = useState([])
    const [edgeEdit, setEdgeEdit] = useState([false,{}])
    const [filter, setFilter] = useState([false,{}, ""])
    const [checked, setChecked] = useState(false);
    const [customCol, setCustomCol] = useState([]);
    const [funnelList, setFunnelList] = useState([]);
    const [GroupList, setGroupList] = useState([]);

    const [colCondition, setColCondition] = useState([
        {"value": "=", "cond" : "Is"},
        {"value": "!=", "cond" : "Is Not"},
        {"value": ">", "cond" : "Greater Than"},
        {"value": ">=", "cond" : "Greater Than or equal"},
        {"value": "<", "cond" : "Less Than"},
        {"value": "<=", "cond" : "Less Than or equal"}
    ]);
    const myInputRef = useRef(null);
    const navigate = useNavigate();

    const [campTemplate, setCampTemplate] = useState([]);
    const [updateValue, setUpdateValue] = useState({
        "id" : null,
        "label" : null,
        "sms_route": null,
        "voice_route": null,
        "sms_coding": null,
        "type" : null,
        "sms_campaign_name" : null,
        "sms_sender" : null,
        "sms_template_id" : null,
        "sms_content" : null,
        "pe_id" : null,
        "voice_campaign_name" : null,
        "voice_sender" :null,
        "voice_content" : null,
        "email_campaign_name" : null,
        "email_sender" : null,
        "email_content" : null,
        "email_subject" : null,
        "email_sender_name" : null,
        "description" : null,
        "parent" : null,
    });





    


    if (!token) {
        localStorage.removeItem('token');
        window.location.href = '/';
    }

    const customCheck = () => {
        setChecked(!checked);
    };

    useMemo( async() => {
        if (isfetch === true) {

            // getDatas(id).custom_col
            // .then(val => {
                // console.log(swrData)
            // })

            myFunction()
            setCampTemplate(temp_data)
            setFetch(false)            
        }
    }, [isfetch])

    function myFunction() {
        
        const headers = {
            Accept: "application/json",
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }



        fetch(host + '/get_all_template/', { 
            "method" : "POST",
            "headers" : headers 
        })
        .then((res) => {
            if(res.status === 401){
                localStorage.removeItem('token');
                window.location.href = '/';
            }else{
                return res.json()
            }
        })
        .then(data => {
            // console.log(data.data)
            temp_data = data.data
            setCampTemplate(temp_data)
        })

        fetch(host +  '/get_flow/' + id, { 
            "method" : "GET",
            "headers" : headers 
        })
        .then((res) => {
            if(res.status === 401){
                localStorage.removeItem('token');
                window.location.href = '/';
            }else{
                return res.json()
            }
        })
        .then(data => {
            setNodes(data.nodes)
            test = [data]
            setEdges(data.connection)
            console.log(data)
        })
       

       
        


        fetch( 'http://oiomni.com:8001/api/get_custom_col', { 
            "method" : "POST",
            "headers" : headers,
            body: JSON.stringify({
                auth_key: auth_key,
            }),
        })
        .then((res) => {
            if(res.status === 401){
                localStorage.removeItem('token');
                window.location.href = '/';
            }else{
                return res.json()
            }
        })
        .then(data => {
            // console.log(data.data)
            setCustomCol(data.data)
        })

        fetch( 'http://oiomni.com:8001/api/get_segment/', { 
            "method" : "POST",
            "headers" : headers,
            body: JSON.stringify({
                auth_key: auth_key,
            }),
        })
        .then((res) => {
            if(res.status === 401){
                localStorage.removeItem('token');
                window.location.href = '/';
            }else{
                return res.json()
            }
        })
        .then(data => {
            setFunnelList(data.data)
            // console.log(funnelList)
            funnel = data.data
        })

        fetch( 'http://oiomni.com:8001/api/get_group/', { 
            "method" : "POST",
            "headers" : headers,
            body: JSON.stringify({
                auth_key: auth_key,
            }),
        })
        .then((res) => {
            if(res.status === 401){
                localStorage.removeItem('token');
                window.location.href = '/';
            }else{
                return res.json()
            }
        })
        .then(data => {
            setGroupList(data.data)
            group = data.data
        })




        


        return true
    };

    const icon_fun = (icon_type) => {
        if(icon_type === "sms"){
            return <i className="fa fa-comments mr-2" aria-hidden="true"></i>
        }else if(icon_type == "voice"){
            return <i className="fa fa-phone mr-2" aria-hidden="true"></i>
        }else if(icon_type == "email"){
            return <i className="fa fa-envelope mr-2" aria-hidden="true"></i>
        }else if(icon_type == "whatsapp"){
            return <i className="fa fa-whatsapp mr-2" aria-hidden="true"></i>
        }else if(icon_type == "funnel"){
            return <i className="fa fa-filter mr-2" aria-hidden="true"></i>
        }else if(icon_type == "group"){
            return <i className="fa fa-users mr-2" aria-hidden="true"></i>
        }else{
            return <i className="fa fa-comments mr-2" aria-hidden="true"></i>
        }
    }

    const CustomNode = (event) => {
        const id = event.id;
        const type = event.data.value.type

      
        
        if(type === 'start'){
            return (
                <>
					<div className="box_star_as">
                        <div className="p_20">
                            <div className="w-as-20">
                                <div className="icon_box_n">
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="w-as-80">
                                <div className="hedd_p">
                                    <p> {event.data.label} </p>
                                </div>
                            </div>
                        </div>
                        <div className="cl"></div>
                        <div className="line_as"></div>
                        <div className="cl"></div>
                        <div className="p_20">
                            <div className="w-as-100">
                                <p> {event.data.value.description} </p>
                            </div>
                        </div>
                        <div className="cl"></div>
                        <div className="line_as"></div>
                        <div className="cl"></div>
                        {template_select_non(type, id, event)}
                    </div>
                    <Handle
                        type="source"
                        position="right"
                        style={{
                            'width':'10px',
                            'height':'10px',
                            'backgroundColor': '#1286a8',
                            "right": "-13px"
                        }} 
                    />
                   
                    
                </>
            );
        }
        else if(type === "funnel"){
            return (
                <>
                    <Handle type="target" position="left" 
                    style={{ width:'10px', height:'10px', border: '1px','backgroundColor': '#1286a8', "left": "-13px"}} />
                    <div className="box_star_as w-72">
                        <div className="p_20">
                            <div className="w-as-20">
                                <div className="icon_box_n">
                                    {icon_fun(type)}
                                </div>
                            </div>
                            <div className="w-as-80">
                                <div className="hedd_p">
                                    <p> {event.data.label}</p>
                                </div>
                            </div>
                        </div>

                        <div className="cl"></div>
                        <div className="line_as"></div>


                        <div className="cl"></div>
                        <div className="p_20">
                            <div className="w-as-100">
                                <p> {event.data.value.description} </p>
                            </div>
                        </div>
                        <div className="cl"></div>
                        <div className="line_as"></div>
                        <div className="cl"></div>
                        
                        
                        <div className="p_20"> 
                            <div className="new_btn_hover flex">
                                <button 
                                    className="delete_btn basis-1/2 mx-2"
                                >
                                    <i className="mr-2 fa fa-trash" aria-hidden="true"></i> Delete 
                                </button>
                                <button 
                                    className="Update_btn basis-1/2 mx-2"
                                    onClick={(event) => OnUpdateNode(event, id)}
                                >
                                    <i className="mr-2 fa fa-pencil-square-o" aria-hidden="true"></i> Update
                                </button>
                            </div>
                        </div>
                        <div className="line_as"></div>
                        <div className="cl"></div>
                        {template_select_non(type, id, event)}
                       
        
                    </div>           
                    <Handle type="source" position="right" style={{ width:'10px', height:'10px', border: '1px','backgroundColor': '#1286a8', "right": "-13px"}} />
                </>
            );
        }
        else if(type === "group"){
            return (
                <>
                    <Handle type="target" position="left" 
                    style={{ width:'10px', height:'10px', border: '1px','backgroundColor': '#1286a8', "left": "-13px"}} />
                    <div className="box_star_as w-72">
                        <div className="p_20">
                            <div className="w-as-20">
                                <div className="icon_box_n">
                                    {icon_fun(type)}
                                </div>
                            </div>
                            <div className="w-as-80">
                                <div className="hedd_p">
                                    <p> {event.data.label}</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="cl"></div>
                        <div className="line_as"></div>


                        <div className="cl"></div>
                        <div className="p_20">
                            <div className="w-as-100">
                                <p> {event.data.value.description} </p>
                            </div>
                        </div>
                        <div className="cl"></div>
                        <div className="line_as"></div>
                        <div className="cl"></div>
                        
                        <div className="p_20"> 
                            <div className="new_btn_hover flex">
                                <button 
                                    className="delete_btn basis-1/2 mx-2"
                                   
                                >
                                    <i className="mr-2 fa fa-trash" aria-hidden="true"></i> Delete 
                                </button>
                                <button 
                                    className="Update_btn basis-1/2 mx-2"
                                    onClick={(event) => OnUpdateNode(event, id)}
                                >
                                    <i className="mr-2 fa fa-pencil-square-o" aria-hidden="true"></i> Update
                                </button>
                            </div>
                        </div>
                        <div className="line_as"></div>
                        <div className="cl"></div>
                        {template_select_non(type, id, event)}
                    </div>           
                    <Handle type="source" position="right" style={{ width:'10px', height:'10px', border: '1px','backgroundColor': '#1286a8', "right": "-13px"}} />
                </>
            );
        }
        else{
            return (
                <>
                    <Handle type="target" position="left" 
                    style={{ width:'10px', height:'10px', border: '1px','backgroundColor': '#1286a8', "left": "-13px"}} />
                    <div className="box_star_as w-72">
                        <div className="p_20">
                            <div className="w-as-20">
                                <div className="icon_box_n">
                                    {icon_fun(type)}
                                </div>
                            </div>
                            <div className="w-as-80">
                                <div className="hedd_p">
                                    <p> {event.data.label}</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="cl"></div>
                        <div className="line_as"></div>


                        <div className="cl"></div>
                        <div className="p_20">
                            <div className="w-as-100">
                                <p> {event.data.value.description} </p>
                            </div>
                        </div>
                        <div className="cl"></div>
                        <div className="line_as"></div>
                        <div className="cl"></div>
                        
                        <div className="p_20"> 
                            <div className="new_btn_hover flex">
                                <button 
                                    className="delete_btn basis-1/2 mx-2"
                                    
                                >
                                    <i className="mr-2 fa fa-trash" aria-hidden="true"></i> Delete 
                                </button>
                                <button 
                                    className="Update_btn basis-1/2 mx-2"

                                    // onClick={(event) => OnUpdateNode(event, id)}
                                  
                                >
                                    <i className="mr-2 fa fa-pencil-square-o" aria-hidden="true"></i> Update
                                </button>
                            </div>
                        </div>
                        <div className="line_as"></div>
                        <div className="cl"></div>
                        {template_select(type, id, event)}
        
                    </div>           
                    <Handle type="source" position="right" style={{ width:'10px', height:'10px', border: '1px','backgroundColor': '#1286a8', "right": "-13px"}} />
                </>
            );
        }

    }


    const template_select_non = (parent_type, parent, datas) => {
        const temp = (type, parent_conn) => {
            if(type == "funnel"){
                return funnel.map(i => {
                    return (
                        <li className="text-left">
                            <button
                                type="button"
                                onClick={(e) => openForm(type, parent, parent_conn,i)}
                            >{i.segment_name}</button>
                        </li>
                    )
                })
            }

            else if(type == "group"){
                return group.map(i => {
                    return (
                        <li className="text-left">
                            <button
                                type="button"
                                onClick={(e) => openForm(type, parent, parent_conn,i)}
                            >{i.group_name}</button>
                        </li>
                    )
                })
            }
            return temp_data.map(i => {
                if(i.type == type){
                    return (
                        <>
                            <li className="text-left">
                                <button
                                    type="button"
                                    onClick={(e) => openForm(type, parent, parent_conn,i)}
                                >{i.template_name}</button>
                            </li>
                        </>
                    )
                }
            })
        }
        return (
            <div className="w-as-100">
                <div className="group text-center p_20 bold_data">
                    <button className="rounded-lg bg-white items-center">
                        Add Channels<i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                    </button>
                    {/*<ul className="as_sed  mt-5 transform scale-0 group-hover:scale-100 transition duration-150 ease-in-out origin-top">
                        <li>
                            <button className="w-full text-left flex items-center outline-none">
                                {icon_fun("sms")}SMS<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </button>
                            <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                {temp("sms", "All")}
                            </ul>
                        </li>
                        <li>
                            <button className="w-full text-left flex items-center outline-none">
                                {icon_fun("voice")}Voice<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </button>
                            <ul className="as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                {temp("voice", "All")}
                            </ul>
                        </li>
                        <li>
                            <button className="w-full text-left flex items-center outline-none">
                                {icon_fun("email")}Email<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </button>
                            <ul className="as_sed top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                {temp("email", "All")}
                            </ul>
                        </li>
                        <li>
                            <button className="w-full text-left flex items-center outline-none">
                                {icon_fun("sms")}WhatsApp<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </button>
                            <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                {temp("whatsapp", "All")}
                            </ul>
                        </li>

                        <li>
                            <button className="w-full text-left flex items-center outline-none">
                                {icon_fun("funnel")}Funnel<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </button>
                            <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                {temp("funnel", "All")}
                            </ul>
                        </li>

                        <li>
                            <button className="w-full text-left flex items-center outline-none">
                                {icon_fun("group")}Group<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </button>
                            <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                {temp("group", "All")}
                            </ul>
                        </li>
                    </ul>*/}
                </div>
            </div> 

        )  
    }


    const template_select = (parent_type, parent, datas) => {
       
    

        const temp = (type, parent_conn) => {
            if(type == "funnel"){
                return funnel.map(i => {
                    return (
                        <li className="text-left">
                            <button
                                type="button"
                                onClick={(e) => openForm(type, parent, parent_conn,i)}
                            >{i.segment_name}</button>
                        </li>
                    )
                })
            }
            else if(type == "group"){
                return group.map(i => {
                    return (
                        <li className="text-left">
                            <button
                                type="button"
                                onClick={(e) => openForm(type, parent, parent_conn,i)}
                            >{i.group_name}</button>
                        </li>
                    )
                })
            }

            return temp_data.map(i => {
                if(i.type == type){
                    return (
                        <>
                            <li className="text-left">
                                <button
                                    type="button"
                                    onClick={(e) => openForm(type, parent, parent_conn,i)}
                                >{i.template_name}</button>
                            </li>
                        </>
                    )
                }
            })
        }

        

        let temp_type = "";
        let temp_wa_type = "";
        let temp_datas = "";

        if(parent_type == "whatsapp"){
            if(datas.data.value.api.whatsapp_type == "interactive"){
                if(datas.data.value.api.interactive_type == "button"){
                    temp_type = datas.data.value.api.whatsapp_type
                    temp_wa_type = datas.data.value.api.interactive_type
                    temp_datas = datas.data.value.api.interactive_btn
                }
            }
        }

        
       const parent_conn = childCondition(parent_type, parent, temp_type, temp_wa_type, temp_datas) 
       // console.log(parent_conn)


       
        
        return (
            <div className="w-as-100">
                <div className="group text-center p_20 bold_data">
                    <button className="rounded-lg bg-white items-center">
                        Add Channels<i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                    </button>
                    {/*<ul className="as_sed  mt-5 transform scale-0 group-hover:scale-100 transition duration-150 ease-in-out origin-top">
                        {
                            parent_conn.map(i => {
                                
                                return (
                                    <li>
                                        <button className="w-full text-left flex items-center outline-none" >
                                            {i}<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                        </button>
                                        <ul className="as_sed  top-0 right-0_8 transition duration-100 ease-in-out origin-top-left">
                                            <li>
                                                <button className="w-full text-left flex items-center outline-none">
                                                    {icon_fun("sms")}SMS<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                </button>
                                                <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                                    {temp("sms", i)}
                                                </ul>
                                            </li>
                                            <li>
                                                <button className="w-full text-left flex items-center outline-none">
                                                    {icon_fun("voice")}Voice<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                </button>
                                                <ul className="as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                                    {temp("voice", i)}
                                                </ul>
                                            </li>
                                            <li>
                                                <button className="w-full text-left flex items-center outline-none">
                                                    {icon_fun("email")}Email<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                </button>
                                                <ul className="as_sed top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                                    {temp("email", i)}
                                                </ul>
                                            </li>
                                            <li>
                                                <button className="w-full text-left flex items-center outline-none">
                                                    {icon_fun("sms")}WhatsApp<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                </button>
                                                <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                                    {temp("whatsapp", i)}
                                                </ul>
                                            </li>

                                            <li>
                                                <button className="w-full text-left flex items-center outline-none">
                                                    {icon_fun("funnel")}Funnel<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                </button>
                                                <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                                    {temp("funnel", i)}
                                                </ul>
                                            </li>

                                            <li>
                                                <button className="w-full text-left flex items-center outline-none">
                                                    {icon_fun("group")}Group<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                                </button>
                                                <ul className=" as_sed  top-0 right-0_8 transition duration-150 ease-in-out origin-top-left">
                                                    {temp("group", i)}
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>*/}
                </div>
            </div> 

        )   
    }


    

    const childCondition = (parent, parent_id, temp_type, temp_wa_type, temp_datas) => {
        if(parent === "start"){
            return ["All"]
        }else if(parent === "sms"){
            return ["All", "Sms Deliver", "Sms Sent", "Sms Fail", "Sms Reject"]
        }else if(parent=== "email"){
            return ["All", "Email Deliver", "Email Sent", "Email Fail"]
        }else if(parent === "voice"){
            return [
                "All",
                "Voice Deliver",
                "Voice Sent",
                "Voice Fail",
                "Voice Reject",
                "Response 1",
                "Response 2",
                "Response 3",
                "Response 4",
                "Response 5",
                "Response 6",
                "Response 7",
                "Response 8",
                "Response 9",
            ]
        }else if(parent === "whatsapp"){
            let t = [
                "All",
                "WhatsApp Deliver",
                "WhatsApp Sent",
                "WhatsApp Fail",
            ]
            if(temp_type == "interactive"){
                if(temp_wa_type == "button"){
                    JSON.parse(temp_datas).map(x => {
                        t.push(x.title + " Button")
                    })
                }
            }
            return t
        }else if(parent === "funnel" || parent === "group"){
            return ["All"];
        }
        else{
            return [];
        }
    }


    const CustomEdge = (event) => {
        const id = event.id;
        let sourceX = event.sourceX
        let sourceY = event.sourceY
        let sourcePosition = event.sourcePosition
        let targetX = event.targetX
        let targetY = event.targetY
        let targetPosition = event.targetPosition
        
        const [edgePath, labelX, labelY] = getBezierPath({sourceX,sourceY,sourcePosition,targetX,targetY,targetPosition});

        const cond = (con) => {
            if(con === "" || con === null){
                return <p></p>
            }else{
                 const conn_des = () => {
                    function addZero(num) {
                        return (num < 10 ? '0' : '') + num;
                    }

                    if(con.split(" ")[1] == "="){
                        return <p>When {event.data.custom_condition.split(" ")[0]} is {event.data.custom_condition.split(" ")[2]}</p>
                        
                    }else if(con.split(" ")[1] == "!="){
                        return <p>When {event.data.custom_condition.split(" ")[0]} is not equal to  {event.data.custom_condition.split(" ")[2]}</p>
                        
                    }else if(con.split(" ")[1] == ">"){
                        return <p>When {event.data.custom_condition.split(" ")[0]} is Greater Than to  {event.data.custom_condition.split(" ")[2]}</p>
                        
                    }else if(con.split(" ")[1] == ">="){
                        return <p>When {event.data.custom_condition.split(" ")[0]} is Greater Than and equal to  {event.data.custom_condition.split(" ")[2]}</p>
                        
                    }else if(con.split(" ")[1] == "<"){
                        return <p>When {event.data.custom_condition.split(" ")[0]} is Less Than to  {event.data.custom_condition.split(" ")[2]}</p>
                       
                    }else if(con.split(" ")[1] == "<="){
                        return <p>When {event.data.custom_condition.split(" ")[0]} is Less Than and equal to  {event.data.custom_condition.split(" ")[2]}</p>
                    }

                    else if(con.split(" ")[1] == "before"){
                        return <p>Send Before {event.data.custom_condition.split(" ")[2]} Days of {event.data.custom_condition.split(" ")[0]} </p>
                    }

                    else if(con.split(" ")[1] == "after"){
                        return <p>Send After {event.data.custom_condition.split(" ")[2]} Days of {event.data.custom_condition.split(" ")[0]} </p>
                    }


                    else if(con.split(" ")[1] == "range" || con.split(" ")[1] == "not_range"){


                        const f_date = event.data.custom_condition.split(" ")[2]
                        const f_new_date = new Date(f_date.split("-")[0] * 1000)
                        const final_f_date = f_new_date.getFullYear() + '-' + addZero(f_new_date.getMonth() + 1) + '-' + addZero(f_new_date.getDate())

                        const t_date = event.data.custom_condition.split(" ")[2]
                        const t_new_date = new Date(t_date.split("-")[1] * 1000)
                        const final_t_date = t_new_date.getFullYear() + '-' + addZero(t_new_date.getMonth() + 1) + '-' + addZero(t_new_date.getDate())

                        if(con.split(" ")[1] == "range"){
                            return <p>If  {event.data.custom_condition.split(" ")[0]} is between {final_f_date} to {final_t_date}  </p>
                        }else if(con.split(" ")[1] == "not_range"){
                            return <p>If  {event.data.custom_condition.split(" ")[0]} is not between {final_f_date} to {final_t_date}  </p>
                        }
                    }
                }
                return (
                    <div className="p_20"> 
                        <div className="w-as-100">
                        {conn_des()} 
                            
                        </div>
                    </div>
                   
                )

                // if(con.split(" ")[1] == "="){
                //     var conn = "is "
                // }else if(con.split(" ")[1] == "!="){
                //     var conn = "is not equal to "
                // }else if(con.split(" ")[1] == ">"){
                //     var conn = "is Greater Than to "
                // }else if(con.split(" ")[1] == ">="){
                //     var conn = "is Greater Than and equal to "
                // }else if(con.split(" ")[1] == "<"){
                //     var conn = "is Less Than to "
                // }else if(con.split(" ")[1] == "<="){
                //     var conn = "is Less Than and equal to "
                // }
                // return (
                //     <div className="p_20"> 
                //         <div className="w-as-100"> 
                //             <p>
                //                 When {event.data.custom_condition.split(" ")[0]} {conn} {event.data.custom_condition.split(" ")[2]}
                //             </p>
                //         </div>
                //     </div>
                   
                // )
            }
        }

        const time_pause = (time) => {

            if(time.days == "0" && time.hours == "0" && time.minutes == "0"){
                return <></>
            }else{
                return (

                    <div className="p_20_0px"> 
                        <div className="w-as-100">
                            <p>
                                <i className="fa fa-calendar-check-o" aria-hidden="true"></i> 
                                {time.days} Days {time.hours} hrs {time.minutes} mins
                            </p>
                        </div> 
                    </div> 
                )
            }

        }


        
        return (
            <>
                <BaseEdge path={edgePath} label={event.data.label}/>


                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            fontSize: 12,
                            pointerEvents: 'all',
                        }}
                        
                    >
				        <div className="Condition_bx_as">
				            <div className="Condition_bx_as_hedding">
				                <h4><i className=" fa fa-code-fork" aria-hidden="true"></i> Condition - {event.data.label} </h4>
				            </div>
				
				            <div className="Condition_bx_as_data">
                                {time_pause(event.data.delay)}
                                <div className="cl"></div>
                                <div className="line_as"></div>
                                <div className="cl"></div>
                                {event.data.custom_condition === "" ? ("") : (cond(event.data.custom_condition))}
                                <div className="cl"></div>	
                                <div className="line_as"></div>	
                                <div className="cl"></div>	
                                <div className="p_20_0px"> 
                                    <div className="w-as-100">  
                                        <div className="new_btn_hover flex">
                							<button className="Filter_btn basis-1/3 mx-2" >
                                                <i className="fa fa-filter" aria-hidden="true"></i>Filter
                                            </button>

                                            <button className="Delay_btn basis-1/3 mx-2">
                                                <i className="fa fa-clock-o" aria-hidden="true"></i> Delay
                                            </button>
                							
                							<button className="Update_btn basis-1/3 mx-2">
                                                <i className=" fa fa-pencil-square-o" aria-hidden="true"></i> Update
                                            </button>  
							            </div>
							        </div>
							    </div>
				            </div>
                        </div> 
                    </div>
              </EdgeLabelRenderer>
            </>
        );
    }

    const onConnect = useCallback((params) => {
        ConnectStart(params);
        setEdges((eds) => addEdge({
            ...params,
            id: 'reactflow__edge-' + params.source + '-' + params.target,
            animated: false,
            type:"customedge"
        }, eds))
    }, [setEdges]);


    const nodeTypes = useMemo(() => ({ customnode: CustomNode }), []);
    const edgeTypes = useMemo(() => ({ customedge: CustomEdge }), []);

    const onSave = useCallback(() => {
        console.log(rfInstance)
        if (rfInstance) {
            console.log(rfInstance)
            const flow = rfInstance.toObject();
            console.log(flow)
            fetch(host + "/save_flow", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    flow_data: { flow: flow, flow_id: id },
                }),
            })
            .then(res => res.json())
            .then(data => {
                if(data.status ===200){
                    return toast.success(data.message)
                }else{
                    return toast.success(data.message)
                }
            }).catch((err) => {
                if(err === 401){
                    localStorage.removeItem('token');
                    window.location.href = '/';
                }
            });
        }
    }, [rfInstance]);


    // const OndeleteNode = (event, node_id) => {
    //     console.log(test[0].nodes)
    //     console.log(node_id)

    //     var nodes = test[0].nodes;
        
    //     const getRecursiveChildren = (id) => {
    //         let chiled = [];
    //         nodes.forEach((x, i) => {
    //             if(x.data.value.parent === id){
    //                 chiled.push(x.id)
    //                 chiled.push(...getRecursiveChildren(x.id))
    //             }
    //         });
    //         return chiled;
    //     }
    //     let node_index = nodes.filter((el, index) => {
    //         if(el.data.value.type !== "start"){
    //             if(el.id === node_id){
    //                 let t = [el.id]
    //                 t.push(...getRecursiveChildren(el.id))
    //                 // let a = [];
    //                 t.forEach((x) => {
    //                     nodes.filter((val,i) => {
    //                         if(x === val.id){
    //                             nodes.splice(i, 1);
    //                         }
    //                     });
    //                 });
    //                 fetch(host + "/node_del", {
    //                     method: "POST",
    //                     headers: {
    //                         Accept: "application/json",
    //                         'Content-type': 'application/json',
    //                         'Authorization': `Bearer ${localStorage.getItem('token')}`,
    //                     },
    //                     body: JSON.stringify({
    //                         node_id: el.id, flow_id: id
    //                     }),
    //                 })
    //                 .then(res => res.json())
    //                 .then(data => {
    //                     if(data.status ===200){
    //                         return  toast.success(data.message)
    //                     }else{
    //                         return  toast.success(data.message)
    //                     }
    //                 }).catch((err) => {
    //                     if(err === 401){
    //                         localStorage.removeItem('token');
    //                         window.location.href = '/';
    //                     }
    //                 });
    //                 return el;
    //             }
    //         }
    //     });
    //     test[0].nodes = nodes
    //     setNodes(nodes)
    // }
    const OnUpdateNode = (eve, node_id) => {
        var nodes = test[0].nodes;
        // console.log(nodes)
        var ele = nodes.filter((x) => {
            if(x.id === node_id){
                return x;
            }
        });
        // console.log(ele[0])
        setViewUpdate([node_id, ele[0]])
        setUpdate(true) 
    }
    const ConnectStart = (params) => {
        const arr = params.source.split("-");
        if (arr[0] === 'sms') {
            setRule(["Sms Deliver", "Sms Sent", "Sms Fail", "Sms Reject"])
        } else if (arr[0] === 'email') {
            setRule(["Email Sent", "Email Deliver", "Email Fail"])
        } else if (arr[0] === 'voice') {
            setRule(["Voice Deliver", "Voice Sent","Voice Fail", "Voice Reject"])
        }else if (arr[0] === 'whatsapp') {
            setRule(["WhatsApp Deliver", "WhatsApp Sent","WhatsApp Fail"])
        }
    };


    const newSave = () => {

        const flow_d = {
            nodes : test[0].nodes,
            edges : test[0].connection,
            viewport : test[0].details,
        }
        console.log(flow_d)
        fetch(host + "/save_flow", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    flow_data: { flow: flow_d, flow_id: id },
                }),
            })
            .then(res => res.json())
            .then(data => {
                if(data.status ===200){
                    return toast.success(data.message)
                }else{
                    return toast.success(data.message)
                }
            }).catch((err) => {
                if(err === 401){
                    localStorage.removeItem('token');
                    window.location.href = '/';
                }
            });
    }
    const openForm = (type, parent, cond, template_id) => {
        if(type){
            if(type === "funnel"){
                console.log(template_id)
                let api = {
                    'campaign_name':template_id.segment_name,
                    'sender':template_id.id,
                }
                let extra = {}
                const node_id = type +  `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.segment_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api':api,
                            'description' : "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id" : 'reactflow__edge-' + parent + '-' + node_id,
                    "animated" : false,
                    "type" : "customedge",
                    'data':{
                        "delay" : {
                            "days" : 0,
                            "hours" : 0,
                            "minutes" : 0
                        },
                        "label" : cond,
                        "custom_condition" : "",
                        "extra" : extra
                    },
                    'source' : parent,
                    'target' : node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)
                
                test[0].connection = test[0].connection.concat(edge1)     
                
                onConnect({
                    'source' : parent,
                    'target' : node_id,
                    'sourceHandle':null,
                    'targetHandle':null,
                    'data':{
                        "delay" : {
                            "days" : 0,
                            "hours" : 0,
                            "minutes" : 0
                        },
                        "label" : cond,
                        "custom_condition" : "",
                        "extra" : extra
                    }
                })


                newSave()
            }else if(type === "group"){
                let api = {
                    'campaign_name':template_id.group_name,
                    'sender':template_id.id,
                }
                let extra = {}
                const node_id = type +  `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.group_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api':api,
                            'description' : "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id" : 'reactflow__edge-' + parent + '-' + node_id,
                    "animated" : false,
                    "type" : "customedge",
                    'data':{
                        "delay" : {
                            "days" : 0,
                            "hours" : 0,
                            "minutes" : 0
                        },
                        "label" : cond,
                        "custom_condition" : "",
                        "extra" : extra
                    },
                    'source' : parent,
                    'target' : node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)
                
                test[0].connection = test[0].connection.concat(edge1)     
                
                onConnect({
                    'source' : parent,
                    'target' : node_id,
                    'sourceHandle':null,
                    'targetHandle':null,
                    'data':{
                        "delay" : {
                            "days" : 0,
                            "hours" : 0,
                            "minutes" : 0
                        },
                        "label" : cond,
                        "custom_condition" : "",
                        "extra" : extra
                    }
                })

                newSave()
            }else{
                let api = {
                    'campaign_name':template_id.template_name,
                    'sender': template_id.sender,
                }
                console.log(template_id)
                let extra = {}

                if(type === 'sms'){
                    api.route = template_id.route;
                    api.template_id = template_id.template_id;
                    api.coding = template_id.coding;
                    api.pe_id = template_id.pe_id;
                    api.content = template_id.message;
                    api.auth_key = template_id.auth_key;
                }else if(type === 'email'){
                    api.subject = template_id.subject;
                    api.content = template_id.message;
                    api.sender_name = template_id.sender_name;
                }else if(type === 'voice'){
                    api.content = template_id.message;
                    api.route = template_id.route;
                    api.voice_type = 'text';
                    api.voice_duration = '30';
                    api.auth_key = template_id.auth_key;
                }else if(type === 'whatsapp'){
                    api.whatsapp_var = template_id.whatsapp_var;
                    api.template_id = template_id.template_id;
                    api.auth_key = template_id.auth_key;
                    api.whatsapp_type = template_id.whatsapp_type;
                    api.interactive_btn = template_id.interactive_btn;
                    api.interactive_type = template_id.interactive_type;
                    if(template_id.whatsapp_type == "interactive"){
                        if(template_id.interactive_type == "button"){
                            JSON.parse(template_id.interactive_btn).map(t => {
                                if(t.title == cond.split(" ")[0]){
                                    extra.button_no = t.button_no
                                    extra.button_id = t.button_id
                                    extra.title = t.title
                                }
                            })
                        }  
                    }
                }

                const node_id = type +  `-channel-${+Date.now()}`
                const node1 = {
                    'id': node_id,
                    'type': 'customnode',
                    'data': {
                        'label': template_id.template_name,
                        'value': {
                            'parent': parent,
                            'type': type,
                            'api':api,
                            'description' : "",
                        }
                    },
                    'position': {
                        'x': Math.random() * window.innerWidth + 100,
                        'y': Math.random() * window.innerHeight
                    }
                };
                const edge1 = {
                    "id" : 'reactflow__edge-' + parent + '-' + node_id,
                    "animated" : false,
                    "type" : "customedge",
                    'data':{
                        "delay" : {
                            "days" : 0,
                            "hours" : 0,
                            "minutes" : 0
                        },
                        "label" : cond,
                        "custom_condition" : "",
                        "extra" : extra
                    },
                    'source' : parent,
                    'target' : node_id,
                }
                setNodes((nds) => nds.concat(node1));
                test[0].nodes = test[0].nodes.concat(node1)
                
                
                test[0].connection = test[0].connection.concat(edge1)
                onConnect({
                    'source' : parent,
                    'target' : node_id,
                    'sourceHandle':null,
                    'targetHandle':null,
                    'data':{
                        "delay" : {
                            "days" : 0,
                            "hours" : 0,
                            "minutes" : 0
                        },
                        "extra" : extra,
                        "label" : cond,
                        "custom_condition" : ""
                    }
                })
                newSave()
            }
        }
    }

    





    const actionEdgeEdit = (e) => {
        e.preventDefault();
        console.log(test)
        if(test.length !== 0){
            var edge = test[0].connection;
            const edge_id = e.target.edge_id.value
            if(e.target.type.value == "time" ){
                const data = {
                    "days" : e.target.days.value,
                    "hours" : e.target.hours.value,
                    "minutes" : e.target.minutes.value
                }
                edge.filter((el, index) => {
                    if(el.id === edge_id){
                        el.data.delay = data;
                    }
                });
            }else if(e.target.type.value == "filter"){
                let custom = e.target.custom_col.value + " " + e.target.custom_condition.value + " " + e.target.custom_val.value
                console.log(custom)
                edge.filter((el, index) => {
                    if(el.id === edge_id){
                        el.data.custom_condition = custom;
                    }
                });
            }
            console.log(edge)
            test[0].connection = edge
            setEdges(edge)
            onSave()
        }
        setFilter([false,{},""])
    }






    

    useEffect(() => {   
        if(viewupdate[0] !== ''){
            console.log(viewupdate[1])
            var api = {
                "id" : viewupdate[1].id,
                "description" : viewupdate[1].data.value.description,
                "label" : viewupdate[1].data.label,
                "type" : viewupdate[1].data.value.type,
                "parent" : viewupdate[1].data.value.parent,

            };
            console.log(api)
            if(viewupdate[1].data.value.type === 'sms'){
                api.sms_campaign_name = viewupdate[1].data.value.api.campaign_name
                api.sms_sender = viewupdate[1].data.value.api.sender
                api.sms_template_id = viewupdate[1].data.value.api.template_id
                api.sms_content = viewupdate[1].data.value.api.content
                api.sms_route = viewupdate[1].data.value.api.route
                api.sms_coding = viewupdate[1].data.value.api.coding
                api.pe_id = viewupdate[1].data.value.api.pe_id
                
            }else if(viewupdate[1].data.value.type === 'voice'){
                api.voice_campaign_name = viewupdate[1].data.value.api.campaign_name
                api.voice_sender = viewupdate[1].data.value.api.sender
                api.voice_content = viewupdate[1].data.value.api.content
                api.voice_route = viewupdate[1].data.value.api.route
            }else if(viewupdate[1].data.value.type === 'email'){
                api.email_campaign_name = viewupdate[1].data.value.api.campaign_name
                api.email_sender = viewupdate[1].data.value.api.sender
                api.email_content = viewupdate[1].data.value.api.content
                api.email_subject = viewupdate[1].data.value.api.subject
                api.email_sender_name = viewupdate[1].data.value.api.sender_name
            }
            setUpdateValue(pre => ({...pre,...api}))
        }
    }, [viewupdate])

    const updateNodeForm = (e) => {
        e.preventDefault();

        let node_id = viewupdate[0];
        var api = {};
        if(updateValue.type === "sms"){
            api.campaign_name= updateValue.sms_campaign_name
            api.sender= updateValue.sms_sender
            api.content= updateValue.sms_content
            api.template_id = updateValue.sms_template_id
            api.coding = updateValue.sms_coding
            api.route =updateValue.sms_route
            api.pe_id =updateValue.pe_id
        }else if(updateValue.type === "voice"){
            api.campaign_name= updateValue.voice_campaign_name
            api.sender= updateValue.voice_sender
            api.content= updateValue.voice_content
            api.voice_type = "text"
            api.voice_duration = 30
            api.route =updateValue.voice_route
        }else if(updateValue.type === "email"){
            api.campaign_name= updateValue.email_campaign_name
            api.sender= updateValue.email_sender
            api.content= updateValue.email_content
            api.subject =updateValue.email_subject
            api.sender_name = updateValue.email_sender_name
        }

        var nodes = test[0].nodes;

        /* eslint-disable */ 
        nodes.filter((el, index) => {
            if(el.data.value.type !== "start"){
                if(el.id === node_id){
                    el.data.value.api = api;
                    el.data.label = updateValue.label;
                    el.data.value.description = updateValue.description;
                    el.data.value.parent = updateValue.parent;

                }
            }
        });
        test[0].nodes = nodes
        setNodes(nodes)
        onSave()
        setUpdate(false)
    }
    const updateform = () => {
        const FullForm = (el) => {
            const type = el.data.value.type;
            console.log(el)
            if(type === 'sms'){
                return (
                    <>
                        <div>
                            <div className="flex my_form p-3">
                                <input
                                    id="node_id" 
                                    value={updateValue.id} 
                                    onChange={e => setUpdateValue(pre => ({...pre,'id' : e.target.value}))}
                                    type="hidden" name="id"/>

                                <div className="flex-1  p-2 ">
                                    <label htmlFor="campaign_name">Campaign Name </label><br />
                                    <input id="campaign_name" 
                                    value={updateValue.sms_campaign_name} 
                                    onChange={e => setUpdateValue(pre => ({...pre,'sms_campaign_name' : e.target.value}))}
                                    className="w-full" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required"/>
                                </div>
                                <div className="flex-1 p-2">
                                    <label htmlFor="sender">sender</label><br />
                                    <input id="sender" 
                                    value={updateValue.sms_sender} 
                                    onChange={e => setUpdateValue(pre => ({...pre,'sms_sender' : e.target.value}))}
                                    className="w-full" type="text" name="sender" required="required" placeholder="Enter sender"  />
                                </div>  

                                 <div className="flex-1  p-2 ">
                                    <label htmlFor="pe_id">pe_id</label><br />
                                    <input id="pe_id" 
                                    value={updateValue.pe_id} 
                                    onChange={e => setUpdateValue(pre => ({...pre,'pe_id' : e.target.value}))}
                                    name="pe_id" type="text" className="w-full" placeholder="Enter pe_id" required="required"/>
                                </div>                                     
                            </div>
                            <div className="flex my_form p-3">
                                <div className="flex-1  p-2 ">
                                    <label htmlFor="template_id">Template Id </label><br />
                                    <input id="template_id" 
                                    value={updateValue.sms_template_id} 
                                    onChange={e => setUpdateValue(pre => ({...pre,'sms_template_id' : e.target.value}))}
                                    name="template_id" type="text" className="w-full" placeholder="Enter Template ID" required="required"/>
                                </div> 
                                <div className="flex-1  p-2 ">
                                    <label htmlFor="content">Content</label><br />
                                    <textarea id="content" 
                                    value={updateValue.sms_content} 
                                    onChange={e => setUpdateValue(pre => ({...pre,'sms_content' : e.target.value}))}
                                    name="content" type="text" className="w-full" placeholder="Enter Content" required="required"/>
                                </div> 
                            </div> 
                            <div className="flex  p-3"> 
                                <div className="flex-1  p-2 ">
                                    <input type="radio" value="TR"
                                        onChange={e => setUpdateValue(pre => ({...pre,'sms_route' : e.target.value}))}
                                        checked={updateValue.sms_route === 'TR'} name="route" /> <label className="font-semibold"> Tranactional </label>
                                </div> 
                                <div className="flex-1  p-2 ">
                                   <input type="radio" value="PR" 
                                        onChange={e => setUpdateValue(pre => ({...pre,'sms_route' : e.target.value}))}
                                        checked={updateValue.sms_route === 'PR'} 
                                        name="route" /><label className="font-semibold">Promotional  </label>
                                </div> 
                                <div className="flex-1  p-2 ">
                                    <input type="radio" value="1" 
                                        onChange={e => setUpdateValue(pre => ({...pre,'sms_coding' : e.target.value}))}
                                        checked={updateValue.sms_coding === 1} 
                                        name="coding" /><label className="font-semibold">English </label>
                                </div> 
                                <div className="flex-1  p-2 ">
                                    <input type="radio" value="2"
                                        onChange={e => setUpdateValue(pre => ({...pre,'sms_coding' : e.target.value}))}
                                        checked={updateValue.sms_coding === 2} name="coding" /> <label className="font-semibold">Unicode </label>
                                </div>                      
                            </div>                                          
                        </div>
                    </>
                )
            }
            else if(type === 'voice'){
                return (
                    <>
                        <input id="node_id" 
                        value={updateValue.id} 
                        onChange={e => setUpdateValue(pre => ({...pre,'id' : e.target.value}))}
                        type="hidden" name="id"/>
                        <div className="flex my_form p-3">
                            <div className="flex-1  p-2 ">
                                <label htmlFor="campaign_name">Campaign Name </label><br />
                                <input id="campaign_name" value={updateValue.voice_campaign_name} 
                                onChange={e => setUpdateValue(pre => ({...pre,'voice_campaign_name' : e.target.value}))}
                                className="w-full" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required"/>
                            </div>
                            <div className="flex-1 p-2">
                                <label htmlFor="sender">sender</label><br />
                                <input id="sender" value={updateValue.voice_sender} 
                                onChange={e => setUpdateValue(pre => ({...pre,'voice_sender' : e.target.value}))}
                                className="w-full" type="text" name="sender" required="required" placeholder="Enter sender"  />
                            </div>                                      
                        </div>


                        <div className="flex  p-3">
                            <div className="flex-1 my_form p-2 basis-1/2">
                                <label htmlFor="content">Voice Text Content</label><br />
                                <textarea id="content" name="content" 
                                value={updateValue.voice_content} 
                                onChange={e => setUpdateValue(pre => ({...pre,'voice_content' : e.target.value}))}
                                type="text" className="w-full" placeholder="Enter Content" required="required"/>
                            </div>                      
                            <div className="flex-1  p-2 basis-1/4">
                                <input type="radio" value="TR"
                                    onChange={e => setUpdateValue(pre => ({...pre,'voice_route' : e.target.value}))}
                                    checked={updateValue.voice_route === 'TR'} name="route" className="mr-2"/><label className="font-semibold"> Tranactional </label>
                            </div> 
                            <div className="flex-1  p-2 basis-1/4">
                               <input type="radio" value="PR" 
                                    onChange={e => setUpdateValue(pre => ({...pre,'voice_route' : e.target.value}))}
                                    checked={updateValue.voice_route === 'PR'} 
                                    name="route" className="mr-2" /><label className="font-semibold">Promotional  </label>
                            </div>                  
                        </div>
                    </>         
                )
            }
            else if(type === 'email'){
                return (
                    <>
                        <div className="flex my_form p-3">
                            <div className="flex-1  p-2 ">
                                <label htmlFor="campaign_name">Campaign Name </label><br />
                                <input id="campaign_name" 
                                value={updateValue.email_campaign_name} 
                                onChange={e => setUpdateValue(pre => ({...pre,'email_campaign_name' : e.target.value}))}
                                 className="w-full" name="campaign_name" type="text" placeholder="Enter Campaign Name" required="required"/>
                            </div>
                            <div className="flex-1 p-2">
                                <label htmlFor="sender">sender</label><br />
                                <input id="sender" 
                                value={updateValue.email_sender} 
                                onChange={e => setUpdateValue(pre => ({...pre,'email_sender' : e.target.value}))}
                                 className="w-full" type="text" name="sender" required="required" placeholder="Enter sender"  />
                            </div>                                      
                        </div>
                        <div className="flex my_form p-3">
                            <div className="flex-1  p-2 ">
                                <label htmlFor="content">Email Content</label><br />
                                <textarea id="content" name="content" 
                                value={updateValue.email_content} 
                                onChange={e => setUpdateValue(pre => ({...pre,'email_content' : e.target.value}))}
                                className="w-full"
                                 type="text" placeholder="Enter Content" required="required"/>
                            </div>
                            <div className="flex-1 p-2">
                                <label htmlFor="subject">Subject </label><br />
                                <input id="subject" name="subject" 
                                value={updateValue.email_subject} 
                                onChange={e => setUpdateValue(pre => ({...pre,'email_subject' : e.target.value}))}
                                className="w-full"
                                 type="text" placeholder="Enter Subject" required="required"/>
                            </div> 
                            <div className="flex-1 p-2">
                                <label htmlFor="sender_name">Sender Name</label><br />
                                <input id="sender_name" 
                                value={updateValue.email_sender_name} 
                                onChange={e => setUpdateValue(pre => ({...pre,'email_sender_name' : e.target.value}))}
                                className="w-full"
                                name="sender_name" type="text" placeholder="Enter Sender Name" required="required"/>
                            </div>                                     
                        </div>
                    </>     
                )
            }
        }
        return (
            <>
                <div className="justify-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-3/5 h-min  mx-auto max-w-5xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h4 className="text-2xl font-semibold">{updateValue.label}</h4>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => {
                                        setUpdate(false)
                                        setViewUpdate(["", {}])
                                    }}
                                >
                                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <div className="relative p-2 flex-auto">
                                <form onSubmit={(e) => updateNodeForm(e)}>      
                                    <input id="label" name="label" value={updateValue.label} type="hidden" />
                                    <input type="hidden" id="type" name="type" value={updateValue.type} />
                                    
                                    {viewupdate[0] === "" ? ("") : (FullForm(viewupdate[1]))}

                                    <input type="hidden" name="description" value={updateValue.description} />

                                    {/*<div className="flex my_form p-3">
                                        <div className="flex-1">      
                                            <label htmlFor="description">Description</label><br />
                                            <textarea id="description" name="description"
                                                value={updateValue.description} 
                                                onChange={e => setUpdateValue(pre => ({...pre,'description' : e.target.value}))}
                                                type="text" className="w-full" placeholder="Enter description" required="required"
                                            />
                                       </div>
                                    </div> */}   

                                    <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setUpdate(false)}
                                        >Close</button>
                                        <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                        >Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        )
    }
    

    const editEdgeForm = (e) => {
        e.preventDefault();
        if(test.length !== 0){
            const edge_id = e.target.edge_id.value
            var edge = test[0].connection;
            edge.filter((el, index) => {
                if(el.id === edge_id){
                    let extra = {}

                    if(el.source.split("-")[0] == "whatsapp"){

                        test[0].nodes.map(t => {
                            if(t.id == el.source){
                                if(t.data.value.api.whatsapp_type == "interactive"){
                                    if(t.data.value.api.interactive_type == "button"){
                                        

                                        JSON.parse(t.data.value.api.interactive_btn).map(x => {
                                            if(x.title == e.target.condition.value.split(" ")[0]){
                                                extra.title = x.title
                                                extra.button_no = x.button_no
                                                extra.button_id = x.button_id
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }


                    el.data.label = e.target.condition.value;
                    el.data.extra = extra;
                }
            });
            test[0].connection = edge
            setEdges(edge)
            onSave()
        }
        setEdgeEdit([false,{}])
    }

    const edge_edge = (data) => {
        console.log(data)
        let temp_type = ""
        let temp_wa_type = ""
        let temp_datas = ""

        if(data.source.split("-")[0] == "whatsapp"){
            nodes.map(i => {
                if(i.id == data.source){

                    if(i.data.value.api.whatsapp_type == "interactive"){
                        if(i.data.value.api.interactive_type == "button"){

                            temp_type = i.data.value.api.whatsapp_type

                            temp_wa_type = i.data.value.api.interactive_type
                            temp_datas = i.data.value.api.interactive_btn
                        }
                    }
                }
            })
        }


        
        
        const rules = childCondition(data.source.split("-")[0], data.source, temp_type, temp_wa_type, temp_datas)
        const label = data.data.label

        const test2 = (r) => {
            return r.map(item => {
                if(item == label){
                    return <option className="font-semibold" selected value={item}>{item}</option>
                }else{
                    return <option className="font-semibold" value={item}>{item}</option>
                }
            })
        }

        return (
            <>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold"> Edge Edit </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setEdgeEdit([false,{}])}
                                >
                                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <div className="relative p-3 flex-auto">
                                <form onSubmit={editEdgeForm}>
                                    <div className="flex my_form">
                                        <div className="flex-1 p-2">
                                            <label htmlFor="label" > Condition </label><br />
                                            <select name="condition"className="w-full">
                                                <option disabled required="required"className="font-semibold">Select Condition</option>
                                                {rules === [] ? ("") : test2(rules)}
                                            </select>                   
                                        </div> 
                                        <input type="hidden" value={data.id} name="edge_id" />
                                    </div>
                                    <div className="flex items-center justify-end border-t border-solid border-slate-200 rounded-b">
                                       
                                        <button 
                                            className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                            onClick={() => setEdgeEdit([false,{}])}
                                            type="button"
                                        >
                                            <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                            <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                        </button>
                                        <button 
                                            className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600 text-white"
                                            type="submit"
                                        >
                                            <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                            <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
        )
    }
    const filter_col_type = (col_data) => {
        const inputElement = myInputRef.current;


        // inputElement.querySelector('#notSaveValue').type = 'password';
    }



    const addTimeFilter = (data) => {
        const delay = data.data.delay
        return (
            <>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="p-3 rounded-lg border border-gray-200 shadow-lg relative flex flex-col w-full bg-white translate-y-2 transition-all duration-700 ease-in-out">
                            <form onSubmit={actionEdgeEdit}>
                                
                                <input type="hidden" name="type" value="time" />
                                <div className="flex border-b border-solid border-slate-200">
                                    <h3 className="text-lg font-semibold"> Time Pause </h3>
                                    <button
                                        className="ml-auto text-3xl font-semibold"
                                        type="button"
                                        onClick={() => setFilter([false,{}, ""])}
                                    >
                                        <span className="text-black text-2xl block outline-none">×</span>
                                    </button>
                                </div>
                                <div className="flex justify-around px-6">
                                    <div className="flex flex-col p-4">
                                        <select name="days" className="appearance-none outline-none no-scrollbar bg-white text-2xl font-semibold text-slate-800 text-center">
                                            {
                                                Array.from(Array(30).keys()).map(item => {
                                                    if(delay.days == item){
                                                        return <option className="text-sm outline-none" selected value={item}>{item}</option>
                                                    }else{
                                                        return <option className="text-sm outline-none" value={item}>{item}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                        <span className="text-sm font-semibold text-slate-500">DAYS</span>
                                    </div>
                                    <span className="h-12 w-px my-4 bg-slate-500"></span>
                                    <div className="flex flex-col p-4">
                                        <select name="hours" className="appearance-none outline-none no-scrollbar bg-white text-2xl font-semibold text-slate-800 text-center">
                                            {
                                                Array.from(Array(24).keys()).map(item => {
                                                    if(delay.hours == item){
                                                        return <option className="text-sm outline-none" selected value={item}>{item}</option>
                                                    }else{
                                                        return <option className="text-sm outline-none" value={item}>{item}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                        <span className="text-sm  font-semibold text-slate-500"> HOURS </span>
                                    </div>
                                    <span className="h-12 w-px my-4 bg-slate-500"></span>
                                    <div className="flex flex-col p-4">
                                        <select name="minutes" className="appearance-none outline-none no-scrollbar bg-white text-2xl font-semibold text-slate-800 text-center">
                                            {Array.from(Array(60).keys()).map((item) => {
                                                if(delay.minutes == item){
                                                    return <option className="text-sm outline-none" selected value={item}>{item}</option>
                                                }else{
                                                    return <option className="text-sm outline-none" value={item}>{item}</option>
                                                }
                                                
                                            })}
                                        </select>
                                        <span className="text-sm font-semibold text-slate-500">MINUTES</span>
                                    </div>
                                </div> 
                    
                                <input type="hidden" value={data.id} name="edge_id" />
                                
                                <div className="flex items-center justify-end rounded-b">
                                    <button 
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                        onClick={() => setFilter([false,{},""])}
                                        type="button"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                    </button>
                                    <button 
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600 text-white"
                                        type="submit"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        )
    }



    const addFunnelFilter = (data, cols) => {
        const custom_condition = data.data.custom_condition

        
        const cond = (conn) => {
            if(conn == "" || conn == null){
                return (
                    <div className="flex" ref={myInputRef}>
                        <div className="flex-1 p-2" >
                            <select
                                name="custom_col"
                                required="required"
                                onChange={e => filter_col_type(e.target.avlue)}
                                className="m-2 p-2 outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow-md rounded-md w-full"
                            >
                                <option selected disabled>Select Variable </option>
                                {
                                    customCol === [] ? ("") : (customCol.map((item) => 
                                        <option value={item.column_name}>
                                            {item.column_name}
                                        </option>
                                    ))
                                }            
                            </select>
                        </div>


                       

                        <div className="flex-1 p-2">
                            <select
                                name="custom_condition"
                                className="m-2 p-2 bg-transparent outline-0 text-grey-500 border-2 border-grey-500 shadow-md rounded-md w-full" 
                                required="required"
                                id="notSaveOpertor"
                            >
                                <option selected disabled>Select Operator</option>
                                {
                                    cols === [] ? ("") : (cols.map((item) => 
                                        <option value={item.value}>
                                            {item.cond}
                                        </option>
                                    ))
                                }
                            </select>                   
                        </div>  

                        <div className="flex-1  p-2 ">
                            <input
                                className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                name="custom_val"
                                type="text"
                                placeholder="Enter Value"
                                required="required"
                                id="notSaveValue"
                            />           
                        </div>
                        
                    </div>
                )
            }else{

                const cus_con = custom_condition.split(" ")
                return (   
                    <div className="flex">
                        <div className="flex-1 p-2" >
                            <select
                                name="custom_col"
                                required="required"
                                className="m-2 p-2 outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow-md rounded-md w-full"
                            >
                                <option value="" >Select Variable </option>
                                {
                                    customCol === [] ? ("") : (customCol.map(item => 
                                        <option
                                            selected={cus_con[0] === item.column_name ? ("selected") : ("")} 
                                            value={item.column_name}
                                        >
                                            {item.column_name}
                                        </option>
                                    ))
                                }            
                            </select>
                        </div>
                        <div className="flex-1 p-2">
                            <select
                                name="custom_condition"
                                className="m-2 p-2 bg-transparent outline-0 text-grey-500 border-2 border-grey-500 shadow-md rounded-md w-full" 
                                required="required"
                            >
                                <option value="">Select Operator</option>
                                <option value="=" selected={cus_con[1] === "=" ? ("selected") : ("")} >Is</option>
                                <option value="!=" selected={cus_con[1] === "!=" ? ("selected") : ("")} >Is Not</option>
                                <option value=">" selected={cus_con[1] === ">" ? ("selected") : ("")} >Greater Than</option>
                                <option value=">=" selected={cus_con[1] === ">=" ? ("selected") : ("")} >Greater Than or equal</option>
                                <option value="<" selected={cus_con[1] === "<" ? ("selected") : ("")} >Less Than</option>
                                <option value="<=" selected={cus_con[1] === "<=" ? ("selected") : ("")} >Less Than or equal</option>
                            </select>                   
                        </div>  

                        <div className="flex-1  p-2 ">
                            <input
                                className="m-2 p-1.5  placeholder-black outline-0 bg-transparent text-grey-500 border-2 border-grey-500 shadow rounded-md w-full"
                                name="custom_val"
                                type="text"
                                placeholder="Enter Value"
                                required="required"
                                defaultValue={cus_con[2]}
                            />           
                        </div>
                    </div>
                )
            }
        }
        return (
            <>
                <div className="flex fixed inset-0 z-50">
                    <div className="w-auto my-6 mx-auto">
                        <div className="p-3 rounded-lg border border-gray-200 shadow-lg relative flex flex-col w-full bg-white translate-y-2 transition-all duration-700 ease-in-out">
                            <form onSubmit={actionEdgeEdit}>
                                <input type="hidden" name="type" value="filter" />
                                <div className="flex border-b border-solid border-slate-200">
                                    <h3 className="text-lg font-semibold"> Add Filter </h3>
                                    <button
                                        className="ml-auto text-3xl font-semibold"
                                        type="button"
                                        onClick={() => setFilter([false,{}, ""])}
                                    >
                                        <span className="text-black text-2xl block outline-none">×</span>
                                    </button>
                                </div>
                                {cond(custom_condition)}
                                <input type="hidden" value={data.id} name="edge_id" />
                                
                                <div className="flex items-center justify-end rounded-b">
                                    <button 
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-red-600 text-red-600 text-white"
                                        onClick={() => setFilter([false,{},""])}
                                        type="button"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-red-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-red-600 transition duration-300 group-hover:text-white ease">Close</span>
                                    </button>
                                    <button 
                                        className="rounded-md px-3 py-1.5 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-sky-600 text-sky-600 text-white"
                                        type="submit"
                                    >
                                        <span className="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-sky-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-sky-600 transition duration-300 group-hover:text-white ease">Edit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        )
    }

    const final = () => {
        if(nodes.length == 0){
            return (
                <div className="flex justify-center items-center h-screen">
                    <div className="relative inline-flex">
                        <div className="w-16 h-16 bg-[#1286a8] rounded-full"></div>
                        <div className="w-16 h-16 bg-[#1286a8] rounded-full absolute top-0 left-0 animate-ping"></div>
                        <div className="w-16 h-16 bg-[#1286a8] rounded-full absolute top-0 left-0 animate-pulse"></div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="dndflow w-full" style={{height: '100vh'}}>
                    <div className="reactflow-wrapper h-full" ref={reactFlowWrapper} >
                       <div className="flex flex-row-reverse zindex_as">

                            
                            <button
                                className="btn_as_my_wew"
                                onClick={() => navigate(-1)}
                            ><i className="fa  fa-arrow-left  mr-1" aria-hidden="true"></i> Back</button>
                        </div>
                        {isupdate === false ? ("") : (updateform())}
                        {edgeEdit[0] === false ? ("") : (edge_edge(edgeEdit[1]))}
                        {filter[0] === false ? ("") : (filter[2] == "time" ? (addTimeFilter(filter[1])) : (addFunnelFilter(filter[1], colCondition)))}
                        {/*{filter[0] === false ? ("") : (addTimeFilter(filter[1], filter[2]))}*/}


                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onInit={setRfInstance}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            fitView
                        >
                            <Background variant="cross" gap={20} size={4} color='#c4c4c4' style={{ 'backgroundColor':'#fafafa'}}/>
                            <Controls position="top-left" ></Controls>
                        </ReactFlow>
                    </div>
                </div>
            )
        }
    }

    

  


    return (
        <ReactFlowProvider>
            {final()}
            <ToastContainer />
        </ReactFlowProvider>
    );
};
