/* eslint-disable */
import { useState, useRef, Fragment } from "react";
import ReactDOM from 'react-dom/client';

import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useForm, useFieldArray } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

const host = "https://oiomni.com/api"
const funnelHost = 'http://people.oiomni.com:8001/api';

const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');

let funnel = []

const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
};


const headers_axios = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Accept': "application/json",
    'mode': 'no-cors',
}

const axios_header = { 'headers': headers_axios }


export default function AllFlow() {
    const [searchInput, setSearchInput] = useState("");
    const [isDel, setDel] = useState("");
    const [runId, setRunId] = useState("");
    const [pauseId, setPauseId] = useState("");
    const [schId, setSchId] = useState("");
    const [copyId, setCopyId] = useState("");

    const myInputRef = useRef(null);
    const schRef = useRef(null);




    const allFlow = useQuery({
        queryKey: ['allFlow'],
        queryFn: async () => {
            try {
                const response = await axios.get(host + '/get_all_flow', axios_header);
                console.log(response.data)
                return response.data;
            } catch (error) {
                throw error;
            }
        },
    })

    const allSch = useQuery({
        queryKey: ['allSch'],
        queryFn: async () => {
            try {
                const response = await axios.get(host + '/get_all_sch', axios_header);
                console.log(response.data)
                return response.data;
            } catch (error) {
                throw error;
            }
        },
    })




    if (!token) noAuth()

   

    const DelModel = ({is_del}) => {

        

        if (is_del !== ""){
            async function del(e) {
                e.preventDefault();
                try {
                    const response = await axios.post(host + "/flow_del", { id: e.target.flow_id.value }, axios_header);
                    if (response.status == 200) {
                        setDel("")
                        allFlow.refetch()
                        toast.success(response.data.message)
                    }
                } catch (error) {
                    if (error.response) {
                        let errs = error.response
                        if (errs.status == 401) {
                            toast.error(errs.data.message)
                            noAuth()
                        } else if (errs.status == 403) {
                            Object.values(errs.data.message).map(val => {
                                toast.error(val[0])
                            })
                        }
                    } else {
                        toast.error(error.message)
                    }
                }
            }
            return (
                <Fragment>
                    <div className="justify-center  flex  fixed inset-0 z-50 mt-5">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="-right-2 -top-2 absolute bg-red-800 text-black z-20  text-3xl font-semibold rounded-full"
                                    onClick={() => { setDel("") }}
                                >
                                    <span className=" text-red-100 leading-6 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                                <div className="relative p-4 flex-auto bg_my_color_2">
                                    <form onSubmit={(e) => del(e)}>
                                        <input type="hidden" name="flow_id" value={is_del} />
                                        <div class="del_icon_big"> <i class="fa fa-trash" aria-hidden="true"></i>  </div>
                                        <div className="flex flex-row w-full">
                                            <h4 >Are you sure to you want to Delete This flow</h4>
                                        </div>
                                        <div className="text-center"> <button type="submit" className="btn_as_delete text-blue-500 font-semibold p-2 rounded-md" >Delete</button> </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </Fragment>
            );
        }
        
    }

    const CopyModel = ({ copy_id }) => {

        if (copy_id !== ""){

            async function copy(e) {
                e.preventDefault();
                try {
                    const response = await axios.post(host + "/copy_flow", { flow_id: e.target.flow_id.value }, axios_header);
                    if (response.status == 200) {
                        setCopyId("")
                        allFlow.refetch()
                        toast.success(response.data.message)
                    }
                } catch (error) {
                    if (error.response) {
                        let errs = error.response
                        if (errs.status == 401) {
                            toast.error(errs.data.message)
                            noAuth()
                        } else if (errs.status == 403) {
                            Object.values(errs.data.message).map(val => {
                                toast.error(val[0])
                            })
                        }
                    } else {
                        toast.error(error.message)
                    }
                }
            }
            return (
                <Fragment>
                    <div className="justify-center  flex mt-5  fixed inset-0 z-50 ">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="-right-2 -top-2 absolute bg-red-800 text-black z-20  text-3xl font-semibold rounded-full"
                                    onClick={() => { setCopyId("") }}
                                >
                                    <span className=" text-red-100 leading-6 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                                <div className="relative p-4 flex-auto bg_my_color_1 ">
                                    <form onSubmit={(e) => copy(e)}>
                                        <input type="hidden" name="flow_id" value={copy_id} />
                                        <div className="copy_icon_big"> <i className="fa fa-clipboard" aria-hidden="true"></i>
                                        </div>

                                        <div className="flex flex-row w-full text-center">
                                            <h5 className="p-2">Are you sure to you want to Copy This flow</h5>
                                        </div>
                                        <div className="text-center">  <button type="submit" className="bg-blue-100 text-blue-500 font-semibold p-2 rounded-md" >Copy</button> </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </Fragment>
            );
        }
        
    }

    const RunModel = ({run_id}) => {

        if (run_id !== ""){

            async function final(e) {
                e.preventDefault();

                const bodyData = {
                    flow_id: e.target.flow_id.value,
                    schedule: {}
                }

                try {
                    const response = await axios.post(host + "/new_flow_run", bodyData, axios_header);
                    if (response.status == 200) {
                        toast.success(response.data.message)
                    }
                } catch (error) {
                    if (error.response) {
                        let errs = error.response
                        if (errs.status == 401) {
                            toast.error(errs.data.message)
                            noAuth()
                        } else if (errs.status == 403) {
                            toast.error(errs.data.message)
                        }
                    } else {
                        toast.error(error.message)
                    }
                }

                setRunId("")
                allFlow.refetch()
                allSch.refetch()
            }
            return (
                <Fragment>
                    <div className="justify-center  flex mt-5  fixed inset-0 z-50 ">
                        <div className="relative w-auto my-6 mx-auto max-w-2xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white">
                                <button onClick={() => setRunId("")} className="-right-2 -top-2 absolute bg-red-800 text-black z-20  text-3xl font-semibold rounded-full">
                                    <span className=" text-red-100 leading-6 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span> </button>
                                <div className="relative p-4 flex-auto bg_my_color_2 ">
                                    <form onSubmit={(e) => final(e)}>
                                        <input type="hidden" name="flow_id" value={run_id} />
                                        <div className="pley_icon_big"> <i class="fa fa-play-circle" aria-hidden="true"></i></div>
                                        <div className="text-center my-2"> <h5>Are you Sure You Want To Run This...?</h5> </div>
                                        <div className="flex align-items-center justify-content-center flex-row-reverse p-2 w-full ">
                                            <button className="btn_as_delete mr-2" onClick={() => setRunId("")}>
                                                <i className="fa fa-times-circle" aria-hidden="true"></i>Close
                                            </button>
                                            <button type="submit" className="btn_as_run mr-2"> <i className="fa fa-paper-plane" aria-hidden="true"></i>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </Fragment>
            );
        }


        
    }

    const PauseModel = ({pause_id}) => {

        if (pause_id !== ""){
            async function pauseFlow(e) {
                e.preventDefault();
                try {
                    const response = await axios.post(host + "/new_flow_pause", { flow_id: e.target.flow_id.value }, axios_header);
                    if (response.status == 200) {
                        toast.success(response.data.message)
                    }
                } catch (error) {
                    if (error.response) {
                        let errs = error.response
                        if (errs.status == 401) {
                            toast.error(errs.data.message)
                            noAuth()
                        } else if (errs.status == 403) {
                            toast.error(errs.data.message)
                        }
                    } else {
                        toast.error(error.message)
                    }
                }
                setPauseId("")
                allFlow.refetch()
                allSch.refetch()

            }


            return (
                <Fragment>
                    <div className="justify-center fixed inset-0 z-50 ">
                        <div className="relative w-auto my-6 mx-auto max-w-2xl">
                            <div className="rounded-lg shadow-lg shadow-gray-500 border-1 border-solid border-gray-500 relative  w-full bg-white  ">
                                <div className="flex flex-row  py-2 px-4 border-b border-solid border-slate-200">
                                    <div className="basis-5/6 pop_headding">
                                        <h4 className="modal-title"> <i className="fa fa-sitemap" aria-hidden="true"></i> Pause Flow</h4>
                                    </div>
                                    <div className="basis-1/6">
                                        <button onClick={() => setPauseId("")}
                                            className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        >
                                            <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                        </button>
                                    </div>
                                </div>
                                <form onSubmit={(e) => pauseFlow(e)}>
                                    <input type="hidden" name="flow_id" value={pause_id} />
                                    <h4>Are you Sure you want to pause this...?</h4>
                                    <div className="flex flex-row-reverse p-2 w-full border-t border-solid border-slate-200">
                                        <button className="btn_as_delete" onClick={() => setPauseId("")}>
                                            <i className="fa fa-times-circle" aria-hidden="true"></i>Close
                                        </button>
                                        <button type="submit" className="btn_as_run"> <i className="fa fa-paper-plane" aria-hidden="true"></i>Pause</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </Fragment>
            );
        }
        
    }

    const RunBtn = ({ status, id }) => {
        if (status == 0) {
            return (
                <button className="btn_as_run" value={id} onClick={(e) => setRunId(id)}>
                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                </button>
            )
        } else {
            return (
                <button className="btn_as_run" value={id} onClick={(e) => setPauseId(id)}>
                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                </button>
            )
        }
    }

    const TableContain = () => {
        const filteredData = allFlow.data.filter((item) => {
            if (searchInput == "") {
                return item
            } else {
                return item.flow_name.toLowerCase().includes(searchInput)
            }
        })

        const SchBtn = ({ item }) => {
            if (item.map_id && item.map_id != null) {
                return (
                    <li>
                        <button className="btn_as_shc" value={item.id} onClick={(e) => setSchId(item.id)}>
                            <i className="fa fa-clock" aria-hidden="true"></i>
                        </button>
                    </li>
                )
            }
        }

        const RunStatus = ({ status }) => {
            if (status == 2 || status == 1) {
                return "Running";
            } else {
                return "Pause";
            }
        }

        return filteredData.map((item, index) => {
            if (item.user_id != 0) {
                return (
                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="flow_tham text-center">
                                    <h6> <i className="fas fa-sitemap"></i><br /> Flow Name<br /> <Link to={"/new/" + item.id}>{item.flow_name}</Link></h6>
                                    <p className="mail"><RunStatus status={item.running_status} /></p>
                                    <div className="btl_list_as">
                                        <ul>
                                            <li>
                                                <RunBtn status={item.running_status} id={item.id} />
                                            </li>
                                            <li>
                                                <button className="btn_as_delete" value={item.id} onClick={(e) => setCopyId(item.id)}>Copy</button>
                                            </li>
                                            <SchBtn item={item} />
                                            <li>
                                                <Link className="btn_as_report" to={"/new_report/" + item.id}>
                                                    <i className="fa fa-file" aria-hidden="true"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <button className="btn_as_delete" value={item.id} onClick={(e) => setDel(item.id)}>
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
    }

    const Schedule = () => {
        console.log(allSch.data)
        const { control, handleSubmit: schHandle, register: schReg } = useForm({
            defaultValues: {
                sch: [{ type: "", "hours": 0, "mins": 0 }]
            }
        });

        const { fields, append, remove } = useFieldArray({ control, name: "sch" });

        const SchFinal = async (formData) => {
            await axios.post(host + "/flow_schedule", formData, axios_header)
                .then(function (response) {
                    console.log(response.data)
                    if (response.data.status == 200) {
                        toast.success(response.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    const err = error.response
                    if (err.status == 401) {
                        localStorage.removeItem('token');
                        window.location.href = '/';
                    } else if (err.status == 403) {
                        toast.error(err.message)
                    }
                });
            setSchId("")


            console.log(formData)
        }
        if (schId != "") {

            const schTypeChange = (val, field, index) => {
                const valId = `#schBox-${field.id}`
                const main_box = myInputRef.current

                if (val == "weekly") {
                    ReactDOM.createRoot(main_box.querySelector(valId)).render(
                        <select className="form-select" {...schReg(`sch.${index}.day`, { required: true })}>
                            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(item => (<option value={item}>{item}</option>))}
                        </select>
                    );
                } else if (val == "monthly") {
                    ReactDOM.createRoot(main_box.querySelector(valId)).render(
                        <select className="form-select" {...schReg(`sch.${index}.date`, { required: true })}>
                            {Array.from(Array(30).keys()).map(item => (<option value={item}>{item}</option>))}
                        </select>
                    );
                }




            }
            return (
                <Fragment>
                    <div className="justify-center  flex  mt-5 fixed inset-0 z-50 ">

                        <div className="relative w-auto my-6 mx-auto max-w-6xl">


                            <div className="border-0 bg_my_color_3 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">



                                <button className="-right-2 -top-2 absolute bg-red-800 text-black z-20  text-3xl font-semibold rounded-full"
                                    onClick={() => {
                                        setSchId("")
                                    }}
                                >
                                    <span className=" text-red-100 leading-6 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>





                                <div className="relative flex-auto px-4 mt-3" ref={myInputRef}>
                                    <form onSubmit={schHandle(SchFinal)} >

                                        <div className="wach_icon_big"> <i className="fa fa-clock" aria-hidden="true"></i>  </div>

                                        <div className="text-center"><h4> Scheduler </h4></div>


                                        <input type="hidden" {...schReg(`flow_id`, { required: true })} value={schId} />

                                        {fields.map((field, index) => (
                                            <div key={field.id} >

                                                <div className="row mb-2">

                                                    <div className="col-md-4">
                                                        <select className="form-select   " {...schReg(`sch.${index}.type`, { required: true, onChange: (e) => schTypeChange(e.target.value, field, index) })}>
                                                            <option value="">Schedule Type</option>
                                                            <option value="daily">Daily</option>
                                                            <option value="weekly">Weekly</option>
                                                            <option value="monthly">Monthly</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <select className="form-select  " {...schReg(`sch.${index}.hours`, { required: true })}>
                                                            {Array.from(Array(24).keys()).map(item => (<option value={item}>{item}</option>))}
                                                        </select>
                                                    </div>



                                                    <div className="col-md-3">
                                                        <select className="form-select   " {...schReg(`sch.${index}.mins`, { required: true })}>
                                                            {Array.from(Array(60).keys()).map(item => (<option value={item}>{item}</option>))}
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <button className="btn_as_delete" type="button" onClick={() => remove(index)}><i className="fa fa-times-circle" aria-hidden="true"></i></button>
                                                    </div>


                                                </div>





                                                <div id={`schBox-${field.id}`}></div>







                                            </div>
                                        ))}





                                        <div className="border-top border-bottom mt-2 text-center">  <button className="btn btn-primary mt-2 mb-2" type="button" onClick={() => append({ type: "", hours: 0, mins: 0, })}>Add More Scheduler </button> </div>






                                        <div className="flex flex-row-reverse p-2 w-full">
                                            <button
                                                className="btn_as_delete "
                                                onClick={() => {
                                                    setSchId("")
                                                }}
                                            >
                                                <i className="fa fa-times-circle" aria-hidden="true"></i>Close
                                            </button>
                                            <button type="submit" className="btn_as_run mr-2"><i className="fa fa-paper-plane" aria-hidden="true"></i>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </Fragment>
            );
        }
    }




    if (allFlow.isLoading || allSch.isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="relative inline-flex">
                    <div className="w-16 h-16 bg-[#1286a8] rounded-full"></div>
                    <div className="w-16 h-16 bg-[#1286a8] rounded-full absolute top-0 left-0 animate-ping"></div>
                    <div className="w-16 h-16 bg-[#1286a8] rounded-full absolute top-0 left-0 animate-pulse"></div>
                </div>
            </div>
        );
    }
    if (allFlow.isError) {
        if (allFlow.error.response.status == '401') noAuth()
    }




    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <h1 class="h3_ashish mb-1 mt-2 text-gray-800"> <i class="fas fa-sitemap"></i> Bot List </h1>
                    </div>
                    <div className="col-md-4">
                        <div className=" mt-2 mr-2">
                            <label htmlFor="simple-search" className="sr-only">Search</label>
                            <div className="relative ">
                                <div className="flex absolute  inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                </div>
                                <input onChange={(e) => setSearchInput(e.target.value)} id="simple-search" className="search_bg placeholder-gray-800 border border-gray-300 text-black text-sm rounded-lg  block w-full pl-10 p-2.5" placeholder="Search..." />
                            </div>
                        </div>
                    </div>
                    {/* {runId === "" ? ("") : run(runId)} */}
                    <RunModel run_id={runId} />
                    <PauseModel pause_id={pauseId} />
                    
                    {/* {pauseId === "" ? ("") : pause(pauseId)} */}

                    <Schedule />
                    <DelModel is_del={isDel} />
                    <CopyModel copy_id={copyId}/>
                    <div className="row">
                        <TableContain />
                    </div>
                </div>
                <ToastContainer />
            </div>
        </Fragment>
    );
}