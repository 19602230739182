import React, { Fragment, useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form"
import axios from 'axios';

const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}

const buttonCounts = (data) => {
    if (data) {
        let main_count = 0
        data.map(i => {
            main_count = main_count + i.rows.length
        })
        return main_count
    }
}
export default function AddNewListTemp({ onSave, nodeId, datas, showError, setNodes, setLoad, load }) {
    const { register, control, handleSubmit, clearErrors, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            "action": JSON.parse(datas.api.intListData),
            "template_name": datas.extra.title,
            "header": datas.extra.header_data,
            "body": datas.extra.body,
            "footer": datas.extra.footer
        }
    });
    const { fields, append, remove } = useFieldArray({ control, name: "action" });

    const [loading, setLoading] = useState(false);
    const [btnCount, setBtnCount] = useState(buttonCounts(JSON.parse(datas.api.intListData)));
    const [tempCount, setTempCount] = useState(0);
    const [headerCount, setHeaderCount] = useState(0);
    const [bodyCount, setBodyCount] = useState(0);
    const [footerCount, setFooterCount] = useState(0);
    const [btnTitleCount, setBtnTitleCount] = useState(0);
    const [varHeaderCount, setVarHeaderCount] = useState(0)

    const [varBodyCount, setVarBodyCount] = useState(0)

    const addBodyVar = () => {
        if (varBodyCount <= 20) {
            const currentValue = getValues('body');
            const newValue = currentValue + "{{" + (varBodyCount + 1) + "}}";
            setValue('body', newValue.toString());
            setValue('is_variable', "1");
            setVarBodyCount(varBodyCount + 1)
        } else {
            showError("You can add only 20 variable in Body")
        }
    }
    const addHeaderVar = () => {
        if (varHeaderCount < 1) {
            const currentValue = getValues('header');
            const newValue = currentValue + "{{" + (varHeaderCount + 1) + "}}";
            setValue('header', newValue.toString());
            setValue('is_variable', "1");
            setVarHeaderCount(varHeaderCount + 1)
        } else {
            showError("You can add only one variable in header")
        }

    }



    const onSubmit = (data) => {
        if (btnCount != 0) {
            // onSave(data)
            setLoading(true);


            if (datas.api.ndsType == "new") {

                const newForm = {
                    "int_type": "list",
                    "header_type": "text",
                    "header_data": data.header,
                    "body": data.body,
                    "footer": data.footer,
                    "template_name": data.template_name,
                    "auth_key": localStorage.getItem('wa_auth_key'),
                    "action": data.action,
                    "btn_title": data.btn_title,
                }
                console.log(newForm)
                try {
                    axios.post("https://tickmaker.io/api/save-interactive-template", newForm, headers).then(response => {

                        if (response.data.status == "success") {
                            try {
                                axios.post("https://tickmaker.io/api/get-int-template-by-id", {
                                    "auth_key": localStorage.getItem('wa_auth_key'),
                                    "template_id": response.data.data.template_id,
                                    "interactive_type": "list",
                                }, headers).then(res => {
                                    console.log(response)
                                    if (response.data.status == "success") {
                                        setNodes(nds => nds.map(node => {
                                            if (node.id == nodeId) {
                                                const nodeVal = node.data.value
                                                node.data.label = data.template_name
                                                nodeVal.type = "whatsapp"
                                                nodeVal.api.ndsType = "edit"
                                                let extras = {
                                                    "template_id": response.data.data.template_id,
                                                    "title": data.template_name,
                                                    "int_type": "list",
                                                    "header_type": "text",
                                                    "header_data": data.header,
                                                    "file_name": null,
                                                    "body": data.body,
                                                    "footer": data.footer,
                                                    "is_variable": "0"
                                                }
                                                nodeVal.api.template_id = response.data.data.template_id



                                                nodeVal.api.interactive_btn = JSON.stringify(res.data.data)
                                                nodeVal.extra = extras
                                            }
                                            return node;
                                        }))
                                        setLoad(!load)
                                    } else {
                                        console.log(response)
                                    }
                                    // toast.success(response.data.message)
                                })
                            } catch (error) {
                                console.log(error)
                            }
                        } else {
                            console.log(response)
                        }
                        // toast.success(response.data.message)
                    })
                } catch (error) {
                    console.log(error)
                    // throw new Error('Failed to fetch day from API');
                }
            }


        } else {
            showError("There should be atleast one Button")
        }
    }


    const addParent = () => {
        const newCount = btnCount + 1
        if (newCount <= 10) {
            append({ title: '', rows: [{ row_title: '', row_description: '' }] })
            setBtnCount(newCount);
        } else {
            showError("You Cannot add button more than 10")
        }
    };

    const removeParent = (parentIndex) => {
        const newCount = btnCount - fields[parentIndex].rows.length
        if (newCount != 0) {
            remove(parentIndex)
            setBtnCount(newCount);
        } else {
            showError("There should be atleast one Button")
        }
    };
    const addChild = (parentIndex) => {
        const newCount = btnCount + 1
        if (newCount <= 10) {
            fields[parentIndex].rows.push({ row_title: '', row_description: '' });
            setBtnCount(newCount);
        } else {
            showError("You Cannot add button more than 10")
        }
    };


    const removeChild = (parentIndex, childIndex) => {
        const newCount = btnCount - 1
        if (newCount != 0) {
            fields[parentIndex].rows.splice(childIndex, 1);
            setBtnCount(newCount);
        } else {
            showError("There should be atleast one Button")
        }
    };

    const errFun = (type, msg) => {
        console.log(fields)
        if (type == "body") {
            if (errors.body.type == "required") {
                showError("The Body field is required")
            } else if (errors.body.type == "maxLength") {
                return ("Max Length of Body Feild is 1000")
            }
        } else if (type == "template_name") {
            if (errors.template_name.type == "required") {
                showError("The Template Name is required")
            } else if (errors.template_name.type == "maxLength") {
                return ("Max Length of Template Name  Feild is 512")
            }
        }
        else if (type == "footer") {
            if (errors.footer.type == "maxLength") {
                return ("Max Length of Footer is 50")
            }
        } else if (type == "btn_title") {
            if (errors.btn_title.type == "required") {
                showError("The Butoon Title is required")
            } else if (errors.btn_title.type == "maxLength") {
                return ("Max Length of Button Title Feild is 20")
            }
        }

        clearErrors(type)
    }

    const newErrFun = (type, parent_index, msg) => {
        if (errors && errors.action) {
            if (errors.action[`${parent_index}`].title) {
                if (errors.action[`${parent_index}`].title.type == "required") {

                    return (<div className="meg_lat_new">{msg}</div>)

                } else if (errors.action[`${parent_index}`].title.type == "maxLength") {
                    return ("Max Length of Section Title Feild is 20")
                }
            }
        }
    }
    const newErrRowFun = (type, parent_index, child_index, msg) => {
        if (errors && errors.action && errors.action[`${parent_index}`]) {
            const rowErr = errors.action[`${parent_index}`].rows
            const rowFeild = fields[`${parent_index}`].rows

            if (rowErr) {
                if (rowErr[`${child_index}`].row_title && type == "row_title") {
                    if (rowErr[`${child_index}`].row_title.type == "required") {
                        return (<div className="meg_lat_new">{msg}</div>)
                    } else if (rowErr[`${child_index}`].row_title.type == "maxLength") {
                        return (<div className="meg_lat_new"> Max Length of Option Title Feild is 20 </div>)

                    }
                } else if (rowErr[`${child_index}`].row_description && type == "row_description") {
                    if (rowErr[`${child_index}`].row_description.type == "required") {
                        return (<div className="meg_lat_new">{msg}</div>)
                    } else if (rowErr[`${child_index}`].row_description.type == "maxLength") {
                        return (<div className="meg_lat_new"> Max Length of Option Description Feild is 70 </div>)
                    }
                }
            }
        }
    }





    const SectionTitle = ({ parentIndex }) => {
        const [charCount, setCharCount] = useState(0);
        const handleChange = (e) => {
            setCharCount(e.target.value.length);
        };

        return (
            <div>
                {newErrFun("title", parentIndex, "Section Title is required")}
                <input
                    maxLength={20} 
                    className='form-control myinputcss' placeholder='Section Title'
                    {...register(`action[${parentIndex}].title`, { required: true, maxLength: 20,


                        onChange: (e) => setCharCount(e.target.value.length)
                     })}
                />
                <p className="cer_cont">{20 - charCount} / 20 </p>

            </div>
        )
    }


    const RowBox = ({ parentIndex, childIndex }) => {
        const [charCount, setCharCount] = useState(0);
        const [titleCount, setTitleCount] = useState(0);
        const handleChange = (e) => {
            setCharCount(e.target.value.length);
        };


        const titleChange = (e) => {
            setTitleCount(e.target.value.length);
        };


        return (
            <div>

                {newErrRowFun("row_title", parentIndex, childIndex, "Option Title is required")}

                <input maxLength={20} className='form-control myinputcss mb-1' placeholder='Option Title'
                    {...register(`action[${parentIndex}].rows[${childIndex}].row_title`, { required: true, maxLength: 20 })}

                    onChange={handleChange}
                />
                <p className="cer_cont">{20 - charCount} / 20</p>

                {newErrRowFun("row_description", parentIndex, childIndex, "Option Description is required")}
                <textarea maxLength={70} className='form-control myinputcss mb-1' placeholder='Option Description'
                    {...register(`action[${parentIndex}].rows[${childIndex}].row_description`, { required: true, maxLength: 70 })}
                    onChange={titleChange}
                />
                <p className="cer_cont">{70 - titleCount} / 70</p>

            </div>
        )
    }
    const gettingLen = (type) => {

        if (type == "template_name") {
            return tempCount
        } else if (type == "body") {
            return bodyCount
        } else if (type == "footer") {
            return footerCount
        } else if (type == "btn_title") {
            return btnTitleCount
        }

    }


    const settingLen = (type, e) => {
        if (type == "template_name") {
            setTempCount(e.target.value.length)
        } else if (type == "body") {
            setBodyCount(e.target.value.length)
        } else if (type == "footer") {
            setFooterCount(e.target.value.length)
        } else if (type == "btn_title") {
            setBtnTitleCount(e.target.value.length)
        }





    }
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <input {...register("nodeId", { required: true })} value={nodeId} type='hidden' />
                <input {...register("waInterType", { required: true })} value="button" type='hidden' />
                <input {...register("is_variable", { required: true })} value="0" type='hidden' />

                <div className="header_teml">
                    <label className="header_heading"><i className="fa-regular fa-address-card"></i> Tempalte Name </label>
                    <div className="header_enter_text_box">
                        <input maxLength={512} {...register("template_name", { required: true, onChange: (e) => settingLen("template_name", e) })} className="form-control" placeholder="Enter Template Name " />
                        {errors.template_name && errFun("template_name", "Template Name is required")}
                    </div>
                    <p className="cer_cont">{512 - gettingLen("template_name")} / 512 </p>
                </div>

                <div className="header_teml">
                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                    <div className="header_enter_text_box">
                        <input {...register("header", {
                            onChange: (e) => setHeaderCount(e.target.value.length)
                        })} className="form-control" placeholder="Enter Header" />
                        <div className="text-right"> <button className="btn btn-sm ad_var_btn" type='button' onClick={(e) => addHeaderVar()} ><i className="fa fa-plus"></i> Var </button></div>
                    </div>
                    <p className="cer_cont">{headerCount} </p>
                </div>

                <div className="body_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>



                    <div className="body_enter_text_box">
                        <textarea maxLength={1000} {...register("body", { required: true, maxLength: 1000, onChange: (e) => setBodyCount(e.target.value.length) })} className="form-control" placeholder="Enter Body" />
                        <div className="text-right"> <button className="btn btn-sm ad_var_btn" type='button' onClick={(e) => addBodyVar()} > <i className="fa fa-plus"></i> Var</button> </div>
                        {errors.body && errFun("body", "Body field is required")}
                    </div>

                    <p className="cer_cont">{1000 - bodyCount} / 1000 </p>

                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                    <div className="footer_enter_text_box">
                        <input maxLength={50} {...register("footer", { maxLength: 50, onChange: (e) => settingLen("footer", e) })} className="form-control" placeholder="Enter Footer" />
                    </div>
                    <p className="cer_cont">{50 - gettingLen("footer")} / 50 </p>

                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Button Title</label>
                    <div className="footer_enter_text_box">
                        <input maxLength={20} {...register("btn_title", {
                            required: true, maxLength: 20,
                            onChange: (e) => settingLen("btn_title", e)
                        })} className="form-control" placeholder="Enter Button Title" />
                        {errors.btn_title && errFun("btn_title", "Button Title is required")}
                    </div>
                    <p className="cer_cont">{20 - gettingLen("btn_title")} / 20 </p>

                </div>

                {fields.map((parent, parentIndex) => (
                    <div className="card-body mt-0 p-2 " key={parent.id}>
                        <div className="fst_div_box ">
                            <div className="new_cro1">
                                <button type="button" onClick={() => removeParent(parentIndex)} className="btn-danger-color ">
                                    <i className="fa-regular fa-circle-xmark"></i>
                                </button>
                            </div>


                            <SectionTitle parentIndex={parentIndex} />
                            {parent.rows.map((child, childIndex) => (
                                <div className="mb-3" key={`${parent.id}-${childIndex}`}>
                                    <div className="one_div_box_new_add ">
                                        <div className="new_cro2">
                                            <button
                                                type="button"
                                                onClick={() => removeChild(parentIndex, childIndex)}
                                                className="btn-danger-color  new_cro3">
                                                <i className="fa-regular fa-circle-xmark"></i>
                                            </button>
                                        </div>

                                        <RowBox parentIndex={parentIndex} childIndex={childIndex} />

                                    </div>
                                </div>
                            ))}
                            <div className='text-center'>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-success new_cro4"
                                    onClick={() => addChild(parentIndex)}
                                >Add Rows</button>
                            </div>
                        </div>
                    </div>
                ))}




                <div className="line_ashish"> </div>

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="text-center">
                            <button className="btn btn-success btn-full" type='submit'>


                                {loading ? "Saving..." : "Save"}


                            </button>
                        </div>
                    </div>
                </div>

                {loading && <div className="loader">Loading...</div>}



                <div className="Button_teml">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <div className="top_ashish_mrg2">
                                    <button className="Button_div_box_plu " type="button" onClick={() => addParent()}>
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>







            </form>

        </div>
    )

}